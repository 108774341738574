import Cookies from "js-cookie";
import { BaseClient } from "../base_client";
import { UserInfoProvider } from "../user_info";
import { User } from "../../models/auth";

const TOKEN_LOCAL_STORAGE_ITEM_NAME = "quartz_token";

export class SimpleAuthenticationService {
  constructor(
    private readonly quartzClient: AuthenticationQuartzClient,
    private readonly state: any,
    private readonly setState: (nextState: any) => void
  ) {}

  static getInitialState(): any {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_ITEM_NAME);
    if (token !== null) {
      Cookies.set("bearer-token", token, {
        domain: window.CONFIG.cookieDomain ?? undefined,
      });
      return { isAuthenticated: true };
    }
    return { isAuthenticated: false };
  }

  async login(username: string, password: string): Promise<void> {
    // const token = Buffer.from(`${username}:${password}`, "utf-8").toString("base64");
    const token = btoa(`${username}:${password}`);
    const quartzClient = new AuthenticationQuartzClient(
      window.CONFIG.backendUrl,
      async () => `Bearer ${token}`
    );
    try {
      await quartzClient.getUserInfo();
    } catch (err) {
      localStorage.removeItem(TOKEN_LOCAL_STORAGE_ITEM_NAME);
      this.setState({ isAuthenticated: false });
      throw err;
    }
    localStorage.setItem(TOKEN_LOCAL_STORAGE_ITEM_NAME, token);
    Cookies.set("bearer-token", token, {
      domain: window.CONFIG.cookieDomain ?? undefined,
    });
    this.setState({ isAuthenticated: true });
  }

  logout(): void {
    localStorage.removeItem(TOKEN_LOCAL_STORAGE_ITEM_NAME);
    this.setState({ isAuthenticated: false });
  }

  async getUserInfo(): Promise<User> {
    return this.quartzClient.getUserInfo();
  }

  isAuthenticated(): boolean {
    return localStorage.getItem(TOKEN_LOCAL_STORAGE_ITEM_NAME) !== null;
  }

  getAccessToken(): string {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_ITEM_NAME);
    if (token === null) {
      throw new Error("not authenticated");
    }
    return token;
  }

  static async authorizationHeader(): Promise<string | null> {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_ITEM_NAME);
    if (token === null) {
      return null;
    }
    return `Bearer ${token}`;
  }
}

export class AuthenticationQuartzClient extends BaseClient {
  async getUserInfo(controller?: AbortController): Promise<User> {
    return this.httpGet("/auth/user_info", controller);
  }
}

export class SimpleUserInfoProvider implements UserInfoProvider {
  constructor(private readonly service: SimpleAuthenticationService) {}

  async getUser(): Promise<User | null> {
    if (!this.isAuthenticated()) {
      return null;
    }
    try {
      return this.service.getUserInfo();
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  isAuthenticated(): boolean {
    return this.service.isAuthenticated();
  }

  async getAccessToken(): Promise<string> {
    return this.service.getAccessToken();
  }
}
