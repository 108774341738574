import classNames from "classnames";
import { AsYouType } from "libphonenumber-js";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { IdentityProviderClientContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";

type FormValues = {
  phoneNumber: string;
};

export default function MFAConfigurationSMS({
  onClose,
  onNext,
}: {
  onClose: (submitted: boolean) => void;
  onNext: () => void;
}) {
  const identityProviderClient = useContext(IdentityProviderClientContext);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();
  const [serverError, setServerError] = useState<string | null>(null);

  const onSubmit = handleSubmit(async (data) => {
    const stdPhoneNumber = checkPhoneFormat(data.phoneNumber);
    if (stdPhoneNumber === undefined) {
      setError("phoneNumber", { type: "validate", message: "Invalid phone number format" });
      return;
    }
    try {
      await identityProviderClient.setSMSMFA(stdPhoneNumber);
    } catch (err: any) {
      if (err.code === "LimitExceededException") {
        setServerError("Too many requests. Please try again later.");
        return;
      }
      throw err;
    }
    onNext();
  });

  const checkPhoneFormat = (phoneNumber: string) => {
    // Default country for the phone number is France
    const asYouType = new AsYouType({ defaultCountry: "FR" });
    phoneNumber = asYouType.input(phoneNumber);
    const numberValue = asYouType.getNumberValue();
    if (!numberValue) return undefined;
    if (asYouType.isValid() === true) return numberValue;
    return undefined;
  };

  return (
    <div style={{ maxWidth: "500px", padding: "2rem" }}>
      <div className="content">
        <h1>Set up SMS MFA</h1>
        <p>
          With SMS MFA, we send a code to your phone number to verify your identity when you log in.
        </p>
      </div>
      <form onSubmit={onSubmit}>
        {serverError && <SnackbarNotification text={serverError} color="danger" />}
        <div className="block">
          <div className="field">
            <label className="label">Your phone number</label>
            <p className="help">Format: +33 X XX XX XX XX</p>
            <p className="control">
              <input
                className={errors.phoneNumber ? "is-danger input" : "input"}
                {...register("phoneNumber", {
                  required: "Field required.",
                })}
                placeholder="Enter your phone number"
              />
            </p>
            {errors.phoneNumber && <p className="help is-danger">{errors.phoneNumber?.message}</p>}
          </div>
        </div>
        <div className="block">
          <button
            className={classNames("button is-info", isSubmitting && "is-loading")}
            type="submit"
          >
            Send code
          </button>
        </div>
      </form>
    </div>
  );
}
