import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { OmopCoding } from "../../models/structuration";
import { getDomainColor } from "../../utils/fact";
import ListItem from "@mui/joy/ListItem";
import List from "@mui/joy/List";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemButton from "@mui/joy/ListItemButton";

export const Dropdown = React.forwardRef<
  HTMLDivElement,
  {
    isListVisible: boolean;
    codings: OmopCoding[];
    onCodingSelection: (coding: OmopCoding) => void;
  }
>(({ isListVisible = false, codings, onCodingSelection }, ref) => {
  if (!isListVisible) {
    return null;
  }
  return (
    <div className="is-active" ref={ref}>
      <List
        variant="outlined"
        sx={{
          maxHeight: "300px",
          width: "100%",
          overflow: "auto",
          zIndex: "999",
          borderRadius: "sm",
          mt: 1,
        }}
      >
        {codings.map((coding, key) => {
          return (
            <ListItem
              key={key}
              onClick={() => {
                onCodingSelection(coding);
              }}
            >
              <ListItemButton>
                <ListItemContent>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Box>
                      <Typography component="span" level="title-sm">
                        {coding.name}
                      </Typography>
                      <Typography level="body-xs">
                        {coding.vocabulary}: {coding.code}
                      </Typography>
                    </Box>
                    <Chip
                      size="sm"
                      variant="outlined"
                      sx={{
                        ml: "auto",
                        borderRadius: "2px",
                        minHeight: "20px",
                        paddingInline: "4px",
                        fontSize: "xs",
                        borderColor: `${getDomainColor(coding.domain) + 60}`,
                        bgcolor: `${getDomainColor(coding.domain) + 60}`,
                      }}
                    >
                      {coding.class_name}
                    </Chip>
                  </Stack>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          );
        })}
        {!codings.length && (
          <div className="dropdown-item">
            <p>No concept found.</p>
          </div>
        )}
      </List>
    </div>
  );
});
