import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Option, Select, Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import Checkbox from "@mui/joy/Checkbox";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import { PickersActionBarProps, pickersLayoutClasses } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import React from "react";
import {
  ApproximateTime,
  ExactTime,
  RelativeTime,
  TemporalPrecision,
  TimeInterval,
  TimeType,
} from "../../../../models/human_time";
import { isApproximateTime, isRelativeTime, isTimeInterval } from "../../../../utils/fact";
import {
  generateApproximateTime,
  generateNoteRelativeTime,
  generateTimeInterval,
  timeToDayjs,
} from "../../../../utils/time";
import { JoyDatePicker } from "./JoyDatePicker";

type DateRangeProps = {
  date?: ApproximateTime | ExactTime | RelativeTime | TimeInterval | null;
  note_datetime?: Dayjs | null;
  onDateChange: (factDate: ApproximateTime | ExactTime | RelativeTime | TimeInterval) => void;
};

export function ComplexDatePicker({ date, note_datetime, onDateChange }: DateRangeProps) {
  const [isDateRange, setIsDateRange] = React.useState(isTimeInterval(date));
  const [isStartRelativeToNoteDate, setIsStartRelativeToNoteDate] = React.useState(
    isTimeInterval(date) && (date as TimeInterval).begin.type === TimeType.RELATIVE
  );
  const [isEndRelativeToNoteDate, setIsEndRelativeToNoteDate] = React.useState(
    isTimeInterval(date) && (date as TimeInterval).end.type === TimeType.RELATIVE
  );
  const [startDate, setStartDate] = React.useState<Dayjs | null>(() => {
    // if it's a date range, we need to set the start date to the start of the interval, and check if it's relative to note date
    if (isDateRange) {
      if ((date as TimeInterval).begin.type === TimeType.RELATIVE) {
        setIsStartRelativeToNoteDate(true);
        return note_datetime!;
      } else {
        return timeToDayjs((date as TimeInterval).begin);
      }
    } else {
      // if not a date range, we just set the start date
      return timeToDayjs(date);
    }
  });
  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    isTimeInterval(date)
      ? (date as TimeInterval).end.type === TimeType.RELATIVE
        ? note_datetime!
        : timeToDayjs((date as TimeInterval).end)
      : null
  );
  const [precision, setPrecision] = React.useState<TemporalPrecision | null>(
    // if it's a relative time, we need to set the precision to day
    isTimeInterval(date)
      ? isApproximateTime((date as TimeInterval).begin)
        ? ((date as TimeInterval).begin as ApproximateTime).precision
        : isRelativeTime((date as TimeInterval).begin)
          ? TemporalPrecision.DAY
          : null
      : isApproximateTime(date)
        ? (date as ApproximateTime).precision || null
        : isRelativeTime(date)
          ? TemporalPrecision.DAY
          : null
  );

  const handleAddEndDate = () => {
    setIsDateRange(true);
    setEndDate(startDate);
    setIsEndRelativeToNoteDate(false);
  };

  const handleRemoveEndDate = async () => {
    await handleDateChange(startDate, null, isStartRelativeToNoteDate, false, false);
    setIsDateRange(false);
    setEndDate(null);
    setIsEndRelativeToNoteDate(false);
  };

  const handleDateChange = async (
    newStartDate: Dayjs | null,
    newEndDate: Dayjs | null,
    startRelative: boolean,
    endRelative: boolean,
    isInterval: boolean = isDateRange,
    newPrecision: TemporalPrecision = precision || TemporalPrecision.DAY
  ) => {
    // a bit special here, because if it is a date range, this becomes and interval and we need to handle both start or end date
    // possible relation to note date
    if (isInterval) {
      // if it's date range, we need to update both start and end date, and generate an interval with possible relation to note date for both
      onDateChange(
        generateTimeInterval(
          startRelative
            ? generateNoteRelativeTime()
            : generateApproximateTime(newStartDate, newPrecision),
          endRelative
            ? generateNoteRelativeTime()
            : generateApproximateTime(newEndDate, newPrecision)
        )
      );
    } else {
      // if not a date range, we just update the start date with precision
      onDateChange(generateApproximateTime(newStartDate, newPrecision));
    }
  };

  function CustomActionBarStartDate(props: PickersActionBarProps) {
    const { className } = props;
    return (
      // Propagate the className such that CSS selectors can be applied
      <Box className={className} sx={{ px: 2, py: 1, borderBottom: 1, borderColor: "divider" }}>
        <Stack direction="column" spacing={1}>
          <Checkbox
            disabled={isEndRelativeToNoteDate}
            checked={isStartRelativeToNoteDate}
            onChange={(e) => {
              setStartDate(note_datetime!);
              setIsStartRelativeToNoteDate(!isStartRelativeToNoteDate);
              if (e.target.checked) {
                setIsEndRelativeToNoteDate(false);
              }
              handleDateChange(note_datetime!, endDate, true, false);
            }}
            label="Relative to note's date"
          />
        </Stack>
      </Box>
    );
  }
  function CustomActionBarEndDate(props: PickersActionBarProps) {
    const { className } = props;
    return (
      // Propagate the className such that CSS selectors can be applied
      <Box className={className} sx={{ px: 2, py: 1, borderBottom: 1, borderColor: "divider" }}>
        <Stack direction="column" spacing={1}>
          <Checkbox
            disabled={isStartRelativeToNoteDate}
            checked={isEndRelativeToNoteDate}
            onChange={(e) => {
              setEndDate(note_datetime!);
              setIsEndRelativeToNoteDate(!isEndRelativeToNoteDate);
              if (e.target.checked) {
                setIsStartRelativeToNoteDate(false);
              }
              handleDateChange(startDate, note_datetime!, false, true);
            }}
            label="Relative to note's date"
          />
        </Stack>
      </Box>
    );
  }

  if (isDateRange) {
    return (
      <Box sx={{ mb: 2, mt: 2 }}>
        <Box sx={{ mt: 1, mb: 1 }}>
          <Typography sx={{ mb: 0.75 }} level="title-sm">
            Precision
          </Typography>
          <Select
            sx={{ width: "100px" }}
            value={precision}
            onChange={(e, value) => {
              setPrecision(value);
              handleDateChange(
                startDate,
                endDate,
                isStartRelativeToNoteDate,
                isEndRelativeToNoteDate,
                true,
                value!
              );
            }}
            defaultValue={TemporalPrecision.DAY}
            disabled={isStartRelativeToNoteDate}
          >
            <Option value={TemporalPrecision.DAY}>Day</Option>
            <Option value={TemporalPrecision.MONTH}>Month</Option>
            <Option value={TemporalPrecision.YEAR}>Year</Option>
          </Select>
        </Box>
        <Stack direction="row" spacing={1}>
          <Box sx={{ width: "calc(50% - 10px - 16px)" }}>
            <JoyDatePicker
              label="Start date"
              value={startDate}
              onChange={(dayjs) =>
                handleDateChange(dayjs, endDate, isStartRelativeToNoteDate, isEndRelativeToNoteDate)
              }
              maxDate={endDate || undefined}
              slots={{
                actionBar: CustomActionBarStartDate,
              }}
              views={
                precision
                  ? precision === TemporalPrecision.DAY
                    ? ["year", "month", "day"]
                    : precision === TemporalPrecision.MONTH
                      ? ["year", "month"]
                      : precision === TemporalPrecision.YEAR
                        ? ["year"]
                        : ["year", "month", "day"]
                  : ["year", "month", "day"]
              }
              format={
                precision
                  ? precision === TemporalPrecision.DAY
                    ? "DD MMM YYYY"
                    : precision === TemporalPrecision.MONTH
                      ? "MMM YYYY"
                      : precision === TemporalPrecision.YEAR
                        ? "YYYY"
                        : "DD MMM YYYY"
                  : "DD MMM YYYY"
              }
              slotProps={{
                layout: {
                  sx: {
                    [`.${pickersLayoutClasses.actionBar}`]: {
                      gridColumn: 2,
                      gridRow: 1,
                    },
                    [`.${pickersLayoutClasses.contentWrapper}`]: {
                      transition: "all .3s ease-out",
                      opacity: isStartRelativeToNoteDate ? 0.3 : 1,
                      pointerEvents: isStartRelativeToNoteDate ? "none" : "initial",
                    },
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ width: "calc(50% - 10px - 16px)" }}>
            <JoyDatePicker
              label="End date"
              value={endDate}
              onChange={(dayjs) =>
                handleDateChange(
                  startDate,
                  dayjs,
                  isStartRelativeToNoteDate,
                  isEndRelativeToNoteDate
                )
              }
              minDate={startDate || undefined}
              slots={{
                actionBar: CustomActionBarEndDate,
              }}
              views={
                precision
                  ? precision === TemporalPrecision.DAY
                    ? ["year", "month", "day"]
                    : precision === TemporalPrecision.MONTH
                      ? ["year", "month"]
                      : precision === TemporalPrecision.YEAR
                        ? ["year"]
                        : ["year", "month", "day"]
                  : ["year", "month", "day"]
              }
              format={
                precision
                  ? precision === TemporalPrecision.DAY
                    ? "DD MMM YYYY"
                    : precision === TemporalPrecision.MONTH
                      ? "MMM YYYY"
                      : precision === TemporalPrecision.YEAR
                        ? "YYYY"
                        : "DD MMM YYYY"
                  : "DD MMM YYYY"
              }
              slotProps={{
                layout: {
                  sx: {
                    [`.${pickersLayoutClasses.actionBar}`]: {
                      gridColumn: 2,
                      gridRow: 1,
                    },
                    [`.${pickersLayoutClasses.contentWrapper}`]: {
                      transition: "all .3s ease-out",
                      opacity: isEndRelativeToNoteDate ? 0.3 : 1,
                      pointerEvents: isEndRelativeToNoteDate ? "none" : "initial",
                    },
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <IconButton
              sx={{ width: "32px", height: "32px" }}
              color="danger"
              onClick={handleRemoveEndDate}
            >
              <RemoveIcon />
            </IconButton>
          </Box>
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box sx={{ mb: 2, mt: 2, width: "100%" }}>
        <Stack sx={{ mt: 1 }} direction="row" spacing={1} alignItems="flex-end">
          <Box>
            <Typography sx={{ mb: 0.75 }} level="title-sm">
              Precision
            </Typography>
            <Select
              sx={{ width: "100px" }}
              value={precision}
              onChange={(e, value) => setPrecision(value)}
              defaultValue={TemporalPrecision.DAY}
            >
              <Option value={TemporalPrecision.DAY}>Day</Option>
              <Option value={TemporalPrecision.MONTH}>Month</Option>
              <Option value={TemporalPrecision.YEAR}>Year</Option>
            </Select>
          </Box>
          <Box sx={{ flex: 1 }}>
            <JoyDatePicker
              label="Date"
              value={startDate}
              onChange={(e) => handleDateChange(e, null, isStartRelativeToNoteDate, false)}
              orientation="portrait"
              views={
                precision
                  ? precision === TemporalPrecision.DAY
                    ? ["year", "month", "day"]
                    : precision === TemporalPrecision.MONTH
                      ? ["year", "month"]
                      : precision === TemporalPrecision.YEAR
                        ? ["year"]
                        : ["year", "month", "day"]
                  : ["year", "month", "day"]
              }
              format={
                precision
                  ? precision === TemporalPrecision.DAY
                    ? "DD MMM YYYY"
                    : precision === TemporalPrecision.MONTH
                      ? "MMM YYYY"
                      : precision === TemporalPrecision.YEAR
                        ? "YYYY"
                        : "DD MMM YYYY"
                  : "DD MMM YYYY"
              }
              sx={{ width: "100%" }}
            />
          </Box>
          <IconButton color="primary" onClick={handleAddEndDate}>
            <AddIcon />
          </IconButton>
        </Stack>
      </Box>
    );
  }
}
