import Typography from "@mui/joy/Typography";
import { MilliDelta } from "../../../../../../models/common";
import {
  ApproximateTime,
  ExactTime,
  QualitativeTimeDelta,
  QuantitativeTimeDelta,
  RelativeTime,
  TemporalPrecision,
  TimeInterval,
} from "../../../../../../models/human_time";
import { isApproximateTime, isRelativeTime, isTimeInterval } from "../../../../../../utils/fact";
import { timeToDayjs } from "../../../../../../utils/time";

function formatDependingOnPrecision(date: ApproximateTime | ExactTime | RelativeTime): string {
  return isApproximateTime(date)
    ? (date as ApproximateTime).precision === TemporalPrecision.YEAR
      ? "YYYY"
      : (date as ApproximateTime).precision === TemporalPrecision.MONTH
        ? "MMM YYYY"
        : "DD MMM YYYY"
    : "DD MMM YYYY";
}

function temporalPrecisionNiceName(delta: MilliDelta, precision: TemporalPrecision): string {
  switch (precision) {
    case TemporalPrecision.YEAR:
      return delta === 1 ? "year" : "years";
    case TemporalPrecision.MONTH:
      return delta === 1 ? "month" : "months";
    case TemporalPrecision.WEEK:
      return delta === 1 ? "week" : "weeks";
    case TemporalPrecision.DAY:
      return delta === 1 ? "day" : "days";
    case TemporalPrecision.HOUR:
      return delta === 1 ? "hour" : "hours";
    case TemporalPrecision.MINUTE:
      return delta === 1 ? "minute" : "minutes";
    case TemporalPrecision.SECOND:
      return delta === 1 ? "second" : "seconds";
    case TemporalPrecision.MILLI:
      return delta === 1 ? "millisecond" : "milliseconds";
    default:
      return "Unknown";
  }
}

function computeRelativeTimeDisplay(time: RelativeTime): string {
  if (typeof time.delta === "string") {
    return time.delta;
  } else {
    // then here it's quantitative
    const delta = time.delta as QuantitativeTimeDelta;
    const precision = delta.precision;
    const value = delta.value;
    if (value < 0) {
      return `${value * -1} ${temporalPrecisionNiceName(
        (value * -1) as MilliDelta,
        precision
      )} ago`;
    } else if (value > 0) {
      return `in ${value} ${temporalPrecisionNiceName(value as MilliDelta, precision)}`;
    } else {
      return "Present";
    }
  }
}

export default function FactPaperDateDisplay({
  factDate,
}: {
  factDate?: ApproximateTime | ExactTime | RelativeTime | TimeInterval | null;
}) {
  if (!factDate) {
    return null;
  } else {
    // If the fact is a time interval, we display the start and end dates separated by a dash
    // If it is a relative, we display the "related to note date" icon + precision
    // If it is an exact time, we display the date
    if (isTimeInterval(factDate)) {
      const startDate = isRelativeTime(factDate.begin)
        ? computeRelativeTimeDisplay(factDate.begin as RelativeTime)
        : timeToDayjs(factDate.begin)!.format(formatDependingOnPrecision(factDate.begin));
      const endDate = isRelativeTime(factDate.end)
        ? computeRelativeTimeDisplay(factDate.end as RelativeTime)
        : timeToDayjs(factDate.end)!.format(formatDependingOnPrecision(factDate.end));
      return (
        <Typography data-cy="fact-paper-date-display" level="body-sm">
          {startDate} — {endDate}
        </Typography>
      );
    } else if (isRelativeTime(factDate)) {
      return (
        <>
          {/*
          if delta is a number, it is quantitative, if it's text it's qualitative and we display other icons
          if delta is positive, it means the fact is in the future, we display the delta and precision after the icon
          otherwise it is displayed before
          */}
          {typeof factDate.delta === "object" ? (
            factDate.delta.value === 0 ? (
              <Typography data-cy="fact-paper-date-display" level="body-sm">
                Present
              </Typography>
            ) : (
              <div>
                {factDate.delta.value < 0 ? (
                  <Typography data-cy="fact-paper-date-display" level="body-sm">
                    {computeRelativeTimeDisplay(factDate as RelativeTime)}
                  </Typography>
                ) : (
                  <Typography data-cy="fact-paper-date-display" level="body-sm">
                    {computeRelativeTimeDisplay(factDate as RelativeTime)}
                  </Typography>
                )}
              </div>
            )
          ) : (
            <div>
              {(factDate.delta as QualitativeTimeDelta) === QualitativeTimeDelta.BEFORE ? (
                <Typography data-cy="fact-paper-date-display" level="body-sm">
                  Past
                </Typography>
              ) : (
                <Typography data-cy="fact-paper-date-display" level="body-sm">
                  Future
                </Typography>
              )}
            </div>
          )}
        </>
      );
    } else {
      const date = timeToDayjs(factDate);
      return (
        <Typography data-cy="fact-paper-date-display" level="body-sm">
          {date && date.format(formatDependingOnPrecision(factDate))}
        </Typography>
      );
    }
  }
}
