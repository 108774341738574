import { useContext, useState } from "react";
import { AccountLifecycleQuartzClientContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";
import { Loading } from "../../common/loading/Loading";
import AlertDialogModal from "../../common/modals/AlertDialogModal";

export const UserProfileAccountLifecycle: React.FC<{}> = () => {
  const accountLifecycleQuartzClient = useContext(AccountLifecycleQuartzClientContext);

  const [loading, setLoading] = useState(false);
  const [openDeleteData, setOpenDeleteData] = useState(false);
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
  const [serverError, setServerError] = useState<string | null>("");
  const handleDeleteData = async () => {
    setLoading(true);
    try {
      await accountLifecycleQuartzClient.initiateDataDeletionCountdown();
    } catch (e: any) {
      setServerError("Data deletion request failed.");
      setLoading(false);
      return;
    }
    setOpenDeleteData(false);
    setLoading(false);
  };

  const handleDeleteAccount = async () => {
    await accountLifecycleQuartzClient.initiateAccountDeletionCountdown();
  };

  if (loading) {
    return (
      <>
        <Loading message={"Deleting data ..."} />
      </>
    );
  }
  return (
    <>
      <AlertDialogModal
        alertMessage="Are you sure you want to delete all the data? All data will be deleted within 7 days."
        open={openDeleteData}
        setOpen={setOpenDeleteData}
        handleDelete={handleDeleteData}
      />
      <AlertDialogModal
        alertMessage="Are you sure you want to delete this account? You will be logout and lost access to your account. All data will be deleted within 7 days."
        open={openDeleteAccount}
        setOpen={setOpenDeleteAccount}
        handleDelete={handleDeleteAccount}
      />
      <h2>Delete data</h2>
      <hr />
      <p>Remove all data including documents, notes, cohorts, and results from your account.</p>
      <p>
        <strong>All data will be deleted within 7 days.</strong>
      </p>
      <button
        className="button is-danger"
        onClick={() => {
          setOpenDeleteData(true);
        }}
      >
        Delete data
      </button>
      <h2>Delete account</h2>
      <hr />
      <p>Delete your account and all linked data.</p>
      <p>
        <strong>
          You will be logout and lost access to your account. All data will be deleted within 7
          days.
        </strong>
      </p>
      <button
        className="button is-danger"
        onClick={() => {
          setOpenDeleteAccount(true);
        }}
      >
        Delete your account
      </button>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
    </>
  );
};
