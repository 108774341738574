import { Fact } from "../../models/fact";
import { ApproximateTime } from "../../models/human_time";
import { Person, PersonId } from "../../models/person";
import { Coding } from "../../models/structuration";
import { isApproximateTime } from "../../utils/fact";

export const fillAttributes = (facts: Fact[]) => {
  let given_name = "";
  let family_name = "";
  let birth_date: ApproximateTime | null = null;
  let gender = "Male";
  let deceased = false;
  let deceased_date: ApproximateTime | null = null;

  facts.forEach((fact) => {
    const coding: Coding[] = fact.code.coding;
    if (coding[0].code === "FAMILY_NAME") {
      if (typeof fact.value === "string") family_name = fact.value;
    }

    if (coding[0].code === "GIVEN_NAME") {
      if (typeof fact.value === "string") given_name = fact.value;
    }
    if (coding[0].code === "BIRTH_DATE") {
      if (isApproximateTime(fact.time)) {
        birth_date = fact.time;
      }
    }
    if (coding[0].code === "4306655") {
      deceased = true;
      if (deceased) deceased_date = isApproximateTime(fact.time) ? fact.time : null;
    }
    if (coding[0].code === "GENDER") {
      if (typeof fact.value === "string") gender = fact.value;
    }
  });

  return {
    person_id: "" as PersonId,
    family_name: family_name,
    given_name: given_name,
    birth_date: birth_date,
    gender: gender,
    deceased: deceased,
    deceased_date: deceased_date,
    external_ids: [],
    consent_withdrawal_date: null,
  } as Person;
};
