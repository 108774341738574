import AttachmentIcon from "@mui/icons-material/Attachment";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { Annotation, Fact } from "../../../../../../models/fact";
import { CodeableConcept, Domain, Quantity } from "../../../../../../models/structuration";
import {
  getCode,
  getDisplay,
  getDomainColor,
  instanceOfCodeableConcept,
  instanceOfQuantity,
} from "../../../../../../utils/fact";
import { useNote } from "../../../../lib/context";
import EditionBox from "../EditionBox";
import FactPaperInfosDisplay from "./FactPaperInfosDisplay";
import FactValueEdit from "./FactValueEdit";

type FactPaperPros = {
  fact: Fact;
  domain: string;
  isFactEditable?: boolean; // Check if the fact is editable
  displayAnnotations?: boolean;
  onRemove: () => void; // Callback for fact removal
  onValueChange: (newValue: Quantity | CodeableConcept | null) => void; // Callback for value change
  onCodeChange: (newCode: CodeableConcept) => void; // Callback for code change
  onRemoveAnnovation: (offset: number) => void;
};

function isScrolledIntoView(el: any) {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  // Only completely visible elements return true:
  const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

export function FactPaper({
  fact,
  domain,
  isFactEditable,
  displayAnnotations,
  onRemove,
  onValueChange,
  onCodeChange,
  onRemoveAnnovation,
}: FactPaperPros) {
  const { selectedFact, selectedLabel, setHoveredFact, hoveredFact } = useNote();
  const [isSelfHover, setIsSelfHover] = React.useState<boolean>(false);
  const ref = React.useRef<any>(null);
  const display = getDisplay(fact.code);
  const code = getCode(fact.code);
  const value = fact.value;

  // If a fact is selected, remove the fact
  // If a label is selected, remove the label in the fact
  const handleRemoveFact = () => {
    if (selectedFact && onRemove) {
      onRemove();
    } else if (selectedLabel) {
      onRemoveAnnovation(selectedLabel.annotation.offset);
    }
  };

  const goToAthenaPage = (e: any, code: string | null) => {
    e.stopPropagation();
    if (code) {
      window.open("https://athena.ohdsi.org/search-terms/terms/" + code, "_blank")?.focus();
    }
  };

  const handleAeChange = (isAe: boolean, previousDomain?: Domain) => {
    const code: CodeableConcept | null = fact.code;
    let newCode = code;
    if (isAe) {
      newCode.coding[0].domain = "adverse-event";
    } else {
      if (!previousDomain) {
        newCode.coding[0].domain = "condition";
      } else {
        newCode.coding[0].domain = previousDomain;
      }
    }
    onCodeChange(newCode);
  };

  React.useEffect(() => {
    if (ref && !isSelfHover && hoveredFact === fact && !isScrolledIntoView(ref.current)) {
      ref.current!.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }, [hoveredFact, isSelfHover, ref, fact]);

  return (
    <>
      <Box
        data-cy="fact-paper"
        className="fact-paper"
        ref={ref}
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          cursor: "pointer",
          backgroundColor: getDomainColor(domain) + 40, // + 40 gives opacity
          scrollMarginTop: "150px",
          borderRadius: "sm",
          transition: "transform .2s ease-out",
          "&:hover": {
            transform: !isFactEditable ? "scale(1.03)" : "initial",
          },
          ...(hoveredFact === fact && {
            transform: !isFactEditable ? "scale(1.03)" : "initial",
          }),
        }}
        onMouseEnter={() => {
          if (!isFactEditable) {
            setHoveredFact(fact);
            setIsSelfHover(true);
          }
        }}
        onMouseLeave={() => {
          if (!isFactEditable) {
            setHoveredFact(null);
            setIsSelfHover(false);
          }
        }}
      >
        <div style={{ flex: 1, maxWidth: "100%" }}>
          <Box
            sx={{
              borderRadius: "sm",
              background: getDomainColor(domain) + 60,
              px: 2,
              ...(hoveredFact === fact && {
                backgroundColor: isFactEditable
                  ? getDomainColor(domain) + 60
                  : getDomainColor(domain) + 40,
              }),
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography
                level="title-lg"
                sx={{ fontSize: "14px", lineHeight: 1.5, my: 1, wordBreak: "break-word" }}
                data-cy="fact-paper-title"
                startDecorator={
                  <BookmarkRoundedIcon
                    fontSize="small"
                    sx={{ color: (theme) => theme.palette.grey[800] }}
                  />
                }
              >
                {display}
              </Typography>
              <Stack direction="row">
                {!["Document", "Patient"].includes(domain) && (
                  <Typography
                    sx={{
                      mr: isFactEditable ? 0.5 : -1,
                      opacity: 0.5,
                      transition: "opacity .2s ease-out",
                      "&:hover": { opacity: 0.8 },
                      lineHeight: 3,
                    }}
                    onClick={(e) => goToAthenaPage(e, code)}
                    level="body-xs"
                    data-cy="fact-paper-title-code"
                  >
                    {code}
                  </Typography>
                )}
                {isFactEditable ? (
                  <IconButton
                    data-cy="fact-paper-title-delete"
                    sx={{ mr: -2 }}
                    onClick={handleRemoveFact}
                    size="sm"
                    color="danger"
                  >
                    <ClearRoundedIcon sx={{ color: "white" }} />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          </Box>
          {isFactEditable ? (
            (value === null ||
              instanceOfCodeableConcept(value) ||
              instanceOfCodeableConcept(value) ||
              instanceOfQuantity(value)) && (
              <Box sx={{ flex: 1 }} data-cy="fact-paper-value">
                <FactValueEdit
                  code={code}
                  domain={domain}
                  value={value}
                  onChange={onValueChange}
                  onAeChange={(isAe, previousDomain) => handleAeChange(isAe, previousDomain)}
                />
              </Box>
            )
          ) : (
            <Box sx={{ flex: 1 }} data-cy="fact-paper-value">
              <FactPaperInfosDisplay fact={fact} />
            </Box>
          )}
        </div>
      </Box>
      {displayAnnotations && (
        <Box sx={{ mt: 2 }}>
          <EditionBox title="Related annotations" icon={<AttachmentIcon />}>
            <Stack
              gap={0.5}
              direction="row"
              sx={{ mt: 1 }}
              data-cy="related-anotations"
              flexWrap="wrap"
            >
              {fact.source.map((annotation: Annotation, index: number) => (
                <Button
                  key={"label" + index}
                  size="sm"
                  sx={{
                    background: getDomainColor(domain),
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: 4,
                    textTransform: "initial",
                    fontSize: "16px",
                    px: 1,
                    py: 0,
                    flexGrow: 0,
                    flexShrink: 0,
                    "&:hover": {
                      background: getDomainColor(domain) + 90,
                    },
                  }}
                  endDecorator={
                    <ClearRoundedIcon
                      data-cy="related-anotations-child-delete"
                      onClick={() => onRemoveAnnovation(annotation.offset)}
                      fontSize="small"
                    />
                  }
                >
                  {annotation.lexical_variant}
                </Button>
              ))}
            </Stack>
          </EditionBox>
        </Box>
      )}
    </>
  );
}
