import LayersIcon from "@mui/icons-material/Layers";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import React from "react";
import { StudyQuartzClientContext } from "../../clients/contexts";
import LoadingButton from "../../components/LoadingButton";
import { Study, StudyEventToState } from "../../models/study";
import { formatAndCapitalizeString } from "../../utils/text";
import { getStudyCurrentState } from "../StudiesPage";

export default function StudyStateCard({ study, refresh }: { study: Study; refresh: () => void }) {
  // Contexts
  const studyQuartzClient = React.useContext(StudyQuartzClientContext);

  // States
  const [openPutState, setOpenPutState] = React.useState(false);
  const [newEvent, setNewEvent] = React.useState<StudyEventToState | null>(null);
  const [reason, setReason] = React.useState<string | null>(null);
  const [nextEvents, setNextEvents] = React.useState<StudyEventToState[]>([]);
  const [sending, setSending] = React.useState(false);

  const currentStudyProgress = getStudyCurrentState(study);

  const putEvent = async () => {
    setSending(true);
    try {
      if (!newEvent) {
        throw new Error("No new event provided.");
      }
      await studyQuartzClient.putStudyEvent(study.study_id, {
        event: newEvent.event,
        reason: reason,
      });
    } catch (error) {
      console.error("Failed to put event:", error);
    }
    refresh();
  };

  const closeModal = () => {
    setOpenPutState(false);
    setNewEvent(null);
  };

  // Effects
  React.useEffect(() => {
    try {
      const getNextEvents = async () => {
        const response = await studyQuartzClient.getStudyNextEvents(study.study_id);
        setNextEvents(response.next_event);
      };
      getNextEvents();
    } catch (error: any) {
      console.error("Failed to get next events:", error);
    }
  }, [study, studyQuartzClient]);

  return (
    <>
      {openPutState && newEvent && (
        <Modal open onClose={() => closeModal()}>
          <ModalDialog sx={{ width: "500px" }}>
            <ModalClose />
            <DialogTitle>Confirm {formatAndCapitalizeString(newEvent.event)}</DialogTitle>
            <Stack direction="column" gap={2}>
              <Typography level="body-md">
                This will change the state of the study to{" "}
                <strong>{formatAndCapitalizeString(newEvent.state)}</strong>.
              </Typography>

              <FormControl>
                <FormLabel>Reason</FormLabel>
                <Textarea
                  value={reason ? reason : ""}
                  onChange={(event) => setReason(event.target.value)}
                  minRows={3}
                />
              </FormControl>
              {/* <FormControl>
                <JoyDatePicker
                  label="Event date"
                  value={unixToDayjs(startDate)}
                  onChange={(e) => e && handleChangeStartDate(e)}
                  format="DD MMM YYYY"
                  disableFuture
                />
              </FormControl> */}
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
              <Button color="primary" onClick={() => closeModal()}>
                Cancel
              </Button>
              <LoadingButton loading={sending} color="success" onClick={() => putEvent()}>
                Confirm
              </LoadingButton>
            </Box>
          </ModalDialog>
        </Modal>
      )}
      <Sheet sx={{ p: 2, borderRadius: "md" }} color="success" variant="soft">
        {currentStudyProgress && (
          <>
            <Stack direction="row" gap={2}>
              <div>
                <LayersIcon sx={{ fontSize: 28 }} />
              </div>
              <div>
                <Typography level="h3">
                  {formatAndCapitalizeString(currentStudyProgress.state)}
                </Typography>
              </div>
            </Stack>
            <Typography level="body-sm">{currentStudyProgress.reason}</Typography>
          </>
        )}
        {nextEvents.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Stack direction="column" justifyContent="flex-end" gap={1}>
              {nextEvents.map((event, index) => (
                <Button
                  variant="outlined"
                  color="neutral"
                  key={index}
                  onClick={() => {
                    setNewEvent(event);
                    setOpenPutState(true);
                  }}
                >
                  {formatAndCapitalizeString(event.event)}
                </Button>
              ))}
            </Stack>
          </Box>
        )}
      </Sheet>
    </>
  );
}
