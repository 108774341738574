import { RouteObject } from "react-router-dom";

import { Logout } from "./Logout";
import PSCLogin from "./PSCLogin";
import PasswordForgotten from "./PasswordForgotten";
import SignUp from "./SignUp";
import SignIn from "./Signin";
import ValidateEmail from "./ValidateEmail";

export const CLOUD_AUTH_ROUTES: RouteObject[] = [
  {
    path: "signin",
    element: <SignIn />,
  },
  {
    path: "validate-email",
    element: <ValidateEmail />,
  },
  {
    path: "signup",
    element: <SignUp />,
  },
  {
    path: "forgot-password",
    element: <PasswordForgotten />,
  },
  {
    path: "logout",
    element: <Logout />,
  },
  {
    path: "psc-login",
    element: <PSCLogin />,
  },
];
