import Key from "@mui/icons-material/Key";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { PutUserRequest } from "../models/auth";

interface EditUserFormProps {
  form: PutUserRequest;
  setForm: (form: PutUserRequest) => void;
  handleEditPasswordSubmit: () => void;
}

export default function EditPasswordForm({
  form,
  setForm,
  handleEditPasswordSubmit,
}: EditUserFormProps) {
  const [passwordValidation, setPasswordValidation] = React.useState<string>("");
  return (
    <form
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleEditPasswordSubmit();
      }}
    >
      <Stack spacing={2}>
        <FormControl>
          <FormLabel>New password</FormLabel>
          <Input
            type="password"
            startDecorator={<Key />}
            required
            value={form.credentials.password}
            onChange={(e) => {
              setForm({
                ...form,
                credentials: { user: form.credentials.user, password: e.target.value },
              });
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>New password (validation)</FormLabel>
          <Input
            type="password"
            startDecorator={<Key />}
            required
            onChange={(e) => setPasswordValidation(e.target.value)}
          />
        </FormControl>
        <Typography level="body-xs" color="danger">
          {form.credentials.password.length < 8 ? "Password must be at least 8 characters" : ""}
        </Typography>
        <Typography level="body-xs" color="danger">
          {form.credentials.password !== passwordValidation ? "Passwords do not match" : ""}
        </Typography>
        <Button type="submit">Submit</Button>
      </Stack>
    </form>
  );
}
