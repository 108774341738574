import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Auth } from "aws-amplify";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import logo from "../../assets/logo_transparent_background.png";

type FormValues = {
  email: string;
};

export default function PasswordForgottenPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = handleSubmit(async (data) => {
    try {
      await Auth.forgotPassword(data.email.trim());
    } catch (err: any) {
      if (err.code === "LimitExceededException") {
        enqueueSnackbar("Too many password reset emails sent. Please try again later.", {
          variant: "error",
        });
        return;
      }
      if (err.code === "UserNotFoundException") {
        enqueueSnackbar("Email address not found.", { variant: "error" });
        return;
      }
      throw err;
    }
    enqueueSnackbar("Password reset email sent.", { variant: "success" });
  });
  return (
    <section className="section">
      <div className="columns is-centered">
        <div className="column is-half is-one-third-desktop">
          <div className="content">
            <img src={logo} alt="logo" />
            <h1>Change your password</h1>
            <p>
              Forgot your password? Enter the email address you usually use to sign in to Gimli.
            </p>
          </div>
          <form onSubmit={onSubmit}>
            <div className="block">
              <div className="field">
                <label className="label">Email address</label>
                <p className="control has-icons-left has-icons-right">
                  <input
                    className={errors.email ? "is-danger input" : "input"}
                    {...register("email", { required: "Field required." })}
                    placeholder="Enter email address"
                  />
                  <span className="icon is-small is-left">
                    <MailOutlineIcon />
                  </span>
                </p>
                <p className="help is-danger">{errors.email?.message}</p>
              </div>
            </div>
            <div className="block">
              <button
                className={classNames("button is-info", isSubmitting && "is-loading")}
                type="submit"
              >
                Continue
              </button>
            </div>
          </form>
          <hr />
          <p>
            Already have an account? <Link to="/auth/signin">Sign in</Link>
          </p>
        </div>
      </div>
    </section>
  );
}
