import PhoneIcon from "@mui/icons-material/Phone";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { IdentityProviderClientContext } from "../../clients/contexts";
import { MFAType, User } from "../../models/auth";

export const MFAConfiguration: React.FC<{
  initiateConfiguration: (mfaType: MFAType) => void;
  user: User | null;
  refreshUser: () => void;
}> = ({ initiateConfiguration, user, refreshUser }) => {
  const auth = useContext(IdentityProviderClientContext);
  const { enqueueSnackbar } = useSnackbar();

  const disableMFA = async () => {
    await auth.disableMFA();
    enqueueSnackbar("Multiple Factor Authentication disabled", { variant: "success" });
    refreshUser();
  };

  return (
    <>
      <h2>Two-factor authentication</h2>
      <hr />
      <p>
        Two-factor authentication adds an additional layer of security to your account by requiring
        more than just a password to sign in.
      </p>
      {user != null && user.mfa_config == null && (
        <>
          <article className="message is-warning">
            <div className="message-body">
              Two-factor authentication is not set up. You can choose to receive an additional
              authentication code via SMS or via an authenticator app such as{" "}
              <a href="https://googleauthenticator.net/" target="_blank" rel="noreferrer">
                Google Authenticator
              </a>
              .
            </div>
          </article>
          <div className="buttons">
            <button
              className="button is-success"
              onClick={() => initiateConfiguration(MFAType.SMS_MFA)}
            >
              <span className="icon">
                <PhoneIcon />
              </span>
              <span>
                I want to use <strong>SMS</strong>
              </span>
            </button>
            <button
              className="button is-success"
              onClick={() => initiateConfiguration(MFAType.SOFTWARE_TOKEN_MFA)}
            >
              <span className="icon">
                <PhoneAndroidIcon />
              </span>
              <span>
                I want to use an <strong>authenticator app</strong>
              </span>
            </button>
          </div>
        </>
      )}
      {user != null && user.mfa_config?.type === MFAType.SMS_MFA && (
        <>
          <p>
            Two-factor authentication is set up: when you log in, you receive an additional
            authentication code via SMS.
          </p>
          <div className="buttons">
            <button className="button is-warning" onClick={disableMFA}>
              <span className="icon">
                <WarningAmberIcon />
              </span>
              <span>
                <strong>Disable</strong> two-factor authentication
              </span>
            </button>
            <button
              className="button is-success"
              onClick={() => initiateConfiguration(MFAType.SMS_MFA)}
            >
              <span className="icon">
                <PhoneIcon />
              </span>
              <span>
                <strong>Change</strong> phone number
              </span>
            </button>
            <button
              className="button is-success"
              onClick={() => initiateConfiguration(MFAType.SOFTWARE_TOKEN_MFA)}
            >
              <span className="icon">
                <PhoneAndroidIcon />
              </span>
              <span>
                I want to use an <strong>authenticator app</strong> instead
              </span>
            </button>
          </div>
        </>
      )}
      {user != null && user.mfa_config?.type === MFAType.SOFTWARE_TOKEN_MFA && (
        <>
          <p>
            Two-factor authentication is set up: when you log in, you receive an additional
            authentication code on an authenticator app.
          </p>
          <div className="buttons">
            <button className="button is-warning" onClick={disableMFA}>
              <span className="icon">
                <RemoveCircleOutlineIcon />
              </span>
              <span>
                <strong>Disable</strong> two-factor authentication
              </span>
            </button>
            <button
              className="button is-success"
              onClick={() => initiateConfiguration(MFAType.SOFTWARE_TOKEN_MFA)}
            >
              <span className="icon">
                <PhoneAndroidIcon />
              </span>
              <span>
                <strong>Reconfigure</strong> authentication via your authenticator app
              </span>
            </button>
            <button
              className="button is-success"
              onClick={() => initiateConfiguration(MFAType.SMS_MFA)}
            >
              <span className="icon">
                <PhoneIcon />
              </span>
              <span>
                I want to receive <strong>SMS</strong> instead
              </span>
            </button>
          </div>
        </>
      )}
    </>
  );
};
