import Stack from "@mui/joy/Stack";
import { Fact } from "../../../../../../models/fact";
import QualifierListDisplay from "../Qualifiers/QualifierListDisplay";
import FactPaperDateDisplay from "./FactPaperDateDisplay";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import { getValue } from "../../../../../../utils/fact";
import Typography from "@mui/joy/Typography";

export default function FactPaperInfosDisplay({ fact }: { fact: Fact }) {
  const valueDisplay = getValue(fact.value);

  return (
    <Stack sx={{ px: 2, pb: 1, mt: 1 }} direction="column" gap={1}>
      {!!valueDisplay && (
        <Typography sx={{ fontFamily: "monospace", mt: 1 }} fontWeight="normal" level="title-md">
          {valueDisplay}
        </Typography>
      )}
      {Object.values(fact.qualifier || {}).some((val) => !!val) && (
        // object can exist but all/some values are false, if there is any true value, display the qualifier
        <Stack direction="row" gap={0.5}>
          <BubbleChartIcon
            sx={{
              color: (theme: any) => theme.palette.text.secondary,
              fontSize: "16px",
              mt: 0.25,
            }}
          />
          <QualifierListDisplay qualifier={fact.qualifier} />
        </Stack>
      )}
      <Stack direction="row" gap={0.5}>
        <AccessTimeFilledIcon
          sx={{
            color: (theme: any) => theme.palette.text.secondary,
            fontSize: "16px",
            mt: 0.25,
          }}
        />
        <FactPaperDateDisplay factDate={fact.time} />
      </Stack>
    </Stack>
  );
}
