import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import React from "react";

interface AgeBoundariesModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleAgeBoundariesChange: (minAge: number, maxAge: number) => void;
  currentMinAge: number;
  currentMaxAge: number;
}

export default function AgeBoundariesModal({
  open,
  setOpen,
  handleAgeBoundariesChange,
  currentMinAge,
  currentMaxAge,
}: AgeBoundariesModalProps) {
  const [minAge, setMinAge] = React.useState<number>(currentMinAge);
  const [maxAge, setMaxAge] = React.useState<number>(currentMaxAge);
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog>
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleAgeBoundariesChange(minAge, maxAge);
            setOpen(false);
          }}
        >
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Minimum age at entry date</FormLabel>
              <Input
                value={minAge}
                onChange={(event) => setMinAge(Number(event.target.value))}
                autoFocus
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Maximum age at entry date</FormLabel>
              <Input
                value={maxAge}
                onChange={(event) => setMaxAge(Number(event.target.value))}
                autoFocus
                required
              />
            </FormControl>
            <Button type="submit">Save</Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}
