import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { SetPasswordStatus } from "../../clients/cloud/auth";
import { IdentityProviderClientContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";
import { unknownType } from "../../utils/assert";

type FormValues = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export function UserProfileChangePassword() {
  const auth = useContext(IdentityProviderClientContext);
  const [serverError, setServerError] = useState<string | null>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormValues>();
  const onSubmit = handleSubmit(async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setError("confirmPassword", { message: "Passwords do not match." });
      return;
    }
    let status: SetPasswordStatus;
    try {
      status = await auth.setPassword(data.oldPassword, data.newPassword);
    } catch (e) {
      setServerError("Cannot update password");
      return;
    }
    switch (status) {
      case SetPasswordStatus.Success:
        reset();
        setServerError("");
        break;
      case SetPasswordStatus.IncorrectOldPassword:
        setError("oldPassword", { message: "Incorrect password." });
        break;
      case SetPasswordStatus.IncorrectOldPasswordLimitExceeded:
        setError("oldPassword", { message: "Incorrect password." });
        setServerError("Incorrect password limit exceeded. Please try again later.");
        break;
      default:
        unknownType(status);
    }
  });
  return (
    <>
      <h2>Change password</h2>
      <hr />
      <form onSubmit={onSubmit}>
        {serverError && <SnackbarNotification text={serverError} color="danger" />}
        <div className="block">
          <div className="field">
            <label className="label">Old password</label>
            <p className="control">
              <input
                type="password"
                className={errors.oldPassword ? "is-danger input" : "input"}
                {...register("oldPassword", { required: "Field required." })}
              />
            </p>
            <p className="help is-danger">{errors.oldPassword?.message}</p>
          </div>
          <div className="field">
            <label className="label">New password</label>
            <p className="control">
              <input
                type="password"
                className={errors.newPassword ? "is-danger input" : "input"}
                {...register("newPassword", { required: "Field required." })}
              />
            </p>
            <p className="help is-danger">{errors.newPassword?.message}</p>
          </div>
          <div className="field">
            <label className="label">Confirm new password</label>
            <p className="control">
              <input
                type="password"
                className={errors.confirmPassword ? "is-danger input" : "input"}
                {...register("confirmPassword", { required: "Field required." })}
              />
            </p>
            <p className="help is-danger">{errors.confirmPassword?.message}</p>
            <p className="help">
              Make sure it's at least 15 characters OR at least 8 characters including a number and
              a lowercase letter.
            </p>
          </div>
          <button className="button">Update password</button>
        </div>
      </form>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
    </>
  );
}
