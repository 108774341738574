import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { SimpleAuthenticationServiceContext } from "../../clients/contexts";

export const Logout: React.FC<{}> = () => {
  const authenticationService = useContext(SimpleAuthenticationServiceContext);
  React.useEffect(() => {
    authenticationService.logout();
  }, [authenticationService]);
  if (!authenticationService.isAuthenticated()) {
    return <Navigate to="/auth/signin" />;
  }
  return <div style={{ padding: "1rem" }}>Logging out...</div>;
};
