import Box from "@mui/joy/Box";
import Table from "@mui/joy/Table";
import Tooltip from "@mui/joy/Tooltip";
import dayjs from "dayjs";
import React from "react";
import { UnixTimestamp } from "../../models/common";
import { unixToDayjs } from "../../utils/time";
import { ClinicalEvent } from "../../models/clinical_event";

interface FactsTimelineData {
  medication: string;
  data: {
    start_date: UnixTimestamp;
    lengthInDays: number;
    text: string;
    validated: boolean;
    fact: ClinicalEvent;
  }[];
}

interface FactsTimeline {
  headers: {
    text: string;
    colSpan: number; // either days or month depending on the view
  }[];
  rows: FactsTimelineData[];
}

interface FactsMonthCalendarProps {
  tx_facts: { [key: string]: Array<ClinicalEvent> };
  mostRecentDate: UnixTimestamp;
  mostAncientDate: UnixTimestamp;
  tooltipDateTitle: (fact: ClinicalEvent, maximalLengthToDisplayDaysCount: number) => string;
  treatmentLengthDisplay: (
    start_date: UnixTimestamp,
    end_date: UnixTimestamp | null,
    days: number
  ) => string;
  openDrawer: (name: string, facts: ClinicalEvent[]) => void;
  hasRendered: () => void;
}

export default function FactsMonthCalendar({
  tx_facts,
  mostRecentDate,
  mostAncientDate,
  tooltipDateTitle,
  treatmentLengthDisplay,
  openDrawer,
  hasRendered,
}: FactsMonthCalendarProps) {
  const [headers, setHeaders] = React.useState<FactsTimeline["headers"]>([]);
  const [rows, setRows] = React.useState<FactsTimeline["rows"]>([]);

  // calculate count of month between mostAncientDate and mostRecentDate, for the monthly view
  const monthCount =
    unixToDayjs(mostRecentDate)!
      .startOf("month")
      .diff(unixToDayjs(mostAncientDate)?.startOf("month"), "months") + 1; // +1 bc index is 0

  // calculating the display to optimize rendering
  // looping through all facts, filling the correct data and sorting it
  React.useEffect(() => {
    // calculating headers, starting from mostRecentDate and going back monthCount months
    setHeaders(
      Array.from({ length: monthCount }, (_, i) => i)
        .reverse()
        .map((i) => {
          const date = dayjs(mostRecentDate).subtract(i, "month");
          return {
            text: date.format("MMM YYYY"),
            colSpan: date.daysInMonth(),
          };
        })
    );
    const rows: FactsTimelineData[] = [];
    Object.keys(tx_facts).forEach((medication_name) => {
      // first finding each fact for each medication
      const data: FactsTimelineData["data"] = tx_facts[medication_name].map(
        (fact: ClinicalEvent) => {
          // length is 1 day if end_date is null
          let lengthInDays = 1;
          if (fact.end_date) {
            lengthInDays = fact.end_date
              ? dayjs(fact.end_date).diff(fact.start_date, "day") + 1
              : 1;
          }
          return {
            start_date: fact.start_date,
            lengthInDays,
            text: treatmentLengthDisplay(fact.start_date, fact.end_date, 7),
            validated: !!fact.probability,
            fact,
          };
        }
      );
      // order by start_date
      data.sort((a, b) => a.start_date - b.start_date);
      rows.push({ medication: medication_name, data });
    });
    setRows(rows);
  }, [tx_facts, treatmentLengthDisplay, monthCount, mostRecentDate]);

  React.useEffect(() => {
    hasRendered();
  }, [hasRendered]);

  return (
    <Table
      sx={{
        display: "block",
        overflowX: "auto",
        whiteSpace: "nowrap",
        background: "white",
        "& tr > *:first-of-type": {
          position: "sticky",
          left: 0,
          boxShadow: "1px 0 var(--TableCell-borderColor)",
          bgcolor: "background.surface",
          overflowX: "scroll",
          zIndex: 2,
        },
      }}
      borderAxis="xBetween"
    >
      <thead>
        <tr>
          {/*
            first column is the name of the medication, and it's fixed
            the rest is scrollable horizontally and it's one column per day, grouped by month on tr
            careful, we need to invert the order of the months, because we want the most recent month to be on the right
            */}
          <th
            style={{
              borderBottom: "1px solid var(--TableCell-borderColor)",
            }}
          ></th>
          {headers.map((header, i) => (
            <th
              key={i}
              colSpan={header.colSpan}
              style={{
                backgroundColor: "background.surface",
                textAlign: "center",
                borderBottom: "1px solid var(--TableCell-borderColor)",
                borderRight: "2px solid var(--TableCell-borderColor)",
              }}
            >
              {header.text}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {
          /* We fill the table with empty td, until we stumble upon a fact with it's start date, the optimization is, is to
          place the first facts and fill the gaps between them, to avoid looping too much
          mostAncienDate becomes day 0, and from then we place our fact, row by row       
          */
          rows.map((row) => {
            const facts = row.data;
            const cells: React.ReactNode[] = [];
            // display first fact and fill the gap before
            facts.forEach((fact, i) => {
              const start_date = unixToDayjs(fact.start_date)!;
              // find the diff of days between mostAncientDate and start_date
              let diff = 0;
              if (i === 0) {
                // careful because mostAncientDate is not the start of the month, so we need to find it
                const month = unixToDayjs(mostAncientDate)!.startOf("month");
                diff = start_date.diff(month, "day");
              } else {
                const previousFact = facts[i - 1];
                diff = start_date.diff(previousFact.start_date, "day");
              }
              // fill the gap
              cells.push(
                ...Array.from({ length: diff }, (_, i) => (
                  <td
                    key={fact.fact.start_date + fact.fact.concept_id + "cell_before" + i}
                    style={{
                      borderBottom: "1px solid var(--TableCell-borderColor)",
                      borderRight: "1px solid var(--TableCell-borderColor)",
                      padding: "8px 8px",
                    }}
                  ></td>
                ))
              );
              // display the fact
              cells.push(
                <td
                  key={fact.fact.start_date + fact.fact.concept_id}
                  style={{
                    position: "relative",
                    textAlign: "center",
                    borderBottom: "1px solid var(--TableCell-borderColor)",
                    borderRight: "1px solid var(--TableCell-borderColor)",
                    padding: "8px 8px",
                  }}
                >
                  <Tooltip arrow placement="top" title={tooltipDateTitle(fact.fact, 7)}>
                    <Box
                      sx={{
                        position: "absolute",
                        width:
                          fact.lengthInDays > 1 ? "calc(" + fact.lengthInDays * 17 + "px)" : "16px",
                        background: fact.validated ? "#A1E8A1" : "#F3C896",
                        height: "calc(100% - 16px)",
                        borderRadius: "16px",
                        marginLeft: "-8px",
                        fontWeight: "bold",
                        color: fact.validated ? "#0A470A" : "#7A3E0A",
                        fontSize: "14px",
                        zIndex: 1,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        openDrawer(row.medication, [fact.fact]);
                      }}
                    >
                      {fact.text}
                    </Box>
                  </Tooltip>
                </td>
              );
              // if last fact, we need to fill the gap until the end of the table
              if (i === facts.length - 1) {
                // first find last day of the month of mostRecentDate
                const lastDay = dayjs(mostRecentDate)?.endOf("month");
                // then make the diff between start_date and last day
                const diff = lastDay!.diff(start_date, "day");
                cells.push(
                  ...Array.from({ length: diff }, (_, i) => (
                    <td
                      key={fact.fact.start_date + fact.fact.concept_id + "cell_after" + i}
                      style={{
                        borderBottom: "1px solid var(--TableCell-borderColor)",
                        borderRight: "1px solid var(--TableCell-borderColor)",
                        padding: "8px 8px",
                      }}
                    ></td>
                  ))
                );
              }
            });
            return (
              <tr key={row.medication}>
                <td style={{ verticalAlign: "center" }}>{row.medication}</td>
                {cells}
              </tr>
            );
          })
        }
      </tbody>
    </Table>
  );
}
