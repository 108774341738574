import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import React from "react";

interface EditCohortDetailsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleEditCohortDetails: (name: string, description: string) => void;
  cohortName: string;
  cohortDescription: string;
}

export default function EditCohortDetailsModal({
  open,
  setOpen,
  handleEditCohortDetails,
  cohortName,
  cohortDescription,
}: EditCohortDetailsModalProps) {
  const [name, setName] = React.useState<string>(cohortName);
  const [description, setDescription] = React.useState<string>(cohortDescription);
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog>
        <ModalClose />
        <DialogTitle>Cohort details</DialogTitle>
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleEditCohortDetails(name, description);
            setOpen(false);
          }}
        >
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                value={name}
                onChange={(event) => setName(event.target.value)}
                autoFocus
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description ?? ""}
                onChange={(event) => setDescription(event.target.value)}
                minRows={3}
              />
            </FormControl>
            <Button type="submit">Save</Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}
