import InfoIcon from "@mui/icons-material/Info";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { SearchBar } from "../../../../annotation/molecules/SearchBar";
import {
  ApproximateTime,
  ExactTime,
  RelativeTime,
  TimeInterval,
} from "../../../../models/human_time";
import { Fact, FactId, FactQualifier } from "../../../../models/fact";
import { CodeableConcept, Coding, Quantity } from "../../../../models/structuration";
import { capitalizeFirstLetter, getDomain, getDomainColor } from "../../../../utils/fact";
import { generateNoteRelativeTime } from "../../../../utils/time";
import { useNote } from "../../lib/context";
import { FactPaper } from "./FactDisplayAndEdit/FactPaper/FactPaper";
import { QualitfierEdit } from "./FactDisplayAndEdit/Qualifiers/QualifierEdit";
import { TemporalityEdit } from "./FactDisplayAndEdit/TemporalityEdit";
import React from "react";

const groupFactsByDomain = (facts: Array<Fact>) => {
  const grouped: { [key: string]: typeof facts } = {};

  facts.forEach((fact) => {
    const domain = getDomain(fact.code);

    if (!domain || domain === "Patient" || domain === "Document" || domain === "PII") {
      return;
    }

    if (grouped[domain]) {
      grouped[domain].push(fact);
    } else {
      grouped[domain] = [fact];
    }
  });

  return grouped;
};

export function FactList() {
  const {
    note,
    selectedLabel,
    selectedFact,
    displayedFacts,
    setNote,
    setSelectedFact,
    setDisplayAllFacts,
  } = useNote();

  const [isFactEditable, setIsFactEditable] = React.useState(false);
  const [displayAnnotations, setDisplayAnnotations] = React.useState(false);
  // this one is used when no fact is selected, we can not update directly so we need to store the value here,
  // and then retrieve it when a fact is added in handleAddFact
  const [newFactDate, setNewFactDate] = React.useState<
    ApproximateTime | ExactTime | RelativeTime | TimeInterval | null
  >(generateNoteRelativeTime());

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (selectedFact || selectedLabel) {
          setDisplayAllFacts();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedFact, selectedLabel, setDisplayAllFacts]);

  React.useEffect(() => {
    if (selectedFact) {
      setIsFactEditable(true);
      setDisplayAnnotations(true);
    }
    // If there's a selected annotation, display facts that have the same source as the annotation
    else if (selectedLabel) {
      setIsFactEditable(true);
      setDisplayAnnotations(false);
    }
    // If there's neither a selected fact nor annotation, display all facts
    else {
      setIsFactEditable(false);
      setDisplayAnnotations(false);
    }
  }, [selectedFact, selectedLabel]);

  function handleAddFact(coding: Coding) {
    if (note && selectedLabel) {
      const factTime = displayedFacts.length > 0 ? displayedFacts[0].time : newFactDate;
      const newFact: Fact = {
        fact_id: "" as FactId,
        code: {
          coding: [coding],
        } as CodeableConcept,
        probability: 1,
        time: factTime,
        source: [selectedLabel.annotation],
      };
      setNote({
        ...note,
        facts: [...note.facts, newFact],
      });
    }
  }

  function handleRemoveFact(factId: FactId) {
    if (note) {
      const updatedFacts = note.facts.filter((fact) => fact.fact_id !== factId);

      setNote({
        ...note,
        facts: updatedFacts,
      });

      if (selectedFact) setDisplayAllFacts();
    }
  }

  function handleRemoveAnnotation(factId: FactId, offset: number) {
    if (note && factId) {
      const factToUpdate = note.facts.find((fact) => fact.fact_id === factId);

      if (factToUpdate) {
        const updatedSources = factToUpdate.source.filter(
          (sourceItem) => sourceItem.offset !== offset
        );

        const updatedFact = {
          ...factToUpdate,
          source: updatedSources,
        };

        const updatedFacts = note.facts.map((fact) =>
          fact.fact_id === updatedFact.fact_id ? updatedFact : fact
        );

        setNote({
          ...note,
          facts: updatedFacts,
        });

        if (updatedSources.length === 0) {
          setDisplayAllFacts();
        }
      }
    }
  }

  function handleDateChange(
    newDate: ApproximateTime | ExactTime | RelativeTime | TimeInterval | null
  ) {
    if (note && displayedFacts.length > 0) {
      const updatedDisplayedFacts = displayedFacts.map((fact) => ({
        ...fact,
        time: newDate,
      }));

      const updatedFacts = note.facts.map((fact) => {
        const displayedFact = updatedDisplayedFacts.find(
          (dispFact) => dispFact.fact_id === fact.fact_id
        );
        return displayedFact || fact;
      });

      setNote({
        ...note,
        facts: updatedFacts,
      });
    } else {
      setNewFactDate(newDate);
    }
  }

  function handleQualifierChange(factQualifier: FactQualifier) {
    if (note && displayedFacts.length > 0) {
      const updatedDisplayedFacts = displayedFacts.map((fact) => ({
        ...fact,
        qualifier: factQualifier,
      }));

      const updatedFacts = note.facts.map((fact) => {
        const displayedFact = updatedDisplayedFacts.find(
          (dispFact) => dispFact.fact_id === fact.fact_id
        );
        return displayedFact || fact;
      });

      setNote({
        ...note,
        facts: updatedFacts,
      });
    }
  }

  function handleValueChange(factId: FactId, value: string | Quantity | CodeableConcept | null) {
    if (note && displayedFacts.length > 0) {
      const updatedDisplayedFacts = displayedFacts.map((fact) => {
        if (fact.fact_id === factId) {
          return {
            ...fact,
            value: value,
          };
        }
        return fact;
      });

      const updatedFacts = note.facts.map((fact) => {
        const displayedFact = updatedDisplayedFacts.find(
          (dispFact) => dispFact.fact_id === fact.fact_id
        );
        return displayedFact || fact;
      });

      setNote({
        ...note,
        facts: updatedFacts,
      });
    }
  }

  function handleCodeChange(newCode: CodeableConcept, factId: FactId) {
    if (note && displayedFacts.length > 0) {
      const updatedDisplayedFacts = displayedFacts.map((fact) => {
        if (fact.fact_id === factId) {
          return {
            ...fact,
            code: newCode,
          };
        }
        return fact;
      });

      const updatedFacts = note.facts.map((fact) => {
        const displayedFact = updatedDisplayedFacts.find(
          (dispFact) => dispFact.fact_id === fact.fact_id
        );
        return displayedFact || fact;
      });

      setNote({
        ...note,
        facts: updatedFacts,
      });
    }
  }

  return (
    <Stack direction="column" spacing={2}>
      {selectedLabel &&
        selectedLabel.domain?.toLowerCase() !== "document" &&
        selectedLabel.domain?.toLowerCase() !== "pii" &&
        selectedLabel.domain?.toLowerCase() !== "patient" && (
          <Box sx={{ mb: 2 }} data-cy="add-fact-search-bar">
            <SearchBar
              onChange={(coding) => coding && handleAddFact(coding)}
              textHelper={
                selectedLabel && (displayedFacts.length > 1 || displayedFacts.length === 0)
              }
            />
          </Box>
        )}
      {isFactEditable && displayedFacts.length > 1 && (
        <Sheet variant="outlined" sx={{ p: 2, borderRadius: "sm" }}>
          <Stack direction="row" gap={1}>
            <InfoIcon sx={{ fontSize: "18px", color: (theme) => theme.palette.grey[700] }} />
            <Typography color="neutral" level="title-sm">
              Multiple facts linked to this label, select one to edit
            </Typography>
          </Stack>
        </Sheet>
      )}
      <ul>
        {Object.entries(groupFactsByDomain(displayedFacts)).map(
          ([domain, facts], index: number) => (
            <Box key={domain}>
              {!(selectedFact || selectedLabel) && (
                <>
                  {index !== 0 && <Divider sx={{ mx: -2, mt: 4, mb: 2 }} />}
                  <Typography level="title-lg" sx={{ mb: 2, color: getDomainColor(domain) }}>
                    {capitalizeFirstLetter(domain.replace("-", " "))}
                  </Typography>
                </>
              )}
              <Stack direction="column">
                {facts.map((fact) => (
                  <Box
                    sx={{ pb: 1 }}
                    key={fact.fact_id}
                    onClick={() =>
                      (!isFactEditable || displayedFacts.length > 1) && setSelectedFact(fact)
                    }
                  >
                    <FactPaper
                      fact={fact}
                      domain={domain}
                      isFactEditable={isFactEditable}
                      displayAnnotations={displayAnnotations}
                      onRemove={() => handleRemoveFact(fact.fact_id)}
                      onValueChange={(newValue) => handleValueChange(fact.fact_id, newValue)}
                      onCodeChange={(newCode) => handleCodeChange(newCode, fact.fact_id)}
                      onRemoveAnnovation={(offset) => handleRemoveAnnotation(fact.fact_id, offset)}
                    />
                  </Box>
                ))}
              </Stack>
            </Box>
          )
        )}
      </ul>
      {selectedLabel?.domain?.toLowerCase() !== "document" &&
        selectedLabel?.domain?.toLowerCase() !== "patient" &&
        selectedLabel?.domain?.toLowerCase() !== "pii" &&
        (selectedLabel || selectedFact) &&
        displayedFacts.length === 1 && (
          <Stack
            direction="column"
            spacing={2}
            key={selectedLabel?.annotation.offset || selectedFact?.fact_id}
          >
            <TemporalityEdit
              time={displayedFacts.length > 0 ? displayedFacts[0].time : null}
              onChange={handleDateChange}
            />
            <QualitfierEdit
              factQualifier={displayedFacts.length > 0 ? displayedFacts[0].qualifier : null}
              onChange={handleQualifierChange}
            />
          </Stack>
        )}
      {!selectedLabel && displayedFacts.length === 0 && (
        <Alert color="neutral">
          <Typography level="title-md">No fact here</Typography>
          Select part of the text to add new facts.
        </Alert>
      )}
    </Stack>
  );
}
