import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MergeIcon from "@mui/icons-material/Merge";
import Checkbox from "@mui/joy/Checkbox";
import Chip from "@mui/joy/Chip";
import CircularProgress from "@mui/joy/CircularProgress";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PersonQuartzClientContext } from "../clients/contexts";
import AlertDialogModal from "../common/modals/AlertDialogModal";
import { Person, PersonId } from "../models/person";
import { unixToDayjs } from "../utils/time";
import MergePatientsModal from "./components/MergePatientsModal";

interface VerificationCompletion {
  [key: PersonId]: number;
}

interface ListOfPatientsProps {
  persons: Person[];
  deletePersons: (personIds: PersonId[]) => Promise<void>;
  mergePersons: (sourcePersonId: PersonId, targetPersonIds: PersonId[]) => Promise<void>;
}

export function ListOfPatients({ persons, deletePersons, mergePersons }: ListOfPatientsProps) {
  const navigate = useNavigate();
  const personQuartzClient = React.useContext(PersonQuartzClientContext);
  const [selectedPatients, setSelectedPatients] = React.useState<PersonId[]>([]);
  const [openDeletePatientModal, setOpenDeletePatientModal] = React.useState(false);
  const [openMergePatientsModal, setOpenMergePatientsModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [merging, setMerging] = React.useState(false);
  const [verificationCompletion, setVerificationCompletion] =
    React.useState<VerificationCompletion>({});

  async function handleDeletePatient() {
    function reset() {
      setSelectedPatients([]);
      setOpenDeletePatientModal(false);
      setDeleting(false);
    }
    if (selectedPatients) {
      await deletePersons(selectedPatients);
      reset();
    } else {
      reset();
    }
  }

  async function handleMerge(sourcePersonId: PersonId) {
    setMerging(true);
    await mergePersons(sourcePersonId, selectedPatients);
    setSelectedPatients([]);
    setMerging(false);
    setOpenMergePatientsModal(false);
  }

  const completionColor = (completion: number) => {
    if (completion === 100) {
      return "success";
    } else if (completion > 50) {
      return "warning";
    } else {
      return "danger";
    }
  };
  const handleRowClick = (link: string) => {
    navigate(link);
  };

  React.useEffect(() => {
    const controller = new AbortController();

    async function getVerificationCompletion() {
      try {
        const verificationCompletion =
          await personQuartzClient.getPersonVerificationCompletion(controller);
        setVerificationCompletion(verificationCompletion);
      } catch (error) {
        console.error(error);
      }
    }

    getVerificationCompletion();

    return () => controller.abort();
  }, [personQuartzClient, persons]);

  if (persons.length === 0) return <div>No patient found</div>;
  return (
    <>
      <AlertDialogModal
        alertMessage="Are you sure you want to delete this patient?"
        open={openDeletePatientModal}
        setOpen={setOpenDeletePatientModal}
        handleDelete={handleDeletePatient}
        loading={deleting}
      />
      <MergePatientsModal
        openMergePatientsModal={openMergePatientsModal}
        setOpenMergePatientsModal={setOpenMergePatientsModal}
        selectedPatients={selectedPatients}
        persons={persons}
        handleMerge={handleMerge}
        merging={merging}
      />
      <Sheet variant="outlined" sx={{ borderRadius: "sm", height: "70vh", overflow: "auto" }}>
        <Table
          aria-label="patients table"
          stickyHeader
          variant="plain"
          sx={{
            "--Table-headerUnderlineThickness": "1px",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: "center", padding: "12px 6px" }}>
                <Checkbox
                  size="sm"
                  indeterminate={
                    selectedPatients.length > 0 && selectedPatients.length !== persons.length
                  }
                  checked={selectedPatients.length === persons.length}
                  onChange={(event) => {
                    setSelectedPatients(
                      event.target.checked ? persons.map((row) => row.person_id) : []
                    );
                  }}
                  color={
                    selectedPatients.length > 0 || selectedPatients.length === persons.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              <th style={{ width: "18%", padding: "12px 6px" }}>Patient</th>
              <th style={{ width: "110px", padding: "12px 6px" }}>Birth date</th>
              <th style={{ padding: "12px 6px" }}>Patient identifier</th>
              <th style={{ width: "140px", padding: "12px 6px" }}>Vital status</th>
              <th style={{ width: "140px", padding: "12px 6px" }}>GDPR</th>
              <th style={{ width: "110px", padding: "12px 6px" }}>Data review</th>
              <th style={{ width: "93px" }}>
                {selectedPatients.length > 0 && (
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    {selectedPatients.length > 1 && (
                      <Tooltip title="Merge duplicated patients" variant="solid">
                        <IconButton
                          onClick={() => {
                            setOpenMergePatientsModal(true);
                          }}
                          color="neutral"
                          variant="outlined"
                        >
                          <MergeIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Delete patient(s)" variant="solid">
                      <IconButton
                        onClick={() => {
                          setOpenDeletePatientModal(true);
                        }}
                        color="danger"
                        variant="outlined"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {persons.map((person, key) => (
              <tr
                key={key}
                onClick={(event) => handleRowClick(`/patient/${person.person_id}`)}
                style={{ cursor: "pointer" }}
              >
                <td
                  style={{ textAlign: "center", width: 120, verticalAlign: "middle" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    size="sm"
                    checked={selectedPatients.includes(person.person_id)}
                    color={selectedPatients.includes(person.person_id) ? "primary" : undefined}
                    onChange={(event) => {
                      setSelectedPatients((ids) =>
                        event.target.checked
                          ? ids.concat(person.person_id)
                          : ids.filter((itemId) => itemId !== person.person_id)
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                    sx={{ verticalAlign: "text-bottom" }}
                  />
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  {person ? (
                    (person.family_name && person.given_name) ||
                    (person.given_name !== "" && person.family_name !== "") ? (
                      <div>
                        {person.given_name} {person.family_name}
                      </div>
                    ) : (
                      <>Unknown</>
                    )
                  ) : (
                    "-"
                  )}
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  {person.birth_date ? [unixToDayjs(person.birth_date)?.format("DD MMM YYYY")] : ""}
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  {person.external_ids &&
                    person.external_ids.length > 0 &&
                    person.external_ids
                      .filter((id) => {
                        return id.value !== "";
                      })
                      .map((id, index) => (
                        <Chip key={index} sx={{ mt: 0.5, mr: 0.5 }}>
                          {id.value}
                        </Chip>
                      ))}
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  {person.deceased ? "Deceased" : "Alive or unknown"}
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  {person.consent_withdrawal_date ? (
                    <Chip color="warning">Opposition</Chip>
                  ) : (
                    <Chip color="success">No opposition</Chip>
                  )}
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  {verificationCompletion[person.person_id] != null ? (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <CircularProgress
                        determinate
                        value={verificationCompletion[person.person_id]}
                        color={completionColor(verificationCompletion[person.person_id])}
                        sx={{ "--CircularProgress-size": "30px" }}
                      />
                      <Typography>{verificationCompletion[person.person_id]}%</Typography>
                    </Stack>
                  ) : (
                    <Typography level="body-xs">No note</Typography>
                  )}
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </>
  );
}
