import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import React from "react";
import { PutUserRequest, Role } from "../models/auth";
import { helperText } from "./roleDescription";

interface EditUserFormProps {
  form: PutUserRequest;
  setForm: (form: PutUserRequest) => void;
  handleEditSubmit: () => void;
}

export default function EditUserForm({ form, setForm, handleEditSubmit }: EditUserFormProps) {
  return (
    <form
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleEditSubmit();
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <FormControl>
            <FormLabel>Given name</FormLabel>
            <Input
              required
              value={form.attributes.given_name}
              onChange={(e) => {
                setForm({
                  ...form,
                  attributes: {
                    given_name: e.target.value,
                    name: form.attributes.name,
                    organization: form.attributes.organization,
                    role: form.attributes.role,
                  },
                });
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Last name</FormLabel>
            <Input
              required
              value={form.attributes.name}
              onChange={(e) => {
                setForm({
                  ...form,
                  attributes: {
                    given_name: form.attributes.given_name,
                    name: e.target.value,
                    organization: form.attributes.organization,
                    role: form.attributes.role,
                  },
                });
              }}
            />
          </FormControl>
        </Stack>
        <FormControl>
          <FormLabel>Role</FormLabel>
          <Select
            required
            value={form.attributes.role}
            onChange={(event: React.SyntheticEvent | null, newValue: string | null) => {
              setForm({
                ...form,
                attributes: {
                  given_name: form.attributes.given_name,
                  name: form.attributes.name,
                  organization: form.attributes.organization,
                  role: newValue as Role,
                },
              });
            }}
          >
            {Object.values(Role).map((role: Role) => (
              <React.Fragment key={role}>
                <Option value={role}>{role}</Option>
              </React.Fragment>
            ))}
          </Select>
          {form.attributes.role && (
            <FormHelperText>{helperText[form.attributes.role]}</FormHelperText>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Organization</FormLabel>
          <Input
            value={form.attributes.organization ?? ""}
            onChange={(e) => {
              setForm({
                ...form,
                attributes: {
                  given_name: form.attributes.given_name,
                  name: form.attributes.name,
                  organization: e.target.value,
                  role: form.attributes.role,
                },
              });
            }}
          />
        </FormControl>
        <Button type="submit">Submit</Button>
      </Stack>
    </form>
  );
}
