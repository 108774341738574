import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import DialogActions from "@mui/joy/DialogActions";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { unixToDayjs } from "../../utils/time";
import { Person, PersonId } from "../../models/person";
import LoadingButton from "../../components/LoadingButton";

interface MergePatientsModalProps {
  openMergePatientsModal: boolean;
  setOpenMergePatientsModal: (open: boolean) => void;
  selectedPatients: PersonId[];
  persons: Person[];
  handleMerge: (sourcePersonId: PersonId) => void;
  merging: boolean;
}

export default function MergePatientsModal({
  openMergePatientsModal,
  setOpenMergePatientsModal,
  selectedPatients,
  persons,
  handleMerge,
  merging,
}: MergePatientsModalProps) {
  const [selectedSourcePersonId, setSelectedSourcePersonId] = React.useState<PersonId | null>(null);
  const handleSelectSourcePersonId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSourcePersonId(event.target.value as PersonId);
  };

  const handleClickMerge = () => {
    if (!selectedSourcePersonId) return;
    handleMerge(selectedSourcePersonId);
  };

  React.useEffect(() => {
    if (selectedPatients.length > 1) {
      setSelectedSourcePersonId(selectedPatients[0]);
    }
  }, [selectedPatients]);

  return (
    <Modal
      sx={{ zIndex: 99999999 }}
      open={openMergePatientsModal}
      onClose={() => setOpenMergePatientsModal(false)}
    >
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          Merge confirmation
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Typography>Are you sure you want to merge these patients?</Typography>
            <Alert variant="soft" color="warning">
              Patient's identifiers will be merged.
            </Alert>
            <FormControl>
              <FormLabel>Select the source patient</FormLabel>
              <RadioGroup defaultValue={selectedPatients[0]} onChange={handleSelectSourcePersonId}>
                {selectedPatients.map((personId) => {
                  const person = persons.find((person) => person.person_id === personId);
                  if (!person) return null;
                  const label = `${person.given_name} ${person.family_name} - ${
                    person.gender
                  } - ${unixToDayjs(person.birth_date)?.format("DD MMM YYYY")}`;
                  const identifiers =
                    person.external_ids && person.external_ids.length > 0
                      ? `Identifiers:${person.external_ids
                          .map((id) => ` ${id.value} (${id.system})`)
                          .join(",")}`
                      : "";
                  return (
                    <div key={personId}>
                      <Radio value={personId} label={label} />
                      <FormHelperText>{identifiers}</FormHelperText>
                    </div>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="solid"
            color="danger"
            onClick={handleClickMerge}
            loading={merging}
          >
            Merge
          </LoadingButton>
          <Button variant="plain" color="neutral" onClick={() => setOpenMergePatientsModal(false)}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}
