import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import { useNavigate } from "react-router";
import { BundleContext } from "../../App";
import { NoteQuartzClientContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";
import BackdropLoader from "../../components/BackdropLoader";
import { Note, NoteId } from "../../models/note";
import { NoteContext } from "./lib/context";
import React, { ReactNode } from "react";
import { Annotation, Fact } from "../../models/fact";

interface NoteProviderProps {
  note_id: NoteId;
  children: ReactNode;
}

export interface Label {
  annotation: Annotation;
  color: string;
  fact?: Fact;
  domain?: string;
}

export const NoteProvider: React.FC<NoteProviderProps> = ({ note_id, children }) => {
  // Contexts
  const noteQuartzClient = React.useContext(NoteQuartzClientContext);
  const [bundleId] = React.useContext(BundleContext);
  const navigate = useNavigate();

  // States
  const [note, setNoteState] = React.useState<Note | null>(null);
  const [selectedFact, setSelectedFactState] = React.useState<Fact | null>(null);
  const [selectedLabel, setSelectedLabelState] = React.useState<Label | null>(null);
  const [displayedFacts, setDisplayedFactsState] = React.useState<Fact[]>([]);
  const [hoveredFact, setHoveredFact] = React.useState<Fact | null>(null);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [serverError, setServerError] = React.useState<string | null>("");
  const [loading, setLoading] = React.useState<boolean>(true);

  const handleReRunNLP = async () => {
    if (!note) {
      console.error("No note provided for validation or skipping.");
      return;
    }
    setLoading(true);
    try {
      const new_note = await noteQuartzClient.runNLP(note.note_id);
      setNoteState(new_note);
    } catch (error) {
      setServerError("Error while running NLP");
    } finally {
      setLoading(false);
    }
  };

  const handleValidate = async (next = false, skip = false) => {
    if (!note) {
      console.error("No note provided for validation or skipping.");
      return;
    }

    try {
      setLoading(true);
      if (skip && bundleId) {
        const newNote = await noteQuartzClient.skipNote(note.note_id);
        newNote
          ? navigateToNote(newNote)
          : note.person_id
            ? navigate(`/patient/${note.person_id}`)
            : navigate("/notes");
      } else {
        const updatedNote = await noteQuartzClient.validateNoteById(note.note_id);
        if (updatedNote && next && bundleId) {
          const nextNote = await noteQuartzClient.getNextNote(updatedNote.note_id);
          nextNote ? navigateToNote(nextNote) : navigate(`/patient/${updatedNote.person_id}`);
        } else {
          setNoteState(updatedNote);
        }
      }
    } catch (e: any) {
      setServerError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const navigateToNote = (note: Note) => {
    navigate(`/note/${note.note_id}`);
    setNoteState(note);
  };

  const setNote = async (newNote: Note) => {
    try {
      const updatedNote = await noteQuartzClient.updateNoteById(note_id, newNote);
      setNoteState(updatedNote);
    } catch (error) {
      console.error("Failed to update note:", error);
    }
  };

  const setSelectedFact = React.useCallback((fact: Fact) => {
    setSelectedFactState(fact);
    setSelectedLabelState(null);
    setDisplayedFactsState([fact]);
  }, []);

  const setSelectedLabel = React.useCallback(
    (label: Label) => {
      if (note) {
        setSelectedFactState(null);
        setSelectedLabelState(label);
        const sameSourceFacts = note.facts.filter((fact) =>
          fact.source.some(
            (annotation) =>
              annotation.offset === label.annotation.offset &&
              annotation.element_index === label.annotation.element_index
          )
        );
        setDisplayedFactsState(sameSourceFacts);
      }
    },
    [note]
  );

  const setDisplayAllFacts = React.useCallback(() => {
    if (note) {
      setSelectedFactState(null);
      setSelectedLabelState(null);
      setDisplayedFactsState(note.facts);
    }
  }, [note]);

  React.useEffect(() => {
    const fetchNote = async () => {
      try {
        const fetchedNote = await noteQuartzClient.getNoteById(note_id);
        setNoteState(fetchedNote);
        setDisplayedFactsState(fetchedNote.facts);
      } catch (error) {
        console.error("Failed to fetch note:", error);
        navigate("/notes");
      } finally {
        setLoading(false);
      }
    };

    fetchNote();
  }, [note_id, noteQuartzClient, navigate]);

  React.useEffect(() => {
    if (!note) {
      return;
    }
    if (selectedFact) {
      const updatedFact = note.facts.find((fact: Fact) => fact.fact_id === selectedFact.fact_id);
      if (updatedFact) {
        setSelectedFact(updatedFact);
      }
    } else if (selectedLabel) {
      // when we add a new fact FROM the click of label, we link the new created fact to the current label
      const filter: Fact[] = note.facts.filter((fact: Fact) => {
        return fact.source.some((annotation: Annotation) => {
          return (
            annotation.offset === selectedLabel.annotation.offset &&
            annotation.element_index === selectedLabel.annotation.element_index
          );
        });
      });
      setDisplayedFactsState(filter);
    } else {
      setDisplayAllFacts();
    }
  }, [note, selectedFact, selectedLabel, setDisplayAllFacts, setSelectedFact]);

  if (loading) {
    return (
      <div>
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress variant="solid" />
        </Box>
      </div>
    );
  }

  if (!note) {
    navigate("/notes");
    return null;
  }

  return (
    <NoteContext.Provider
      value={{
        note,
        setNote,
        handleValidate,
        handleReRunNLP,
        loading,
        selectedLabel,
        setSelectedLabel,
        selectedFact,
        setSelectedFact,
        displayedFacts,
        hoveredFact,
        setHoveredFact,
        setDisplayAllFacts,
        selectedTab,
        setSelectedTab,
      }}
    >
      <BackdropLoader loading={loading} text="Loading...">
        {children}
      </BackdropLoader>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
    </NoteContext.Provider>
  );
};
