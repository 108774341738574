import {
  ApproximateTime,
  QualitativeTimeDelta,
  RelativeTime,
  TimeInterval,
} from "../models/human_time";
import { Annotation, FactQualifier } from "../models/fact";
import { CodeableConcept, Coding, Quantity, Range } from "../models/structuration";

export const getDomain = (code: CodeableConcept | null): string | undefined => {
  if (!code) return undefined;
  return code.coding && code.coding.length > 0 && code.coding[0].domain
    ? code.coding[0].domain
    : undefined;
};

export const getDisplay = (code: CodeableConcept | null): string | null => {
  if (!code) return null;
  return code.coding && code.coding.length > 0 && code.coding[0].display
    ? code.coding[0].display
    : null;
};

export const getCode = (code: CodeableConcept | null): string | null => {
  if (!code) return null;
  return code.coding && code.coding.length > 0 && code.coding[0].code ? code.coding[0].code : null;
};

export function getDomainColor(domain: string | null | undefined): string {
  let color = "#bbb";

  if (!domain) return color;

  switch (domain.toLowerCase()) {
    case "measurement":
      color = "#C99B1D";
      break;
    case "condition":
      color = "#48c78e";
      break;
    case "drug":
      color = "#485fc7";
      break;
    case "procedure":
      color = "#f14668";
      break;
    case "observation":
      color = "#4BC0D9";
      break;
    case "adverse-event":
      color = "#48304D";
      break;
    case "episode":
      color = "#BF1363";
      break;
    case "regimen":
      color = "#BB4430";
      break;
    case "patient":
      color = "#F85E00";
      break;
    case "device":
      color = "#E10F00";
      break;
    case "document":
      color = "#C76BC9";
      break;
  }

  return color;
}

export function isApproximateTime(obj: any): obj is ApproximateTime {
  return (
    obj && "type" in obj && "value" in obj && "precision" in obj && "utc_offset_minutes" in obj
  );
}

export function isRelativeTime(obj: any): obj is RelativeTime {
  return obj && "type" in obj && "reference" in obj && "delta" in obj;
}

export function isPastRelativeTime(obj: any): obj is RelativeTime {
  return (
    isRelativeTime(obj) &&
    (obj.delta === QualitativeTimeDelta.BEFORE || (obj.delta as any).value < 0)
  );
}

export function isFuturRelativeTime(obj: any): obj is RelativeTime {
  return (
    isRelativeTime(obj) &&
    (obj.delta === QualitativeTimeDelta.AFTER || (obj.delta as any).value > 0)
  );
}

export function isTimeInterval(obj: any): obj is TimeInterval {
  return obj && typeof obj === "object" && "type" in obj && "begin" in obj && "end" in obj;
}

export function instanceOfAnnotation(obj: any): obj is Annotation {
  return obj && typeof obj === "object" && "offset" in obj;
}
export function instanceOfCodeableConcept(obj: any): obj is CodeableConcept {
  return obj && typeof obj === "object" && "coding" in obj;
}
export function instanceOfRange(obj: any): obj is Range {
  return obj && typeof obj === "object" && "low" in obj;
}
export function instanceOfQuantity(obj: any): obj is Quantity {
  return obj && typeof obj === "object" && "unit" in obj;
}

export function instanceOfCoding(obj: any): obj is Coding {
  return obj && typeof obj === "object" && "display" in obj;
}

export const getValue = (
  value: string | Quantity | CodeableConcept | Coding | null | undefined,
  qualifier?: FactQualifier | null
): string => {
  if (!value) {
    if (qualifier) {
      if (qualifier.negated === true) return "Absent";
      return "Present";
    }
    return "";
  }
  if (typeof value === "string") return value;
  if (instanceOfCodeableConcept(value))
    return value.coding.length > 0 ? value.coding[0].display || value.text || "" : "";
  if (instanceOfRange(value)) return "";
  if (instanceOfQuantity(value))
    return (
      (value.comparator !== "=" ? value.comparator : "") +
      " " +
      value.value +
      " " +
      (value.unit ? value.unit.display : "")
    );
  if (instanceOfCoding(value) && value.display) return value.display;
  return "";
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
