import Chip from "@mui/joy/Chip";
import Drawer from "@mui/joy/Drawer";
import React from "react";
import { EventSource } from "../../models/clinical_event";
import ProofChipDrawerContent from "./ProofChipDrawerContent";

interface ProofChipProps {
  name: string;
  probability?: number;
  eventSources: EventSource[];
}

export const ProofChip = ({ name, probability, eventSources }: ProofChipProps) => {
  const [open, setOpen] = React.useState(false);
  const [sortedSources, setSortedSources] = React.useState<EventSource[]>(eventSources);

  // Sort sources by date
  React.useEffect(() => {
    setSortedSources(eventSources.sort((a, b) => (a.note_date > b.note_date ? 1 : -1)));
  }, [eventSources]);

  const getChipColor = () => {
    if (probability === undefined) {
      // If no probability is provided, check if at least one source has a validation date
      if (eventSources.some((source) => source.validation_date)) return "success";
      return "warning";
    }
    if (probability !== 1) return "warning";
    return "success";
  };

  return (
    <>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)} sx={{ width: "400px" }}>
        <ProofChipDrawerContent eventSources={sortedSources} name={name} />
      </Drawer>
      <Chip
        sx={{ textOverflow: "ellipsis", cursor: "pointer" }}
        key={name}
        title={name}
        color={getChipColor()}
        onClick={() => setOpen(true)}
      >
        {name}
      </Chip>
    </>
  );
};
