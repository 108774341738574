import dayjs, { Dayjs } from "dayjs";
import {
  ApproximateTime,
  ExactTime,
  QualitativeTimeDelta,
  QuantitativeTimeDelta,
  RelativeTime,
  TemporalPrecision,
  Time,
  TimeInterval,
  TimeReference,
  TimeType,
} from "../models/human_time";
import { assert } from "./assert";
import { MilliDelta, UnixTimestamp } from "../models/common";
import { useCallback, useRef } from "react";

export function DebouncedFunction() {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const debounce = useCallback(<T extends any[]>(func: (...args: T) => void, delay: number) => {
    const debouncedFunc = (...args: T) => {
      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => func(...args), delay);
    };

    debouncedFunc.cancel = () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };

    return debouncedFunc;
  }, []);

  return debounce;
}

export const sleep = async function (ms: number, abortController?: AbortController) {
  await new Promise<void>((resolve) => {
    let handleAbort: () => void;
    const id = setTimeout(() => {
      if (abortController !== undefined) {
        assert(handleAbort !== undefined);
        abortController.signal.removeEventListener("abort", handleAbort);
      }
      resolve();
    }, ms);
    if (abortController !== undefined) {
      handleAbort = () => {
        clearTimeout(id);
        resolve();
      };
      abortController.signal.addEventListener("abort", handleAbort);
    }
  });
};

export const date_short = () => {
  const date = new Date();
  const formattedDate = date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear();
  return formattedDate;
};

export const date = () => {
  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return formattedDate;
};

export const format_date = (date: Date) => {
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return formattedDate;
};

export const format_date_long = (date: Date) => {
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  return formattedDate;
};

export const date_from_timestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  return format_date_long(date);
};

const temporalPrecisionToDayjsUnit: Record<TemporalPrecision, dayjs.ManipulateType> = {
  [TemporalPrecision.MILLI]: "millisecond",
  [TemporalPrecision.SECOND]: "second",
  [TemporalPrecision.MINUTE]: "minute",
  [TemporalPrecision.HOUR]: "hour",
  [TemporalPrecision.DAY]: "day",
  [TemporalPrecision.WEEK]: "week",
  [TemporalPrecision.MONTH]: "month",
  [TemporalPrecision.YEAR]: "year",
};

export const unixToDayjs = (milliunix?: UnixTimestamp | null): Dayjs | null => {
  if (!milliunix) return null;
  return dayjs.unix(milliunix / 1000);
};

export const generateUnixTimestamp = (value?: Dayjs | null): UnixTimestamp => {
  const dateToUse = value ? dayjs(value) : dayjs();
  return dateToUse.valueOf() as UnixTimestamp;
};

export const timeToDayjs = (
  time?: Time | null,
  birthDate?: Dayjs | null,
  noteDate?: Dayjs | null
): Dayjs | null => {
  if (!time) return null;
  switch (time.type) {
    case TimeType.EXACT:
      return dayjs.unix((time as ExactTime).timestamp);

    case TimeType.APPROXIMATE: {
      const approximateTime = time as ApproximateTime;
      const baseDate = dayjs.unix(approximateTime.value / 1000);

      if (approximateTime.utc_offset_minutes !== null) {
        return baseDate.add(approximateTime.utc_offset_minutes, "minute");
      }

      return baseDate;
    }

    case TimeType.RELATIVE: {
      const relativeTime = time as RelativeTime;

      if (relativeTime.reference === TimeReference.NOTE && noteDate) {
        if (
          typeof relativeTime.delta === "string" &&
          relativeTime.delta === QualitativeTimeDelta.BEFORE
        ) {
          return noteDate.subtract(1, "day"); // Using 1 day before noteDate by default
        } else if (relativeTime.delta === QualitativeTimeDelta.AFTER) {
          return noteDate.add(1, "day"); // Using 1 day after noteDate by default
        } else {
          const quantitativeDelta = relativeTime.delta as QuantitativeTimeDelta;
          const unit = temporalPrecisionToDayjsUnit[quantitativeDelta.precision];
          return noteDate.add(quantitativeDelta.value, unit);
        }
      } else if (relativeTime.reference === TimeReference.BIRTH && birthDate) {
        // Similar logic can be applied for BIRTH reference
        // ...
      }

      return null;
    }

    case TimeType.INTERVAL:
      const intervalTime = time as TimeInterval;
      return timeToDayjs(intervalTime.begin);

    default:
      return null;
  }
};

export const generateApproximateTime = (
  value?: dayjs.Dayjs | null,
  precision?: TemporalPrecision | null
): ApproximateTime => {
  // Get the current date.
  const dateToUse = value ? dayjs(value) : dayjs();

  // Set time to the start of the day.
  const unixTimestamp: number = dateToUse.startOf("day").unix();

  // Calculate the UTC offset in minutes.
  const utcOffsetMinutes: number = dateToUse.utcOffset();

  return {
    type: TimeType.APPROXIMATE,
    value: (unixTimestamp * 1000) as UnixTimestamp,
    precision: precision || TemporalPrecision.DAY,
    utc_offset_minutes: utcOffsetMinutes,
  };
};

export function generateNoteRelativeTime(): RelativeTime {
  return {
    type: TimeType.RELATIVE,
    reference: TimeReference.NOTE,
    delta: {
      value: 0 as MilliDelta,
      precision: TemporalPrecision.DAY,
    },
  };
}

export function generateTimeInterval(
  begin: ApproximateTime | RelativeTime,
  end: ApproximateTime | RelativeTime
): TimeInterval {
  return {
    type: TimeType.INTERVAL,
    begin: begin,
    end: end,
  };
}
