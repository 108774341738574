import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { Person } from "../models/person";
import { AllPatientsDropdown } from "../modules/patient/components/AllPatientsDropdown";
import { AddNote } from "./AddNote";
import { PatientDetails } from "../patient/PatientDetails";

export const AddNewNote = () => {
  const [person, setPerson] = React.useState<Person | null>(null);

  const onSelectPatient = async (newValue: Person | null) => {
    setPerson(newValue);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ mb: 2 }}>
        <Typography level="h1">Add a note</Typography>
      </Box>
      <Stack spacing={3}>
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Patient</Typography>
            <Typography level="body-sm">
              Select the patient for whom you want to add a note.
            </Typography>
          </Box>
          <Divider />
          <Stack direction="row" spacing={4}>
            <Box sx={{ width: 300 }}>
              <AllPatientsDropdown onSelect={onSelectPatient} />
            </Box>
            {person && <PatientDetails person={person} />}
          </Stack>
        </Card>
        <AddNote person={person} />
      </Stack>
    </Box>
  );
};
