import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import CircularProgress from "@mui/joy/CircularProgress";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import ListItemContent from "@mui/joy/ListItemContent";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { PersonQuartzClientContext } from "../../../clients/contexts";
import { Person } from "../../../models/person";
import { unixToDayjs } from "../../../utils/time";

type AllPatientsDropdownProps = {
  onSelect: (person: Person | null) => void;
  formLabel?: string;
};

export function AllPatientsDropdown({ onSelect, formLabel }: AllPatientsDropdownProps) {
  const personClientContext = React.useContext(PersonQuartzClientContext);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Person[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    const listPatients = async () => {
      try {
        const fetchPatientsBundle = await personClientContext.listPersons(500, 0, null);
        const fetchPatients = fetchPatientsBundle.entry as Person[];
        if (active) {
          setOptions(fetchPatients);
        }
      } catch (error) {
        console.error("Could not fetch all patients.");
      }
    };

    if (!loading) {
      return undefined;
    }

    listPatients();
    return () => {
      active = false;
    };
  }, [loading, personClientContext]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <FormControl id="patients-list">
      <FormLabel>{formLabel}</FormLabel>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        placeholder="Choose a patient"
        onChange={(event: any, newValue: Person | null) => {
          onSelect(newValue);
        }}
        slotProps={{
          input: {
            autoComplete: "new-password", // disable autocomplete and autofill
          },
        }}
        options={options}
        getOptionLabel={(option) => option.given_name + " " + option.family_name}
        noOptionsText={<Typography color="neutral">No patients found</Typography>}
        renderOption={(props, option) => (
          <AutocompleteOption {...props} key={option.person_id}>
            <ListItemContent sx={{ fontSize: "sm" }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  {option.given_name + " " + option.family_name}
                  <Typography level="body-xs">
                    {unixToDayjs(option.birth_date)?.format("DD MMM YYYY")} | {option.gender}
                  </Typography>
                </Box>
                {option.external_ids &&
                  option.external_ids.length > 0 &&
                  option.external_ids.map((identifier, key) => {
                    return (
                      <Chip
                        size="sm"
                        key={key}
                        variant="outlined"
                        sx={{
                          ml: "auto",
                          borderRadius: "2px",
                          minHeight: "20px",
                          paddingInline: "4px",
                          fontSize: "xs",
                        }}
                      >
                        {identifier.system}: {identifier.value}
                      </Chip>
                    );
                  })}
              </Stack>
            </ListItemContent>
          </AutocompleteOption>
        )}
        endDecorator={
          loading ? <CircularProgress size="sm" sx={{ bgcolor: "background.surface" }} /> : null
        }
      />
    </FormControl>
  );
}
