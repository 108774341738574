import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IdentityProviderClientContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";
import { Loading } from "../../common/loading/Loading";
import { useQuery } from "../../utils/use_query";

/*
  This page is used as the Pro Santé Connect OAuth2 callback page.
  It is called by Pro Santé Connect after the user has logged in.
*/

export default function PSCLoginPage() {
  const [serverError, setServerError] = useState<string | null>("");
  const navigate = useNavigate();
  const query = useQuery();
  const identityProviderClient = useContext(IdentityProviderClientContext);

  useEffect(() => {
    const submitPscCode = async (state: string, code: string) => {
      let result;
      try {
        result = await identityProviderClient.submitPSCCallback(code!, state!);
      } catch (e: any) {
        setServerError(e.message);
      }

      if (result.task === "create") {
        localStorage.setItem("psc-id-token", result.id_token);
        navigate("/auth/signin");
      } else if (result.task === "login") {
        await identityProviderClient.loginWithPSC(result.id_token!, result.email!);
        navigate("/");
      }
    };

    // the use of the use effect is to make sure that the function is called once
    const code = query.get("code");
    if (!code) {
      setServerError("No code provided");
      return;
    }
    submitPscCode("", code);
  }, [query, identityProviderClient, navigate, setServerError]);

  return (
    <>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <div className="column pt-6 mt-6">
        <Loading message={"Connecting using Pro Santé Connect..."} />
      </div>
    </>
  );
}
