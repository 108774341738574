import Launch from "@mui/icons-material/Launch";
import Link from "@mui/joy/Link";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import { ConsistencyError } from "../models/consistency";

interface DataConsistencyProps {
  dataInconsistencies: ConsistencyError[];
}

export default function DataConsistency({ dataInconsistencies }: DataConsistencyProps) {
  return (
    <Sheet variant="outlined" sx={{ borderRadius: "sm" }}>
      <Table aria-label="inconsistencies table">
        <thead>
          <tr>
            <th style={{ width: "30%" }}>Details</th>
            <th>Sources</th>
          </tr>
        </thead>
        <tbody>
          {dataInconsistencies.map((inconsistency, index) => (
            <tr key={index}>
              <td>{inconsistency.detail}</td>
              <td>
                <Stack direction="column" spacing={1}>
                  {inconsistency.source.map((event, eIndex) => (
                    <div key={eIndex}>
                      {event.source.map((source, sIndex) => (
                        <Link
                          key={sIndex}
                          underline="hover"
                          href={"/note/" + source.note_id}
                          target="_blank"
                          rel="noopener"
                          endDecorator={<Launch fontSize="small" />}
                        >
                          ...{source.annotation[0].snippet_before}{" "}
                          {source.annotation[0].lexical_variant}{" "}
                          {source.annotation[0].snippet_after}...
                        </Link>
                      ))}
                    </div>
                  ))}
                </Stack>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  );
}
