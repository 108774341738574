import Typography from "@mui/joy/Typography";
import React from "react";

export default function Footer() {
  const [rawBackendVersion, setrawBackendVersion] = React.useState<string>("unknown");

  async function getRawBackendVersion() {
    try {
      const response = await fetch(`${window.CONFIG.backendUrl}/version`);
      return await response.text();
    } catch (e) {
      console.error(e);
      return "unknown";
    }
  }

  React.useEffect(() => {
    async function fetchRawBackendVersion() {
      const version = await getRawBackendVersion();
      setrawBackendVersion(version);
    }
    if (window.CONFIG.env === "dev" || window.CONFIG.env === "uat") {
      fetchRawBackendVersion();
    }
  }, []);

  let shortFrontendVersion = "Unknown";
  try {
    shortFrontendVersion = window.CONFIG ? window.CONFIG.frontendVersion.split("-")[0] : "";
    if (shortFrontendVersion.includes(" ")) {
      shortFrontendVersion = shortFrontendVersion.split(" ")[0];
    }
  } catch (e) {
    console.error(e);
  }

  let backendSoftwareVersion = "Unknown";
  let dataVersion: { [key: string]: string } = {};
  let dataVersionString: string = "";
  if (rawBackendVersion !== "unknown") {
    backendSoftwareVersion = JSON.parse(rawBackendVersion)["software_version"];
    dataVersion = JSON.parse(rawBackendVersion)["data_version"];
    let prettyDate = new Date(Number(dataVersion.date) * 1000).toISOString();
    dataVersionString = `generated at ${prettyDate} by ${dataVersion.user}@${dataVersion.machine}`;
  }

  return (
    <footer style={{ paddingRight: "16px", paddingLeft: "16px", marginBottom: "8px" }}>
      <div style={{ textAlign: "center" }}>
        <Typography level="body-xs">
          Created by <a href="https://www.gimli-med.io">Gimli</a> ({shortFrontendVersion}). For
          research purposes only. <a href="mailto:alexandre@gimli-med.io">Give us feedback</a> -{" "}
          <a href="https://www.gimli-med.io/privacy-policy">Privacy Policy</a> -{" "}
          <a href="https://www.gimli-med.io/legal-notice">Legal Notice</a> -{" "}
          <a href="https://www.gimli-med.io/terms-of-use">Terms of use</a>
          <br />
          {window.CONFIG && (window.CONFIG.env === "dev" || window.CONFIG.env === "uat") && (
            <Typography sx={{ color: "#bbb" }}>
              Backend: {backendSoftwareVersion} | Front: {window.CONFIG.frontendVersion} | Data:{" "}
              {dataVersionString}
            </Typography>
          )}
        </Typography>
      </div>
    </footer>
  );
}
