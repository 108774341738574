import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Button from "@mui/joy/Button";
import DialogActions from "@mui/joy/DialogActions";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import Divider from "@mui/joy/Divider";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import React from "react";

interface RefreshModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleRefresh: () => void;
}

export default function RefreshModal({ open, setOpen, handleRefresh }: RefreshModalProps) {
  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            The fact detection algorithms will be run again.{" "}
            <strong>All manual changes will be erased.</strong>
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              onClick={() => {
                handleRefresh();
                setOpen(false);
              }}
            >
              Refresh
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
