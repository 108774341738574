import Snackbar from "@mui/joy/Snackbar";
import { useState } from "react";

interface SnackbarNotificationProps {
  text: string;
  color: "primary" | "neutral" | "danger" | "success" | "warning";
}

export default function SnackbarNotification({ text, color }: SnackbarNotificationProps) {
  const [message, setMessage] = useState<string | null>(text);
  return (
    <Snackbar
      open={!!message}
      autoHideDuration={4000}
      variant="plain"
      size="md"
      color={color}
      onClose={() => {
        setMessage(null);
      }}
    >
      {message}
    </Snackbar>
  );
}
