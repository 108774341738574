import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AdminPage from "./admin/AdminPage";
import App from "./App";
import { AWSCognitoUserInfoProvider, IdentityProviderClient } from "./clients/cloud/auth";
import {
  IdentityProviderClientContext,
  SimpleAuthenticationServiceContext,
  UserInfoProviderContext,
} from "./clients/contexts";
import {
  AuthenticationQuartzClient,
  SimpleAuthenticationService,
  SimpleUserInfoProvider,
} from "./clients/onprem/auth";
import CohortBuilder from "./cohort/CohortBuilder";
import { CohortPage } from "./cohort/CohortPage";
import { ExportBuilder } from "./export/ExportBuilder";
import "./index.css";
import NoteById from "./modules/notes/NoteById";
import { AddNewNote } from "./note/AddNewNote";
import { AllNotesPage } from "./note/AllNotesPage";
import { UploadDocumentPage } from "./note/UploadDocumentPage";
import { CLOUD_AUTH_ROUTES } from "./pages/cloud/routes";
import UserProfile from "./pages/cloud/UserProfile";
import ErrorPage from "./pages/Error";
import { ONPREM_AUTH_ROUTES } from "./pages/onprem/routes";
import SimpleUserProfile from "./pages/onprem/SimpleUserProfile";
import { ListPatientsPage } from "./patient/ListPatientPage";
import { PatientPage } from "./patient/PatientPage";
import reportWebVitals from "./reportWebVitals";
import StudiesPage from "./study/StudiesPage";
import StudyPage from "./study/StudyPage";

const router = createBrowserRouter([
  {
    path: "/auth/",
    errorElement: <ErrorPage />,
    children: window.CONFIG.auth.kind === "AWS_COGNITO" ? CLOUD_AUTH_ROUTES : ONPREM_AUTH_ROUTES,
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <App />,
    children: [
      {
        path: "user/profile",
        element:
          window.CONFIG.auth.kind === "AWS_COGNITO" ? <UserProfile /> : <SimpleUserProfile />,
      },
      {
        path: "add-document",
        element: <UploadDocumentPage />,
      },
      {
        path: "add-note",
        element: <AddNewNote />,
      },
      {
        path: "",
        element: <ListPatientsPage />,
      },
      {
        path: "cohorts",
        element: <CohortPage />,
      },
      {
        path: "studies",
        element: <StudiesPage />,
      },
      {
        path: "study/:id",
        element: <StudyPage />,
      },
      {
        path: "cohort/:id",
        element: <CohortBuilder />,
      },
      {
        path: "cohort/:id/export",
        element: <ExportBuilder />,
      },
      {
        path: "notes",
        element: <AllNotesPage />,
      },
      {
        path: "patients",
        element: <ListPatientsPage />,
      },
      {
        path: "patient/:id",
        element: <PatientPage />,
      },
      {
        path: "note/:id",
        element: <NoteById />,
        errorElement: <ErrorPage />,
      },
      {
        path: "admin",
        element: <AdminPage />,
      },
    ],
  },
]);

const CloudRoot: React.FC<{}> = () => {
  const [authState, setAuthState] = React.useState<any>(IdentityProviderClient.getInitialState());
  const [loadingUser, setLoadingUser] = React.useState<boolean>(true);

  const identityProviderClient = React.useMemo(
    () => new IdentityProviderClient(authState, setAuthState),
    [authState, setAuthState]
  );
  const userInfoProvider = React.useMemo(
    () => new AWSCognitoUserInfoProvider(identityProviderClient),
    [identityProviderClient]
  );

  React.useEffect(() => {
    if (window.DISABLE_AUTH) {
      setLoadingUser(false);
    } else {
      IdentityProviderClient.initialize(setAuthState).finally(() => {
        setLoadingUser(false);
      });
    }
  }, [setLoadingUser, setAuthState]);

  if (loadingUser) {
    return <></>;
  }

  return (
    <React.StrictMode>
      <IdentityProviderClientContext.Provider value={identityProviderClient}>
        <UserInfoProviderContext.Provider value={userInfoProvider}>
          <RouterProvider router={router} />
        </UserInfoProviderContext.Provider>
      </IdentityProviderClientContext.Provider>
    </React.StrictMode>
  );
};

const OnpremRoot: React.FC<{}> = () => {
  const [authState, setAuthState] = React.useState<any>(
    SimpleAuthenticationService.getInitialState()
  );
  const authenticationService = new SimpleAuthenticationService(
    new AuthenticationQuartzClient(
      window.CONFIG.backendUrl,
      SimpleAuthenticationService.authorizationHeader
    ),
    authState,
    setAuthState
  );
  const userInfoProvider = new SimpleUserInfoProvider(authenticationService);

  return (
    <React.StrictMode>
      <SimpleAuthenticationServiceContext.Provider value={authenticationService}>
        <UserInfoProviderContext.Provider value={userInfoProvider}>
          <RouterProvider router={router} />
        </UserInfoProviderContext.Provider>
      </SimpleAuthenticationServiceContext.Provider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if (window.CONFIG.auth.kind === "AWS_COGNITO") {
  root.render(<CloudRoot />);
} else {
  root.render(<OnpremRoot />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
