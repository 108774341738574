import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "bulma/css/bulma.css";
import React from "react";
import { Navigate, Outlet, matchPath, useLocation } from "react-router-dom";
import { UserProvider } from "./admin/UserProvider";
import {
  AccountLifecycleQuartzClientContext,
  AdminQuartzClientContext,
  AnalyticsClientContext,
  CohortQuartzClientContext,
  DocumentQuartzClientContext,
  NoteQuartzClientContext,
  PersonQuartzClientContext,
  QuestionAnsweringClientContext,
  StudyQuartzClientContext,
  TaskQuartzClientContext,
  TerminologyQuartzClientContext,
  UserInfoProviderContext,
} from "./clients/contexts";
import { QuartzClient } from "./clients/quartz";
import { initialize } from "./config";
import { BundleId } from "./models/bundle";
import PageLayout from "./pages/PageLayout";

initialize();

type BundleContextType = [BundleId | null, React.Dispatch<React.SetStateAction<BundleId | null>>];
export const BundleContext = React.createContext<BundleContextType>(
  null as unknown as BundleContextType
);

export default function App() {
  const auth = React.useContext(UserInfoProviderContext);
  const [bundleId, setBundleId] = React.useState<BundleId | null>(null);

  const location = useLocation();
  let noLayout = false;

  const match_note = matchPath(
    {
      path: "/note/:id",
    },
    location.pathname
  );
  if (match_note) noLayout = true;

  const quartzClientRef = React.useRef(
    new QuartzClient(window.CONFIG.backendUrl, () => {
      if (window.DISABLE_AUTH) {
        return Promise.resolve(null);
      } else {
        return auth.getAccessToken().then((token) => `Bearer ${token}`);
      }
    })
  );

  if (!window.DISABLE_AUTH && !auth.isAuthenticated()) {
    return <Navigate to="/auth/signin" />;
  }

  return (
    <AccountLifecycleQuartzClientContext.Provider value={quartzClientRef.current.accountLifecycle}>
      <AnalyticsClientContext.Provider value={quartzClientRef.current.analytics}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TerminologyQuartzClientContext.Provider value={quartzClientRef.current.terminology}>
            <UserProvider>
              <TaskQuartzClientContext.Provider value={quartzClientRef.current.task}>
                <BundleContext.Provider value={[bundleId, setBundleId]}>
                  <NoteQuartzClientContext.Provider value={quartzClientRef.current.note}>
                    <StudyQuartzClientContext.Provider value={quartzClientRef.current.study}>
                      <CohortQuartzClientContext.Provider value={quartzClientRef.current.cohort}>
                        <AdminQuartzClientContext.Provider value={quartzClientRef.current.admin}>
                          <PersonQuartzClientContext.Provider
                            value={quartzClientRef.current.person}
                          >
                            <QuestionAnsweringClientContext.Provider
                              value={quartzClientRef.current.questionAnswering}
                            >
                              <DocumentQuartzClientContext.Provider
                                value={quartzClientRef.current.document}
                              >
                                <PageLayout noLayout={noLayout}>
                                  <Outlet />
                                </PageLayout>
                              </DocumentQuartzClientContext.Provider>
                            </QuestionAnsweringClientContext.Provider>
                          </PersonQuartzClientContext.Provider>
                        </AdminQuartzClientContext.Provider>
                      </CohortQuartzClientContext.Provider>
                    </StudyQuartzClientContext.Provider>
                  </NoteQuartzClientContext.Provider>
                </BundleContext.Provider>
              </TaskQuartzClientContext.Provider>
            </UserProvider>
          </TerminologyQuartzClientContext.Provider>
        </LocalizationProvider>
      </AnalyticsClientContext.Provider>
    </AccountLifecycleQuartzClientContext.Provider>
  );
}
