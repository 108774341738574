import Box from "@mui/joy/Box";
import Drawer from "@mui/joy/Drawer";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import dayjs from "dayjs";
import React from "react";
import { ClinicalEvent, EventSource } from "../../models/clinical_event";
import { UnixTimestamp } from "../../models/common";
import ProofChipDrawerContent from "../QuestionAnswering/ProofChipDrawerContent";
import { unixToDayjs } from "../../utils/time";
import TableSkeleton from "../skeletons/TableSkeleton";
import FactsMonthCalendar from "./FactsMonthCalendar";
import FactsYearCalendar from "./FactsYearCalendar";

const dayOfYear = require("dayjs/plugin/dayOfYear");
dayjs.extend(dayOfYear);
// add typing for dayjs to exgtend the library with the dayOfYear plugin
declare module "dayjs" {
  interface Dayjs {
    dayOfYear(): number;
  }
}

interface FactsTimelineProps {
  eventsByName: { [key: string]: Array<ClinicalEvent> };
}

const YEARLY = "yearly";
const MONTHLY = "monthly";

export default function FactsTimeline({ eventsByName }: FactsTimelineProps) {
  const [temporalityView, setTemporalityView] = React.useState(YEARLY);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerName, setDrawerName] = React.useState("");
  const [drawerEventSource, setDrawerEventSource] = React.useState<EventSource[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [mostRecentDate, setMostRecentDate] = React.useState<UnixTimestamp | null>(null);
  const [mostAncientDate, setMostAncientDate] = React.useState<UnixTimestamp | null>(null);

  React.useEffect(() => {
    // Get all unique dates
    const start_dates = Array.from(
      new Set(
        Object.values(eventsByName)
          .flat()
          .map((event) => event.start_date)
      )
    ).sort((a, b) => b - a);
    const end_dates = Array.from(
      new Set(
        Object.values(eventsByName)
          .flat()
          // only add end_date if it's not null
          .filter((event) => event.end_date !== null)
          .map((event) => event.end_date)
      )
    ).sort((a, b) => b! - a!);

    const dates = Array.from(new Set([...start_dates, ...end_dates])).sort((a, b) => b! - a!);
    setMostRecentDate(dates[0]);
    setMostAncientDate(dates[dates.length - 1]);
  }, [eventsByName]);

  const openDrawer = (name: string, facts: ClinicalEvent[]) => {
    setDrawerName(name);
    setDrawerEventSource(facts.flatMap((fact) => fact.source));
    setDrawerOpen(true);
  };

  const treatmentLengthDisplay = (
    start_date: UnixTimestamp,
    end_date: UnixTimestamp | null,
    minimalLength: number
  ) => {
    if (end_date) {
      const length = unixToDayjs(end_date)!.diff(unixToDayjs(start_date), "day") + 1;
      if (minimalLength && length > minimalLength) {
        return unixToDayjs(end_date)!.diff(unixToDayjs(start_date), "day") + 1 + " days";
      }
    }
    return "";
  };

  const tooltipDateTitle = (fact: ClinicalEvent, maximalLengthToDisplayDaysCount?: number) => {
    if (fact.end_date) {
      let text =
        "from " +
        unixToDayjs(fact.start_date)?.format("DD MMM YYYY") +
        " to " +
        unixToDayjs(fact.end_date)?.format("DD MMM YYYY");
      if (maximalLengthToDisplayDaysCount) {
        const length = unixToDayjs(fact.end_date)!.diff(unixToDayjs(fact.start_date), "day") + 1;
        if (length < maximalLengthToDisplayDaysCount) {
          text += " (" + length + " days)";
        }
      }
      return text;
    } else {
      return "1 day on " + unixToDayjs(fact.start_date)?.format("DD MMM YYYY");
    }
  };

  React.useEffect(() => {
    setLoading(true);
  }, [temporalityView]);

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{ width: "400px" }}
      >
        <ProofChipDrawerContent eventSources={drawerEventSource} name={drawerName} />
      </Drawer>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1, mb: 2 }}
      >
        <RadioGroup
          orientation="horizontal"
          aria-labelledby="segmented-controls-example"
          name="justify"
          value={temporalityView}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setTemporalityView(event.target.value)
          }
          sx={{
            minHeight: 48,
            padding: "4px",
            borderRadius: "12px",
            bgcolor: "neutral.softBg",
            "--RadioGroup-gap": "4px",
            "--Radio-actionRadius": "8px",
          }}
        >
          {[YEARLY, MONTHLY].map((item) => (
            <Radio
              key={item}
              color="neutral"
              value={item}
              disableIcon
              label={item}
              variant="plain"
              sx={{
                px: 2,
                alignItems: "center",
              }}
              slotProps={{
                action: ({ checked }) => ({
                  sx: {
                    ...(checked && {
                      bgcolor: "background.surface",
                      boxShadow: "sm",
                      "&:hover": {
                        bgcolor: "background.surface",
                      },
                    }),
                  },
                }),
              }}
            />
          ))}
        </RadioGroup>
      </Box>
      {mostRecentDate && mostAncientDate && (
        <Sheet
          variant="outlined"
          sx={{
            "--TableCell-height": "40px",
            // the number is the amount of the header rows.
            "--TableHeader-height": "calc(1 * var(--TableCell-height))",
            "--Table-firstColumnWidth": "200px",
            overflow: "auto",
            backgroundSize:
              "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "local, local, scroll, scroll",
            backgroundPosition:
              "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
            backgroundColor: "background.surface",
            borderRadius: "8px",
          }}
        >
          {temporalityView === MONTHLY ? (
            <>
              {loading && <TableSkeleton />}
              <Box sx={{ opacity: loading ? 0 : 1 }}>
                <FactsMonthCalendar
                  tx_facts={eventsByName}
                  mostRecentDate={mostRecentDate}
                  mostAncientDate={mostAncientDate}
                  openDrawer={openDrawer}
                  treatmentLengthDisplay={treatmentLengthDisplay}
                  tooltipDateTitle={tooltipDateTitle}
                  hasRendered={() => setLoading(false)}
                />
              </Box>
            </>
          ) : (
            <FactsYearCalendar
              tx_facts={eventsByName}
              mostRecentDate={mostRecentDate}
              mostAncientDate={mostAncientDate}
              openDrawer={openDrawer}
              treatmentLengthDisplay={treatmentLengthDisplay}
              tooltipDateTitle={tooltipDateTitle}
            />
          )}
        </Sheet>
      )}
    </>
  );
}
