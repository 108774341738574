import Box from "@mui/joy/Box";
import Skeleton from "@mui/joy/Skeleton";
import Stack from "@mui/joy/Stack";

export default function TableSkeleton({ includeFilters = false }: { includeFilters?: boolean }) {
  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          background: "linear-gradient(0deg, rgba(255,255,255,1) 15%, rgba(255,149,0,0) 100%)",
          width: "100%",
          height: "100%",
          zIndex: 99,
        }}
      >
        &nbsp;
      </Box>
      <Stack direction="column" spacing={1}>
        {includeFilters && (
          <Stack direction="row" spacing={2} sx={{ height: "60px" }}>
            {Array.from({ length: 3 }, (_, index) => (
              <Box key={index} sx={{ height: "150px", width: "250px" }}>
                <Skeleton animation="wave" sx={{ width: "100px", height: "12px" }} />
                <Skeleton animation="wave" sx={{ mt: "16px", width: "250px", height: "36px" }} />
              </Box>
            ))}
          </Stack>
        )}
        {Array.from({ length: 30 }, (_, index) => (
          <Box sx={{ height: "36px" }} key={index}>
            <Skeleton animation="wave" sx={{ height: "36px" }} />
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
