import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Checkbox from "@mui/joy/Checkbox";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import AlertDialogModal from "../common/modals/AlertDialogModal";
import { Cohort, CohortId } from "../models/cohort";
import { UnixTimestamp } from "../models/common";
import { date_from_timestamp } from "../utils/time";

interface CohortListProps {
  cohorts: Cohort[];
  deleteCohort: (ids: CohortId[]) => void;
}

export const CohortList = ({ cohorts, deleteCohort }: CohortListProps) => {
  const navigate = useNavigate();
  const [openDeleteCohort, setOpenDeleteCohort] = React.useState(false);
  const [selectedCohorts, setSelectedCohorts] = React.useState<CohortId[]>([]);
  const [deleting, setDeleting] = React.useState(false);

  async function handleDeleteCohort() {
    function reset() {
      setSelectedCohorts([]);
      setOpenDeleteCohort(false);
      setDeleting(false);
    }
    if (selectedCohorts) {
      setDeleting(true);
      await deleteCohort(selectedCohorts);
      reset();
    } else {
      reset();
    }
  }
  return (
    <>
      <AlertDialogModal
        alertMessage="Are you sure you want to delete this cohort?"
        open={openDeleteCohort}
        setOpen={setOpenDeleteCohort}
        handleDelete={handleDeleteCohort}
        loading={deleting}
      />
      {cohorts.length === 0 ? (
        <Sheet
          color="neutral"
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
            borderRadius: "sm",
          }}
        >
          <Typography level="h2" color="neutral">
            No cohort here yet
          </Typography>
        </Sheet>
      ) : (
        <Sheet variant="outlined" sx={{ borderRadius: "sm", height: "70vh", overflow: "auto" }}>
          <Table
            aria-label="cohort table"
            stickyHeader
            hoverRow
            sx={{
              "--TableCell-headBackground": "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 48, textAlign: "center", padding: "12px 6px" }}>
                  <Checkbox
                    size="sm"
                    indeterminate={
                      selectedCohorts.length > 0 && selectedCohorts.length !== cohorts.length
                    }
                    checked={selectedCohorts.length === cohorts.length}
                    onChange={(event) => {
                      setSelectedCohorts(
                        event.target.checked ? cohorts.map((row) => row.cohort_id) : []
                      );
                    }}
                    color={
                      selectedCohorts.length > 0 || selectedCohorts.length === cohorts.length
                        ? "primary"
                        : undefined
                    }
                    sx={{ verticalAlign: "text-bottom" }}
                  />
                </th>
                <th style={{ width: "40%", padding: "12px 6px" }}>Name</th>
                <th style={{ padding: "12px 6px" }}>Number of patients</th>
                <th style={{ padding: "12px 6px" }}>Last updated date</th>
                <th style={{ width: "52px" }}>
                  {selectedCohorts.length > 0 && (
                    <IconButton
                      onClick={() => {
                        setOpenDeleteCohort(true);
                      }}
                      color="danger"
                      variant="outlined"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {cohorts.map((cohort: Cohort, key: number) => (
                <tr
                  key={key}
                  onClick={(event) => navigate(`/cohort/${cohort.cohort_id}`)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <td
                    style={{ textAlign: "center", width: 120, verticalAlign: "middle" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Checkbox
                      size="sm"
                      checked={selectedCohorts.includes(cohort.cohort_id)}
                      color={selectedCohorts.includes(cohort.cohort_id) ? "primary" : undefined}
                      onChange={(event) => {
                        setSelectedCohorts((ids) =>
                          event.target.checked
                            ? ids.concat(cohort.cohort_id)
                            : ids.filter((itemId) => itemId !== cohort.cohort_id)
                        );
                      }}
                      slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                      sx={{ verticalAlign: "text-bottom" }}
                    />
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <Typography>
                      {cohort.name}{" "}
                      {cohort.snapshot_id && <Typography level="body-xs">(frozen)</Typography>}
                    </Typography>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    {cohort.subjects && cohort.subjects.length !== 0
                      ? cohort.subjects.length + " patients"
                      : "0 patient"}
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    {date_from_timestamp(cohort.update_date as UnixTimestamp)}
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      )}
    </>
  );
};
