import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useLocation } from "react-router-dom";
import { StudyQuartzClientContext } from "../clients/contexts";
import { Loading } from "../common/loading/Loading";
import {
  Participant,
  ParticipantByState,
  ParticipantId,
  PutStudyRequest,
  Study,
  StudyId,
} from "../models/study";
import { formatAndCapitalizeString } from "../utils/text";
import CohortSmallCard from "./cards/CohortSmallCard";
import StudyStateCard from "./cards/StudyStateCard";
import StudyCohortDrawer from "./drawers/StudyCohortDrawer";
import StudyParticipantDrawer from "./drawers/StudyParticipantDrawer";
import EditStudyModal from "./modals/EditStudyModal";
import ParticipantsTable from "./tables/ParticipantsTable";

export const StudyContext = React.createContext<Study>(null as unknown as Study);

export const getParticipantCurrentState = (participant: Participant) => {
  return participant.progress.reduce((max, current) => {
    return new Date(max.start_date) > new Date(current.start_date) ? max : current;
  });
};

export default function StudyPage() {
  const location = useLocation();
  const studyId = location.pathname.split("/")[2] as StudyId;
  const studyQuartzClient = React.useContext(StudyQuartzClientContext);
  const [study, setStudy] = React.useState<Study | null>(null);
  const [participants, setParticipants] = React.useState<Participant[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<ParticipantId[]>([]);
  const [selectedParticipants, setSelectedParticipants] = React.useState<Participant[]>([]);
  const [participantByState, setParticipantByState] = React.useState<ParticipantByState[]>([]);
  const [editStudy, setEditStudy] = React.useState(false);
  const [openCohortDrawer, setOpenCohortDrawer] = React.useState(false);
  const [refreshTime, setRefreshTime] = React.useState(new Date().getTime());

  const refresh = () => {
    setOpenCohortDrawer(false);
    setSelected([]);
    setEditStudy(false);
    setRefreshTime(new Date().getTime());
  };

  const handleUpdateStudy = async (study: Study) => {
    const updateStudyRequest = {
      title: study.title,
      cohort_id: study.cohort_id,
      acronym: study.acronym,
      description: study.description,
      phase: study.phase,
    } as PutStudyRequest;
    try {
      await studyQuartzClient.updateStudy(study.study_id, updateStudyRequest);
      refresh();
    } catch (err: any) {
      console.log(err.message);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await studyQuartzClient.getStudyById(studyId);
        setStudy(response);
        const participants = await studyQuartzClient.getParticipantsByStudyId(studyId);
        setParticipants(participants.entry as Participant[]);
        const studyStats = await studyQuartzClient.getStudyStats(studyId);
        setParticipantByState(studyStats.participant_by_state);
      } catch (e: any) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [studyQuartzClient, studyId, refreshTime]);

  React.useEffect(() => {
    if (selected.length > 0) {
      const selectedParticipants = participants.filter((participant) =>
        selected.includes(participant.participant_id)
      );
      setSelectedParticipants(selectedParticipants);
    } else {
      setSelectedParticipants([]);
    }
  }, [selected, participants]);

  const toggleCohortDrawer = () => {
    setOpenCohortDrawer(!openCohortDrawer);
  };

  if (study === null || loading) {
    return <Loading />;
  }

  return (
    <Box sx={{ p: 4 }}>
      <StudyContext.Provider value={study}>
        <Stack sx={{ flewGrow: 1 }} direction="row">
          <Box sx={{ flex: 1 }}>
            {study.acronym && <Typography level="h2">{study.acronym}</Typography>}
            <Typography sx={{ mt: 1, maxWidth: "80%", minWidth: "50%" }}>{study.title}</Typography>
            <Box sx={{ width: "100%", mt: 4 }}>
              <Box sx={{ mt: 4, width: "100%" }}>
                <ParticipantsTable
                  study={study}
                  participants={participants}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: 0 }}>
            <Stack sx={{ width: "100%" }} direction="row">
              {openCohortDrawer ? (
                <Box sx={{ width: "450px" }}>
                  <StudyCohortDrawer
                    study={study}
                    cohortId={study.cohort_id!}
                    onClose={() => setOpenCohortDrawer(false)}
                    handleUpdateStudy={handleUpdateStudy}
                  />
                </Box>
              ) : selected.length > 0 ? (
                <Box sx={{ width: "450px" }}>
                  <StudyParticipantDrawer
                    selectedIds={selected}
                    selectedParticipants={selectedParticipants}
                    participantByState={participantByState}
                    closeModal={() => setSelected([])}
                    refresh={refresh}
                  />
                </Box>
              ) : (
                <Box sx={{ width: "33%", minWidth: "300px", ml: 4 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography level="h4">About</Typography>
                    <IconButton variant="plain" onClick={() => setEditStudy(true)} color="neutral">
                      <SettingsIcon />
                    </IconButton>
                  </Stack>
                  <Stack direction="column" gap={2}>
                    {study.description ? (
                      <Typography sx={{ mt: 1 }} level="body-sm">
                        {study.description}
                      </Typography>
                    ) : (
                      <Typography sx={{ mt: 1 }} level="body-sm">
                        No description provided.
                      </Typography>
                    )}
                    <Typography level="body-sm" color="neutral">
                      Phase:{" "}
                      {study.phase ? formatAndCapitalizeString(study.phase) : "Not specified"}
                    </Typography>
                    <Typography level="body-sm" color="neutral">
                      Sponsor:{" "}
                      {study.associated_parties.filter((ap) => ap.role === "sponsor").length > 0
                        ? study.associated_parties
                            .filter((ap) => ap.role === "sponsor")
                            .map((ap, index) => (
                              <span key={index}>{formatAndCapitalizeString(ap.name)}</span>
                            ))
                        : "Not specified"}
                    </Typography>
                    <Typography level="body-sm" color="neutral">
                      Principal investigator:{" "}
                      {study.associated_parties.filter((ap) => ap.role === "principal-investigator")
                        .length > 0
                        ? study.associated_parties
                            .filter((ap) => ap.role === "principal-investigator")
                            .map((ap, index) => (
                              <span key={index}>{formatAndCapitalizeString(ap.name)}</span>
                            ))
                        : "Not specified"}
                    </Typography>
                    <Divider />
                    <Typography level="title-lg">Study state</Typography>
                    <StudyStateCard study={study} refresh={refresh} />
                    <Divider />
                    <Typography level="title-lg">Participants by status</Typography>
                    {participantByState.length > 0 && (
                      <Stack direction="column" gap={1}>
                        {participantByState.map((pbs, index) => (
                          <Stack key={index} direction="row" justifyContent="space-between">
                            <Typography level="body-md">
                              {formatAndCapitalizeString(pbs.state)}
                            </Typography>
                            <Typography level="body-md">{pbs.count}</Typography>
                          </Stack>
                        ))}
                      </Stack>
                    )}
                    <Divider />
                    <Typography level="title-lg">Linked cohorts</Typography>
                    <CohortSmallCard
                      study={study}
                      cohortId={study.cohort_id}
                      actionButton={() => toggleCohortDrawer()}
                      openCohortDrawer={openCohortDrawer}
                      handleUpdateStudy={handleUpdateStudy}
                    />
                    <Divider />
                    <Typography level="title-lg">Collaborators</Typography>
                    {study.associated_parties.filter((ap) => ap.role === "collaborator").length >
                    0 ? (
                      <Stack direction="column" gap={1}>
                        {study.associated_parties
                          .filter((ap) => ap.role === "collaborator")
                          .map((ap, index) => (
                            <Stack key={index} direction="row" justifyContent="space-between">
                              <Typography level="body-md">
                                {formatAndCapitalizeString(ap.name)}
                              </Typography>
                            </Stack>
                          ))}
                      </Stack>
                    ) : (
                      <Typography color="neutral" level="body-md">
                        Not specified
                      </Typography>
                    )}
                    <Divider />
                    <Typography level="title-lg">Study design</Typography>
                    {study.study_design.length > 0 ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ flexWrap: "wrap" }}>
                          {study.study_design.map((sd, index) => (
                            <Chip key={index}># {formatAndCapitalizeString(sd)}</Chip>
                          ))}
                        </Box>
                      </Box>
                    ) : (
                      <Typography color="neutral" level="body-md">
                        Not specified
                      </Typography>
                    )}
                  </Stack>
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>

        {editStudy && (
          <EditStudyModal study={study} onClose={() => setEditStudy(false)} refresh={refresh} />
        )}
      </StudyContext.Provider>
    </Box>
  );
}
