import Add from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Switch from "@mui/joy/Switch";
import ToggleButtonGroup from "@mui/joy/ToggleButtonGroup";
import Typography from "@mui/joy/Typography";
import { Dayjs } from "dayjs";
import React from "react";
import { UnixTimestamp } from "../../../models/common";
import { Gender, Identifier, Person } from "../../../models/person";
import { generateUnixTimestamp, unixToDayjs } from "../../../utils/time";
import { JoyDatePicker } from "../../notes/components/Datepickers/JoyDatePicker";

type PatientFormProps = {
  patient: Person;
  onSubmit: (person: Person) => void;
};

export function PatientForm({ patient, onSubmit }: PatientFormProps) {
  const [person, setPerson] = React.useState<Person>(patient);
  const [errors, setErrors] = React.useState({
    gender: false,
    birth_date: false,
    nameOrIdentifier: false,
  });
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [consentDate, setConsentDate] = React.useState<Dayjs | null>(null);
  const [otherIdentifier, setOtherIdentifier] = React.useState<Identifier[]>([]);

  const getIdentifierBySystem = (system: string) => {
    const identifier = person.external_ids?.find((id) => id.system === system);
    return identifier ? identifier.value : "";
  };

  const handleAddIdentifier = () => {
    setOtherIdentifier((prev) => [...prev, { system: "", value: "" }]);
  };

  const handleIdentifierChange = (system: string, newValue: string) => {
    setPerson((prev) => {
      let newExternalIds = prev.external_ids || [];
      const index = newExternalIds.findIndex((id) => id.system === system);

      if (index !== -1) {
        newExternalIds[index].value = newValue;
      } else {
        newExternalIds = [...newExternalIds, { value: newValue, system }];
      }

      return { ...prev, external_ids: newExternalIds };
    });
  };

  const handleGenderChange = (newGender: Gender | null) => {
    if (newGender !== null) {
      setPerson({
        ...person,
        gender: newGender,
      });
    }
  };

  const handleDeceasedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setPerson((prev) => ({
      ...prev,
      deceased: checked,
      deceased_date: checked ? prev.deceased_date : null, // reset deceased_date if not deceased
    }));
  };

  const handleDeceasedDateChange = (newDeceasedDate: Dayjs | null) => {
    if (newDeceasedDate !== null) {
      setPerson({
        ...person,
        deceased_date: newDeceasedDate.valueOf() as UnixTimestamp,
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPerson({
      ...person,
      [name]: value,
    });
  };

  const handleBirthDateChange = (newBirthDate: Dayjs) => {
    setPerson({
      ...person,
      birth_date: newBirthDate.valueOf() as UnixTimestamp,
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    person.external_ids = [
      ...(person.external_ids?.filter((id) => id.system === "INS" || id.system === "IPP/NIP") ||
        []),
      ...otherIdentifier,
    ];
    onSubmit(person);
  };

  React.useEffect(() => {
    // Set other identifiers (not INS or IPP/NIP)
    const otherIds = person.external_ids?.filter(
      (id) => id.system !== "INS" && id.system !== "IPP/NIP"
    );
    setOtherIdentifier(otherIds || []);
    setConsentDate(
      person.consent_withdrawal_date ? unixToDayjs(person.consent_withdrawal_date) : null
    );

    // Check if gender and birth date are provided
    const genderError = !person.gender;
    const birthDateError = !person.birth_date;

    // Check if either full name or at least one identifier is provided
    const nameOrIdentifierError = !(
      (person.given_name && person.family_name) ||
      (person.external_ids &&
        person.external_ids.filter(
          (id) =>
            (id.system === "INS" && id.value.length > 0) ||
            (id.system === "IPP/NIP" && id.value.length > 0)
        ).length > 0)
    );

    setErrors({
      gender: genderError,
      birth_date: birthDateError,
      nameOrIdentifier: nameOrIdentifierError,
    });

    setIsFormValid(!(genderError || birthDateError || nameOrIdentifierError));
  }, [person]);

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Box sx={{ m: 1 }}>
        <Stack spacing={2} direction="column">
          {!isFormValid && (
            <Alert
              sx={{ alignItems: "flex-start" }}
              color="danger"
              startDecorator={<WarningIcon />}
              variant="soft"
            >
              <div>
                <div>Missing fields:</div>

                {errors.gender && (
                  <Typography level="body-sm" color="danger">
                    - Gender is required.
                  </Typography>
                )}
                {errors.birth_date && (
                  <Typography level="body-sm" color="danger">
                    - Birth date is required.
                  </Typography>
                )}
                {errors.nameOrIdentifier && (
                  <Typography level="body-sm" color="danger">
                    - Either a full name or an identifier is required.
                  </Typography>
                )}
              </div>
            </Alert>
          )}
          <FormControl>
            <FormLabel>Given name</FormLabel>
            <Input
              required
              value={person.given_name || ""}
              onChange={handleInputChange}
              name="given_name"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Family name</FormLabel>
            <Input
              required
              value={person.family_name || ""}
              onChange={handleInputChange}
              name="family_name"
            />
          </FormControl>
          <Stack direction="row" spacing={1} alignItems="center">
            <div data-cy="birth-date-picker">
              <JoyDatePicker
                disableFuture
                label="Birth date"
                value={unixToDayjs(person.birth_date)}
                onChange={(x) => x && handleBirthDateChange(x)}
                format="DD MMM YYYY"
              />
            </div>
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <ToggleButtonGroup
                color="primary"
                value={person.gender}
                onChange={(event: any, newValue: Gender | null) => handleGenderChange(newValue)}
                aria-label="Gender"
                data-cy="gender-toggle-group"
              >
                <Button value="Male">Male</Button>
                <Button value="Female">Female</Button>
              </ToggleButtonGroup>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Alive or unknown</Typography>
            <Switch checked={person.deceased ? true : false} onChange={handleDeceasedChange} />
            <Typography>Deceased</Typography>
          </Stack>

          {person.deceased && (
            <JoyDatePicker
              disableFuture
              format="DD MMM YYYY"
              label="Deceased date"
              value={unixToDayjs(person.deceased_date)}
              onChange={handleDeceasedDateChange}
            />
          )}

          <Divider />
          <Typography level="title-lg">Identifiers</Typography>
          <FormControl>
            <FormLabel>INS (NIR/NIA)</FormLabel>
            <Input
              value={getIdentifierBySystem("INS")}
              onChange={(event) => handleIdentifierChange("INS", event.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>IPP/NIP</FormLabel>
            <Input
              value={getIdentifierBySystem("IPP/NIP")}
              onChange={(event) => handleIdentifierChange("IPP/NIP", event.target.value)}
            />
          </FormControl>
          {otherIdentifier.length > 0 && (
            <FormControl>
              <FormLabel>Other identifier(s)</FormLabel>
              <Stack direction="column" spacing={1}>
                {otherIdentifier.map((identifier, index) => (
                  <Stack direction="row" spacing={1} key={index}>
                    <Input
                      placeholder="System"
                      value={identifier.system || ""}
                      onChange={(event) => {
                        const newSystem = event.target.value;
                        setOtherIdentifier((prev) => {
                          const newIdentifiers = [...prev];
                          newIdentifiers[index].system = newSystem;
                          return newIdentifiers;
                        });
                      }}
                    />
                    <Input
                      placeholder="Value"
                      value={identifier.value}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        setOtherIdentifier((prev) => {
                          const newIdentifiers = [...prev];
                          newIdentifiers[index].value = newValue;
                          return newIdentifiers;
                        });
                      }}
                    />

                    <IconButton
                      variant="soft"
                      onClick={() => {
                        setOtherIdentifier((prev) => {
                          const newIdentifiers = [...prev];
                          newIdentifiers.splice(index, 1);
                          return newIdentifiers;
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            </FormControl>
          )}
          <Button
            color="neutral"
            onClick={handleAddIdentifier}
            size="sm"
            variant="soft"
            startDecorator={<Add />}
            sx={{ width: "fit-content" }}
          >
            Add other identifier
          </Button>

          <Divider />
          <Typography level="title-lg">Consent</Typography>
          <Checkbox
            label="The patient opposes the secondary use of their data."
            variant="outlined"
            onChange={(event) => {
              setPerson({
                ...person,
                consent_withdrawal_date: event.target.checked ? generateUnixTimestamp() : null,
              });
            }}
            checked={person.consent_withdrawal_date ? true : false}
          />
          {consentDate && (
            <JoyDatePicker
              disableFuture
              format="DD MMM YYYY"
              label="Consent withdrawal date"
              value={consentDate}
              onChange={(x) => x && setConsentDate(x)}
            />
          )}
          <Button color="primary" type="submit" disabled={!isFormValid}>
            {patient.person_id ? "Save changes" : "Create new patient"}
          </Button>
        </Stack>
      </Box>
    </form>
  );
}
