import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import { Dayjs } from "dayjs";
import React from "react";
import { UnixTimestamp } from "../../models/common";
import {
  ParticipantEventToState,
  ParticipantId,
  PutParticipantEventRequest,
} from "../../models/study";
import { JoyDatePicker } from "../../modules/notes/components/Datepickers/JoyDatePicker";
import { formatAndCapitalizeString } from "../../utils/text";
import { generateUnixTimestamp, unixToDayjs } from "../../utils/time";
import { StudyContext } from "../StudyPage";

interface NewParticipantEventModalProps {
  event: ParticipantEventToState;
  participantIds: ParticipantId[];
  handleAddParticipantAction: (putParticipantActionRequest: PutParticipantEventRequest) => void;
  onClose: () => void;
}

export default function NewParticipantActionModal({
  event,
  participantIds,
  handleAddParticipantAction,
  onClose,
}: NewParticipantEventModalProps) {
  const study = React.useContext(StudyContext);
  const [eventRequest, setEventRequest] = React.useState<PutParticipantEventRequest>({
    study_id: study.study_id,
    participant_ids: participantIds,
    event: event.event,
    reason: null,
    start_date: generateUnixTimestamp(),
  });

  const handleChangeStartDate = (date: Dayjs) => {
    setEventRequest({ ...eventRequest, start_date: date.valueOf() as UnixTimestamp });
  };

  return (
    <React.Fragment>
      <Modal open onClose={() => onClose()}>
        <ModalDialog sx={{ minWidth: "500px" }}>
          <ModalClose />
          <DialogTitle>{formatAndCapitalizeString(event.event)}</DialogTitle>
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              handleAddParticipantAction(eventRequest);
              onClose();
            }}
          >
            <Stack spacing={2}>
              <Typography>
                {participantIds.length} participant{participantIds.length > 1 && "s"} will be
                modified.
              </Typography>
              <Typography>
                The new participant's status will be{" "}
                <strong>{formatAndCapitalizeString(event.state)}</strong>.
              </Typography>
              <FormControl>
                <FormLabel>Reason</FormLabel>
                <Textarea
                  value={eventRequest.reason ? eventRequest.reason : ""}
                  onChange={(event) =>
                    setEventRequest({ ...eventRequest, reason: event.target.value })
                  }
                  minRows={3}
                />
              </FormControl>
              <FormControl>
                <JoyDatePicker
                  label="Event date"
                  value={unixToDayjs(eventRequest.start_date)}
                  onChange={(e) => e && handleChangeStartDate(e)}
                  format="DD MMM YYYY"
                  disableFuture
                />
              </FormControl>
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
