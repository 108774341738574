/* AUTOGENERATED: DO NOT EDIT */
import { UnixTimestamp } from "./common";

/** Info for an API key. */
export interface APIKeyInfo {
  /** The name of the API key. */
  name: APIKeyName;

  /** When the API key was created. */
  created_at: UnixTimestamp;
}

/**
 * The name cannot be an empty string and cannot start nor end with a blank.
 *
 * This is a "NewType" (see https://kubyshkin.name/posts/newtype-in-typescript/).
 */
export type APIKeyName = string & { readonly __tag: unique symbol };

/** Creates a APIKeyName from a string. */
export const aPIKeyName = (value: string) => value as APIKeyName;

export interface Credentials {
  user: string;

  /** Plain text password (DO NOT STORE!) */
  password: string;
}

/**
 */
export type MFAConfig = SMSMFAConfig | SoftwareTokenMFAConfig;

export enum MFAType {
  SMS_MFA = "SMS_MFA",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
}

/**
 * The principal is the user, the program, ... that has made a request
 * to the system.  The identity of a principal is always in the format
 * of an email address.
 *
 * This is a "NewType" (see https://kubyshkin.name/posts/newtype-in-typescript/).
 */
export type Principal = string & { readonly __tag: unique symbol };

/** Creates a Principal from a string. */
export const principal = (value: string) => value as Principal;

export interface PutUserRequest {
  credentials: Credentials;
  attributes: UserAttributes;
}

export enum Role {
  ADMIN = "ADMIN",
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export interface SMSMFAConfig {
  phoneNumber: string;
  type: MFAType;
}

export interface SoftwareTokenMFAConfig {
  type: MFAType;
}

export interface User {
  email: string;
  attributes: UserAttributes;
  mfa_config?: SMSMFAConfig | SoftwareTokenMFAConfig | null;
}

export interface UserAttributes {
  given_name: string;
  name: string;
  role: Role;
  organization?: string | null;
  bio?: string | null;
}

export interface UserEntry {
  password_hash: string;
  attributes: UserAttributes;
  created_at?: UnixTimestamp | null;
  updated_at?: UnixTimestamp | null;
  deleted_at?: UnixTimestamp | null;
}

export interface UserProfile {
  user: string;
  user_entry: UserEntry;
}
