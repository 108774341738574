/* AUTOGENERATED: DO NOT EDIT */
import { Coding, Quantity } from "./structuration";

export interface AgeCriteria {
  minAge: number;
  maxAge: number | null;
}

/** Class for all base criteria definition. */
export interface BaseCriteria {
  params:
    | AgeCriteria
    | CodingValueCriteria
    | QuantityValueCriteria
    | StringValueCriteria
    | CodingCriteria
    | GenderCriteria
    | ObservationPeriodCriteria
    | VitalStatusCriteria;
  type: CriteriaType;
}

export interface CanBeExclusionCriteria {
  isExclusion: boolean;
}

export interface CodingCriteria extends CanBeExclusionCriteria {
  coding: Coding | null;
}

export interface CodingValueCriteria extends CanBeExclusionCriteria {
  base_coding: Coding | null;
  value_type: ValueType | null;
  value_coding: Coding | null;
}

/** A group of criteria that are combined together with "OR" or "THEN" rules. */
export interface CriteriaGroup {
  criteria_list: Array<BaseCriteria>;
  operator: GroupingOperator | null;
}

/**
 */
export type CriteriaParams =
  | AgeCriteria
  | CodingValueCriteria
  | QuantityValueCriteria
  | StringValueCriteria
  | CodingCriteria
  | GenderCriteria
  | ObservationPeriodCriteria
  | VitalStatusCriteria;

export enum CriteriaType {
  DRUG = "DRUG",
  CONDITION = "CONDITION",
  PROCEDURE = "PROCEDURE",
  OBSERVATION = "OBSERVATION",
  MEASUREMENT = "MEASUREMENT",
  DEVICE = "DEVICE",
  AGE = "AGE",
  OBSERVATION_PERIOD = "OBSERVATION_PERIOD",
  GENDER = "GENDER",
  VITAL_STATUS = "VITAL_STATUS",
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export interface GenderCriteria {
  gender: Gender;
}

export enum GroupingOperator {
  THEN = "THEN",
  OR = "OR",
  AND = "AND",
}

export interface ObservationPeriodCriteria {
  start: string | null;
  end: string | null;
}

export interface QuantityValueCriteria extends CanBeExclusionCriteria {
  base_coding: Coding | null;
  value_type: ValueType | null;
  value_quantity: Quantity;
}

export interface StringValueCriteria extends CanBeExclusionCriteria {
  base_coding: Coding | null;
  value_type: ValueType | null;
  value: string | null;
}

export enum ValueType {
  QUANTITY = "QUANTITY",
  STRING = "STRING",
  CODING = "CODING",
}

export interface VitalStatusCriteria {
  deceased: boolean;
}
