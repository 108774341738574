import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Checkbox from "@mui/joy/Checkbox";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import React from "react";
import { useNavigate } from "react-router-dom";
import AlertDialogModal from "../../common/modals/AlertDialogModal";
import { Note, NoteId } from "../../models/note";
import { Person } from "../../models/person";
import { unixToDayjs } from "../../utils/time";

interface ListOfNotesProps {
  notes: Note[];
  persons?: Person[];
  deleteNotes: (notes: NoteId[]) => void;
}

export const ListOfNotes = ({ notes, persons, deleteNotes }: ListOfNotesProps) => {
  const [selectedNotes, setSelectedNotes] = React.useState<NoteId[]>([]);
  const [openDeleteNoteModal, setOpenDeleteNoteModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  async function handleDeleteNote() {
    function reset() {
      setOpenDeleteNoteModal(false);
      setSelectedNotes([]);
      setDeleting(false);
    }
    if (selectedNotes) {
      setDeleting(true);
      deleteNotes(selectedNotes);
      reset();
    } else {
      reset();
    }
  }
  const personMap = new Map<string | undefined | null, Person>();

  if (persons) {
    for (const person of persons) {
      personMap.set(person.person_id, person);
    }
  }
  const navigate = useNavigate();
  const handleRowClick = (note_id: NoteId) => {
    navigate("/note/" + note_id);
  };

  if (!notes) return <div>No note found</div>;
  return (
    <>
      <AlertDialogModal
        alertMessage="Are you sure you want to delete the selected note(s)?"
        open={openDeleteNoteModal}
        setOpen={setOpenDeleteNoteModal}
        handleDelete={handleDeleteNote}
        loading={deleting}
      />
      <Sheet variant="outlined" sx={{ borderRadius: "sm", height: "70vh", overflow: "auto" }}>
        <Table
          aria-labelledby="listNotesTable"
          stickyHeader
          hoverRow
          sx={{
            "--Table-headerUnderlineThickness": "1px",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: "center", padding: "12px 6px" }}>
                <Checkbox
                  size="sm"
                  indeterminate={selectedNotes.length > 0 && selectedNotes.length !== notes.length}
                  checked={selectedNotes.length === notes.length}
                  onChange={(event) => {
                    setSelectedNotes(event.target.checked ? notes.map((row) => row.note_id) : []);
                  }}
                  color={
                    selectedNotes.length > 0 || selectedNotes.length === notes.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              {personMap.size > 0 && (
                <th style={{ padding: "12px 6px", width: "220px" }}>Patient</th>
              )}
              <th style={{ padding: "12px 6px", width: "130px" }}>Date</th>
              <th style={{ padding: "12px 6px", width: "200px" }}>Type</th>
              <th style={{ padding: "12px 6px", width: "180px" }}>Specialty</th>
              <th style={{ padding: "12px 6px", width: "200px" }}>Title</th>
              <th style={{ padding: "12px 6px", width: "120px" }}>Status</th>
              <th style={{ width: "52px" }}>
                {selectedNotes.length > 0 && (
                  <IconButton
                    variant="outlined"
                    color="danger"
                    onClick={() => {
                      setOpenDeleteNoteModal(true);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {notes.map((note: Note) => (
              <tr
                key={note.note_id}
                style={{ cursor: "pointer" }}
                onClick={() => handleRowClick(note.note_id)}
              >
                <td
                  style={{ textAlign: "center", width: 120, verticalAlign: "middle" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    size="sm"
                    checked={selectedNotes.includes(note.note_id)}
                    color={selectedNotes.includes(note.note_id) ? "primary" : undefined}
                    onChange={(event) => {
                      setSelectedNotes((ids) =>
                        event.target.checked
                          ? ids.concat(note.note_id)
                          : ids.filter((itemId) => itemId !== note.note_id)
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                    sx={{ verticalAlign: "text-bottom" }}
                  />
                </td>
                {personMap.size > 0 && (
                  <td style={{ verticalAlign: "middle" }}>
                    {note.person_id ? (
                      <>
                        {personMap.get(note.person_id)?.given_name}{" "}
                        {personMap.get(note.person_id)?.family_name}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                )}
                <td style={{ verticalAlign: "middle" }}>
                  {unixToDayjs(note.note_datetime)?.format("DD MMM YYYY")}
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  {note.note_type?.display ? note.note_type.display : "-"}
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  {note.note_category
                    ? note.note_category?.map((coding) => (
                        <Chip key={coding.code} color="neutral" size="sm" sx={{ mr: 1 }}>
                          {coding.display}
                        </Chip>
                      ))
                    : "-"}
                </td>
                <td style={{ verticalAlign: "middle" }}>{note.note_title}</td>
                <td style={{ verticalAlign: "middle" }}>
                  {note.validation_date ? (
                    <Chip color="success">Verified</Chip>
                  ) : (
                    <Chip color="warning">Unverified</Chip>
                  )}
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </>
  );
};
