import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { CohortQuartzClientContext } from "../../clients/contexts";
import { filterExclusionCriteria } from "../../cohort/CohortBuilder";
import CriteriaGroupDisplay from "../../cohort/CriteriaGroupDisplay";
import AlertDialogModal from "../../common/modals/AlertDialogModal";
import { Cohort, CohortId } from "../../models/cohort";
import { CriteriaGroup } from "../../models/criteria";
import { Study } from "../../models/study";

interface StudyCohortDrawerProps {
  study: Study;
  cohortId: CohortId;
  onClose: () => void;
  handleUpdateStudy: (study: Study) => void;
}

export default function StudyCohortDrawer({
  study,
  onClose,
  handleUpdateStudy,
}: StudyCohortDrawerProps) {
  const cohortQuartzClient = React.useContext(CohortQuartzClientContext);
  const [cohort, setCohort] = React.useState<Cohort | null>(null);
  const [openUnlinkCohort, setOpenUnlinkCohort] = React.useState(false);

  const handleUnlinkCohort = () => {
    handleUpdateStudy({
      ...study,
      cohort_id: undefined,
    });
    setOpenUnlinkCohort(false);
  };

  React.useEffect(() => {
    const fetchCohort = async (cohortId: CohortId) => {
      try {
        const response = await cohortQuartzClient.getCohortById(cohortId);
        setCohort(response);
      } catch (e: any) {
        console.log(e.message);
      }
    };

    if (study.cohort_id) {
      fetchCohort(study.cohort_id);
    }
  }, [study.cohort_id, cohortQuartzClient]);

  return (
    <>
      <AlertDialogModal
        alertMessage="Are you sure you want to unlink the cohort from the study?"
        open={openUnlinkCohort}
        setOpen={setOpenUnlinkCohort}
        handleDelete={handleUnlinkCohort}
      />
      <Sheet
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "450px",
          marginTop: -4,
          marginRight: -4,
          marginBottom: -4,
          marginLeft: 4,
          borderLeft: "1px solid",
          borderColor: "divider",
          height: "100vh",
        }}
      >
        {cohort ? (
          <>
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <Typography level="title-md" sx={{ flex: 1 }}>
                {cohort.name}{" "}
                <Chip startDecorator={<GroupOutlinedIcon />} size="sm" color="neutral">
                  {cohort.subjects.length} patients
                </Chip>
              </Typography>
              <IconButton component="span" variant="plain" color="neutral" size="sm">
                <CloseRoundedIcon onClick={onClose} />
              </IconButton>
            </Box>
            <Divider />
            <Box sx={{ background: "white", flexGrow: 1 }}>
              <Stack sx={{ p: 2 }} direction="column" gap={2}>
                <Sheet
                  sx={{ borderRadius: "sm", border: "1px solid", borderColor: "divider", p: 2 }}
                >
                  <Typography sx={{ mb: 2 }} level="title-lg">
                    Entry event
                  </Typography>
                  {cohort.entry_event.length === 0 && <Typography level="body-md">-</Typography>}
                  {cohort.entry_event.map((criteria: CriteriaGroup, index) => (
                    <CriteriaGroupDisplay key={index} criteriaGroup={criteria} />
                  ))}
                </Sheet>
                <Sheet
                  sx={{ borderRadius: "sm", border: "1px solid", borderColor: "divider", p: 2 }}
                >
                  <Typography sx={{ mb: 2 }} level="title-lg">
                    Inclusion criteria
                  </Typography>
                  {cohort.inclusion_criteria.length === 0 && (
                    <Typography level="body-md">-</Typography>
                  )}
                  {cohort.inclusion_criteria.map(
                    (criteriaGroup, index) =>
                      filterExclusionCriteria(criteriaGroup).length === 0 && (
                        <CriteriaGroupDisplay key={index} criteriaGroup={criteriaGroup} />
                      )
                  )}
                </Sheet>
                <Sheet
                  sx={{ borderRadius: "sm", border: "1px solid", borderColor: "divider", p: 2 }}
                >
                  <Typography sx={{ mb: 2 }} level="title-lg">
                    Exclusion criteria
                  </Typography>
                  {cohort.inclusion_criteria.length === 0 && (
                    <Typography level="body-md">-</Typography>
                  )}
                  {cohort.inclusion_criteria.map(
                    (criteriaGroup, index) =>
                      filterExclusionCriteria(criteriaGroup).length > 0 && (
                        <CriteriaGroupDisplay key={index} criteriaGroup={criteriaGroup} />
                      )
                  )}
                </Sheet>
              </Stack>
            </Box>
            <Box>
              <Divider />
              <Stack sx={{ p: 2 }} direction="row" justifyContent="flex-end" spacing={2}>
                <Button
                  endDecorator={<OpenInNewIcon fontSize="small" />}
                  onClick={() => {
                    window.open(`/cohort/${study.cohort_id}`, "_blank");
                  }}
                >
                  Open in cohort builder
                </Button>
                <Button
                  color="warning"
                  onClick={() => {
                    setOpenUnlinkCohort(true);
                  }}
                >
                  Unlink cohort
                </Button>
              </Stack>
            </Box>
          </>
        ) : (
          <Box sx={{ p: 2 }}>
            <Typography>Loading...</Typography>
          </Box>
        )}
      </Sheet>
    </>
  );
}
