import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import illustrationProfile from "../../assets/illustration-profile.png";
import TitleStylizedUnderline from "../../components/TitleStylizedUnderline";
import { UserContext } from "../../admin/UserProvider";
import { helperText } from "../../admin/roleDescription";

export default function SimpleUserProfile() {
  const { user } = React.useContext(UserContext);

  if (!user) {
    return null;
  }

  let firstPart = user.email.includes("/") ? user.email.split("/")[0] : "";
  let username = firstPart.split("@")[0];
  let rootPart = username.replace("-", " ").toUpperCase();
  let email = user.email.includes("/") ? user.email.split("/")[1] : user.email;

  return (
    <Box sx={{ p: 4 }}>
      <div className="columns">
        <div className="column is-half">
          <div className="content">
            <Typography level="h2">
              <TitleStylizedUnderline>Personal profile</TitleStylizedUnderline>
            </Typography>
            <Stack spacing={2}>
              {rootPart !== "" && (
                <Alert variant="soft" color="success" startDecorator={<DomainAddIcon />}>
                  {rootPart}
                </Alert>
              )}

              <Alert
                variant="soft"
                color="warning"
                sx={{ alignItems: "flex-start" }}
                startDecorator={<AccountCircleRoundedIcon />}
              >
                <div>
                  <div>Permissions: {user.attributes.role}</div>
                  <Typography level="body-sm" color="warning">
                    {helperText[user.attributes.role]}
                  </Typography>
                </div>
              </Alert>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input value={email} disabled />
              </FormControl>
              <FormControl>
                <FormLabel>First name</FormLabel>
                <Input value={user.attributes.given_name} disabled />
              </FormControl>
              <FormControl>
                <FormLabel>Last name</FormLabel>
                <Input value={user.attributes.name} disabled />
              </FormControl>
              <FormControl>
                <FormLabel>Organization</FormLabel>
                <Input value={user.attributes.organization ?? ""} disabled />
              </FormControl>
            </Stack>
          </div>
        </div>
        <div className="column is-4 is-offset-1">
          <figure className="image">
            <img src={illustrationProfile} alt="illustration" />
          </figure>
        </div>
      </div>
    </Box>
  );
}
