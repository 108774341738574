import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { IdentityProviderClientContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";

type FormValues = {
  code: string;
};

export default function MFAConfigurationSMS2({
  onClose,
}: {
  onClose: (submitted: boolean) => void;
}) {
  const identityProviderClient = useContext(IdentityProviderClientContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormValues>();
  const [serverError, setServerError] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const sendAgain = async () => {
    try {
      await identityProviderClient.verifyPhoneNumber();
    } catch (err: any) {
      console.log("ERROR", err);
      if (err.code === "LimitExceededException") {
        setServerError("Too many attempts. Please try again later.");
        return;
      }
      throw err;
    }
    enqueueSnackbar("SMS sent.", { variant: "success" });
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      await identityProviderClient.continueSMSMFASetup(data.code);
    } catch (err: any) {
      console.log("ERROR", err);
      if (err.code === "CodeMismatchException") {
        setError("code", { message: "Code mismatch. Please try again." });
        return;
      }
      if (err.code === "LimitExceededException") {
        setServerError("Too many attempts. Please try again later.");
        return;
      }
      throw err;
    }
    enqueueSnackbar("SMS MFA configured.", { variant: "success" });
    onClose(true);
  });
  return (
    <div style={{ maxWidth: "500px", padding: "2rem" }}>
      <div className="content">
        <h1>Set up SMS MFA</h1>
        <p>
          With SMS MFA, we send a code to your phone number to verify your identity when you log in.
        </p>
      </div>
      <form onSubmit={onSubmit}>
        {serverError && <SnackbarNotification text={serverError} color="danger" />}
        <div className="block">
          <div className="field">
            <label className="label">Code</label>
            <p className="control">
              <input
                className={errors.code ? "is-danger input" : "input"}
                {...register("code", { required: "Field required." })}
                placeholder="Enter the code you received"
              />
            </p>
            <p className="help is-danger">{errors.code?.message}</p>
          </div>
        </div>
        <div className="block">
          <p>
            You did not receive an SMS? <button onClick={sendAgain}>send again.</button>
          </p>
        </div>
        <div className="block">
          <button
            className={classNames("button is-info", isSubmitting && "is-loading")}
            type="submit"
          >
            Complete setup
          </button>
        </div>
      </form>
    </div>
  );
}
