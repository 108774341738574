import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import Drawer from "@mui/joy/Drawer";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ModalClose from "@mui/joy/ModalClose";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";

interface CohortCreationGuideProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function CohortCreationGuide({ open, setOpen }: CohortCreationGuideProps) {
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="right" size="lg">
      <ModalClose />
      <DialogTitle>
        <Typography level="title-lg">Cohort creation guide</Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography level="body-md">
            Creating a cohort enables you to group patients based on specific health events,
            conditions, and characteristics. This is essential for conducting targeted studies,
            understanding patient outcomes, and improving healthcare delivery. Here’s a simplified
            guide on how to define your cohorts:
          </Typography>

          <Typography level="h3">What is a Cohort?</Typography>
          <Typography level="body-md">
            <b>A cohort</b> is a group of patients who share a common characteristic or experience
            within a defined time frame. This approach is pivotal in observational studies, clinical
            trials, and healthcare monitoring, allowing for detailed analysis and insights into
            patient health and treatment efficacy.
          </Typography>

          <Typography level="h3">Entry Events: The Clinical Starting Point</Typography>
          <Typography level="body-md">
            <b>Entry events</b> are specific health-related occurrences that mark a patient's
            eligibility for inclusion in a cohort. This could be the onset of a disease, a surgical
            procedure, the initiation of a treatment, or a diagnosis. Entry events serve as the
            clinical threshold that patients must cross to be considered for inclusion.
          </Typography>

          <Typography level="h3">Inclusion Criteria: Defining Eligibility</Typography>
          <Typography>
            <b>Inclusion criteria</b> specify the essential characteristics or medical conditions
            patients must have to be included in your cohort. These criteria can range from age,
            disease stage, prior treatments, to specific diagnostic criteria. Setting these
            parameters allows you to precisely target the patient population you wish to study or
            monitor.
          </Typography>

          <Typography level="h3">Exclusion Criteria: Refining Your Cohort</Typography>
          <Typography>
            <b>Exclusion criteria</b> outline the conditions or characteristics that disqualify a
            patient from being included in your cohort. These might include comorbid conditions,
            previous treatment failures, or any factor that could confound the study’s outcomes or
            objectives. By defining these criteria, you ensure the cohort's integrity and relevance
            to your research or clinical question.
          </Typography>

          <Typography level="h3">How to Create Your Cohort?</Typography>
          <List marker="decimal">
            <ListItem>
              <b>Identify the Entry Event</b>: Select a health event or condition that marks the
              beginning of a patient’s journey in your study or monitoring process.
            </ListItem>
            <ListItem>
              <b>Set Inclusion Criteria</b>: Define the specific health characteristics, conditions,
              or atient demographics necessary for inclusion in the cohort.
            </ListItem>
            <ListItem>
              <b>Establish Exclusion Criteria</b>: Determine any health factors or patient
              characteristics that would exclude someone from the cohort to maintain the focus and
              quality of your study.
            </ListItem>
          </List>
        </Stack>
      </DialogContent>
    </Drawer>
  );
}
