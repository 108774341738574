import { ClinicalEvent, Episode, EventSource } from "../models/clinical_event";
import { AnswerType } from "../models/questionnaire";
import { getValue } from "./fact";
import { unixToDayjs } from "./time";

export const sortGroupedFacts = (groupedFacts: { [key: string]: EventSource[] }) => {
  const sortedKeys = Object.keys(groupedFacts).sort();
  const sortedGroupedFacts: { [key: string]: EventSource[] } = {};
  sortedKeys.forEach((key) => {
    sortedGroupedFacts[key] = groupedFacts[key];
  });
  return sortedGroupedFacts;
};

export const episodeLabel = (episode: Episode) => {
  let label = unixToDayjs(episode.start_date)?.format("YYYY-MM-DD")!;
  if (episode.end_date) {
    label += ` - ${unixToDayjs(episode.end_date)?.format("YYYY-MM-DD")}`;
  }
  label += ` | ${episode.name}`;
  if (episode.episode_number) {
    label += ` ${episode.episode_number}`;
  }
  label += ` | ${episode.episode_object_name}`;
  return label;
};

export const groupFactsByAnswerType = (events: ClinicalEvent[], answerType: AnswerType) => {
  const groupedFacts: { [key: string]: EventSource[] } = {};
  events.forEach((fact) => {
    let key = "";
    const date = unixToDayjs(fact.start_date)?.format("YYYY-MM-DD");
    if (answerType === "concept-date") {
      key = `${date} | ${fact.name}`;
    } else if (answerType === "concept") {
      key = `${fact.name}`;
    } else if (answerType === "concept-date-value") {
      key = `${date} | ${fact.name} | ${getValue(fact.value, fact.qualifier)}`;
    } else if (answerType === "date-value") {
      key = `${date} | ${getValue(fact.value, fact.qualifier)}`;
    } else if (answerType === "date") {
      key = `${date}`;
    }
    if (!groupedFacts[key]) {
      groupedFacts[key] = [];
    }
    groupedFacts[key] = groupedFacts[key].concat(fact.source);
  });

  return sortGroupedFacts(groupedFacts);
};
