import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import { Auth } from "aws-amplify";
import { ReactNode, useState } from "react";
import LoadingButton from "./LoadingButton";

export default function PasswordResetForm({ goBack }: { goBack: () => void }) {
  const [message, setMessage] = useState<ReactNode>(null);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = async () => {
    if (email !== "") {
      try {
        await Auth.forgotPassword(email.trim());
      } catch (err: any) {
        if (err.code === "LimitExceededException") {
          setIsLoading(false);
          return setMessage(
            <Alert color="danger">
              Too many password reset emails sent. Please try again later.
            </Alert>
          );
        }
        if (err.code === "UserNotFoundException") {
          setIsLoading(false);
          return setMessage(<Alert color="danger">Email address not found.</Alert>);
        }
        throw err;
      }
      setIsLoading(false);
      return setMessage(<Alert color="success">Password reset email sent.</Alert>);
    } else {
      setIsLoading(false);
      return setMessage(<Alert color="danger">You must provide an email.</Alert>);
    }
  };

  return (
    <>
      <Typography
        sx={{ mt: 2 }}
        level="title-md"
        startDecorator={
          <IconButton onClick={() => goBack()}>
            <ArrowBackIcon color="primary" />
          </IconButton>
        }
      >
        Reset your password
      </Typography>
      {message && <Box sx={{ mt: 2 }}>{message}</Box>}
      <Typography level="body-sm" sx={{ mt: 2 }}>
        Enter the email address you usually use to sign in to Gimli.
      </Typography>
      <FormControl sx={{ mt: 2 }} disabled={isLoading}>
        <FormLabel>Email</FormLabel>
        <Input
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        />
      </FormControl>
      <LoadingButton
        loading={isLoading}
        sx={{ mt: 2 }}
        type="submit"
        onClick={() => {
          resetPassword();
          setIsLoading(true);
        }}
        fullWidth
        onKeyUp={(e: React.KeyboardEvent<HTMLButtonElement>) => {
          if (e.key === "Enter") {
            resetPassword();
          }
        }}
      >
        Send reset email
      </LoadingButton>
    </>
  );
}
