import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { SimpleAuthenticationServiceContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";
import AuthPage from "../../components/AuthPage";
import React from "react";

type FormValues = {
  email: string;
  password: string;
};

export default function SignInPage() {
  const authenticationService = React.useContext(SimpleAuthenticationServiceContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [serverError, setServerError] = React.useState<string | null>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      await authenticationService.login(data.email, data.password);
    } catch (e: any) {
      setServerError(e.message);
      return;
    } finally {
      setLoading(false);
    }
  });

  if (authenticationService.isAuthenticated()) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <AuthPage loading={loading} onSubmit={onSubmit} errors={errors} register={register} />
    </>
  );
}
