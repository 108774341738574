import AddIcon from "@mui/icons-material/Add";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
import PublishIcon from "@mui/icons-material/Publish";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CircularProgress from "@mui/joy/CircularProgress";
import DialogTitle from "@mui/joy/DialogTitle";
import Grid from "@mui/joy/Grid";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import Skeleton from "@mui/joy/Skeleton";
import Stack from "@mui/joy/Stack";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import React from "react";
import { SubmitHandler } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { BundleContext } from "../App";
import { NoteQuartzClientContext, PersonQuartzClientContext } from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";
import FactsTimeline from "../components/FactsTimeline/FactsTimeline";
import { DisplayProofChipSet } from "../components/QuestionAnswering/DisplayAnswerFacts";
import { ProofChip } from "../components/QuestionAnswering/ProofChip";
import TablePagination from "../components/TablePagination";
import { Link as LinkBundle } from "../models/bundle";
import { ClinicalEvent, Episode } from "../models/clinical_event";
import { Note, NoteId, ValidationState } from "../models/note";
import { Person, PersonId } from "../models/person";
import { Coding } from "../models/structuration";
import { PatientView } from "../models/view";
import { PatientForm } from "../modules/patient/components/PatientForm";
import { AddNote } from "../note/AddNote";
import NoteFilters from "../note/NoteFilters";
import { UploadDocument } from "../note/UploadDocument";
import { ListOfNotes } from "../note/organisms/ListOfNotes";
import { episodeLabel } from "../utils/question_answering";
import DataConsistency from "./DataConsistency";
import { DecisionSupport } from "./DecisionSupport";
import { PatientDetails } from "./PatientDetails";
import { QuestionAnswering } from "./QuestionAnswering";

export const PatientPage = () => {
  // Contexts
  const noteQuartzClient = React.useContext(NoteQuartzClientContext);
  const personQuartzClient = React.useContext(PersonQuartzClientContext);
  const [, setBundleId] = React.useContext(BundleContext);
  const location = useLocation();

  // States
  const [serverError, setServerError] = React.useState<string | null>("");
  const [noteLoading, setNoteLoading] = React.useState<boolean>(true);
  const [person, setPerson] = React.useState<Person | null>(null);
  const [personId, setPersonId] = React.useState<PersonId | undefined | null>(
    location.pathname.split("/")[2] as PersonId
  );
  const [patientView, setPatientView] = React.useState<PatientView | null>(null);
  const [validationState, setValidationState] = React.useState<ValidationState>("all");
  const [noteTypeCode, setNoteTypeCode] = React.useState<string | null>(null);
  const [searchByCoding, setSearchByCoding] = React.useState<Coding | null>(null);
  const [links, setLinks] = React.useState<LinkBundle[]>([]);
  const [totalEntries, setTotalEntries] = React.useState<number>(0);
  const [notes, setNotes] = React.useState<Note[] | undefined>([]);
  const [modalModifyPatient, setModalModifyPatient] = React.useState<boolean>(false);
  const [refreshTime, setRefreshTime] = React.useState<number>(new Date().getTime());
  const [offset, setOffset] = React.useState<number>(0);

  // Constants
  const count = 100;

  const refresh = () => {
    setRefreshTime(new Date().getTime());
  };

  const updatePatient = async (person_id: PersonId, patientInputs: Person) => {
    try {
      const personUpdate = await personQuartzClient.putPersonById(person_id, patientInputs);
      setPerson(personUpdate);
      setModalModifyPatient(false);
    } catch (e: any) {
      setServerError(e.message);
    }
  };

  const onModifyPatient: SubmitHandler<Person> = async (data) => {
    data.person_id && updatePatient(data.person_id, data);
  };

  const deleteNotes = async (todelete: Array<NoteId>) => {
    try {
      await noteQuartzClient.deleteNotes(todelete);
      refresh();
    } catch (e: any) {
      setServerError(e.message);
    }
  };

  const groupEventByName = (events: ClinicalEvent[]) => {
    const grouped: { [key: string]: ClinicalEvent[] } = {};
    events.forEach((event) => {
      if (grouped[event.name]) {
        grouped[event.name].push(event);
      } else {
        grouped[event.name] = [event];
      }
    });
    return grouped;
  };

  React.useEffect(() => {
    const getPatientView = async () => {
      if (!personId) return;
      try {
        const patient = await personQuartzClient.getPersonById(personId);
        setPerson(patient);
        const view = await noteQuartzClient.getPatientView(personId, "all");
        setPatientView(view);
      } catch (e: any) {
        setServerError(e.message);
      }
    };

    getPatientView();
  }, [personQuartzClient, noteQuartzClient, personId]);

  React.useEffect(() => {
    const getNotesOfTheCurrentPatient = async () => {
      if (!personId) return;
      try {
        const bundle = await noteQuartzClient.listNotes(
          count,
          offset,
          validationState,
          noteTypeCode,
          personId,
          searchByCoding
        );
        setBundleId(bundle.id ? bundle.id : null);
        setTotalEntries(bundle.total ? bundle.total : 0);
        setLinks(bundle.link ? bundle.link : []);
        const listOfNotes = bundle.entry as Note[];

        const sorted = listOfNotes.sort((a, b) => {
          // If both person_ids are null or both are not null, then sort by note_datetime
          if (a.note_datetime && b.note_datetime) {
            return a.note_datetime - b.note_datetime; // Older notes first (ascending order)
          }
          // If note_datetime is not available for both, keep the original order
          return 0;
        });
        setNotes(sorted);
      } catch (e: any) {
        setPersonId(null);
        setServerError(e.message);
      } finally {
        setNoteLoading(false);
      }
    };

    getNotesOfTheCurrentPatient();
  }, [
    refreshTime,
    noteTypeCode,
    validationState,
    searchByCoding,
    personId,
    noteQuartzClient,
    count,
    offset,
    setBundleId,
  ]);

  return (
    <Box>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <Grid container spacing={2} sx={{ flexGrow: 1, p: 4 }}>
        <Grid xs={8}>
          {person ? (
            <PatientDetails person={person} />
          ) : (
            <Skeleton variant="rectangular" width={400} height={44} />
          )}
        </Grid>
        <Grid xs={4}>
          <Box justifyContent="flex-end" display="flex">
            <Button
              onClick={() => {
                setModalModifyPatient(!modalModifyPatient);
              }}
              variant="solid"
              startDecorator={<EditIcon fontSize="small" />}
            >
              Edit patient details
            </Button>
          </Box>
        </Grid>
      </Grid>
      {person && (
        <Modal open={modalModifyPatient} onClose={() => setModalModifyPatient(false)}>
          <ModalOverflow>
            <ModalDialog sx={{ minWidth: "500px" }}>
              <ModalClose />
              <DialogTitle>Edit patient details</DialogTitle>
              <PatientForm patient={person} onSubmit={onModifyPatient} />
            </ModalDialog>
          </ModalOverflow>
        </Modal>
      )}
      <Box sx={{ flex: 1, width: "100%" }}>
        <Box
          sx={{
            position: "sticky",
            top: { sm: -100, md: -110 },
            bgcolor: "background.body",
          }}
        >
          <Tabs
            defaultValue={0}
            sx={{
              bgcolor: "transparent",
            }}
          >
            <TabList
              tabFlex={1}
              size="sm"
              sx={{
                justifyContent: "left",
                [`&& .${tabClasses.root}`]: {
                  fontWeight: "600",
                  flex: "initial",
                  color: "text.tertiary",
                  [`&.${tabClasses.selected}`]: {
                    bgcolor: "transparent",
                    color: "text.primary",
                    "&::after": {
                      height: "2px",
                      bgcolor: "primary.500",
                    },
                  },
                },
              }}
            >
              <Tab sx={{ borderRadius: "6px 6px 0 0" }} value={0}>
                <ListItemDecorator>
                  <SummarizeOutlinedIcon fontSize="small" />
                </ListItemDecorator>
                Overview
              </Tab>
              <Tab sx={{ borderRadius: "6px 6px 0 0" }} value={1}>
                <ListItemDecorator>
                  <ArticleOutlinedIcon fontSize="small" />
                </ListItemDecorator>
                Notes
              </Tab>
              <Tab sx={{ borderRadius: "6px 6px 0 0" }} value={2}>
                <ListItemDecorator>
                  <MedicationOutlinedIcon fontSize="small" />
                </ListItemDecorator>
                Medications
              </Tab>
              {patientView && patientView.data_inconsistencies.length > 0 && (
                <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={3}>
                  <ListItemDecorator>
                    <WarningAmberRoundedIcon fontSize="small" />
                  </ListItemDecorator>
                  Data issues ({patientView.data_inconsistencies.length})
                </Tab>
              )}
              <Tab
                sx={{ borderRadius: "6px 6px 0 0", backgroundColor: "#DED2AE", mr: 1, ml: 1 }}
                indicatorInset
                value={4}
              >
                <ListItemDecorator>
                  <AddIcon fontSize="small" />
                </ListItemDecorator>
                Add a note
              </Tab>
              <Tab
                sx={{ borderRadius: "6px 6px 0 0", backgroundColor: "#DED2AE" }}
                indicatorInset
                value={5}
              >
                <ListItemDecorator>
                  <PublishIcon fontSize="small" />
                </ListItemDecorator>
                Upload documents
              </Tab>
            </TabList>
            <TabPanel value={0}>
              {!patientView && (
                <Stack
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ pt: "30vh" }}
                >
                  <CircularProgress />
                  <Typography>Loading patient's data...</Typography>
                </Stack>
              )}
              {patientView && personId && <QuestionAnswering personIds={[personId]} />}
              {patientView && Object.keys(patientView.cancer_facts).length > 0 && (
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Typography level="h3">Cancer panel</Typography>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography level="title-lg">Cancer type</Typography>
                        <Box>
                          {patientView.cancer_facts.length > 0 ? (
                            <DisplayProofChipSet
                              events={patientView.cancer_facts}
                              answerType="concept"
                            />
                          ) : (
                            <small>-</small>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography level="title-lg">Genetic variants</Typography>
                        <Box>
                          {patientView.mutation_facts.length > 0 ? (
                            <DisplayProofChipSet
                              events={patientView.mutation_facts}
                              answerType="concept"
                            />
                          ) : (
                            <small>-</small>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography level="title-lg">Metastasis</Typography>
                        <Box>
                          <Box>
                            {patientView.metastasis_facts.length > 0 ? (
                              <DisplayProofChipSet
                                events={patientView.metastasis_facts}
                                answerType="concept"
                              />
                            ) : (
                              <small>-</small>
                            )}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography level="title-lg">TNM</Typography>
                        <Box>
                          {patientView.tnm_facts.length > 0 ? (
                            <DisplayProofChipSet
                              events={patientView.tnm_facts}
                              answerType="date-value"
                            />
                          ) : (
                            <small>-</small>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography level="title-lg">Comorbitidies</Typography>
                        <Box>
                          {patientView.comorbidities_facts.length > 0 ? (
                            <DisplayProofChipSet
                              events={patientView.comorbidities_facts}
                              answerType="concept"
                            />
                          ) : (
                            <small>-</small>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={12} sm={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography level="title-lg">Episode of treatment</Typography>
                        <Box>
                          {patientView.episode.length > 0 ? (
                            patientView.episode.map((episode: Episode, index: number) => (
                              <ProofChip
                                key={index}
                                name={episodeLabel(episode)}
                                probability={episode.probability}
                                eventSources={episode.source}
                              />
                            ))
                          ) : (
                            <small>-</small>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} sx={{ p: 1 }}>
                {patientView &&
                  patientView.cds_response &&
                  patientView.cds_response.cards.length > 0 && (
                    <Grid xs={12}>
                      <DecisionSupport cards={patientView.cds_response.cards} />
                    </Grid>
                  )}
              </Grid>
            </TabPanel>
            <TabPanel sx={{ p: 3 }} value={1}>
              <Grid xs={12}>
                {!noteLoading ? (
                  <Stack direction="column" spacing={2}>
                    {notes && notes.length !== 0 && (
                      <NoteFilters
                        defaultStatus={validationState}
                        defaultNoteType={noteTypeCode}
                        searchByCoding={searchByCoding}
                        onStatusChange={setValidationState}
                        onNoteTypeChange={setNoteTypeCode}
                        onSearch={setSearchByCoding}
                      />
                    )}
                    {notes && notes.length !== 0 ? (
                      <ListOfNotes deleteNotes={deleteNotes} notes={notes ? notes : []} />
                    ) : (
                      <Box
                        sx={{
                          mt: "150px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography level="h3">
                            There are no notes linked to this patient yet.
                          </Typography>
                          <Stack sx={{ mt: 2 }} direction="row" spacing={1} justifyContent="center">
                            <Link to={"/add-document"}>
                              <Button startDecorator={<ImportExportIcon />}>Upload document</Button>
                            </Link>
                            <Link to={"/add-note"}>
                              <Button startDecorator={<AddIcon />}>Add a manual note</Button>
                            </Link>
                          </Stack>
                        </div>
                      </Box>
                    )}
                    {totalEntries > count && (
                      <TablePagination
                        totalEntries={totalEntries}
                        pageSize={count}
                        links={links}
                        onPageChange={(offset) => {
                          setOffset(offset);
                          refresh();
                        }}
                      />
                    )}
                  </Stack>
                ) : (
                  <Skeleton variant="rectangular" width="100%" height="100px" />
                )}
              </Grid>
            </TabPanel>
            <TabPanel sx={{ p: 3 }} value={2}>
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={12}>
                  {patientView ? (
                    <>
                      {patientView.rx_facts && Object.keys(patientView.rx_facts).length > 0 ? (
                        <FactsTimeline eventsByName={groupEventByName(patientView.rx_facts)} />
                      ) : (
                        <Typography level="h3">No treatment found</Typography>
                      )}
                    </>
                  ) : (
                    <Skeleton variant="rectangular" width={44} height={44} />
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel sx={{ p: 3 }} value={3}>
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={12}>
                  {patientView ? (
                    <>
                      {patientView.data_inconsistencies.length > 0 && (
                        <DataConsistency dataInconsistencies={patientView.data_inconsistencies} />
                      )}
                    </>
                  ) : (
                    <Skeleton variant="rectangular" width={44} height={44} />
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel sx={{ p: 3 }} value={4}>
              <AddNote person={person} />
            </TabPanel>
            <TabPanel sx={{ p: 3 }} value={5}>
              <UploadDocument personId={person?.person_id} />
            </TabPanel>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};
