import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import { User } from "../models/auth";

interface UsersTableProps {
  users: User[];
  editUserAttributes: (user: User) => void;
  editUserPwd: (user: User) => void;
  deleteUser: (email: string) => void;
}

export default function UsersTable({
  users,
  editUserAttributes,
  editUserPwd,
  deleteUser,
}: UsersTableProps) {
  return (
    <Sheet sx={{ mt: 4, borderRadius: "8px", td: { verticalAlign: "middle" } }}>
      <Table
        aria-label="users table"
        hoverRow
        sx={{ borderRadius: "8px" }}
        variant="outlined"
        borderAxis="xBetween"
      >
        <thead>
          <tr>
            <th>Username</th>
            <th>Given name</th>
            <th>Last name</th>
            <th>Role</th>
            <th>Organization</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user: User) => (
            <tr key={user.email}>
              <td>{user.email}</td>
              <td>{user.attributes.given_name}</td>
              <td>{user.attributes.name}</td>
              <td>{user.attributes.role}</td>
              <td>{user.attributes.organization}</td>
              <td>
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                  <IconButton variant="plain" size="sm">
                    <EditIcon
                      sx={{ ":hover": { cursor: "pointer" } }}
                      onClick={() => editUserAttributes(user)}
                    />
                  </IconButton>
                  <IconButton variant="plain" size="sm">
                    <LockResetIcon
                      sx={{ ":hover": { cursor: "pointer" } }}
                      onClick={() => editUserPwd(user)}
                    />
                  </IconButton>
                  <IconButton variant="plain" size="sm">
                    <DeleteIcon
                      sx={{ ":hover": { cursor: "pointer" } }}
                      onClick={() => deleteUser(user.email)}
                    />
                  </IconButton>
                </Stack>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  );
}
