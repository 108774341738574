import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import WarningIcon from "@mui/icons-material/WarningRounded";
import Autocomplete from "@mui/joy/Autocomplete";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import Tooltip from "@mui/joy/Tooltip";
import { Dayjs } from "dayjs";
import React from "react";
import { useNavigate } from "react-router";
import { BundleContext } from "../../../../App";
import { TerminologyQuartzClientContext } from "../../../../clients/contexts";
import { UnixTimestamp } from "../../../../models/common";
import { Person } from "../../../../models/person";
import { Coding } from "../../../../models/structuration";
import { fillAttributes } from "../../../../note/utils/utils";
import { DebouncedFunction, unixToDayjs } from "../../../../utils/time";
import { AllPatientsDropdown } from "../../../patient/components/AllPatientsDropdown";
import { PatientDisplay } from "../../../patient/components/PatientDisplay";
import { PatientForm } from "../../../patient/components/PatientForm";
import { emptyPatient, useNote, usePatient } from "../../lib/context";
import { JoyDatePicker } from "../Datepickers/JoyDatePicker";
import Breadcrumb from "./Breadcrumb";
import { FactList } from "./FactList";
import RefreshModal from "./RefreshModal";

export function DrawerContent() {
  // Contexts
  const {
    note,
    setNote,
    handleValidate,
    handleReRunNLP,
    selectedFact,
    selectedLabel,
    setDisplayAllFacts,
  } = useNote();
  const { patient, setPatient, createPatient, linkPatient } = usePatient();
  const [bundleId] = React.useContext(BundleContext);
  const terminologyQuartzClient = React.useContext(TerminologyQuartzClientContext);
  const navigate = useNavigate();
  const debounce = DebouncedFunction();

  // States
  const [isEditPatient, setIsEditPatient] = React.useState(false);
  const [documentTypes, setDocumentTypes] = React.useState<Coding[]>([]);
  const [medicalSpecialities, setMedicalSpecialties] = React.useState<Coding[]>([]);
  const [refreshModal, setRefreshModal] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const defaultPatient = fillAttributes(note?.facts || []);
  setPatient(defaultPatient);

  const handleRecordingDateChange = (newDate: Dayjs | null) => {
    if (note && newDate) {
      setNote({
        ...note,
        note_datetime: newDate.valueOf() as UnixTimestamp,
      });
    }
  };

  const handleNoteTypeChange = (newValue: string | null) => {
    const newNoteType = documentTypes.find((type) => type.code === newValue);
    if (note) {
      setNote({ ...note, note_type: newNoteType });
    }
  };

  const handleNoteCategoryChange = (newCategories: Coding[]) => {
    if (note) {
      setNote({ ...note, note_category: newCategories });
    }
  };

  const handleNoteTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = event.target.value as string;
    if (!newTitle) return;
    if (note) {
      setNote({ ...note, note_title: newTitle });
    }
  };

  const handleUnlinkPatient = () => {
    if (note) {
      setNote({ ...note, person_id: null });
      setPatient(emptyPatient);
    }
  };

  const handleLinkPatient = (person: Person | null) => {
    if (person) {
      linkPatient(person.person_id);
      setIsEditPatient(false);
    }
  };

  const updatePatient = (patient: Person) => {
    setPatient(patient);
    setIsEditPatient(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await terminologyQuartzClient.listCodings("document-type");
        setDocumentTypes(response);
        const mSpecialties = await terminologyQuartzClient.listCodings("medical-specialities");
        setMedicalSpecialties(mSpecialties);
      } catch (error) {
        console.error("Failed to fetch document types:", error);
      }
    };

    fetchData();
  }, [terminologyQuartzClient]);

  React.useEffect(() => {
    setSelectedTab(0); // ensure we go to fact tab on label click
  }, [selectedLabel, setSelectedTab]);

  if (!note) {
    navigate("/notes");
    return;
  }

  let isNoteReadyForValidation = "";
  if (!note.person_id) {
    isNoteReadyForValidation = "Note has no patient linked";
  }
  if (note.facts.length === 0) {
    isNoteReadyForValidation = "Note does not have any facts";
  }
  if (!note.note_datetime) {
    isNoteReadyForValidation = "No note date";
  }

  return (
    <Box sx={{ width: "100%", position: "relative", height: "100%" }}>
      <RefreshModal open={refreshModal} setOpen={setRefreshModal} handleRefresh={handleReRunNLP} />
      <Tabs
        sx={{ height: "100%", overflow: "hidden", bgcolor: "transparent", margin: "initial" }}
        value={selectedTab}
        defaultValue={0}
        size="lg"
        onChange={(event: React.SyntheticEvent | null, newValue: string | number | null) =>
          setSelectedTab(Number(newValue))
        }
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            left: 0,
            zIndex: 10,
            background: "white",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box sx={{ p: 0.5, pb: 0 }}>
            <Breadcrumb
              handleTitleClick={() => setSelectedTab(2)}
              handleUnkownPatientClick={() => setSelectedTab(1)}
            />
          </Box>
          <TabList
            tabFlex={1}
            sx={{
              [`&& .${tabClasses.root}`]: {
                fontWeight: "600",
                color: "text.tertiary",
                [`&.${tabClasses.selected}`]: {
                  bgcolor: "transparent",
                  color: "text.primary",
                },
              },
            }}
            data-cy="note-tab-list"
          >
            <Tab color="primary" indicatorInset>
              Facts
            </Tab>
            <Tab color="primary" indicatorInset>
              Patient
              {!note.person_id && <WarningIcon data-cy="warning-icon" color="warning" />}
            </Tab>
            <Tab color="primary" indicatorInset>
              Note details
              {!note.note_datetime ? <WarningIcon color="warning" /> : ""}
            </Tab>
          </TabList>
        </Box>
        <TabPanel value={0} sx={{ p: 0, overflow: "scroll" }}>
          <Stack sx={{ height: "100%" }} direction="column" justifyContent="space-between">
            <Box sx={{ p: 2 }} data-cy="fact-list">
              <FactList />
            </Box>
            <Box
              sx={{
                position: "sticky",
                bottom: 0,
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
                p: 2,
                zIndex: 1,
              }}
            >
              {selectedFact || selectedLabel ? (
                <Button
                  onClick={() => {
                    setDisplayAllFacts();
                  }}
                  variant="soft"
                  color="primary"
                  startDecorator={<ArrowBackIcon />}
                  data-cy="back-to-fact-list-button"
                >
                  Back to fact list
                </Button>
              ) : !note.validation_date ? (
                <>
                  <Stack direction="row" spacing={2}>
                    <Tooltip title={isNoteReadyForValidation} variant="solid" color="warning">
                      <span>
                        <Button
                          onClick={() => handleValidate(false, false)}
                          variant="soft"
                          color="success"
                          disabled={isNoteReadyForValidation !== ""}
                        >
                          <DoneIcon />
                        </Button>
                      </span>
                    </Tooltip>
                    {bundleId && (
                      <>
                        <Tooltip title={isNoteReadyForValidation} variant="solid" color="warning">
                          <span>
                            <Button
                              onClick={() => handleValidate(true, false)}
                              variant="soft"
                              color="success"
                              disabled={!bundleId || isNoteReadyForValidation !== ""}
                              startDecorator={<DoneIcon />}
                            >
                              Next
                            </Button>
                          </span>
                        </Tooltip>
                        <Button
                          onClick={() => handleValidate(true, true)}
                          variant="soft"
                          color="danger"
                          disabled={!bundleId}
                          startDecorator={<DoNotDisturbIcon />}
                        >
                          Skip
                        </Button>
                      </>
                    )}
                    <Button
                      onClick={() => setRefreshModal(true)}
                      variant="soft"
                      startDecorator={<AutorenewIcon />}
                      color="warning"
                    >
                      Refresh
                    </Button>
                  </Stack>
                </>
              ) : (
                <Stack direction="row" spacing={2}>
                  <Button startDecorator={<DoneIcon />}>
                    Note verified on {unixToDayjs(note.validation_date)?.format("DD MMM YYYY")}
                  </Button>

                  <Button
                    onClick={() => handleValidate(false, true)}
                    variant="soft"
                    color="success"
                    disabled={!bundleId}
                    endDecorator={<NavigateNextOutlinedIcon />}
                  >
                    Next
                  </Button>
                </Stack>
              )}
            </Box>
          </Stack>
        </TabPanel>
        <TabPanel value={1} sx={{ p: 0, background: "white", overflow: "scroll" }}>
          <Stack sx={{ flex: 1 }} direction="column" justifyContent="space-between">
            <Box sx={{ p: 2 }}>
              {note.person_id ? (
                isEditPatient ? (
                  <PatientForm patient={patient} onSubmit={updatePatient} />
                ) : (
                  <PatientDisplay patient={patient} />
                )
              ) : (
                <>
                  <AllPatientsDropdown onSelect={handleLinkPatient} />
                  <Divider sx={{ my: 4 }}>OR</Divider>
                  <PatientForm patient={patient} onSubmit={createPatient} />
                </>
              )}
            </Box>
            {note.person_id && !isEditPatient && (
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div className="field is-grouped px-4 py-4">
                  <Stack direction="row" spacing={2}>
                    <Button
                      onClick={handleUnlinkPatient}
                      variant="soft"
                      color="warning"
                      startDecorator={<PersonOffIcon />}
                    >
                      Unlink patient
                    </Button>
                    <Button
                      onClick={() => setIsEditPatient(true)}
                      variant="soft"
                      color="primary"
                      startDecorator={<EditIcon />}
                    >
                      Edit patient
                    </Button>
                  </Stack>
                </div>
              </div>
            )}
          </Stack>
        </TabPanel>
        <TabPanel value={2} sx={{ p: 0, background: "white" }}>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ pt: 2, mt: 2, pr: 2, pl: 2 }}>
              <FormControl data-cy="note-details-title">
                <FormLabel>Note title</FormLabel>
                <Input
                  required
                  defaultValue={note.note_title}
                  onChange={debounce(handleNoteTitleChange, 500)}
                />
              </FormControl>
            </Box>
            <Box sx={{ mt: 2, pr: 2, pl: 2 }} data-cy="note-details-recording-date">
              <JoyDatePicker
                sx={{ width: "100%" }}
                label="Recording date"
                value={unixToDayjs(note.note_datetime)}
                onChange={handleRecordingDateChange}
                format="DD MMM YYYY"
                slotProps={{
                  textField: {
                    helperText:
                      "The recording date refers to when the practitioner wrote the note.",
                  },
                }}
              />
            </Box>
            <Box sx={{ mt: 2, pr: 2, pl: 2 }} data-cy="note-details-type">
              <FormControl>
                <FormLabel>Note type</FormLabel>
                <Select
                  defaultValue={note.note_type ? note.note_type.code : ""}
                  onChange={(event: any, newValue: string | null) => handleNoteTypeChange(newValue)}
                >
                  {documentTypes.map((type) => (
                    <Option key={type.code} value={type.code}>
                      {type.display}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ mt: 2, pr: 2, pl: 2 }} data-cy="note-categories">
              <FormControl>
                <FormLabel>Medical specialities</FormLabel>
                <Autocomplete
                  multiple
                  id="medical-specialities"
                  options={medicalSpecialities}
                  getOptionLabel={(option) => option.display!}
                  defaultValue={
                    note.note_category && note.note_category.length > 0 ? note.note_category : []
                  }
                  onChange={(event: any, newValue: Coding[]) => handleNoteCategoryChange(newValue)}
                />
              </FormControl>
            </Box>
          </Box>
        </TabPanel>
      </Tabs>
    </Box>
  );
}
