import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";

export default function BackdropLoader({
  children,
  loading = true,
  text,
}: {
  children?: React.ReactNode;
  loading: boolean;
  text?: string;
}) {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: 999999999,
            backdropFilter: "blur(8px)",
            textAlign: "center",
          }}
        >
          <div>
            <CircularProgress variant="soft" />
            {text && (
              <Typography level="title-lg" sx={{ mt: 1, color: "white" }}>
                {text}
              </Typography>
            )}
          </div>
        </Box>
      )}
      {children}
    </Box>
  );
}
