import Launch from "@mui/icons-material/Launch";
import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import { Subject } from "../models/cohort";

interface PatientTableProps {
  subjects: Subject[];
}

export default function PatientsTable({ subjects }: PatientTableProps) {
  return (
    <Sheet variant="outlined" sx={{ borderRadius: "sm" }}>
      <Table
        aria-labelledby="patientCohortTable"
        stickyHeader
        hoverRow
        sx={{
          "--TableCell-headBackground": "var(--joy-palette-background-level1)",
          "--Table-headerUnderlineThickness": "1px",
          "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
          "--TableCell-paddingY": "4px",
          "--TableCell-paddingX": "8px",
        }}
      >
        <thead>
          <tr>
            <th style={{ padding: "12px 6px", width: "320px" }}>Patient ID</th>
            <th style={{ padding: "12px 6px" }}>Entry date</th>
            <th style={{ padding: "12px 6px" }}>Status</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map((subject, index) => (
            <tr key={index}>
              <td>
                <Link
                  underline="hover"
                  href={"/patient/" + subject.person_source_value}
                  target="_blank"
                  rel="noopener"
                  endDecorator={<Launch fontSize="small" />}
                >
                  {subject.person_source_value}
                </Link>
              </td>
              <td>{subject.entry_date}</td>
              <td>
                <Chip color={subject.inactive ? "warning" : "success"}>
                  {subject.inactive ? "Excluded" : "Included"}
                </Chip>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  );
}
