import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import DialogTitle from "@mui/joy/DialogTitle";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { SearchBar } from "../annotation/molecules/SearchBar";
import { TerminologyQuartzClientContext } from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";
import { Cohort } from "../models/cohort";
import {
  BaseCriteria,
  CodingCriteria,
  CodingValueCriteria,
  CriteriaGroup,
  CriteriaType,
  GroupingOperator,
  ValueType,
} from "../models/criteria";
import { Coding, SimplifiedCoding } from "../models/structuration";
import { instanceOfCodingCriteria, instanceOfCodingValueCriteria } from "../utils/criteria_group";
import CriteriaGroupDisplay from "./CriteriaGroupDisplay";

function codingToCriteriaType(domain: string): CriteriaType {
  switch (domain) {
    case "Condition":
      return CriteriaType.CONDITION;
    case "Procedure":
      return CriteriaType.PROCEDURE;
    case "Drug":
      return CriteriaType.DRUG;
    case "Observation":
      return CriteriaType.OBSERVATION;
    case "Measurement":
      return CriteriaType.MEASUREMENT;
    case "Device":
      return CriteriaType.DEVICE;
    default:
      throw new Error(`${domain} are not yet managed in the cohort builder`);
  }
}

export interface CodingCriteriaModalConfig {
  isEntryEvent: boolean;
  isExclusion?: boolean;
  index?: number | null;
  coding?: Coding | null;
  criteriaGroupPrefill?: CriteriaGroup | null;
}

interface CodingCriteriaModalProps {
  config: CodingCriteriaModalConfig | null;
  setOpen: (open: CodingCriteriaModalConfig | null) => void;
  updateCohort: (cohort: Cohort) => void;
  cohort: Cohort;
}

export default function CodingCriteriaModal({
  config,
  setOpen,
  updateCohort,
  cohort,
}: CodingCriteriaModalProps) {
  // Context
  const terminologyQuartzClient = React.useContext(TerminologyQuartzClientContext);

  // State
  const [serverError, setServerError] = React.useState<string | null>("");
  const [criteriaGroup, setCriteriaGroup] = React.useState<CriteriaGroup | null>(null);
  const [codingsValues, setCodingsValues] = React.useState<SimplifiedCoding[][] | null>(null);

  const getValueParameterSet = async (coding: Coding | null) => {
    if (!coding) return null;

    try {
      return await terminologyQuartzClient.valueParameters(coding.code);
    } catch (e: any) {
      setServerError(e.message);
      return null;
    }
  };

  const handleValueChange = (index: number, newValue: string | null) => {
    if (criteriaGroup && codingsValues) {
      const newCriteriaList = criteriaGroup.criteria_list.map(
        (criteria: BaseCriteria, i: number) => {
          if (i === index) {
            return {
              ...criteria,
              params: {
                ...criteria.params,
                value_coding: codingsValues[index].find(
                  (coding: SimplifiedCoding) => coding.code === newValue
                ),
              } as CodingValueCriteria,
            };
          }
          return criteria;
        }
      );
      setCriteriaGroup({ ...criteriaGroup, criteria_list: newCriteriaList });
    }
  };

  const handleAddNewCriteria = async (coding: Coding | null) => {
    if (!coding) return;
    const valueParameterSet = await getValueParameterSet(coding);
    if (!valueParameterSet) return;
    let newCodingValues = valueParameterSet.default;

    // Add ABSENT and PRESENT in the possible codings values
    if (["Measurement", "Observation"].includes(valueParameterSet.concept.domain_id)) {
      newCodingValues.push({ code: "4181412", display: "Present" });
      newCodingValues.push({ code: "4132135", display: "Absent" });
    }
    if (codingsValues) {
      setCodingsValues([...codingsValues, newCodingValues]);
    } else {
      setCodingsValues([newCodingValues]);
    }
    const base_coding = {
      code: coding.code,
      system: "OMOP",
      domain: valueParameterSet.concept.domain_id,
      display: valueParameterSet.concept.concept_name,
    } as Coding;

    if (["Measurement", "Observation"].includes(valueParameterSet.concept.domain_id)) {
      if (criteriaGroup) {
        setCriteriaGroup({
          ...criteriaGroup,
          criteria_list: [
            ...criteriaGroup.criteria_list,
            {
              params: {
                base_coding: base_coding,
                value_coding: valueParameterSet.default[0],
                value_type: ValueType.CODING,
                isExclusion: config?.isExclusion,
              } as CodingValueCriteria,
              type: codingToCriteriaType(valueParameterSet.concept.domain_id),
            },
          ],
          operator: GroupingOperator.OR,
        });
      } else {
        setCriteriaGroup({
          criteria_list: [
            {
              params: {
                base_coding: base_coding,
                value_coding: valueParameterSet.default[0],
                value_type: ValueType.CODING,
                isExclusion: config?.isExclusion,
              } as CodingValueCriteria,
              type: codingToCriteriaType(valueParameterSet.concept.domain_id),
            },
          ],
          operator: null,
        });
      }
    } else {
      if (criteriaGroup) {
        setCriteriaGroup({
          ...criteriaGroup,
          criteria_list: [
            ...criteriaGroup.criteria_list,
            {
              params: {
                coding: base_coding,
                isExclusion: config?.isExclusion,
              } as CodingCriteria,
              type: codingToCriteriaType(valueParameterSet.concept.domain_id),
            },
          ],
          operator: GroupingOperator.OR,
        });
      } else {
        setCriteriaGroup({
          criteria_list: [
            {
              params: {
                coding: base_coding,
                isExclusion: config?.isExclusion,
              } as CodingCriteria,
              type: codingToCriteriaType(valueParameterSet.concept.domain_id),
            },
          ],
          operator: null,
        });
      }
    }
  };

  const handleOnClose = () => {
    setOpen(null);
    setCriteriaGroup(null);
    setCodingsValues(null);
  };

  React.useEffect(() => {
    if (!config) {
      return;
    }
    const initFromConfig = async (config: CodingCriteriaModalConfig) => {
      if (config.coding) {
        handleAddNewCriteria(config.coding);
      } else if (config.criteriaGroupPrefill) {
        setCriteriaGroup(config.criteriaGroupPrefill);
        const values = await Promise.all(
          config.criteriaGroupPrefill.criteria_list.map(async (criteria: BaseCriteria) => {
            if (instanceOfCodingValueCriteria(criteria.params) && criteria.params.base_coding) {
              const valueParameterSet = await getValueParameterSet(criteria.params.base_coding);
              return valueParameterSet ? valueParameterSet.default : [];
            }
            return [];
          })
        );
        setCodingsValues(values);
      }
    };

    initFromConfig(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  if (!criteriaGroup || !config) {
    return null;
  }
  return (
    <>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <Modal open={!!config} onClose={handleOnClose}>
        <ModalOverflow>
          <ModalDialog sx={{ width: "600px" }}>
            <ModalClose />
            <DialogTitle>
              {config.isEntryEvent ? "Entry event" : config.isExclusion ? "Exclusion" : "Inclusion"}
            </DialogTitle>
            <form
              onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                if (config.isEntryEvent) {
                  if (config.index !== null) {
                    const newEntryEvent = cohort.entry_event.map((group, i) =>
                      i === config.index ? criteriaGroup : group
                    );
                    updateCohort({ ...cohort, entry_event: newEntryEvent });
                  } else {
                    updateCohort({
                      ...cohort,
                      entry_event: [...cohort.entry_event, criteriaGroup],
                    });
                  }
                } else {
                  if (config.index !== null) {
                    const newInclusionCriteria = cohort.inclusion_criteria.map((group, i) =>
                      i === config.index ? criteriaGroup : group
                    );
                    updateCohort({ ...cohort, inclusion_criteria: newInclusionCriteria });
                  } else {
                    updateCohort({
                      ...cohort,
                      inclusion_criteria: [...cohort.inclusion_criteria, criteriaGroup],
                    });
                  }
                }
                handleOnClose();
              }}
            >
              <Stack spacing={2}>
                {criteriaGroup.criteria_list.map((criteria: BaseCriteria, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Card>
                        <div>
                          {instanceOfCodingCriteria(criteria.params) && (
                            <Typography level="title-lg">
                              {criteria.params.coding?.domain} / {criteria.params.coding?.display}
                            </Typography>
                          )}
                          {instanceOfCodingValueCriteria(criteria.params) && (
                            <Typography level="title-lg">
                              {criteria.params.base_coding?.domain} /{" "}
                              {criteria.params.base_coding?.display}
                            </Typography>
                          )}

                          <IconButton
                            aria-label="close"
                            variant="plain"
                            color="neutral"
                            size="sm"
                            sx={{ position: "absolute", top: "0.875rem", right: "0.5rem" }}
                            onClick={() =>
                              setCriteriaGroup({
                                ...criteriaGroup,
                                criteria_list: criteriaGroup.criteria_list.filter(
                                  (_, i) => i !== index
                                ),
                              })
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </div>
                        {instanceOfCodingValueCriteria(criteria.params) &&
                          codingsValues !== null && (
                            <CardContent>
                              <FormControl>
                                <FormLabel>Select a value</FormLabel>
                                <Select
                                  value={criteria.params.value_coding?.code}
                                  onChange={(_, newValue) => {
                                    handleValueChange(index, newValue);
                                  }}
                                  sx={{
                                    minWidth: "15rem",
                                  }}
                                  slotProps={{
                                    listbox: {
                                      sx: {
                                        width: "100%",
                                      },
                                    },
                                  }}
                                >
                                  {codingsValues[index].map((coding: SimplifiedCoding) => (
                                    <Option key={coding.code} value={coding.code}>
                                      {coding.display}
                                    </Option>
                                  ))}
                                </Select>
                              </FormControl>
                            </CardContent>
                          )}
                      </Card>
                      <Divider>OR</Divider>
                    </React.Fragment>
                  );
                })}
                <SearchBar
                  message="Add another criteria"
                  onChange={(coding) => {
                    handleAddNewCriteria(coding);
                  }}
                />
                <Divider />
                <Card>
                  <CriteriaGroupDisplay criteriaGroup={criteriaGroup} />
                </Card>
                <Button type="submit">Save</Button>
              </Stack>
            </form>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
