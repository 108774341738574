import CakeIcon from "@mui/icons-material/Cake";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Identifier, Person } from "../models/person";
import { unixToDayjs } from "../utils/time";

interface PatientDetailsProps {
  person: Person;
}

const GenderIcon: React.FC<{ gender: string | null | undefined }> = ({ gender }) => {
  if (gender?.toLowerCase() === "male") {
    return <MaleIcon sx={{ fontSize: 16 }} />;
  } else if (gender?.toLowerCase() === "female") {
    return <FemaleIcon sx={{ fontSize: 16 }} />;
  } else {
    return <></>;
  }
};

export function PatientDetails({ person }: PatientDetailsProps) {
  const calculateAge = (birthDate: number) => {
    const date = new Date(birthDate);
    const diff = Date.now() - date.getTime();
    const age = new Date(diff);
    return Math.abs(age.getUTCFullYear() - 1970);
  };

  return (
    <Box>
      <Stack direction="column" spacing={1}>
        <Typography sx={{ display: "inline-block" }} level="h2">
          {person.given_name} {person.family_name}
        </Typography>
        <Stack sx={{ display: "inline-block", ml: 2 }} direction="row" spacing={1}>
          {person.birth_date && (
            <Chip variant="outlined" startDecorator={<CakeIcon sx={{ fontSize: 14 }} />}>
              {unixToDayjs(person.birth_date)?.format("DD MMM YYYY")}{" "}
              {!person.deceased && <>({calculateAge(person.birth_date)} y.o.)</>}
            </Chip>
          )}
          <Chip variant="outlined" startDecorator={<GenderIcon gender={person.gender} />}>
            {person.gender}
          </Chip>
          <Chip
            variant="outlined"
            startDecorator={<MonitorHeartOutlinedIcon sx={{ fontSize: 16 }} />}
          >
            {!person.deceased ? "Alive or unknown" : "Deceased"}
          </Chip>
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ my: 2 }} spacing={1}>
        {person.external_ids && person.external_ids.length !== 0 && (
          <>
            {person.external_ids.map((id: Identifier, key: number) => {
              return (
                id.value && (
                  <Chip key={key}>
                    {id.system}: <b>{id.value}</b>
                  </Chip>
                )
              );
            })}
          </>
        )}
      </Stack>
      {person.consent_withdrawal_date && (
        <Alert startDecorator={<WarningIcon />} variant="outlined" color="danger">
          The patient opposes the secondary use of their data (
          {unixToDayjs(person.consent_withdrawal_date)?.format("DD MMM YYYY")}).
        </Alert>
      )}
    </Box>
  );
}
