import Launch from "@mui/icons-material/Launch";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import Box from "@mui/joy/Box";
import Checkbox from "@mui/joy/Checkbox";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import React from "react";
import { Participant, ParticipantId, Study } from "../../models/study";
import { formatAndCapitalizeString } from "../../utils/text";

interface ParticipantsTableProps {
  study: Study;
  participants: Participant[];
  selected: ParticipantId[];
  setSelected: React.Dispatch<React.SetStateAction<ParticipantId[]>>;
}

export default function ParticipantsTable({
  study,
  participants,
  selected,
  setSelected,
}: ParticipantsTableProps) {
  // States
  const [filtered, setFiltered] = React.useState<Participant[]>(participants);

  // Utils
  const getParticipantCurrentState = (participant: Participant) => {
    return participant.progress.reduce((max, current) => {
      return new Date(max.start_date) > new Date(current.start_date) ? max : current;
    });
  };

  if (participants.length === 0) {
    return (
      <Sheet
        variant="outlined"
        sx={{
          minHeight: "450px",
          borderRadius: "md",
          minWidth: "100%",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Stack direction="column" gap={1} alignItems="center">
          <PersonOffIcon sx={{ fontSize: 128 }} color="disabled" />
          <Typography level="h2" color="neutral">
            No participant in this study yet.
          </Typography>
          <Typography level="body-md" color="neutral">
            You can link a cohort to this study to automatically add participants.
          </Typography>
        </Stack>
      </Sheet>
    );
  }
  return (
    <>
      <Box>
        <FormControl sx={{ width: 240 }}>
          <FormLabel id="select-field-participant-status" htmlFor="select-field-participant-status">
            Participant status
          </FormLabel>
          <Select
            id="select-field-participant-status"
            defaultValue="all"
            onChange={(e, newValue) => {
              if (newValue === "all") {
                setFiltered(participants);
              } else {
                setFiltered(
                  participants.filter(
                    (participant) => getParticipantCurrentState(participant).state === newValue
                  )
                );
              }
            }}
          >
            <Option value="all">All</Option>
            {Array.from(
              new Set(
                participants.map((participant) => getParticipantCurrentState(participant).state)
              )
            ).map((state) => (
              <Option key={state} value={state}>
                {formatAndCapitalizeString(state)}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Sheet sx={{ borderRadius: "sm", mt: 2 }} variant="outlined">
        <Table
          aria-labelledby="studyTable"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground": "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: "center", padding: "12px 6px" }}>
                <Checkbox
                  size="sm"
                  indeterminate={selected.length > 0 && selected.length !== participants.length}
                  checked={selected.length === participants.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? participants.map((row) => row.participant_id) : []
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === participants.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              <th style={{ padding: "12px 6px", width: "300px" }}>Link to participant details</th>
              <th style={{ padding: "12px 6px" }}>Participant number</th>
              <th style={{ padding: "12px 6px" }}>Current status</th>
            </tr>
          </thead>
          <tbody>
            {filtered.map((participant) => (
              <tr key={participant.participant_id}>
                <td
                  style={{ textAlign: "center", width: 120, verticalAlign: "middle" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    size="sm"
                    checked={selected.includes(participant.participant_id)}
                    color={selected.includes(participant.participant_id) ? "primary" : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(participant.participant_id)
                          : ids.filter((itemId) => itemId !== participant.participant_id)
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                    sx={{ verticalAlign: "text-bottom" }}
                  />
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Link
                    underline="hover"
                    href={"/patient/" + participant.person_id}
                    target="_blank"
                    rel="noopener"
                    endDecorator={<Launch fontSize="small" />}
                  >
                    {participant.person_id}
                  </Link>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Typography>
                    {participant.identifier.length > 0 ? participant.identifier[0].value : ""}
                  </Typography>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Typography>
                    {formatAndCapitalizeString(getParticipantCurrentState(participant).state)}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </>
  );
}
