import Stack from "@mui/joy/Stack";
import { FactQualifier } from "../../../../../../models/fact";
import Chip from "@mui/joy/Chip";

export default function QualifierListDisplay({
  qualifier,
}: {
  qualifier: FactQualifier | null | undefined;
}) {
  return (
    qualifier && (
      <Stack direction="row" gap={0.5}>
        {qualifier.family_related && (
          <Chip variant="outlined" color="neutral" size="sm">
            Family related
          </Chip>
        )}
        {qualifier.negated && (
          <Chip variant="outlined" color="neutral" size="sm">
            Negated
          </Chip>
        )}
        {qualifier.not_applicable && (
          <Chip variant="outlined" color="neutral" size="sm">
            Not applicable
          </Chip>
        )}
        {qualifier.uncertain && (
          <Chip variant="outlined" color="neutral" size="sm">
            Uncertain
          </Chip>
        )}
      </Stack>
    )
  );
}
