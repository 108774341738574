import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import React from "react";
import { QuestionAnsweringClientContext } from "../clients/contexts";
import { Questionnaire } from "../models/questionnaire";

interface SaveQuestionnaireModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleSaveNewQuestionnaire: (title: string, description: string) => void;
  handleUpdateQuestionnaire: (questionnaire: Questionnaire) => void;
}

export default function SaveQuestionnaireModal({
  open,
  setOpen,
  handleSaveNewQuestionnaire,
  handleUpdateQuestionnaire,
}: SaveQuestionnaireModalProps) {
  // Context
  const questionAnsweringClient = React.useContext(QuestionAnsweringClientContext);

  // State
  const [questionnaires, setQuestionnaires] = React.useState<Questionnaire[]>([]);
  const [title, setTitle] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [selectedQuestionnaire, setSelectedQuestionnaire] = React.useState<Questionnaire | null>(
    null
  );

  React.useEffect(() => {
    const fetchQuestionnaires = async () => {
      try {
        const response = await questionAnsweringClient.listQuestionnaires();
        setQuestionnaires(response);
      } catch (e: any) {
        console.error(e.message);
      }
    };
    if (open) {
      fetchQuestionnaires();
    }
  }, [open, questionAnsweringClient]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalOverflow>
        <ModalDialog sx={{ width: "650px" }}>
          <ModalClose />
          <DialogTitle>Questionnaire details</DialogTitle>
          <Typography>
            Save this questionnaire as a template to use it somewhere else (on another group of
            patients for instance).
          </Typography>
          <Stack spacing={2}>
            <form
              onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                handleSaveNewQuestionnaire(title, description);
                setTitle("");
                setDescription("");
                setOpen(false);
              }}
            >
              <Stack spacing={2}>
                <Typography level="h4">Save as a new template</Typography>
                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Input
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    autoFocus
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    value={description}
                    minRows={5}
                    maxRows={5}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </FormControl>
                <Button type="submit">Save as a new template</Button>
              </Stack>
            </form>
            {questionnaires.length > 0 && (
              <>
                <Divider orientation="horizontal">Or</Divider>
                <form
                  onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    selectedQuestionnaire && handleUpdateQuestionnaire(selectedQuestionnaire);
                    setSelectedQuestionnaire(null);
                    setOpen(false);
                  }}
                >
                  <Stack spacing={2}>
                    <Typography level="h4">Update an existing template</Typography>
                    <RadioGroup
                      name="radio-select-questionnaire"
                      sx={{ gap: 1, "& > div": { p: 1 } }}
                    >
                      {questionnaires.map((questionnaire) => (
                        <FormControl key={questionnaire.questionnaire_id}>
                          <Radio
                            overlay
                            value={questionnaire.questionnaire_id}
                            label={`${questionnaire.title} (${questionnaire.item.length} questions) `}
                            onChange={() => setSelectedQuestionnaire(questionnaire)}
                          />
                          <FormHelperText>{questionnaire.description}</FormHelperText>
                        </FormControl>
                      ))}
                    </RadioGroup>
                    {selectedQuestionnaire && (
                      <>
                        <FormControl>
                          <FormLabel>Title</FormLabel>
                          <Input
                            value={selectedQuestionnaire.title}
                            onChange={(event) =>
                              setSelectedQuestionnaire(
                                (prev) => prev && { ...prev, title: event.target.value }
                              )
                            }
                            autoFocus
                            required
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Description</FormLabel>
                          <Textarea
                            value={selectedQuestionnaire.description || ""}
                            minRows={5}
                            maxRows={5}
                            onChange={(event) =>
                              setSelectedQuestionnaire(
                                (prev) => prev && { ...prev, description: event.target.value }
                              )
                            }
                          />
                        </FormControl>
                      </>
                    )}
                    <Button type="submit">Update an existing template</Button>
                  </Stack>
                </form>
              </>
            )}
          </Stack>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
