import ClearIcon from "@mui/icons-material/Clear";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Divider from "@mui/joy/Divider";
import Grid from "@mui/joy/Grid";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useLocation } from "react-router-dom";
import AnalyticsModal from "../analytics/AnalyticsModal";
import { SearchBar } from "../annotation/molecules/SearchBar";
import { CohortQuartzClientContext } from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";
import { Loading } from "../common/loading/Loading";
import { Cohort, CohortId, PutCohort } from "../models/cohort";
import { BaseCriteria, CriteriaGroup, CriteriaType, Gender } from "../models/criteria";
import { Coding } from "../models/structuration";
import AgeBoundariesModal from "./AgeBoundariesModal";
import CodingCriteriaModal, { CodingCriteriaModalConfig } from "./CodingCriteriaModal";
import CohortCreationGuide from "./CohortCreationGuide";
import CriteriaGroupDisplay from "./CriteriaGroupDisplay";
import EditCohortDetailsModal from "./EditCohortDetailsModal";
import ExportDropDown from "./ExportDropDown";
import PatientsTable from "./PatientsTable";
import Box from "@mui/joy/Box";

export const filterExclusionCriteria = (criteria: CriteriaGroup) => {
  return criteria.criteria_list.filter((c: BaseCriteria) => {
    if (typeof c.params === "object" && "isExclusion" in c.params) {
      return c.params.isExclusion;
    }
    return false;
  });
};

export default function CohortBuilder() {
  const cohortClient = React.useContext(CohortQuartzClientContext);
  const location = useLocation();
  const cohortId = location.pathname.split("/")[2] as CohortId;

  const [analyticsModal, setAnalyticsModal] = React.useState(false);
  const [openCohortNameModal, setOpenCohortNameModal] = React.useState(false);
  const [openAgeBoundariesModal, setOpenAgeBoundariesModal] = React.useState(false);
  const [openCodingCriteriaModal, setOpenCodingCriteriaModal] =
    React.useState<CodingCriteriaModalConfig | null>(null);
  const [openGuide, setOpenGuide] = React.useState(false);
  const [cohort, setCohort] = React.useState<Cohort | null>(null);
  const [hasGenderCriteria, setHasGenderCriteria] = React.useState<boolean>(false);
  const [hasAgeCriteria, setHasAgeCriteria] = React.useState<boolean>(false);
  const [hasVitalStatusCriteria, setHasVitalStatusCriteria] = React.useState<boolean>(false);
  const [serverError, setServerError] = React.useState<string | null>("");
  const [loading, setLoading] = React.useState(true);

  const getCohort = React.useCallback(async () => {
    setLoading(true);
    try {
      const result = await cohortClient.getCohortById(cohortId);
      setCohort(result);
    } catch (e: any) {
      setServerError(e.message);
    } finally {
      setLoading(false);
    }
  }, [cohortClient, cohortId]);

  const updateCohort = async (updatedCohort: Cohort) => {
    setLoading(true);
    const body = {
      name: updatedCohort.name,
      description: updatedCohort.description,
      db_name: updatedCohort.db_name,
      entry_event: updatedCohort.entry_event,
      inclusion_criteria: updatedCohort.inclusion_criteria,
      excluded_subjects: [],
    } as PutCohort;
    try {
      const result = await cohortClient.updateCohortByID(updatedCohort.cohort_id, body);
      setCohort(result);
    } catch (e: any) {
      setServerError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditCohortDetails = async (newName: string, newDescription: string) => {
    if (cohort === null) {
      return;
    }
    updateCohort({ ...cohort, name: newName, description: newDescription });
  };

  const handleNewCodingCriteria = async (
    coding: Coding,
    isExclusion: boolean,
    isEntryEvent: boolean
  ) => {
    setOpenCodingCriteriaModal({
      coding: coding,
      isExclusion: isExclusion,
      index: null,
      isEntryEvent: isEntryEvent,
      criteriaGroupPrefill: null,
    });
  };

  const handleEditCriteriaGroup = async (
    isEntryEvent: boolean,
    isExclusion: boolean,
    index: number,
    criteriaGroup: CriteriaGroup
  ) => {
    setOpenCodingCriteriaModal({
      coding: null,
      isExclusion: isExclusion,
      index: index,
      isEntryEvent: isEntryEvent,
      criteriaGroupPrefill: criteriaGroup,
    });
  };

  const handleGenderCriteria = async (gender: Gender) => {
    if (cohort === null) {
      return;
    }
    const criteria_group = {
      criteria_list: [
        {
          params: { gender: gender },
          type: CriteriaType.GENDER,
        },
      ],
      operator: null,
    } as CriteriaGroup;
    updateCohort({ ...cohort, inclusion_criteria: [...cohort.inclusion_criteria, criteria_group] });
  };

  const handleVitalStatusCriteria = async (deceased: boolean) => {
    if (cohort === null) {
      return;
    }
    const criteria_group = {
      criteria_list: [
        {
          params: { deceased: deceased },
          type: CriteriaType.VITAL_STATUS,
        },
      ],
      operator: null,
    } as CriteriaGroup;
    updateCohort({ ...cohort, inclusion_criteria: [...cohort.inclusion_criteria, criteria_group] });
  };

  const handleAgeBoundariesChange = async (minAge: number, maxAge: number) => {
    if (cohort === null) {
      return;
    }
    const criteria_group = {
      criteria_list: [
        {
          params: { minAge: minAge, maxAge: maxAge },
          type: CriteriaType.AGE,
        },
      ],
      operator: null,
    } as CriteriaGroup;
    updateCohort({ ...cohort, inclusion_criteria: [...cohort.inclusion_criteria, criteria_group] });
    setOpenAgeBoundariesModal(false);
  };

  React.useEffect(() => {
    getCohort();
  }, [getCohort]);

  React.useEffect(() => {
    if (cohort === null) {
      return;
    }
    if (cohort.inclusion_criteria === null) {
      return;
    }
    setHasAgeCriteria(
      cohort.inclusion_criteria.some(
        (criteria) =>
          criteria &&
          criteria.criteria_list &&
          criteria.criteria_list.some((c: BaseCriteria) => c && c.type === CriteriaType.AGE)
      )
    );
    setHasGenderCriteria(
      cohort.inclusion_criteria.some(
        (criteria) =>
          criteria &&
          criteria.criteria_list &&
          criteria.criteria_list.some((c: BaseCriteria) => c && c.type === CriteriaType.GENDER)
      )
    );
    setHasVitalStatusCriteria(
      cohort.inclusion_criteria.some(
        (criteria) =>
          criteria &&
          criteria.criteria_list &&
          criteria.criteria_list.some(
            (c: BaseCriteria) => c && c.type === CriteriaType.VITAL_STATUS
          )
      )
    );
  }, [cohort]);

  if (loading || cohort === null) {
    return <Loading />;
  }
  return (
    <Box sx={{ p: 4 }}>
      {cohort.statistics && (
        <AnalyticsModal
          isOpen={analyticsModal}
          onClose={() => setAnalyticsModal(false)}
          dbReport={cohort.statistics}
        />
      )}
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <CohortCreationGuide open={openGuide} setOpen={setOpenGuide} />
      <EditCohortDetailsModal
        open={openCohortNameModal}
        setOpen={setOpenCohortNameModal}
        cohortName={cohort.name}
        cohortDescription={cohort.description}
        handleEditCohortDetails={handleEditCohortDetails}
      />
      <AgeBoundariesModal
        open={openAgeBoundariesModal}
        setOpen={setOpenAgeBoundariesModal}
        handleAgeBoundariesChange={handleAgeBoundariesChange}
        currentMinAge={18}
        currentMaxAge={80}
      />
      <CodingCriteriaModal
        config={openCodingCriteriaModal}
        setOpen={setOpenCodingCriteriaModal}
        updateCohort={updateCohort}
        cohort={cohort}
      />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Typography level="h2">{cohort.name}</Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            disabled={cohort.subjects.length === 0}
            onClick={() => {
              setAnalyticsModal(true);
            }}
            color="success"
          >
            Analytics
          </Button>
          <ExportDropDown cohortId={cohortId} />
          <Button color="success" onClick={() => setOpenGuide(true)}>
            Open cohort creation guide
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={4} sx={{ flexGrow: 1, mt: 4 }}>
        <Grid xs={9}>
          <Stack spacing={2}>
            <Card>
              <Typography level="h3">Entry event</Typography>
              <Typography level="body-sm">
                Select a health event or condition that marks the beginning of a patient’s journey
                in your study or monitoring process. It will define the patient's entry date.
              </Typography>
              <SearchBar
                onChange={(coding) => coding && handleNewCodingCriteria(coding, false, true)}
                message="Search for a disease, a drug, ..."
              />
              {cohort.entry_event.length !== 0 && (
                <List>
                  {cohort.entry_event.map((criteriaGroup, index) => (
                    <ListItem
                      key={index}
                      endAction={
                        <IconButton
                          aria-label="Delete"
                          size="sm"
                          color="neutral"
                          onClick={(e) => {
                            updateCohort({
                              ...cohort,
                              entry_event: [
                                ...cohort.entry_event.filter((_, i) => {
                                  return i !== index;
                                }),
                              ],
                            });
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      }
                    >
                      <ListItemButton
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditCriteriaGroup(true, false, index, criteriaGroup);
                        }}
                      >
                        {index !== 0 && (
                          <ListItemDecorator>
                            <small>AND</small>
                          </ListItemDecorator>
                        )}
                        <CriteriaGroupDisplay criteriaGroup={criteriaGroup} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </Card>
            <Card>
              <Typography level="h3">Inclusion criteria</Typography>
              <Typography level="body-sm">
                Define the specific health characteristics, conditions, or patient demographics
                necessary for inclusion in the cohort.
              </Typography>
              <SearchBar
                onChange={(coding) => coding && handleNewCodingCriteria(coding, false, false)}
                message="Search for a disease, a drug, ..."
              />
              {cohort.inclusion_criteria.length !== 0 && (
                <List>
                  {cohort.inclusion_criteria.map(
                    (criteriaGroup, index) =>
                      filterExclusionCriteria(criteriaGroup).length === 0 && (
                        <ListItem
                          key={index}
                          endAction={
                            <IconButton
                              aria-label="Delete"
                              size="sm"
                              color="neutral"
                              onClick={() => {
                                updateCohort({
                                  ...cohort,
                                  inclusion_criteria: [
                                    ...cohort.inclusion_criteria.filter((_, i) => {
                                      return i !== index;
                                    }),
                                  ],
                                });
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          }
                        >
                          <ListItemButton
                            onClick={(e) => {
                              e.preventDefault();
                              handleEditCriteriaGroup(false, false, index, criteriaGroup);
                            }}
                          >
                            {index !== 0 && (
                              <ListItemDecorator>
                                <small>AND</small>
                              </ListItemDecorator>
                            )}
                            <CriteriaGroupDisplay criteriaGroup={criteriaGroup} />
                          </ListItemButton>
                        </ListItem>
                      )
                  )}
                </List>
              )}
              <Stack direction="row" spacing={2}>
                {hasGenderCriteria === false && (
                  <Button
                    startDecorator={<MaleIcon />}
                    sx={{ border: "dotted", borderColor: "#84888c", color: "#84888c" }}
                    variant="outlined"
                    color="neutral"
                    onClick={() => handleGenderCriteria(Gender.MALE)}
                  >
                    Male only
                  </Button>
                )}
                {hasGenderCriteria === false && (
                  <Button
                    startDecorator={<FemaleIcon />}
                    sx={{ border: "dotted", borderColor: "#84888c", color: "#84888c" }}
                    variant="outlined"
                    color="neutral"
                    onClick={() => handleGenderCriteria(Gender.FEMALE)}
                  >
                    Female only
                  </Button>
                )}
                {hasAgeCriteria === false && (
                  <Button
                    sx={{
                      border: "dotted",
                      borderColor: "#84888c",
                      color: "#84888c",
                    }}
                    variant="outlined"
                    color="neutral"
                    onClick={() => setOpenAgeBoundariesModal(true)}
                  >
                    Age boundaries
                  </Button>
                )}
                {hasVitalStatusCriteria === false && (
                  <Button
                    sx={{ border: "dotted", borderColor: "#84888c", color: "#84888c" }}
                    variant="outlined"
                    color="neutral"
                    onClick={() => handleVitalStatusCriteria(false)}
                  >
                    Still alive
                  </Button>
                )}
                {hasVitalStatusCriteria === false && (
                  <Button
                    sx={{ border: "dotted", borderColor: "#84888c", color: "#84888c" }}
                    variant="outlined"
                    color="neutral"
                    onClick={() => handleVitalStatusCriteria(true)}
                  >
                    Deceased
                  </Button>
                )}
              </Stack>
            </Card>
            <Card>
              <Typography level="h3">Exclusion criteria</Typography>
              <Typography level="body-sm">
                Determine any health factors or patient characteristics that would exclude someone
                from the cohort to maintain the focus and quality of your study.
              </Typography>
              <SearchBar
                onChange={(coding) => coding && handleNewCodingCriteria(coding, true, false)}
                message="Search for a disease, a drug, ..."
              />
              {cohort.inclusion_criteria.length !== 0 && (
                <List>
                  {cohort.inclusion_criteria.map(
                    (criteriaGroup, index) =>
                      filterExclusionCriteria(criteriaGroup).length !== 0 && (
                        <ListItem
                          key={index}
                          endAction={
                            <IconButton
                              aria-label="Delete"
                              size="sm"
                              color="neutral"
                              onClick={() => {
                                updateCohort({
                                  ...cohort,
                                  inclusion_criteria: [
                                    ...cohort.inclusion_criteria.filter((_, i) => {
                                      return i !== index;
                                    }),
                                  ],
                                });
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          }
                        >
                          <ListItemButton
                            onClick={(e) => {
                              e.preventDefault();
                              handleEditCriteriaGroup(false, true, index, criteriaGroup);
                            }}
                          >
                            {index !== 0 && (
                              <ListItemDecorator>
                                <small>AND</small>
                              </ListItemDecorator>
                            )}
                            <CriteriaGroupDisplay criteriaGroup={criteriaGroup} />
                          </ListItemButton>
                        </ListItem>
                      )
                  )}
                </List>
              )}
            </Card>
            {cohort.subjects.length > 0 && <PatientsTable subjects={cohort.subjects} />}
          </Stack>
        </Grid>
        <Grid xs={3}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography level="h4">About</Typography>
              <IconButton
                variant="plain"
                onClick={() => setOpenCohortNameModal(true)}
                color="neutral"
              >
                <SettingsIcon />
              </IconButton>
            </Stack>
            {cohort.description ? (
              <Typography level="body-md">{cohort.description}</Typography>
            ) : (
              <Typography level="body-md">
                <i>No description</i>
              </Typography>
            )}
            <Card variant="soft" color="success">
              <CardContent orientation="horizontal">
                <PeopleOutlineOutlinedIcon />
                <CardContent>
                  <Typography level="body-md">Number of patients</Typography>
                  <Typography level="h3">
                    {cohort.subjects ? cohort.subjects.length : 0} patient
                    {cohort.subjects && cohort.subjects.length > 1 ? "s" : ""}
                  </Typography>
                </CardContent>
              </CardContent>
            </Card>
            <Divider />
            <Typography level="title-lg">Database selected</Typography>
            <Card variant="outlined">
              <Typography level="title-lg">
                {cohort.db_name === "verified" ? "Verified facts only" : "All facts"}
              </Typography>
              <Typography level="body-sm">
                {cohort.db_name === "verified"
                  ? "Facts from verified notes only."
                  : "All facts from all notes (verified and unverified)."}
              </Typography>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
