import React from "react";
type Props = {
  types?: Array<string>;
  minSize?: number;
  maxSize?: number;
};
/**
 * Draw the types and sizes restrictions for the uploading.
 * @param {Object} fileData file data types and maxSize
 * @returns JSX Element | null
 *
 * @internal
 */
export default function DrawTypes({ types, maxSize }: Props): null | JSX.Element {
  if (types) {
    const stringTypes = types.join(", ");
    return (
      <div className="block">
        <p>
          Accepted types: {stringTypes}. Max size: {maxSize} Mb.
        </p>
      </div>
    );
  }
  return null;
}
