import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";
import Skeleton from "@mui/joy/Skeleton";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CohortQuartzClientContext, StudyQuartzClientContext } from "../../clients/contexts";
import NewCohortModal from "../../cohort/NewCohortModal";
import { Cohort, CohortId } from "../../models/cohort";
import { PutStudyRequest, Study } from "../../models/study";
import ListCohortModal from "../modals/ListCohortModal";

interface CohortSmallCardProps {
  study: Study;
  cohortId?: CohortId | null;
  actionButton: () => void;
  openCohortDrawer: boolean;
  handleUpdateStudy: (study: Study) => void;
}

export default function CohortSmallCard({
  study,
  actionButton,
  openCohortDrawer,
  handleUpdateStudy,
}: CohortSmallCardProps) {
  const navigate = useNavigate();
  const cohortQuartzClient = React.useContext(CohortQuartzClientContext);
  const studyQuartzClient = React.useContext(StudyQuartzClientContext);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [cohort, setCohort] = React.useState<Cohort | null>(null);
  const [openListCohortModal, setOpenListCohortModal] = React.useState(false);
  const [openNewCohortModal, setOpenNewCohortModal] = React.useState(false);

  const handleLinkCohort = (cohortId: CohortId) => {
    handleUpdateStudy({
      ...study,
      cohort_id: cohortId,
    });
  };

  const handleCreateCohort = async (title: string, description: string, dbName: string) => {
    try {
      const newCohort = await cohortQuartzClient.postCohort({
        name: title,
        description: description,
        db_name: dbName,
      });
      const updateStudyRequest = {
        title: study.title,
        cohort_id: newCohort.cohort_id,
        acronym: study.acronym,
      } as PutStudyRequest;
      await studyQuartzClient.updateStudy(study.study_id, updateStudyRequest);
      navigate(`/cohort/${newCohort.cohort_id}`);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  React.useEffect(() => {
    const fetchCohort = async () => {
      if (!study.cohort_id) {
        setLoading(false);
        return;
      }
      try {
        const result = await cohortQuartzClient.getCohortById(study.cohort_id);
        setCohort(result);
      } catch (e: any) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCohort();
  }, [cohortQuartzClient, study.cohort_id]);

  return (
    <>
      <ListCohortModal
        open={openListCohortModal}
        setOpen={setOpenListCohortModal}
        handleLinkCohort={handleLinkCohort}
      />
      <NewCohortModal
        open={openNewCohortModal}
        setOpen={setOpenNewCohortModal}
        handleCreateCohort={handleCreateCohort}
      />
      {loading ? (
        <Box sx={{ width: 300, height: 100 }}>
          <Skeleton animation="wave" width={300} height={100} />
        </Box>
      ) : (
        <>
          {!cohort?.cohort_id ? (
            <Card variant="outlined">
              <Typography level="title-md">No cohort linked.</Typography>
              <ButtonGroup spacing={1} variant="outlined">
                <Button size="sm" onClick={() => setOpenNewCohortModal(true)}>
                  Create a new cohort
                </Button>
                <Button size="sm" onClick={() => setOpenListCohortModal(true)}>
                  Link with an existing cohort
                </Button>
              </ButtonGroup>
            </Card>
          ) : (
            <Card sx={{ position: "relative", paddingRight: "52px" }} variant="outlined">
              <Typography level="title-lg">{cohort?.name}</Typography>
              <Box>
                {cohort?.description && (
                  <Typography level="body-sm">{cohort?.description}</Typography>
                )}
                <Chip startDecorator={<GroupOutlinedIcon />} size="sm" color="neutral">
                  {cohort?.subjects.length} subjects
                </Chip>
              </Box>
              <IconButton
                size="sm"
                sx={{ position: "absolute", right: "8px", top: "8px" }}
                variant="plain"
                color="neutral"
                onClick={actionButton}
              >
                {openCohortDrawer ? (
                  <VisibilityOffIcon fontSize="small" />
                ) : (
                  <VisibilityOutlined fontSize="small" />
                )}
              </IconButton>
            </Card>
          )}
        </>
      )}
    </>
  );
}
