import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { ProofChip } from "../components/QuestionAnswering/ProofChip";
import { Card as CDSCard } from "../models/cds";
import { instanceOfQuantity } from "../utils/fact";

interface DecisionSupportProps {
  cards: CDSCard[];
}

export const DecisionSupport = ({ cards }: DecisionSupportProps) => {
  return (
    <>
      <Typography level="h3" sx={{ mb: 2 }}>
        Decision Support
      </Typography>
      <Box
        sx={{
          width: "100%",
          maxWidth: 800,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(390px, 1fr))",
          gap: 2,
        }}
      >
        {cards.map((card, index) => (
          <Card key={index}>
            <CardContent>
              <Stack direction="row" gap={1}>
                {card.source.url ? (
                  <Link underline="hover" href={card.source.url} target="_blank" rel="noopener">
                    <strong>{card.source.label}</strong>
                  </Link>
                ) : (
                  <Typography level="title-lg">
                    <strong>{card.source.label}</strong>
                  </Typography>
                )}
                <Chip color={card.indicator}>
                  <strong>{card.indicator}</strong>
                </Chip>
              </Stack>
              <Typography>{card.summary}</Typography>
              <Typography level="title-sm" sx={{ mt: 2 }}>
                <i>Facts</i>
              </Typography>
              {card.reason.map((fact, index) =>
                instanceOfQuantity(fact.value) ? (
                  <ProofChip
                    name={`${fact.name} ${fact.value.comparator} ${fact.value.value} ${fact.value.unit?.display}`}
                    key={index}
                    eventSources={fact.source}
                  />
                ) : (
                  <ProofChip name={fact.name} key={index} eventSources={fact.source} />
                )
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
    </>
  );
};
