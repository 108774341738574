import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import SwitchAccessShortcutAddIcon from "@mui/icons-material/SwitchAccessShortcutAdd";
import Alert from "@mui/joy/Alert";
import CircularProgress from "@mui/joy/CircularProgress";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import React from "react";
import { TerminologyQuartzClientContext } from "../../clients/contexts";
import { Coding, OmopCoding } from "../../models/structuration";
import { useDebounce } from "../../utils/use_debounce";
import { useOutsideAlerter } from "../../utils/use_outside_alerter";
import { Dropdown } from "../atoms/Dropdown";

// Minimum time between two fulltext searches.
const DEBOUNCING_DELAY_MS = 200;

// Maximum number of results to show in the dropdown.
const MAX_SEARCH_RESULT_COUNT = 300;

export const SearchBar: React.FC<{
  coding?: Coding | null;
  onChange: (coding: Coding | null) => void;
  error?: string | null;
  isSmall?: boolean;
  message?: string;
  textHelper?: boolean;
}> = ({ coding, isSmall = false, onChange, error, message = "Add a new fact", textHelper }) => {
  const terminologyQuartzClient = React.useContext(TerminologyQuartzClientContext);

  // Whether to show the dropdown
  const [isListVisible, setIsListVisible] = React.useState(false);

  // The text as it is typed in the search bar.
  const [text, setText] = React.useState("");

  // Whether the text in the search bar comes from the input `coding` instead
  // of being typed by the user and not already "committed" to a coding
  // using the `onChange` callback.
  const [textFromCoding, setTextFromCoding] = React.useState(false);

  // To avoid running a fulltext search every time the search bar changes,
  // we use a "debounced" text whose value changes at most every
  // `DEBOUNCING_DELAY_MS` milliseconds.
  const debouncedText = useDebounce(text, DEBOUNCING_DELAY_MS);

  // The codings to show in the dropdown, resulting from the fulltext search.
  const [omopCodings, setOmopCodings] = React.useState<OmopCoding[]>([]);

  // Reference to the search bar input.
  const inputRef = React.createRef<HTMLInputElement>();

  // Reference to the dropdown.
  const dropdownRef = React.createRef<HTMLDivElement>();

  // State to track if the input is not clear
  const [isNotClear, setIsNotClear] = React.useState(false);

  // State to track if the search is loading
  const [isLoading, setIsLoading] = React.useState(false);

  // If the user clicks outside of the search bar input and of the dropdown,
  // we hide the dropdown.
  useOutsideAlerter([inputRef, dropdownRef], () => {
    setIsListVisible(false);
  });

  // When the input coding changes, we update the search bar content.
  React.useEffect(() => {
    if (!coding) {
      setText("");
      setIsNotClear(false);
    } else {
      if (coding.display) {
        setIsNotClear(true);
        setTextFromCoding(true);
        setText(coding.display);
      }
    }
  }, [coding]);

  // When the debounced text changes, we trigger a fulltext search.
  React.useEffect(() => {
    if (textFromCoding) {
      return;
    }
    if (debouncedText.trim().length === 0) {
      setIsListVisible(false);
      setOmopCodings([]);
      setIsLoading(false);
      return () => {};
    } else {
      setIsLoading(true);
      const controller = new AbortController();
      terminologyQuartzClient
        .searchOmopCoding(debouncedText, MAX_SEARCH_RESULT_COUNT, undefined, controller)
        .then((omopCodings) => {
          setIsListVisible(true);
          setOmopCodings(omopCodings);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
      return () => {
        // When the debounced text changes again, we cancel the request above.
        // Cancelling allows one to be certain to not receive out-of-order updates
        // to the dropdown.
        controller.abort();
        setIsLoading(false);
      };
    }
  }, [debouncedText, textFromCoding, terminologyQuartzClient]);

  return (
    <>
      <FormControl error={!!error}>
        <Input
          type="text"
          fullWidth
          size={isSmall ? "md" : "lg"}
          startDecorator={<SearchIcon />}
          endDecorator={
            isLoading ? (
              <CircularProgress size="sm" />
            ) : isNotClear ? (
              <IconButton
                title="Clear field"
                size="sm"
                sx={{ mr: -1.5, mt: -3, width: "24px", height: "24px", fontSize: "16px" }}
                onClick={() => {
                  setText("");
                  onChange(null);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : null
          }
          placeholder={message}
          value={text}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // The user has set the text directly, so `textFromCoding`
            // is set to `false`.
            setTextFromCoding(false);
            setText(e.target.value);
          }}
          ref={inputRef}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
      <Dropdown
        codings={omopCodings}
        isListVisible={isListVisible}
        onCodingSelection={(omopCoding: OmopCoding | null) => {
          const coding = omopCoding
            ? { system: "OMOP", code: omopCoding.id, display: omopCoding.name }
            : null;
          onChange(coding);
          setText("");
          setIsListVisible(false);
        }}
        ref={dropdownRef}
      />
      {textHelper && (
        <Alert
          sx={{ mt: 1 }}
          startDecorator={<SwitchAccessShortcutAddIcon fontSize="inherit" />}
          color="neutral"
        >
          Add a fact to this annotation
        </Alert>
      )}
    </>
  );
};
