import { OmopDbReport, UserEvent } from "../models/analytics";
import { Credentials, PutUserRequest, User, UserAttributes } from "../models/auth";
import { Bundle } from "../models/bundle";
import { Cohort, CohortId, ExportFormat, InitCohort, PutCohort } from "../models/cohort";
import { PutDocumentRequest } from "../models/document";
import { Note, NoteId, PutNoteRequest, ValidationState } from "../models/note";
import { Person, PersonId } from "../models/person";
import {
  PutQuestionnaireRequest,
  Question,
  QuestionAnswer,
  Questionnaire,
} from "../models/questionnaire";
import { Coding, OmopCoding, ValueParameterSet } from "../models/structuration";
import {
  NextStudyEvent,
  PutParticipantEventRequest,
  PutStudyEventRequest,
  PutStudyRequest,
  Study,
  StudyId,
  StudyStats,
} from "../models/study";
import { Task, TaskId } from "../models/task";
import { PatientView } from "../models/view";
import { BaseClient } from "./base_client";

export class QuartzClient {
  private _accountLifecycle: AccountLifecycleQuartzClient;
  private _terminology: TerminologyQuartzClient;
  private _note: NoteQuartzClient;
  private _document: DocumentQuartzClient;
  private _cohort: CohortQuartzClient;
  private _study: StudyQuartzClient;
  private _person: PersonQuartzClient;
  private _analytics: AnalyticsQuartzClient;
  private _questionAnswering: QuestionAnsweringQuartzClient;
  private _task: TaskQuartzClient;
  private _admin: AdminQuartzClient;

  constructor(url: string, authorizationHeader: () => Promise<string | null> = async () => null) {
    this._accountLifecycle = new AccountLifecycleQuartzClient(url, authorizationHeader);
    this._terminology = new TerminologyQuartzClient(url, authorizationHeader);
    this._note = new NoteQuartzClient(url, authorizationHeader);
    this._document = new DocumentQuartzClient(url, authorizationHeader);
    this._cohort = new CohortQuartzClient(url, authorizationHeader);
    this._person = new PersonQuartzClient(url, authorizationHeader);
    this._study = new StudyQuartzClient(url, authorizationHeader);
    this._analytics = new AnalyticsQuartzClient(url, authorizationHeader);
    this._questionAnswering = new QuestionAnsweringQuartzClient(url, authorizationHeader);
    this._task = new TaskQuartzClient(url, authorizationHeader);
    this._admin = new AdminQuartzClient(url, authorizationHeader);
  }

  get accountLifecycle() {
    return this._accountLifecycle;
  }
  get terminology() {
    return this._terminology;
  }
  get questionAnswering() {
    return this._questionAnswering;
  }
  get note() {
    return this._note;
  }
  get document() {
    return this._document;
  }
  get cohort() {
    return this._cohort;
  }
  get person() {
    return this._person;
  }
  get study() {
    return this._study;
  }
  get analytics() {
    return this._analytics;
  }
  get task() {
    return this._task;
  }
  get admin() {
    return this._admin;
  }
}

export class AccountLifecycleQuartzClient extends BaseClient {
  async initiateDataDeletionCountdown(): Promise<void> {
    return this.httpPost("/account_lifecycle/$delete_data", {});
  }

  async initiateAccountDeletionCountdown(): Promise<void> {
    return this.httpPost("/account_lifecycle/$delete_account", {});
  }
}

export class QuestionAnsweringQuartzClient extends BaseClient {
  async askQuestions(
    questions: Question[],
    personIds: PersonId[],
    controller?: AbortController
  ): Promise<QuestionAnswer[]> {
    const body = { questions: questions, person_ids: personIds };
    return this.httpPost(`/question`, body, controller);
  }
  async getPrebuiltQuestions(controller?: AbortController): Promise<Question[]> {
    return this.httpGet(`/question`, controller);
  }
  async exportQuestionAnswers(
    questionAnswers: QuestionAnswer[],
    format?: string,
    controller?: AbortController
  ): Promise<any> {
    return this.httpPostBlob(`/question-answer/$export`, questionAnswers, controller);
  }
  async saveQuestionnaire(request: PutQuestionnaireRequest, controller?: AbortController) {
    return this.httpPost(`/questionnaire`, request, controller);
  }
  async updateQuestionnaire(
    questionnaireId: string,
    questionnaire: Questionnaire,
    controller?: AbortController
  ) {
    return this.httpPut(`/questionnaire/${questionnaireId}`, questionnaire, controller);
  }
  async listQuestionnaires(controller?: AbortController): Promise<Questionnaire[]> {
    return this.httpGet(`/questionnaire`, controller);
  }
}

export class StudyQuartzClient extends BaseClient {
  async listStudies(controller?: AbortController): Promise<Bundle> {
    return this.httpGet("/study", controller);
  }
  async getStudyById(studyId: StudyId, controller?: AbortController): Promise<Study> {
    return this.httpGet(`/study/${studyId}`, controller);
  }
  async postNewStudy(body: PutStudyRequest, controller?: AbortController): Promise<Study> {
    return this.httpPost("/study", body, controller);
  }
  async getParticipantsByStudyId(studyId: StudyId, controller?: AbortController): Promise<Bundle> {
    return this.httpGet(`/participant?study_id=${studyId}`, controller);
  }
  async putParticipantEvent(
    body: PutParticipantEventRequest,
    controller?: AbortController
  ): Promise<null> {
    return this.httpPost(`/participant/$event`, body, controller);
  }
  async updateStudy(
    studyId: StudyId,
    updateStudyRequest: PutStudyRequest,
    controller?: AbortController
  ): Promise<Study> {
    return this.httpPut(`/study/${studyId}`, updateStudyRequest, controller);
  }
  async putStudyEvent(
    study_id: StudyId,
    request: PutStudyEventRequest,
    controller?: AbortController
  ): Promise<null> {
    return this.httpPost(`/study/${study_id}/$event`, request, controller);
  }
  async getStudyNextEvents(
    study_id: StudyId,
    controller?: AbortController
  ): Promise<NextStudyEvent> {
    return this.httpGet(`/study/${study_id}/$next-events`, controller);
  }
  async getStudyStats(study_id: StudyId, controller?: AbortController): Promise<StudyStats> {
    return this.httpGet(`/study/${study_id}/$stats`, controller);
  }
}

export class TerminologyQuartzClient extends BaseClient {
  async searchOmopCoding(
    content: string,
    result_count: number,
    vocabulary_id?: string,
    controller?: AbortController
  ): Promise<OmopCoding[]> {
    return this.httpGet(`/omop-coding?_content=${content}`, controller);
  }

  async listCodings(valuesetName: string, controller?: AbortController): Promise<Coding[]> {
    // Attempt to retrieve the codings from session storage if valuesetName is different from full-concepts
    if (valuesetName !== "full-concepts") {
      const cachedCodings = sessionStorage.getItem(`codings_${valuesetName}`);
      if (cachedCodings) {
        return JSON.parse(cachedCodings);
      }
    }

    // If not in session storage, fetch from the server and cache the result
    const codings = await this.httpGet(`/valueset/${valuesetName}/coding`, controller);
    if (valuesetName !== "full-concepts") {
      sessionStorage.setItem(`codings_${valuesetName}`, JSON.stringify(codings));
    }
    return codings;
  }
  async valueParameters(coding: string, controller?: AbortController): Promise<ValueParameterSet> {
    // Attempt to retrieve the value parameters from session storage
    const cachedValueParameters = sessionStorage.getItem(`valueParameters_${coding}`);
    if (cachedValueParameters) {
      return JSON.parse(cachedValueParameters);
    }
    // If not in session storage, fetch from the server and cache the result
    const valueParameters = await this.httpGet(`/value-parameter/${coding}`, controller);
    sessionStorage.setItem(`valueParameters_${coding}`, JSON.stringify(valueParameters));
    return valueParameters;
  }
}

export class NoteQuartzClient extends BaseClient {
  async listNotes(
    count: number,
    offset: number,
    validationState?: ValidationState,
    noteTypeCode?: string | null,
    personId?: string | null,
    coding?: Coding | null,
    controller?: AbortController
  ): Promise<Bundle> {
    const route = `/note?_count=${count}&_offset=${offset}&_sort=person_id,note_datetime`;
    return this.httpGet(
      route +
        (validationState ? `&validation_state=${validationState}` : "") +
        (personId ? `&person_id=${personId}` : "") +
        (coding ? `&code=${coding.code}` : "") +
        (noteTypeCode ? `&note_type_code=${noteTypeCode}` : ""),
      controller
    );
  }
  async deleteNotes(notes_ids: NoteId[], controller?: AbortController) {
    notes_ids.forEach((note_id) => {
      return this.httpDelete(`/note/${note_id}`, controller);
    });
  }
  async getNextNote(note_id: NoteId, controller?: AbortController): Promise<Note | null> {
    return this.httpGet(`/note/${note_id}/$next`, controller);
  }
  async skipNote(note_id: NoteId, controller?: AbortController): Promise<Note> {
    return this.httpGet(`/note/${note_id}/$skip`, controller);
  }
  async getNoteById(note_id: NoteId, controller?: AbortController): Promise<Note> {
    return this.httpGet(`/note/${note_id}`, controller);
  }
  async updateNoteById(note_id: NoteId, body: any, controller?: AbortController): Promise<Note> {
    return this.httpPut(`/note/${note_id}`, body, controller);
  }
  async postNewNote(body: PutNoteRequest, controller?: AbortController): Promise<Note[]> {
    return this.httpPost("/note", body, controller);
  }
  async validateNoteById(note_id: NoteId, controller?: AbortController): Promise<Note> {
    return this.httpPut(`/note/${note_id}/$validate`, {}, controller);
  }
  async runNLP(note_id: NoteId, controller?: AbortController): Promise<Note> {
    return this.httpPut(`/note/${note_id}/$run-nlp`, {}, controller);
  }
  async runNLPBatch(controller?: AbortController): Promise<null> {
    return this.httpPut(`/note/$run-all-unvalidated-notes`, {}, controller);
  }
  async getPatientView(
    person_id: PersonId,
    validation_state?: ValidationState,
    controller?: AbortController
  ): Promise<PatientView> {
    return this.httpGet(
      `/view/person?person_id=${person_id}&validation_state=${validation_state}`,
      controller
    );
  }
}

export class PersonQuartzClient extends BaseClient {
  async listPersons(
    count: number,
    offset: number,
    content: string | null,
    controller?: AbortController
  ): Promise<Bundle> {
    // if (link) return this.httpGet(link, controller);
    const route = `/person?_count=${count}&_offset=${offset}&_sort=family_name`;
    return this.httpGet(content ? route + `&_content=${content}` : route, controller);
  }
  async getPersonById(person_id: PersonId, controller?: AbortController): Promise<Person> {
    return this.httpGet(`/person/${person_id}`, controller);
  }
  async postNewPerson(body: Person, controller?: AbortController): Promise<Person> {
    return this.httpPost(`/person`, body, controller);
  }
  async mergePersons(
    sourcePersonId: PersonId,
    targetPersonIds: PersonId[],
    controller?: AbortController
  ): Promise<Person> {
    const body = {
      source_person_id: sourcePersonId,
      target_person_ids: targetPersonIds,
    };
    return this.httpPost(`/person/$merge`, body, controller);
  }
  async putPersonById(
    personId: PersonId,
    patientInputs: Person,
    controller?: AbortController
  ): Promise<Person> {
    return this.httpPut(`/person/${personId}`, patientInputs, controller);
  }
  async getPersonVerificationCompletion(
    controller?: AbortController
  ): Promise<Record<PersonId, number>> {
    return this.httpGet(`/person/$verification-completion`, controller);
  }
}

export class TaskQuartzClient extends BaseClient {
  async listTasks(controller?: AbortController): Promise<Task[]> {
    return this.httpGet(`/task`, controller);
  }
  async getTaskById(taskId: TaskId, controller?: AbortController): Promise<Task> {
    return this.httpGet(`/task/${taskId}`, controller);
  }
}

export class DocumentQuartzClient extends BaseClient {
  async postDocument(
    putDocumentRequest: PutDocumentRequest,
    controller?: AbortController
  ): Promise<Task> {
    return this.httpPost(`/document`, putDocumentRequest, controller);
  }
}

export class CohortQuartzClient extends BaseClient {
  async postCohort(init_cohort: InitCohort, controller?: AbortController): Promise<Cohort> {
    return this.httpPost(`/cohort`, init_cohort, controller);
  }
  async getCohortById(cohort_id: string, controller?: AbortController): Promise<Cohort> {
    return this.httpGet(`/cohort/${cohort_id}`, controller);
  }
  async getOmopDbReport(controller?: AbortController): Promise<OmopDbReport> {
    return this.httpGet(`/omop/report`, controller);
  }
  async listCohorts(controller?: AbortController): Promise<Cohort[]> {
    return this.httpGet(`/cohort`, controller);
  }
  async freezeCohort(cohort_id: string, controller?: AbortController): Promise<null> {
    return this.httpPut(`/cohort/${cohort_id}/freeze`, controller);
  }
  async updateCohortByID(
    cohort_id: string,
    body: PutCohort,
    controller?: AbortController
  ): Promise<Cohort> {
    return this.httpPut(`/cohort/${cohort_id}`, body, controller);
  }
  async deleteCohort(cohort_id: CohortId[], controller?: AbortController) {
    cohort_id.forEach((cohort_id) => {
      return this.httpDelete(`/cohort/${cohort_id}`, controller);
    });
  }
  async recomputeCohort(cohort_id: string, controller?: AbortController) {
    return this.httpGet(`/cohort/${cohort_id}/recompute`, controller);
  }
  async exportOmop(exportFormat: ExportFormat, cohort_id: CohortId, controller?: AbortController) {
    return this.httpGetBlob(
      `/omop/export?export_format=${exportFormat}&cohort_id=${cohort_id}`,
      controller
    );
  }
  async refreshOmopData(controller?: AbortController): Promise<null> {
    return this.httpGet(`/cohort/$recompute-all`, controller);
  }
}

export class AnalyticsQuartzClient extends BaseClient {
  async postUserEvent(userEvent: UserEvent, controller?: AbortController): Promise<void> {
    return this.httpPost("/analytics/user_event", userEvent, controller);
  }
}

export class AdminQuartzClient extends BaseClient {
  async listUsers(controller?: AbortController): Promise<User[]> {
    return this.httpGet(`/admin/user`, controller);
  }

  async createUser(request: PutUserRequest, controller?: AbortController) {
    return this.httpPost(`/admin/user`, request, controller);
  }

  async updateUser(username: string, request: UserAttributes, controller?: AbortController) {
    return this.httpPut(`/admin/user/${username}/attributes`, request, controller);
  }

  async updateUserPassword(username: string, request: Credentials, controller?: AbortController) {
    return this.httpPut(`/admin/user/${username}/password`, request, controller);
  }

  async deleteUser(username: string, controller?: AbortController) {
    return this.httpDelete(`/admin/user/${username}`, controller);
  }

  async batchOperation(bundle: Bundle, controller?: AbortController) {
    return this.httpPost(`/`, bundle, controller);
  }
}
