import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import TitleStylizedUnderline from "../../components/TitleStylizedUnderline";

export const PendingEmailValidation = ({
  username,
  email,
}: {
  username: string;
  email: string;
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [countdown, setCountdown] = React.useState<number>(0);

  React.useEffect(() => {
    console.log("VALUE", email, username);
    if (email == null || username == null) {
      navigate("/");
    }
  }, [email, username, navigate]);

  const resend = async () => {
    try {
      await Auth.resendSignUp(username);
      setCountdown(60); // Start the countdown
      enqueueSnackbar("Validation email sent.", { variant: "success" });
    } catch (err: any) {
      if (err.code === "LimitExceededException") {
        setCountdown(60);
        enqueueSnackbar("Too many validation emails sent. Please try again later.", {
          variant: "error",
        });
        return;
      }
      throw err;
    }
  };

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box>
        <Typography sx={{ mb: 4 }} level="h2">
          <TitleStylizedUnderline>Please verify your email address</TitleStylizedUnderline>
        </Typography>
        <Stack direction="column" spacing={4}>
          <Typography level="body-md">
            Click the verification button in the email we sent to
            <br />
            <Typography level="title-lg">{email}</Typography>
          </Typography>
          <Typography level="body-md">
            No email in your inbox ? Have you checked your spam folder ?
            <br />
            <Button
              sx={{ mt: 2 }}
              variant={countdown > 0 ? "outlined" : "solid"}
              disabled={countdown > 0}
              onClick={resend}
            >
              {countdown > 0 ? `Resend in ${countdown} seconds` : "Resend verification email"}
            </Button>
          </Typography>
          <Divider />
          <Typography level="body-md">
            If you mistyped your email when signing up,{" "}
            <Link to="/auth/signup" onClick={() => window.location.reload()}>
              create a new account
            </Link>
            .
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
