import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { AdminQuartzClientContext, PersonQuartzClientContext } from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";
import HexagonBackground from "../components/HexagonBackground";
import TablePagination from "../components/TablePagination";
import TitleStylizedUnderline from "../components/TitleStylizedUnderline";
import TableSkeleton from "../components/skeletons/TableSkeleton";
import { BatchBundle, Link as LinkBundle } from "../models/bundle";
import { Person, PersonId } from "../models/person";
import { PatientForm } from "../modules/patient/components/PatientForm";
import { ListOfPatients } from "./ListOfPatients";

interface ListNotesPageProps {
  personList?: Person[];
  loading?: boolean;
}

const emptyPatient: Person = {
  person_id: "" as PersonId,
  family_name: "",
  given_name: "",
  birth_date: null,
};

export const ListPatientsPage = ({ personList, loading = true }: ListNotesPageProps) => {
  const personQuartzClient = React.useContext(PersonQuartzClientContext);
  const adminQuartzClient = React.useContext(AdminQuartzClientContext);
  const [persons, setPersons] = React.useState<Person[] | undefined>(personList);
  const [isLoading, setLoading] = React.useState(loading);
  const [openNewPatientModal, setOpenNewPatientModal] = React.useState<boolean>(false);
  const [links, setLinks] = React.useState<LinkBundle[]>([]);
  const [totalEntries, setTotalEntries] = React.useState<number>(0);
  const [serverError, setServerError] = React.useState<string | null>("");
  const [refreshTime, setRefreshTime] = React.useState(new Date().getTime());
  const [searchContent, setSearchContent] = React.useState<string | null>(null);
  const [offset, setOffset] = React.useState<number>(0);
  const count = 100;

  const refresh = () => {
    setRefreshTime(new Date().getTime());
  };

  const createPatient = React.useCallback(
    async (data: Person) => {
      try {
        await personQuartzClient.postNewPerson(data);
        refresh();
        setOpenNewPatientModal(false);
      } catch (e: any) {
        setServerError(e.message);
      }
    },
    [personQuartzClient]
  );

  const deletePatient = async (personIds: PersonId[]) => {
    try {
      const bundle = {
        entry: personIds.map((id) => {
          return {
            request: {
              method: "DELETE",
              url: `Person/${id}`,
            },
          };
        }),
      } as BatchBundle;
      await adminQuartzClient.batchOperation(bundle);
      refresh();
    } catch (e: any) {
      setServerError(e.message);
    }
  };

  const mergePersons = async (sourcePersonId: PersonId, targetPersonIds: PersonId[]) => {
    try {
      await personQuartzClient.mergePersons(sourcePersonId, targetPersonIds);
      refresh();
    } catch (e: any) {
      setServerError(e.message);
    }
  };

  React.useEffect(() => {
    const getPatients = async () => {
      try {
        const bundle = await personQuartzClient.listPersons(count, offset, searchContent);
        const listOfPersons = bundle.entry as Person[];
        setTotalEntries(bundle.total ? bundle.total : 0);
        setLinks(bundle.link ? bundle.link : []);
        setPersons(listOfPersons);
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        setServerError(e.message);
      }
    };

    getPatients();
  }, [refreshTime, count, offset, personQuartzClient, searchContent]);

  return (
    <Box sx={{ p: 4 }}>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <HexagonBackground color="rgb(11, 107, 203)" />
      <Modal open={openNewPatientModal} onClose={() => setOpenNewPatientModal(false)}>
        <ModalOverflow>
          <ModalDialog sx={{ minWidth: "500px" }}>
            <ModalClose />
            <DialogTitle>Add patient</DialogTitle>
            <PatientForm patient={emptyPatient} onSubmit={createPatient} />
          </ModalDialog>
        </ModalOverflow>
      </Modal>
      <Grid>
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography level="h2">
              <TitleStylizedUnderline color="rgb(11, 107, 203)">Patients</TitleStylizedUnderline>
            </Typography>
            <Button
              data-cy="add-patient-button"
              startDecorator={<AddIcon />}
              onClick={() => {
                setOpenNewPatientModal(true);
              }}
            >
              Add patient
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12} sx={{ mt: 4 }}>
          {isLoading ? (
            <Box sx={{ height: "70vh", overflow: "hidden" }}>
              <TableSkeleton />
            </Box>
          ) : (
            <Stack direction="column" spacing={2}>
              <Input
                startDecorator={<SearchIcon />}
                placeholder="Search for a patient..."
                onChange={(event) => setSearchContent(event.target.value)}
              />
              {persons && persons.length > 0 ? (
                <ListOfPatients
                  persons={persons}
                  deletePersons={deletePatient}
                  mergePersons={mergePersons}
                />
              ) : (
                <Sheet
                  color="neutral"
                  variant="plain"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "74vh",
                  }}
                >
                  <Stack direction="column" spacing={4} justifyContent="center" alignItems="center">
                    <Typography level="h1" color="neutral">
                      No patient here yet
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <Link to={"/add-document"}>
                        <Button variant="solid" startDecorator={<PublishIcon />}>
                          Upload document
                        </Button>
                      </Link>
                      <Link to={"/add-note"}>
                        <Button variant="solid" startDecorator={<AddIcon />}>
                          Add a note
                        </Button>
                      </Link>
                      <Button
                        variant="solid"
                        startDecorator={<AddIcon />}
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOpenNewPatientModal(true);
                        }}
                      >
                        Add a patient
                      </Button>
                    </Stack>
                  </Stack>
                </Sheet>
              )}
              {totalEntries > count && (
                <TablePagination
                  totalEntries={totalEntries}
                  links={links}
                  pageSize={count}
                  onPageChange={(offset) => {
                    setOffset(offset);
                    refresh();
                  }}
                />
              )}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
