import Box from "@mui/joy/Box";
import Table from "@mui/joy/Table";
import Tooltip from "@mui/joy/Tooltip";
import dayjs from "dayjs";
import { UnixTimestamp } from "../../models/common";
import { unixToDayjs } from "../../utils/time";
import { ClinicalEvent } from "../../models/clinical_event";

interface FactsYearCalendarProps {
  tx_facts: { [key: string]: Array<ClinicalEvent> };
  mostRecentDate: UnixTimestamp;
  mostAncientDate: UnixTimestamp;
  tooltipDateTitle: any;
  treatmentLengthDisplay: any;
  openDrawer: (name: string, facts: ClinicalEvent[]) => void;
}

export default function FactsYearCalendar({
  tx_facts,
  mostRecentDate,
  mostAncientDate,
  tooltipDateTitle,
  treatmentLengthDisplay,
  openDrawer,
}: FactsYearCalendarProps) {
  // for the yearly view
  let yearCount =
    unixToDayjs(mostRecentDate)!
      .startOf("years")
      .diff(unixToDayjs(mostAncientDate)?.startOf("years"), "years") + 1;

  return (
    <Table
      sx={{
        display: "block",
        overflowX: "auto",
        whiteSpace: "nowrap",
        background: "white",
        "& tr > *:first-of-type": {
          position: "sticky",
          left: 0,
          boxShadow: "1px 0 var(--TableCell-borderColor)",
          bgcolor: "background.surface",
          overflowX: "scroll",
          zIndex: 2,
        },
      }}
      borderAxis="xBetween"
    >
      <thead>
        <tr>
          <th
            style={{
              borderBottom: "1px solid var(--TableCell-borderColor)",
            }}
          ></th>
          {Array.from({ length: yearCount }, (_, i) => i)
            .reverse()
            .map((i) => {
              const year = dayjs(mostRecentDate).subtract(i, "year");
              return (
                <th
                  key={year.format("YYYY")}
                  colSpan={12}
                  style={{
                    backgroundColor: "background.surface",
                    textAlign: "center",
                    borderBottom: "1px solid var(--TableCell-borderColor)",
                    borderRight: "2px solid var(--TableCell-borderColor)",
                  }}
                >
                  {year.format("YYYY")}
                </th>
              );
            })}
        </tr>
      </thead>
      <tbody>
        {Object.entries(tx_facts).map(([medication, facts]) => (
          <tr key={medication}>
            <td style={{ verticalAlign: "middle" }}>{medication}</td>
            {Array.from({ length: yearCount }, (_, i) => i)
              .reverse()
              .map((i) => {
                const year = dayjs(mostRecentDate).subtract(i, "year");
                return Array.from({ length: 12 }, (_, i) => i).map((i) => {
                  const month = year.set("month", i);
                  return (
                    <td
                      key={month.format("YYYY-MM")}
                      style={{
                        position: "relative",
                        textAlign: "center",
                        borderBottom: "1px solid var(--TableCell-borderColor)",
                        // if last day of month, add border right
                        borderRight:
                          i === 11
                            ? "2px solid var(--TableCell-borderColor)"
                            : "1px solid var(--TableCell-borderColor)",
                        padding: "8px 24px",
                      }}
                    >
                      {/* 
                            we calculate the length of the treatment in months
                            if the end_date is null, we calculate the length of the treatment in months until the most recent date
                            then we check if it's the first month of the treatment, if it is we display the length of the treatment in months
                            if it's not the first month, we display nothing

                            one month = 48px
                            one day = 1.6px
                            we need to ajust the size of the box to the length of the treatment in days
                            minimal with : 8px
                        */}
                      {facts.map((fact) => {
                        const treatmentStart = dayjs(fact.start_date);
                        const treatmentEnd = fact.end_date
                          ? dayjs(fact.end_date)
                          : treatmentStart.add(1, "day"); // if treatment as no end_date, it means it's a one shot treatment, so we add 1 day to the start_date
                        const treatmentLengthInDays = treatmentEnd.diff(treatmentStart, "days");
                        // we need to find the day in the month where the treatment starts
                        const dayInMonth = treatmentStart.date();
                        if (
                          treatmentStart.isSame(month, "month") &&
                          treatmentStart.isSame(year, "year")
                        ) {
                          return (
                            <Tooltip
                              title={tooltipDateTitle(fact, 49)}
                              key={fact.start_date}
                              arrow
                              placement="top"
                            >
                              <Box
                                sx={{
                                  position: "absolute",
                                  width:
                                    treatmentLengthInDays > 1
                                      ? "calc(" + (treatmentLengthInDays * 1.6 - dayInMonth) + "px)"
                                      : "8px",
                                  background: fact.probability ? "#A1E8A1" : "#F3C896",
                                  height: "calc(100% - 16px)",
                                  borderRadius: "16px",
                                  // since we are using a 1px width for each day, we need to adjust the marginLeft to center the box
                                  marginLeft:
                                    dayInMonth > 15
                                      ? dayInMonth * 1.3 - 24 + "px"
                                      : -24 + dayInMonth * 1.3 + "px",
                                  fontWeight: "bold",
                                  color: fact.probability ? "#0A470A" : "#7A3E0A",
                                  zIndex: 1,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  openDrawer(medication, [fact]);
                                }}
                              >
                                {treatmentLengthDisplay(fact.start_date, fact.end_date, 49)}
                              </Box>
                            </Tooltip>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </td>
                  );
                });
              })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
