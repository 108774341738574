import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import Stepper from "@mui/joy/Stepper";
import Typography from "@mui/joy/Typography";
import React from "react";
import { StudyQuartzClientContext } from "../../clients/contexts";
import {
  Participant,
  ParticipantByState,
  ParticipantEventToState,
  ParticipantId,
  PutParticipantEventRequest,
} from "../../models/study";
import { formatAndCapitalizeString } from "../../utils/text";
import { unixToDayjs } from "../../utils/time";
import NewParticipantActionModal from "../modals/NewParticipantActionModal";
import { getParticipantCurrentState } from "../StudyPage";

interface StudyParticipantDrawerProps {
  selectedIds: ParticipantId[];
  selectedParticipants: Participant[];
  participantByState: ParticipantByState[];
  closeModal: () => void;
  refresh: () => void;
}

export default function StudyParticipantDrawer({
  selectedIds,
  selectedParticipants,
  participantByState,
  closeModal,
  refresh,
}: StudyParticipantDrawerProps) {
  // Contexts
  const studyQuartzClient = React.useContext(StudyQuartzClientContext);

  // States
  const [commonNextEvents, setCommonNextEvents] = React.useState<ParticipantEventToState[]>([]);
  const [selectedNextEvent, setSelectedNextEvent] = React.useState<ParticipantEventToState | null>(
    null
  );

  // Handlers
  const handleAddParticipantEvent = async (
    putParticipantEventRequest: PutParticipantEventRequest
  ) => {
    await studyQuartzClient.putParticipantEvent(putParticipantEventRequest);
    refresh();
  };

  // Effects
  React.useEffect(() => {
    if (selectedParticipants.length > 0) {
      const selectedParticipantsStates = selectedParticipants.map(
        (participant) => getParticipantCurrentState(participant).state
      );
      const commonNextEvents = participantByState
        .filter((pbs) => {
          return selectedParticipantsStates.includes(pbs.state);
        })
        .map((pbs) => pbs.next_events)
        .reduce(
          (acc, val) =>
            acc && val && acc.filter((event) => val.map((v) => v.event).includes(event.event))
        );
      if (commonNextEvents) {
        setCommonNextEvents(commonNextEvents);
      } else {
        setCommonNextEvents([]);
      }
    }
  }, [selectedParticipants, participantByState]);
  return (
    <>
      {selectedNextEvent && (
        <NewParticipantActionModal
          event={selectedNextEvent}
          participantIds={selectedIds}
          handleAddParticipantAction={handleAddParticipantEvent}
          onClose={() => setSelectedNextEvent(null)}
        />
      )}
      <Sheet
        sx={{
          display: selectedParticipants.length > 0 ? "flex" : "none",
          flexDirection: "column",
          borderLeft: "1px solid",
          borderColor: "divider",
          height: "100vh",
          zIndex: 9999,
          width: "450px",
          marginTop: -4,
          marginRight: -4,
          marginBottom: -4,
          marginLeft: 4,
        }}
      >
        <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
          <Typography level="title-md" sx={{ flex: 1 }}>
            {selectedParticipants.length} participant{selectedParticipants.length > 1 && "s"}{" "}
            selected
          </Typography>
          <IconButton component="span" variant="plain" color="neutral" size="sm">
            <CloseRoundedIcon onClick={closeModal} />
          </IconButton>
        </Box>
        <Divider />
        <Stack
          sx={{ flex: 1, background: "white" }}
          direction="column"
          justifyContent="space-between"
        >
          {selectedParticipants.length === 1 ? (
            <Box sx={{ p: 2 }}>
              <Typography sx={{ mb: 2 }} level="title-lg">
                Participant progress
              </Typography>
              <Stepper orientation="vertical">
                {selectedParticipants[0].progress.map((progress, index) => (
                  <Step
                    key={index}
                    indicator={
                      <StepIndicator
                        variant="soft"
                        color={
                          index + 1 === selectedParticipants[0].progress.length
                            ? "primary"
                            : "neutral"
                        }
                      >
                        {index + 1}
                      </StepIndicator>
                    }
                  >
                    <Typography level="title-md">
                      {formatAndCapitalizeString(progress.state)}
                    </Typography>
                    <Stack spacing={1}>
                      <Typography level="body-sm">{progress.reason}</Typography>
                      <ButtonGroup variant="plain" spacing={1}>
                        {progress.start_date && (
                          <Chip size="sm">
                            {unixToDayjs(progress.start_date)?.format("DD MMM YYYY")}
                          </Chip>
                        )}
                        {progress.end_date && (
                          <Chip size="sm">
                            {unixToDayjs(progress.end_date)?.format("DD MMM YYYY")}
                          </Chip>
                        )}
                      </ButtonGroup>
                    </Stack>
                  </Step>
                ))}
              </Stepper>
            </Box>
          ) : (
            <Box sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Typography level="title-md">Select one patient to view its history here</Typography>
            </Box>
          )}
          {commonNextEvents.length > 0 && (
            <Box
              sx={{
                p: 2,
                background: (theme) => theme.palette.background.surface,
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <Stack direction="row" gap={0.5} justifyContent="flex-end" flexWrap="wrap">
                {commonNextEvents.map((event) => (
                  <Button
                    sx={{ whiteSpace: "nowrap" }}
                    key={event.event}
                    size="md"
                    onClick={() => {
                      setSelectedNextEvent(event);
                    }}
                    color="primary"
                  >
                    {formatAndCapitalizeString(event.event)}
                  </Button>
                ))}
              </Stack>
            </Box>
          )}
        </Stack>
      </Sheet>
    </>
  );
}
