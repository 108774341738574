import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Checkbox from "@mui/joy/Checkbox";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import AlertDialogModal from "../../common/modals/AlertDialogModal";
import { Study, StudyId } from "../../models/study";
import { formatAndCapitalizeString } from "../../utils/text";
import IconButton from "@mui/joy/IconButton";
import Chip from "@mui/joy/Chip";
import { getStudyCurrentState } from "../StudiesPage";

interface StudyTableProps {
  studies: Study[];
  deleteStudies: (ids: StudyId[]) => void;
}

export default function StudyTable({ studies, deleteStudies }: StudyTableProps) {
  const navigate = useNavigate();

  // States
  const [selected, setSelected] = React.useState<StudyId[]>([]);
  const [openDeleteStudyModal, setOpenDeleteStudyModal] = React.useState(false);

  return (
    <>
      <AlertDialogModal
        alertMessage="Are you sure you want to delete the selected studies?"
        open={openDeleteStudyModal}
        setOpen={setOpenDeleteStudyModal}
        handleDelete={() => deleteStudies(selected)}
      />
      <Sheet variant="outlined" sx={{ borderRadius: "sm" }}>
        <Table
          aria-labelledby="studyTable"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground": "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: "center", padding: "12px 6px" }}>
                <Checkbox
                  size="sm"
                  indeterminate={selected.length > 0 && selected.length !== studies.length}
                  checked={selected.length === studies.length}
                  onChange={(event) => {
                    setSelected(event.target.checked ? studies.map((row) => row.study_id) : []);
                  }}
                  color={
                    selected.length > 0 || selected.length === studies.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              <th style={{ width: 100, padding: "12px 6px" }}>Acronym</th>
              <th style={{ padding: "12px 6px" }}>Title</th>
              <th style={{ width: 290, padding: "12px 6px" }}>Current state</th>
              <th style={{ width: 200, padding: "12px 6px" }}>Principal investigator</th>
              <th style={{ width: "52px" }}>
                {selected.length > 0 && (
                  <IconButton
                    onClick={() => {
                      setOpenDeleteStudyModal(true);
                    }}
                    color="danger"
                    variant="outlined"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {studies.map((study) => (
              <tr
                key={study.study_id}
                onClick={() => navigate(`/study/${study.study_id}`)}
                style={{ cursor: "pointer" }}
              >
                <td
                  style={{ textAlign: "center", width: 120, verticalAlign: "middle" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    size="sm"
                    checked={selected.includes(study.study_id)}
                    color={selected.includes(study.study_id) ? "primary" : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(study.study_id)
                          : ids.filter((itemId) => itemId !== study.study_id)
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                    sx={{ verticalAlign: "text-bottom" }}
                  />
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Typography>{study.acronym}</Typography>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Typography>{study.title}</Typography>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Chip>{formatAndCapitalizeString(getStudyCurrentState(study).state)}</Chip>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Typography>
                    {
                      study.associated_parties.filter((x) => x.role === "principal-investigator")[0]
                        ?.name
                    }
                  </Typography>
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </>
  );
}
