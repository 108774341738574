import { Auth } from "aws-amplify";
import { CognitoUser, CognitoUserSession, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { assert } from "../../utils/assert";
import QRCode from "qrcode";
import { v4 as uuidv4 } from "uuid";
import { ForbiddenError } from "../base_client";
import { UserInfoProvider } from "../user_info";
import {
  MFAConfig,
  MFAType,
  Role,
  SMSMFAConfig,
  SoftwareTokenMFAConfig,
  User,
  UserAttributes,
} from "../../models/auth";

export type AuthenticationState =
  | { state: "INITIAL" }
  | { state: "SIGN_UP_INITIATED"; user: CognitoUser }
  | { state: "NEW_PASSWORD_REQUIRED"; user: CognitoUser }
  | { state: "SIGN_IN_PENDING_MFA_CHALLENGE"; user: CognitoUser; mfaType: MFAType }
  | { state: "AUTHENTICATED"; user: CognitoUser }
  | {
      state: "SOFTWARE_TOKEN_MFA_SHARED_SECRET_GENERATED";
      user: CognitoUser;
      sharedSecret: string;
      qrCodeURL: string;
    };

export const enum SetPasswordStatus {
  Success,
  IncorrectOldPassword,
  IncorrectOldPasswordLimitExceeded,
}

/**
 * Client for the AWS Cognito Identity Provider.  Note that this client
 * does not go through the Quartz backend: it talks directly to AWS Cognito,
 * through the AWS Amplify framework.  This ensures that the AWS Amplify
 * client-side checks and flows are respected.
 */
export class IdentityProviderClient {
  /**
   * Because authentication is stateful, we keep an internal state machine to
   * make the authentication flow more obvious.
   */
  constructor(
    private readonly state: any,
    private readonly setState: (nextState: any) => void
  ) {}

  static getInitialState(): any {
    return { state: "INITIAL" };
  }

  private getTypedState(): AuthenticationState {
    return this.state;
  }

  private setTypedState(nextState: AuthenticationState): void {
    // TODO: Provide additional checks on allowed state transitions
    this.setState(nextState);
  }

  protected async httpPost(path: string, body: any, controller?: AbortController): Promise<any> {
    const headers: { [name: string]: string } = {
      "Content-Type": "application/json",
    };
    const json_body = JSON.stringify(body);
    const resp = await fetch(`${path}`, {
      method: "POST",
      mode: "cors",
      headers,
      body: json_body,
      signal: controller?.signal,
    });
    if (resp.status === 403) {
      throw new ForbiddenError();
    }
    if (resp.status === 504) {
      throw new Error("504");
    }
    if (resp.status === 404) {
      throw new Error("404");
    }
    if (resp.status === 410) {
      throw new Error("410");
    }
    if (resp.status !== 200) {
      throw new Error(`unexpected status ${resp.status}`);
    }
    const resp_body = await resp.json();
    return resp_body;
  }

  static initialize(setState: (nextState: any) => void): Promise<void> {
    return Auth.currentAuthenticatedUser().then((currentUser) => {
      IdentityProviderClient.setAuthenticated(currentUser, setState);
    });
  }

  async submitPSCCallback(code: string, state: string, controller?: AbortController): Promise<any> {
    return this.httpPost(
      `${window.CONFIG.backendUrl}/auth/psc/$submit-authcode`,
      { code, state },
      controller
    );
  }

  async loginWithPSC(token: string, email: string, controller?: AbortController): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "INITIAL", () => `unexpected state: ${state.state}`);

    let user = await Auth.signIn(email);
    const challengeName = user.challengeName;
    if (challengeName === "CUSTOM_CHALLENGE" && user.challengeParam.to_provide === "id_token") {
      user = await Auth.sendCustomChallengeAnswer(user, token);
      IdentityProviderClient.setAuthenticated(user, this.setState.bind(this));
      localStorage.setItem("logged-in-psc-id-token", token);
      localStorage.removeItem("psc-id-token");
    } else {
      throw new Error("unexpected challenge");
    }
  }

  async signUp(email: string, password: string, attributes: UserAttributes): Promise<string> {
    const username = uuidv4();
    const { userConfirmed, user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        "custom:organization": attributes.organization,
        name: attributes.name,
        given_name: attributes.given_name,
      },
      autoSignIn: {
        // enables auto sign in after user is confirmed
        enabled: true,
      },
    });
    assert(
      !userConfirmed,
      "user already confirmed even though email verification should have been enabled"
    );
    this.setTypedState({ state: "SIGN_UP_INITIATED", user });
    return username;
  }

  async resendSignUpConfirmationCode(): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "SIGN_UP_INITIATED");
    await Auth.resendSignUp(state.user.getUsername());
  }

  async signIn(email: string, password: string): Promise<MFAType | null> {
    const state = this.getTypedState();
    if (state.state === "SIGN_IN_PENDING_MFA_CHALLENGE") {
      return state.mfaType;
    }
    assert(state.state === "INITIAL", () => `unexpected state: ${state.state}`);
    const user = await Auth.signIn({
      username: email,
      password: password,
      validationData: {
        "psc-id-token": localStorage.getItem("psc-id-token"),
      },
    });

    // After PSC account link, move token to logged-in-psc-id-token for logout.
    const pscIdToken = localStorage.getItem("psc-id-token");
    if (pscIdToken) {
      localStorage.removeItem("psc-id-token");
      localStorage.setItem("logged-in-psc-id-token", pscIdToken);
    }

    const challengeName = user.challengeName;
    if (!challengeName) {
      // Signing up completed without a challenge
      this.setTypedState({ state: "AUTHENTICATED", user });
      return null;
    } else if (challengeName === "NEW_PASSWORD_REQUIRED") {
      // The user must change their password
      this.setTypedState({ state: "NEW_PASSWORD_REQUIRED", user });
      return null;
    } else {
      let mfaType: MFAType;
      switch (challengeName) {
        case "SMS_MFA":
          mfaType = MFAType.SMS_MFA;
          break;
        case "SOFTWARE_TOKEN_MFA":
          mfaType = MFAType.SOFTWARE_TOKEN_MFA;
          break;
        default:
          throw new Error("unexpected challenge");
      }
      this.setTypedState({ state: "SIGN_IN_PENDING_MFA_CHALLENGE", user, mfaType });
      return mfaType;
    }
  }

  static setAuthenticated(user: CognitoUser, setState: (nextState: any) => void) {
    let nextState: AuthenticationState = { state: "AUTHENTICATED", user };
    setState(nextState);
  }

  async confirmSignIn(code: string): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "SIGN_IN_PENDING_MFA_CHALLENGE");
    const user = await Auth.confirmSignIn(
      state.user,
      code,
      {
        [MFAType.SMS_MFA]: "SMS_MFA" as const,
        [MFAType.SOFTWARE_TOKEN_MFA]: "SOFTWARE_TOKEN_MFA" as const,
      }[state.mfaType]
    );
    this.setTypedState({ state: "AUTHENTICATED", user });
  }

  async completeNewPassword(password: string): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "NEW_PASSWORD_REQUIRED" || state.state === "AUTHENTICATED");
    const user: CognitoUser = await Auth.completeNewPassword(state.user, password);
    assert(user.challengeName == null);
    this.setTypedState({ state: "AUTHENTICATED", user });
  }

  async setSMSMFA(phoneNumber: string): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "AUTHENTICATED");
    await Auth.updateUserAttributes(state.user, {
      phone_number: phoneNumber,
    });
    await this.verifyPhoneNumber();
  }

  async verifyPhoneNumber(): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "AUTHENTICATED");
    await Auth.verifyUserAttribute(state.user, "phone_number");
  }

  async continueSMSMFASetup(code: string): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "AUTHENTICATED");
    Auth.verifyUserAttributeSubmit(state.user, "phone_number", code);
    await Auth.setPreferredMFA(state.user, "SMS_MFA");
  }

  async initiateSoftwareTokenMFAConfiguration(): Promise<string> {
    const state = this.getTypedState();
    assert(state.state === "AUTHENTICATED");
    await Auth.deleteUserAttributes(state.user, ["phone_number"]);
    const sharedSecret = await new Promise<string>((resolve, reject) => {
      state.user.associateSoftwareToken({
        associateSecretCode(sharedSecret) {
          resolve(sharedSecret);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
    // https://github.com/google/google-authenticator/wiki/Key-Uri-Format
    const user = await this.getUser();
    const keyUri = `otpauth://totp/Gimli:${user.email}?secret=${sharedSecret}&issuer=Gimli`;
    const qrCodeURL = await QRCode.toDataURL(keyUri);
    // this.setTypedState({
    //   state: "SOFTWARE_TOKEN_MFA_SHARED_SECRET_GENERATED",
    //   user: state.user,
    //   sharedSecret,
    //   qrCodeURL,
    // });
    return qrCodeURL;
  }

  async confirmSoftwareTokenMFAConfiguration(totpCode: string): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "AUTHENTICATED");
    const friendlyDeviceName = "Authenticator";
    await new Promise<CognitoUserSession>((resolve, reject) => {
      state.user.verifySoftwareToken(totpCode, friendlyDeviceName, {
        onSuccess(session) {
          resolve(session);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
    await Auth.setPreferredMFA(state.user, "SOFTWARE_TOKEN_MFA");
    // this.setTypedState({ state: "AUTHENTICATED", user: state.user });
    // TODO: Error when bad association
  }

  async disableMFA(): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "AUTHENTICATED");
    await Auth.setPreferredMFA(state.user, "NOMFA");
  }

  async signOut(): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "AUTHENTICATED");
    await Auth.signOut();
    this.setTypedState({ state: "INITIAL" });
    const pscIdToken = localStorage.getItem("logged-in-psc-id-token");
    if (pscIdToken) {
      assert(window.CONFIG.auth.kind === "AWS_COGNITO", "unexpected auth kind");
      const logoutUrl =
        window.CONFIG.auth.pscConfig.url.replace("wallet", "auth") +
        "/auth/realms/esante-wallet/protocol/openid-connect/logout" +
        "?post_logout_redirect_uri=" +
        window.CONFIG.auth.pscConfig.redirect_uri.replace("/auth/psc-login", "") +
        "&id_token_hint=" +
        pscIdToken;
      window.location.replace(logoutUrl);
      localStorage.removeItem("logged-in-psc-id-token");
    }
  }

  isAuthenticated(): boolean {
    const state = this.getTypedState();
    return state.state === "AUTHENTICATED";
  }

  async getAccessToken(): Promise<string> {
    assert(this.isAuthenticated());
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  }

  async getUser(): Promise<User> {
    const state = this.getTypedState();
    assert(state.state === "AUTHENTICATED");
    const attributeList: CognitoUserAttribute[] = await new Promise((resolve, reject) => {
      state.user.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
        } else {
          assert(attributes != null);
          resolve(attributes);
        }
      });
    });
    const attributes: { [name: string]: string } = {};
    for (const attribute of attributeList) {
      attributes[attribute.Name] = attribute.Value;
    }
    const mfaTypeString = await Auth.getPreferredMFA(state.user, { bypassCache: true });
    let mfaConfig: MFAConfig | undefined;
    switch (mfaTypeString) {
      case "SMS_MFA":
        mfaConfig = {
          type: MFAType.SMS_MFA,
          phoneNumber: attributes["phone"],
        } as SMSMFAConfig;
        break;
      case "SOFTWARE_TOKEN_MFA":
        mfaConfig = { type: MFAType.SOFTWARE_TOKEN_MFA } as SoftwareTokenMFAConfig;
        break;
      case "NOMFA":
        mfaConfig = undefined;
        break;
      default:
        throw new Error(`unexpected MFA type ${mfaTypeString}`);
    }
    return {
      email: attributes["email"],
      mfa_config: mfaConfig,
      attributes: {
        organization: attributes["custom:organization"],
        bio: attributes["custom:bio"],
        given_name: attributes["given_name"],
        name: attributes["name"],
        role: attributes["role"] as unknown as Role,
      },
    };
  }

  async setUserAttributes(email: string, attributes: UserAttributes): Promise<void> {
    const state = this.getTypedState();
    assert(state.state === "AUTHENTICATED");
    await Auth.updateUserAttributes(state.user, {
      email: email,
      "custom:organization": attributes.organization,
      "custom:bio": attributes.bio,
      given_name: attributes.given_name,
      name: attributes.name,
    });
  }

  async setPassword(oldPassword: string, newPassword: string): Promise<SetPasswordStatus> {
    const state = this.getTypedState();
    assert(state.state === "AUTHENTICATED");
    try {
      await Auth.changePassword(state.user, oldPassword, newPassword);
      return SetPasswordStatus.Success;
    } catch (err: any) {
      console.log("ERR", err, err.code);
      if (err.code === "NotAuthorizedException") {
        return SetPasswordStatus.IncorrectOldPassword;
      }
      if (err.code === "LimitExceededException") {
        return SetPasswordStatus.IncorrectOldPasswordLimitExceeded;
      }
      throw err;
    }
  }
}

export class AWSCognitoUserInfoProvider implements UserInfoProvider {
  constructor(private readonly identityProviderClient: IdentityProviderClient) {}

  async getUser(): Promise<User | null> {
    const user = await this.identityProviderClient.getUser();
    if (user == null) {
      return null;
    }
    return {
      email: user.email,
      attributes: {
        given_name: user.attributes.given_name,
        name: user.attributes.name,
        organization: user.attributes.organization,
        role: user.attributes.role ?? Role.ADMIN,
      },
    };
  }

  isAuthenticated(): boolean {
    return this.identityProviderClient.isAuthenticated();
  }

  getAccessToken(): Promise<string> {
    return this.identityProviderClient.getAccessToken();
  }
}
