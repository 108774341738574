import Typography from "@mui/joy/Typography";
import { Answer, AnswerType } from "../../models/questionnaire";
import { groupFactsByAnswerType } from "../../utils/question_answering";
import { ProofChip } from "./ProofChip";
import { ClinicalEvent } from "../../models/clinical_event";

interface DisplayAnswerFactsProps {
  answerType: AnswerType;
  answers: Answer[];
}

interface DisplayProofChipSetProps {
  answerType: AnswerType;
  events: ClinicalEvent[];
}

export function DisplayProofChipSet({ events, answerType }: DisplayProofChipSetProps) {
  const groupedFacts = groupFactsByAnswerType(events, answerType);
  return Object.keys(groupedFacts).map((key, i) => (
    <ProofChip key={i} name={key} eventSources={groupedFacts[key]} />
  ));
}

export function DisplayAnswerFacts({ answerType, answers }: DisplayAnswerFactsProps) {
  if (answers.length === 0) {
    return <Typography level="body-xs">-</Typography>;
  }
  const events = answers.flatMap((answer) => answer.events);
  if (events.length === 0) {
    return <Typography level="body-xs">-</Typography>;
  }

  if (answers.length === 1 && answers[0].text !== null) {
    return <Typography>{answers[0].text}</Typography>;
  }
  return <DisplayProofChipSet events={events} answerType={answerType} />;
}
