import Typography from "@mui/joy/Typography";
import { Person } from "../../../models/person";
import { unixToDayjs } from "../../../utils/time";
import Table from "@mui/joy/Table";

type PatientDisplayProps = {
  patient: Person;
};

export function PatientDisplay({ patient }: PatientDisplayProps) {
  // Helper function to create list items with custom styling
  const createStyledItem = (label: string, value: string) => (
    <tr>
      <td>
        <Typography
          id="ellipsis-list-demo"
          level="body-xs"
          textTransform="uppercase"
          sx={{ letterSpacing: "0.15rem" }}
        >
          {label}
        </Typography>
      </td>
      <td>{value}</td>
    </tr>
  );

  // Extracting patient details
  const fullName = `${patient.given_name} ${patient.family_name}`;
  const birthDate = unixToDayjs(patient.birth_date)?.format("DD MMM YYYY") || "";
  const gender = patient.gender || "";
  const deceasedDate = patient.deceased_date
    ? `on ${unixToDayjs(patient.deceased_date)?.format("DD MMM YYYY")}`
    : "(unknown date)";
  const vitalStatus = patient.deceased ? `Deceased ${deceasedDate}` : "Alive or unknown";
  const identifiers =
    patient.external_ids && patient.external_ids.length > 0
      ? patient.external_ids.map((id) => `${id.system}: ${id.value}`).join(", ")
      : "No identifiers available";

  return (
    <Table>
      <tbody>
        {createStyledItem("Name", fullName)}
        {createStyledItem("Birth date", birthDate)}
        {createStyledItem("Gender", gender)}
        {createStyledItem("Vital status", vitalStatus)}
        {patient.external_ids && createStyledItem("Identifiers", identifiers)}
      </tbody>
    </Table>
  );
}
