import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Annotation, Fact } from "../../../models/fact";
import { getDomain, getDomainColor } from "../../../utils/fact";
import { DebouncedFunction } from "../../../utils/time";
import { Label } from "../NoteProvider";
import { useNote } from "../lib/context";

const boxStyles = {
  flexGrow: 1,
  p: 4,
  backgroundColor: "#eee",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
};

const closeButtonStyle = {
  position: "absolute",
  top: 0,
  right: 0,
};

const paperStyle = {
  p: 2,
  fontSize: "20px",
  lineHeight: 2,
  whiteSpace: "pre-line",
  transition: "color .3s ease-out",
  boxShadow: "0px 0px 8px 2px #00000010",
  borderRadius: "8px",
};

function generateLabelColor(fact: Fact) {
  let color = getDomainColor(getDomain(fact.code));

  // it's stripped if it has any qualifier as a way of bringing attention to the label
  // qualifier is an object with booleans for each type of qualifier
  if (fact.qualifier && Object.values(fact.qualifier).some((q) => q)) {
    const strip = color + 99;
    color = `repeating-linear-gradient(45deg,
      ${strip},
      ${strip} 4px,
      ${color} 4px,
      ${color} 8px)`;
  }
  return color;
}

export function NoteDisplay() {
  const {
    note,
    selectedFact,
    selectedLabel,
    setNote,
    setSelectedLabel,
    hoveredFact,
    setHoveredFact,
  } = useNote();
  const debounce = DebouncedFunction();
  const navigate = useNavigate();
  const [allLabels, setAllLabels] = React.useState<Label[]>([]);

  React.useEffect(() => {
    // Check if the note has at least one element in not_elements dict
    if (!note || note.elements.length === 0) return;

    const defaultColor = "#bbb";
    const facts = note.facts;
    const filteredFacts = facts
      .filter((fact) => getDomain(fact.code) !== "PII")
      .map((fact) => {
        return fact;
      });

    const labels: Label[] = [];

    if (selectedFact) {
      filteredFacts.forEach((fact) => {
        const isSelectedFact = selectedFact ? fact.fact_id === selectedFact.fact_id : false;

        const factLabels = fact.source.map((annotation: Annotation) => {
          const isSelectedLabel = selectedLabel
            ? annotation.offset === selectedLabel.annotation.offset &&
              annotation.element_index === selectedLabel.annotation.element_index
            : false;

          const color = isSelectedFact ? generateLabelColor(fact) : defaultColor;
          let labelColor = isSelectedLabel ? generateLabelColor(fact) : color;

          return {
            annotation: annotation,
            domain: getDomain(fact.code),
            fact: fact,
            color: labelColor,
          };
        });

        labels.push(...factLabels);
      });
    } else if (selectedLabel) {
      filteredFacts.forEach((fact) => {
        const factLabels = fact.source.map((annotation) => {
          return {
            annotation: annotation,
            domain: getDomain(fact.code),
            fact: fact,
            color:
              annotation.offset === selectedLabel.annotation.offset &&
              annotation.element_index === selectedLabel.annotation.element_index
                ? generateLabelColor(fact)
                : "#bbb",
          };
        });
        labels.push(...factLabels);
      });
      labels.push(selectedLabel);
    } else {
      filteredFacts.forEach((fact) => {
        const factLabels = fact.source.map((annotation) => {
          let color = generateLabelColor(fact);

          return {
            annotation: annotation,
            domain: getDomain(fact.code),
            fact: fact,
            color: color,
          };
        });

        labels.push(...factLabels);
      });
    }

    setAllLabels(labels);
  }, [note, selectedFact, selectedLabel]);

  const getSelectionCharOffsetsWithin = (
    element: Node
  ): { selectedText: string; start: number; end: number } | null => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) {
      return null;
    }

    const range = selection.getRangeAt(0);
    const selectedText = range.toString();
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(element);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);

    const start = preSelectionRange.toString().length;
    const end = start + range.toString().length;

    return { selectedText, start, end };
  };

  function handleAddAnnotation(newAnnotation: Annotation) {
    if (note && selectedFact) {
      const factToUpdate = note.facts.find((fact) => fact.fact_id === selectedFact.fact_id);

      if (factToUpdate) {
        const updatedSources = [...factToUpdate.source, newAnnotation];

        const updatedFact = {
          ...factToUpdate,
          source: updatedSources,
        };

        const updatedFacts = note.facts.map((fact) =>
          fact.fact_id === updatedFact.fact_id ? updatedFact : fact
        );

        setNote({
          ...note,
          facts: updatedFacts,
        });
      }
    }
  }

  const handleTextSelection = async (element_index: number) => {
    const textElement = document.getElementById(`content-${element_index}`);
    if (!textElement) return;

    const charOffsets = getSelectionCharOffsetsWithin(textElement);
    if (!charOffsets) return;

    const fullText = textElement.textContent || "";

    let { start: startOffset, end: endOffset, selectedText } = charOffsets;

    // Adjust start and end offsets to capture the whole word if only part of it is selected.
    while (startOffset > 0 && !fullText[startOffset - 1].match(/\s/)) {
      startOffset--;
    }
    while (endOffset < fullText.length && !fullText[endOffset].match(/\s/)) {
      endOffset++;
    }

    // Update the selectedText to reflect the whole word selection.
    selectedText = fullText.substring(startOffset, endOffset);

    // Select the current labels in the elements
    const elementLabels = allLabels.filter(
      (label) => label.annotation.element_index === element_index
    );

    const isOverlapping = elementLabels.some((label) => {
      const existingStart = label.annotation.offset;
      const existingEnd = existingStart + label.annotation.length;
      return startOffset < existingEnd && endOffset > existingStart;
    });

    if (isOverlapping) {
      console.warn("The new annotation overlaps with an existing one.");
      return;
    }

    const snippetBefore = fullText.substring(startOffset - 20, startOffset);
    const snipperAfter = fullText.substring(endOffset, endOffset + 20);

    const newLabel: Label = {
      annotation: {
        element_index: element_index,
        offset: startOffset,
        length: endOffset - startOffset,
        lexical_variant: selectedText,
        snippet_before: snippetBefore,
        snippet_after: snipperAfter,
      },
      color: "#00A8E8",
    };

    setAllLabels((prevLabels) => [...prevLabels, newLabel]);

    if (selectedFact) {
      handleAddAnnotation(newLabel.annotation);
    } else {
      handleNewLabel(newLabel);
    }
  };

  const handleNewLabel = (label: Label) => {
    setSelectedLabel(label);
  };

  const handleNavigateBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/patients");
    }
  };

  const renderAnnotatedText = (content: string, element_index: number) => {
    const handleMouseEnter = debounce((label: Label) => {
      setHoveredFact(label.fact!);
    }, 600);

    const handleMouseLeave = () => {
      setHoveredFact(null);
      handleMouseEnter.cancel();
    };
    let result: JSX.Element[] = [];
    let lastIndex = 0;

    // Get the label of the element
    const elementLabels = allLabels.filter(
      (label) => label.annotation.element_index === element_index
    );

    // find in allLabels the labels that have the same offset, and remove the shortest ones in length
    // this is to prevent overlapping annotations
    const map = new Map<number, Label>();
    elementLabels.forEach((label) => {
      const existing = map.get(label.annotation.offset);
      if (!existing || label.annotation.length > existing.annotation.length) {
        map.set(label.annotation.offset, label);
      }
    });
    const uniqueLabels = Array.from(map.values());

    // Sort annotations to ensure correct display of labels
    const sortedLabels: Label[] = [...uniqueLabels].sort(
      (a, b) => a.annotation.offset - b.annotation.offset
    );

    sortedLabels.forEach((label: Label, index: number) => {
      // Prevent overlapping annotations by using the maximum index so far
      const trueStart = Math.max(lastIndex, label.annotation.offset);

      // Text before the annotation
      if (trueStart > lastIndex) {
        const startText = content.substring(lastIndex, trueStart);
        result.push(<span key={index + "start"}>{startText}</span>);
      }

      // The annotated text
      const annotatedText = content.substring(
        trueStart,
        label.annotation.offset + label.annotation.length
      );
      lastIndex = label.annotation.offset + label.annotation.length;

      result.push(
        <span
          data-offset={label.annotation.offset}
          key={index + "annotated"}
          style={{
            // position: "relative",
            // display: annotatedText ? "inline-block" : "none", // make sure if we have multiple facts for a label, that we do not display the following ones
            background: label.color,
            color: "white",
            fontWeight: "bold",
            height: "26px",
            lineHeight: "24px",
            paddingLeft: "4px",
            paddingBottom: "2px",
            paddingRight: "4px",
            borderRadius: "4px",
            transition: "all .2s ease-out",
            cursor: "pointer",
            transform: hoveredFact && hoveredFact === label.fact ? "scale(110%)" : "scale(1)",
            boxShadow: hoveredFact && hoveredFact === label.fact ? "0px 0px 8px 2px white" : "none",
            zIndex: hoveredFact && hoveredFact === label.fact ? "1000" : "initial",
          }}
          onClick={() => setSelectedLabel(label)}
          onMouseEnter={() => handleMouseEnter(label)}
          onMouseLeave={handleMouseLeave}
        >
          {annotatedText}
        </span>
      );
    });

    // Text after the last annotation
    const endText = content.substring(lastIndex);
    result.push(<span key={"end"}>{endText}</span>);

    return result;
  };

  if (!note) {
    return null;
  }

  return (
    <Box component="main" sx={boxStyles}>
      <IconButton aria-label="close" onClick={handleNavigateBack} sx={closeButtonStyle}>
        <CloseRoundedIcon />
      </IconButton>
      <Sheet sx={paperStyle}>
        {note.elements.map((element, index) => (
          <div
            key={index}
            id={`content-${index}`}
            onMouseUp={() => handleTextSelection(index)}
            style={{
              color: selectedLabel || selectedFact ? "#757575" : "black",
              transition: "color .3s ease-out",
            }}
          >
            {renderAnnotatedText(element.content, index)}
          </div>
        ))}
      </Sheet>
    </Box>
  );
}
