import AdjustIcon from "@mui/icons-material/Adjust";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import Link from "@mui/joy/Link";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import { EventSource } from "../../models/clinical_event";
import { Annotation } from "../../models/fact";
import { unixToDayjs } from "../../utils/time";

interface ProofChipDrawerContentProps {
  eventSources: EventSource[];
  name: string;
}

export default function ProofChipDrawerContent({
  eventSources,
  name,
}: ProofChipDrawerContentProps) {
  return (
    <>
      <Box>
        <Typography fontWeight="lg" fontSize="lg" sx={{ p: 2 }}>
          {name}
        </Typography>
        <ModalClose />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 2,
        }}
      >
        {eventSources.map((event_source: EventSource, index) => (
          <div key={index}>
            {event_source.annotation.map((annotation: Annotation, key: number) => (
              <Box key={key} sx={{ display: "flex", gap: 1, width: "100%", mt: 1 }}>
                <AdjustIcon color={event_source.validation_date ? "success" : "warning"} />
                <div>
                  <Typography fontWeight="lg" fontSize="sm">
                    "{annotation.snippet_before} {annotation.lexical_variant}{" "}
                    {annotation.snippet_after}"
                  </Typography>
                  <Typography
                    textColor="text.secondary"
                    fontSize="sm"
                    sx={{ mb: 1 }}
                    startDecorator={
                      <Link
                        underline="always"
                        href={"/note/" + event_source.note_id}
                        color="neutral"
                        fontSize="sm"
                        target="_blank"
                      >
                        {event_source.note_title}
                      </Link>
                    }
                  >
                    on {unixToDayjs(event_source.note_date)?.format("DD MMM YYYY")}
                  </Typography>
                </div>
              </Box>
            ))}
          </div>
        ))}
      </Box>
    </>
  );
}
