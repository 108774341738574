import React from "react";
import { Task } from "../models/task";
import { TaskQuartzClientContext } from "../clients/contexts";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import { unixToDayjs } from "../utils/time";
import CircularProgress from "@mui/joy/CircularProgress";

export default function ListTasks() {
  // Context
  const taskQuartzClient = React.useContext(TaskQuartzClientContext);

  // State
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const [loading, setLoading] = React.useState(true);

  // Effects
  React.useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true);
      try {
        const tasks = await taskQuartzClient.listTasks();
        setTasks(tasks);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchTasks();
  }, [taskQuartzClient]);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography level="body-lg" component="div" sx={{ pb: "5px" }}>
        Tasks
      </Typography>
      {loading && <CircularProgress size="sm" />}
      {tasks.length === 0 && !loading ? (
        <Typography>No task found</Typography>
      ) : (
        <Sheet sx={{ mt: 2, borderRadius: "8px", td: { verticalAlign: "middle" } }}>
          <Table
            aria-label="users table"
            hoverRow
            sx={{ borderRadius: "8px" }}
            variant="outlined"
            borderAxis="xBetween"
          >
            <thead>
              <tr>
                <th style={{ width: "280px" }}>Task ID</th>
                <th style={{ width: "180px" }}>Start date</th>
                <th style={{ width: "180px" }}>Last modified date</th>
                <th>Description</th>
                <th style={{ width: "140px" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task: Task) => (
                <tr key={task.task_id}>
                  <td>{task.task_id}</td>
                  <td>{unixToDayjs(task.authored_on)!.format("YYYY MMM DD HH:mm:ss")}</td>
                  <td>{unixToDayjs(task.last_modified_date)!.format("YYYY MMM DD HH:mm:ss")}</td>
                  <td>{task.description}</td>
                  <td>{task.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      )}
    </Box>
  );
}
