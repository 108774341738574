import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import React from "react";
import { CohortQuartzClientContext } from "../clients/contexts";
import { CohortId, ExportFormat } from "../models/cohort";
import { useNavigate } from "react-router-dom";

interface ExportDropDownProps {
  cohortId: CohortId;
}

export default function ExportDropDown({ cohortId }: ExportDropDownProps) {
  const cohortClient = React.useContext(CohortQuartzClientContext);
  const navigate = useNavigate();

  const handleExport = async (export_format: ExportFormat, cohortId: CohortId) => {
    try {
      const result = await cohortClient.exportOmop(export_format, cohortId);
      const url = window.URL.createObjectURL(result);
      const a = document.createElement("a");
      a.href = url;

      // Get current date and format it as YYYY.MM.DD
      const date = new Date();
      const formattedDate = `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;

      a.download = `cohort_${cohortId}_${formattedDate}.${
        export_format === ExportFormat.CLINICAL_TABLE ? "xlsx" : "zip"
      }`;
      a.click();
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <Dropdown>
      <MenuButton variant="solid" color="success">
        Export
      </MenuButton>
      <Menu placement="bottom-end">
        <MenuItem onClick={() => handleExport(ExportFormat.CSV, cohortId)}>OMOP (CSV)</MenuItem>
        <MenuItem onClick={() => handleExport(ExportFormat.SQLITE, cohortId)}>
          OMOP (SQLite)
        </MenuItem>
        <MenuItem onClick={() => navigate(`/cohort/${cohortId}/export`)}>Custom export</MenuItem>
      </Menu>
    </Dropdown>
  );
}
