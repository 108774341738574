import Button, { ButtonProps } from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import { ReactNode } from "react";

export default function LoadingButton({
  children,
  loading,
  ...props
}: {
  children: ReactNode;
  loading: boolean;
} & ButtonProps) {
  return (
    <Button sx={{ transition: "all .5s ease-out" }} {...props} disabled={loading || props.disabled}>
      {loading ? <CircularProgress color={props.color} /> : children}
    </Button>
  );
}
