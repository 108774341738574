import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { CohortQuartzClientContext } from "../../clients/contexts";
import { Cohort, CohortId } from "../../models/cohort";

interface ListCohortModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleLinkCohort: (cohortId: CohortId) => void;
}

export default function ListCohortModal({ open, setOpen, handleLinkCohort }: ListCohortModalProps) {
  const cohortQuartzClient = React.useContext(CohortQuartzClientContext);
  const [cohortId, setCohortId] = React.useState<CohortId | null>(null);
  const [cohorts, setCohorts] = React.useState<Cohort[]>([]);

  React.useEffect(() => {
    const fetchCohorts = async () => {
      try {
        const result = await cohortQuartzClient.listCohorts();
        setCohorts(result);
      } catch (e: any) {
        console.log(e.message);
      }
    };
    fetchCohorts();
  }, [cohortQuartzClient]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog sx={{ width: "500px" }}>
        <ModalClose />
        <DialogTitle>Choose an existing cohort</DialogTitle>
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            if (cohortId) {
              handleLinkCohort(cohortId);
              setOpen(false);
            }
          }}
        >
          <Stack spacing={2}>
            <Typography>
              All patients <b>included</b> in the cohort will be added as{" "}
              <b>potential candidates</b> in the study.
            </Typography>
            <FormControl>
              <FormLabel>Link with an existing cohort</FormLabel>
              <Select
                onChange={(e, cohortId) => setCohortId(cohortId as CohortId)}
                disabled={cohorts.length === 0}
                placeholder={cohorts.length === 0 ? "You have no cohort" : "Select a cohort"}
              >
                {cohorts.map((cohort) => (
                  <Option key={cohort.cohort_id} value={cohort.cohort_id}>
                    {cohort.name}
                  </Option>
                ))}
              </Select>
            </FormControl>
            <Button type="submit">Link</Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}
