import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IdentityProviderClientContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";
import Skeleton from "@mui/joy/Skeleton";

type FormValues = {
  code: string;
};

const QR_CODE_WIDTH_PX = 196;

export default function MFAConfigurationSoftwareToken({
  onClose,
}: {
  onClose: (submitted: boolean) => void;
}) {
  const identityProviderClient = useContext(IdentityProviderClientContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormValues>();
  const auth = useContext(IdentityProviderClientContext);
  const [serverError, setServerError] = useState<string | null>(null);
  const [qrCode, setQRCode] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function initiate() {
      try {
        setQRCode(await auth.initiateSoftwareTokenMFAConfiguration());
      } catch (err: any) {
        if (err.code === "EnableSoftwareTokenMFAException") {
          setServerError("Code mismatch. Please try again.");
          return;
        }
        throw err;
      }
    }

    initiate();
  }, [setQRCode, auth, setServerError]);

  const onSubmit = handleSubmit(async (data) => {
    if (!/^\d{6}$/.test(data.code)) {
      setError("code", { message: "Code must be 6 digits." });
      return;
    }
    try {
      await identityProviderClient.confirmSoftwareTokenMFAConfiguration(data.code);
    } catch (err: any) {
      if (err.code === "EnableSoftwareTokenMFAException") {
        setError("code", { message: "Code mismatch. Please try again." });
        return;
      }
      throw err;
    }
    enqueueSnackbar("Software MFA configured successfully.", { variant: "success" });
    onClose(true);
  });
  return (
    <div style={{ maxWidth: "500px", padding: "2rem" }}>
      <div className="content">
        <h1>Set up software MFA</h1>
        <p>Scan the QR code with your authenticator app and enter the code to continue.</p>
        <p>
          {!qrCode && (
            <Skeleton variant="rectangular" width={QR_CODE_WIDTH_PX} height={QR_CODE_WIDTH_PX} />
          )}
          {qrCode && (
            <img alt="qrcode" src={qrCode} width={QR_CODE_WIDTH_PX} height={QR_CODE_WIDTH_PX} />
          )}
        </p>
      </div>
      <form onSubmit={onSubmit}>
        {serverError && <SnackbarNotification text={serverError} color="danger" />}
        <div className="block">
          <div className="field">
            <label className="label">Authentication code</label>
            <p className="control">
              <input
                className={errors.code ? "is-danger input" : "input"}
                {...register("code", { required: "Field required." })}
                placeholder="Enter code"
              />
            </p>
            <p className="help is-danger">{errors.code?.message}</p>
          </div>
        </div>
        <div className="block">
          <button
            className={classNames("button is-info", isSubmitting && "is-loading")}
            type="submit"
          >
            Set up software MFA
          </button>
        </div>
      </form>
    </div>
  );
}
