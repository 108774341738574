/* AUTOGENERATED: DO NOT EDIT */
import { MilliDelta, UnixTimestamp } from "./common";

/**
 * A time of varying precision.
 *
 * Because of the varying precision, we need the timezone to give, e.g., a YYYY-MM-DD date
 * from the Unix timestamp.
 *
 * If the timestamp lands within the approximate period (for instance, within a day),
 * it is interpreted as if the timestamp landed at the beginning of the period (for instance,
 * at the beginning of the day).  This is equivalent to, e.g., truncating a float (taking
 * the lower integer value) to get an integer.
 *
 * If the timezone is unknown, `utc_offset_minutes` must be set to `0` and `unknown_timezone``
 * to `True`.  Then, the `value` must be given with respect to the UTC timezone.
 */
export interface ApproximateTime extends Time {
  type: TimeType;
  value: UnixTimestamp;
  precision: TemporalPrecision;
  utc_offset_minutes: number;
}

/** An exact time, for instance if the subject comes from a fully automated system. */
export interface ExactTime extends Time {
  type: TimeType;
  timestamp: UnixTimestamp;
}

/**
 */
export type FactTime = ApproximateTime | ExactTime | RelativeTime | TimeInterval;

/** A time delta without any quantitative offset. */
export enum QualitativeTimeDelta {
  BEFORE = "BEFORE",
  AFTER = "AFTER",
}

/**
 * A time delta with a quantitative offset, of varying precision.
 *
 * The value is interpreted as if truncated to the beginning of the period given
 * by `precision`.
 */
export interface QuantitativeTimeDelta {
  value: MilliDelta;
  precision: TemporalPrecision;
}

/** A time relative to point of reference. */
export interface RelativeTime extends Time {
  type: TimeType;
  reference: TimeReference;
  delta: QualitativeTimeDelta | QuantitativeTimeDelta;
}

/** The precision of an approximate time or time delta. */
export enum TemporalPrecision {
  MILLI = "MILLI",
  SECOND = "SECOND",
  MINUTE = "MINUTE",
  HOUR = "HOUR",
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export interface Time {
  type: TimeType;
}

/**
 * A time interval.
 *
 * Note that, in most cases, `ApproximateTime` should be enough and used instead
 * of `TimeInterval`.
 *
 * The `begin` and `end` times cannot be time intervals themselves.
 */
export interface TimeInterval extends Time {
  type: TimeType;
  begin: RelativeTime | ApproximateTime;
  end: RelativeTime | ApproximateTime;
}

/**
 * The nature of the linked entity used as a point of reference for a relative time.
 *
 * If the reference is another date, not note, birth or death, it should be computed directly.
 */
export enum TimeReference {
  NOTE = "NOTE",
  BIRTH = "BIRTH",
  DEATH = "DEATH",
}

/** Used to discriminated between subclasses of `Time` when serializing/deserializing. */
export enum TimeType {
  EXACT = "EXACT",
  APPROXIMATE = "APPROXIMATE",
  RELATIVE = "RELATIVE",
  INTERVAL = "INTERVAL",
}
