import Checkbox from "@mui/joy/Checkbox";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import React from "react";
import {
  QualitativeTimeDelta,
  QuantitativeTimeDelta,
  RelativeTime,
  TemporalPrecision,
  TimeInterval,
  TimeReference,
  TimeType,
} from "../../../../models/human_time";
import { isApproximateTime, isRelativeTime, isTimeInterval } from "../../../../utils/fact";
import { Temporality } from "../DrawerContent/FactDisplayAndEdit/TemporalityEdit";

export default function RelativeDatePicker({
  time,
  temporality,
  onUpdate,
}: {
  time: RelativeTime | TimeInterval;
  temporality: Temporality;
  onUpdate: (newValue: RelativeTime | TimeInterval) => void;
}) {
  // this addPrecision is determined if the delta is qualitative or quantitative
  const addPrecision =
    // we need to check if the delta is quantitative for relative time
    // or if the begin and end are quantitative for time interval
    isTimeInterval(time)
      ? temporality === Temporality.PAST
        ? isRelativeTime(time.begin) && typeof (time.begin as RelativeTime).delta !== "string"
        : isRelativeTime(time.end) && typeof (time.end as RelativeTime).delta !== "string"
      : isRelativeTime(time)
        ? typeof (time as RelativeTime).delta !== "string" &&
          ((time as RelativeTime).delta as QuantitativeTimeDelta).value !== 0
        : false;

  const precision: TemporalPrecision =
    // if it is a relative time, we need to get the precision of the delta
    // if it's not, we set it to day as to simplify everything
    isTimeInterval(time)
      ? temporality === Temporality.PAST
        ? isRelativeTime(time.begin) && typeof (time.begin as RelativeTime).delta !== "string"
          ? ((time.begin as RelativeTime).delta as QuantitativeTimeDelta).precision
          : TemporalPrecision.DAY
        : isRelativeTime(time.end) && typeof (time.end as RelativeTime).delta !== "string"
          ? ((time.end as RelativeTime).delta as QuantitativeTimeDelta).precision
          : TemporalPrecision.DAY
      : isRelativeTime(time)
        ? ((time as RelativeTime).delta as QuantitativeTimeDelta).precision
        : TemporalPrecision.DAY;

  const [value, setValue] = React.useState(
    Math.abs(
      Number(
        // we need to check if the delta is quantitative for relative time
        // or if the begin and end are quantitative for time interval
        isRelativeTime(time)
          ? // if there is a delta, we need to get the value, else we set it to 42
            time.delta
            ? (time.delta as QuantitativeTimeDelta).value || 1
            : 1
          : isTimeInterval(time)
            ? // if time.begin or end are approximate, we set the value to 1
              isApproximateTime(time.begin) || isApproximateTime(time.end)
              ? 1
              : // for past time interval, we need to get the negative value, vice-versa for future
                temporality === Temporality.PAST
                ? ((time.begin as RelativeTime).delta as QuantitativeTimeDelta).value
                : ((time.end as RelativeTime).delta as QuantitativeTimeDelta).value
            : 1
      ) || 1
    )
  );
  const untilOrFromPresent =
    time && isTimeInterval(time) && isRelativeTime(time.begin) && isRelativeTime(time.end);

  const generateRelativeTime = (
    newTemporality: Temporality,
    newAddPrecision: boolean,
    newPrecision: TemporalPrecision,
    value: number,
    untilOrFromPresent: boolean
  ) => {
    const deltaValue = value * (newTemporality === Temporality.PAST ? -1 : 1);
    const prec = !!precision ? newPrecision : TemporalPrecision.DAY;
    const delta = newAddPrecision
      ? { value: deltaValue, precision: prec }
      : newTemporality === Temporality.PAST
        ? QualitativeTimeDelta.BEFORE
        : QualitativeTimeDelta.AFTER;

    if (untilOrFromPresent) {
      // if it is until/from present date, we need to generate an interval
      const note_time = {
        type: TimeType.RELATIVE,
        reference: TimeReference.NOTE,
        delta: {
          value: 0,
          precision: TemporalPrecision.DAY,
        },
      } as RelativeTime;
      if (newTemporality === Temporality.PAST) {
        return {
          type: TimeType.INTERVAL,
          begin: {
            type: TimeType.RELATIVE,
            reference: TimeReference.NOTE,
            delta: newAddPrecision ? delta : 1,
          },
          end: note_time,
        } as TimeInterval;
      } else {
        return {
          type: TimeType.INTERVAL,
          begin: note_time,
          end: {
            type: TimeType.RELATIVE,
            reference: TimeReference.NOTE,
            delta: newAddPrecision ? delta : 1,
          },
        } as TimeInterval;
      }
    } else {
      // if not we just generate a relative time object
      // if temporality is past, we need to make the delta value negative
      return {
        type: TimeType.RELATIVE,
        reference: TimeReference.NOTE,
        delta: delta,
      } as RelativeTime;
    }
  };

  return (
    <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
      <Checkbox
        label="Add a delta"
        size="sm"
        variant="outlined"
        checked={addPrecision}
        onChange={() => {
          const newAddPrecision = !addPrecision;
          onUpdate(
            generateRelativeTime(temporality, newAddPrecision, precision, value, untilOrFromPresent)
          );
        }}
        data-cy="add-delta-button"
      />
      {addPrecision && (
        <Stack direction="row" spacing={1}>
          <Input
            type="number"
            value={value}
            onChange={(e) => {
              const newValue = parseInt(e.target.value);
              setValue(newValue);
              // making sure on clear of form, we don't update the value
              if (!isNaN(newValue)) {
                onUpdate(
                  generateRelativeTime(
                    temporality,
                    addPrecision,
                    precision,
                    newValue,
                    untilOrFromPresent
                  )
                );
              }
            }}
            slotProps={{
              input: { min: 1 },
            }}
            data-cy="delta-value"
          />
          <Select
            value={precision}
            onChange={(event, newPrecision) => {
              onUpdate(
                generateRelativeTime(
                  temporality,
                  addPrecision,
                  newPrecision as TemporalPrecision,
                  value,
                  untilOrFromPresent
                )
              );
            }}
            data-cy="delta-precision"
          >
            <Option value={TemporalPrecision.DAY}>Day</Option>
            <Option value={TemporalPrecision.WEEK}>Week</Option>
            <Option value={TemporalPrecision.MONTH}>Month</Option>
            <Option value={TemporalPrecision.YEAR}>Year</Option>
          </Select>
        </Stack>
      )}
      <Checkbox
        label={temporality === Temporality.PAST ? "Until present date" : "From present date"}
        size="sm"
        variant="outlined"
        disabled={!addPrecision}
        checked={untilOrFromPresent}
        onChange={() => {
          const newUntilOrFromPresent = !untilOrFromPresent;
          onUpdate(
            generateRelativeTime(temporality, addPrecision, precision, value, newUntilOrFromPresent)
          );
        }}
        data-cy="until-from-present-button"
      />
    </Stack>
  );
}
