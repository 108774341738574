import Box from "@mui/joy/Box";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../common/loading/Loading";
import { useQuery } from "../../utils/use_query";

export default function ValidateEmail() {
  const query = useQuery();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const username = query.get("username");
  const confirmationCode = query.get("confirmation_code");

  React.useEffect(() => {
    async function verifyEmail() {
      if (username == null || confirmationCode == null) {
        enqueueSnackbar("Invalid verification link.", { variant: "error" });
        navigate("/");
        return;
      }
      try {
        await Auth.confirmSignUp(username, confirmationCode);
        enqueueSnackbar("Email successfully verified.", { variant: "success" });
        navigate("/auth/signin?email_status=verified");
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Invalid verification link.", { variant: "error" });
        navigate("/auth/signin?email_status=error");
      }
    }

    verifyEmail();
  }, [username, confirmationCode, enqueueSnackbar, navigate]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: { xs: "flex-start", sm: "center" },
        justifyContent: "center",
        backgroundColor: "HSLA(210,89%,41%,1)",
        backgroundImage: `
          radial-gradient(at 10% 43%, hsla(235,98%,71%,1) 0px, transparent 50%),
          radial-gradient(at 41% 25%, hsla(28,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 0% 0%, hsla(210,89%,41%,1) 0px, transparent 50%),
          radial-gradient(at 80% 0%, hsla(210,74%,76%,1) 0px, transparent 50%),
          radial-gradient(at 0% 50%, hsla(355,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 73% 58%, hsla(340,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 0% 100%, hsla(22,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 80% 100%, hsla(242,100%,65%,1) 0px, transparent 50%);
            `,
      }}
    >
      <Loading message="Validating your email address..." />
    </Box>
  );
}
