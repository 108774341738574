import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { IdentityProviderClientContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";
import AuthPage, { EmailCheckNotification } from "../../components/AuthPage";
import { MFAType } from "../../models/auth";
import { assert, unknownType } from "../../utils/assert";
import { useQuery } from "../../utils/use_query";

type FormValues = {
  email: string;
  password: string;
};

export default function SignInPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const identityProviderClient = useContext(IdentityProviderClientContext);
  const navigate = useNavigate();
  const [serverError, setServerError] = useState<string | null>("");
  const query = useQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();

  const [hidePSCButton, setHidePSCButton] = useState<boolean>(false);

  useEffect(() => {
    // the notification is inside this useEffect to avoid too many renders limit to prevent infinite loop
    const pscIdToken = localStorage.getItem("psc-id-token");
    if (pscIdToken) {
      setServerError(
        "Create an account or login with an existing account to use Pro Santé Connect."
      );
      setHidePSCButton(true);
    }
  }, [setHidePSCButton, setServerError]);

  const [mfa, setMfa] = useState<MFAType | undefined>(undefined);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const mfaChallenge = await identityProviderClient.signIn(data.email, data.password);
      switch (mfaChallenge) {
        case MFAType.SMS_MFA:
          setMfa(MFAType.SMS_MFA);
          break;
        case MFAType.SOFTWARE_TOKEN_MFA:
          setMfa(MFAType.SOFTWARE_TOKEN_MFA);
          break;
        case null:
          navigate("/");
          break;
        default:
          unknownType(mfaChallenge);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.code === "UserNotFoundException") {
        setError("email", { message: "User not found." });
        return;
      }
      if (error.code === "NotAuthorizedException") {
        setServerError("Incorrect username or password.");
        return;
      }
      console.error(error);
      setServerError("Could not sign in");
      return;
    }
  });

  const [emailCheckNotification, setEmailCheckNotification] =
    useState<EmailCheckNotification | null>(null);

  useEffect(() => {
    const status = query.get("email_status");
    if (status === "verified") {
      setEmailCheckNotification({
        error: false,
        message: "Thank you for verifying your email! You can now log in.",
      });
    } else if (status === "error") {
      setEmailCheckNotification({
        error: true,
        message: "Invalid or expired verification link. Contact support if you need assistance.",
      });
    }
  }, [query]);

  if (identityProviderClient.isAuthenticated()) {
    return <Navigate to="/" />;
  }

  assert(window.CONFIG.auth.kind === "AWS_COGNITO", "expected AWS_COGNITO auth");
  const pscConfig = window.CONFIG.auth.pscConfig;

  return (
    <>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <AuthPage
        loading={loading}
        onSubmit={onSubmit}
        errors={errors}
        register={register}
        cloud
        hidePSCButton={hidePSCButton}
        pscConfig={pscConfig}
        mfaType={mfa}
        email_check_notification={emailCheckNotification}
      />
    </>
  );
}
