import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { date } from "../utils/time";

interface NewCohortModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleCreateCohort: (title: string, description: string, dbName: string) => void;
}

export default function NewCohortModal({ open, setOpen, handleCreateCohort }: NewCohortModalProps) {
  const [selectedDb, setSelectedDb] = React.useState<string>("unverified");
  const [title, setTitle] = React.useState<string>("Cohort of " + date());
  const [description, setDescription] = React.useState<string>("");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDb(event.target.value);
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ width: "500px" }}>
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              handleCreateCohort(title, description, selectedDb);
              setOpen(false);
            }}
          >
            <DialogTitle>Build a new cohort</DialogTitle>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <Card variant="soft" color="primary">
                <CardContent>
                  <Typography level="inherit" sx={{ mb: 2 }}>
                    Select a database
                  </Typography>
                  <RadioGroup
                    name="database"
                    sx={{ gap: 1, "& > div": { p: 1 } }}
                    onChange={handleRadioChange}
                    defaultValue="unverified"
                  >
                    <FormControl>
                      <Radio overlay value="verified" label="Verified facts only" />
                      <FormHelperText>Facts from notes verified by an expert.</FormHelperText>
                    </FormControl>
                    <FormControl>
                      <Radio overlay value="unverified" label="All facts" />
                      <FormHelperText>
                        All facts from all notes (verified and unverified).
                      </FormHelperText>
                    </FormControl>
                  </RadioGroup>
                </CardContent>
              </Card>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                  autoFocus
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  value={description ?? ""}
                  onChange={(event) => setDescription(event.target.value)}
                  minRows={3}
                />
              </FormControl>
              <Button type="submit">Create</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
