import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import { unixToDayjs } from "../../../../utils/time";
import { useNote, usePatient } from "../../lib/context";

export interface BreadcrumbType {
  handleTitleClick: () => void;
  handleUnkownPatientClick: () => void;
}

export default function Breadcrumb({ handleTitleClick, handleUnkownPatientClick }: BreadcrumbType) {
  const { note } = useNote();
  const { patient } = usePatient();

  if (!note) {
    return <p>Note was not found!</p>;
  }

  return (
    <>
      <Breadcrumbs separator="›" aria-label="breadcrumbs" sx={{ pb: 0 }}>
        <Link color="neutral" href="/patients">
          <PersonOutlineIcon sx={{ mr: 0.5 }} />
          All patients
        </Link>
        {note.person_id ? (
          <Link color="neutral" href={`/patient/` + note.person_id}>
            {patient.given_name} {patient.family_name}
          </Link>
        ) : (
          <Link
            color={note.person_id ? "neutral" : "warning"}
            onClick={handleUnkownPatientClick}
            aria-current="page"
          >
            Unknown patient
          </Link>
        )}
      </Breadcrumbs>
      <Breadcrumbs separator="›" aria-label="breadcrumbs">
        <Link
          color={note.note_datetime ? "neutral" : "warning"}
          onClick={handleTitleClick}
          aria-current="page"
        >
          <NotesOutlinedIcon sx={{ mr: 0.5 }} />
          {note.note_title} -{" "}
          {note.note_datetime ? unixToDayjs(note.note_datetime)?.format("DD MMM YYYY") : "No date"}
        </Link>
      </Breadcrumbs>
    </>
  );
}
