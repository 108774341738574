import AddIcon from "@mui/icons-material/Add";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import CircularProgress from "@mui/joy/CircularProgress";
import Grid from "@mui/joy/Grid";
import Stack from "@mui/joy/Stack";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import AnalyticsModal from "../analytics/AnalyticsModal";
import { AdminQuartzClientContext, CohortQuartzClientContext } from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";
import HexagonBackground from "../components/HexagonBackground";
import TitleStylizedUnderline from "../components/TitleStylizedUnderline";
import TableSkeleton from "../components/skeletons/TableSkeleton";
import { OmopDbReport } from "../models/analytics";
import { BatchBundle } from "../models/bundle";
import { Cohort, CohortId, InitCohort } from "../models/cohort";
import { CohortList } from "./CohortList";
import NewCohortModal from "./NewCohortModal";

export const CohortPage = () => {
  const cohortQuartzClient = React.useContext(CohortQuartzClientContext);
  const adminQuartzClient = React.useContext(AdminQuartzClientContext);
  const [openNewCohortModal, setOpenNewCohortModal] = React.useState(false);
  const [analyticsModal, setAnalyticsModal] = React.useState(false);
  const [refreshOmopLoading, setRefreshOmopLoading] = React.useState(false);
  const [cohorts, setCohorts] = React.useState<Cohort[] | null>(null);
  const [dbReport, setDbReport] = React.useState<OmopDbReport | null>(null);
  const [dbSize, setDbSize] = React.useState<number>(0);
  const [serverError, setServerError] = React.useState<string | null>("");
  const navigate = useNavigate();

  const handleCreateCohort = async (name: string, description: string, dbName: string) => {
    const body = { name: name, description: description, db_name: dbName } as InitCohort;
    try {
      const result = await cohortQuartzClient.postCohort(body);
      navigate(`/cohort/${result?.cohort_id}`);
    } catch (e: any) {
      setServerError(e.message);
    }
  };

  const handleRefreshOmopDb = async () => {
    const abordController = new AbortController();

    setRefreshOmopLoading(true);
    try {
      await cohortQuartzClient.refreshOmopData(abordController);
      getCohorts();
      getMainCohortStats();
    } catch (e: any) {
      setServerError(e.message);
    } finally {
      setRefreshOmopLoading(false);
    }

    return () => abordController.abort();
  };

  const getCohorts = React.useCallback(async () => {
    try {
      const result = await cohortQuartzClient.listCohorts();
      setCohorts(result);
    } catch (e: any) {
      setServerError(e.message);
    }
  }, [cohortQuartzClient]);

  const deleteCohort = async (ids: CohortId[]) => {
    const bundle = {
      entry: ids.map((id) => {
        return {
          request: {
            method: "DELETE",
            url: `Cohort/${id}`,
          },
        };
      }),
    } as BatchBundle;

    try {
      await adminQuartzClient.batchOperation(bundle);
    } catch (e: any) {
      setServerError(e.message);
    }
    getCohorts();
  };

  const getMainCohortStats = React.useCallback(async () => {
    setRefreshOmopLoading(true);
    try {
      const result = await cohortQuartzClient.getOmopDbReport();
      setDbSize(result.number_of_patient);
      setDbReport(result);
    } catch (e: any) {
      setServerError(e.message);
    } finally {
      setRefreshOmopLoading(false);
    }
  }, [cohortQuartzClient]);

  React.useEffect(() => {
    Promise.all([getCohorts(), getMainCohortStats()]);
  }, [getCohorts, getMainCohortStats]);

  return (
    <Box sx={{ p: 4, height: "94vh" }}>
      <HexagonBackground color="rgb(81, 188, 81)" />
      <NewCohortModal
        open={openNewCohortModal}
        setOpen={setOpenNewCohortModal}
        handleCreateCohort={handleCreateCohort}
      />
      <AnalyticsModal
        isOpen={analyticsModal}
        onClose={() => setAnalyticsModal(false)}
        dbReport={dbReport}
      />
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Typography level="h2">
                <TitleStylizedUnderline color="rgb(81, 188, 81)">Cohorts</TitleStylizedUnderline>
                <Chip sx={{ ml: 2 }} startDecorator={<GroupOutlinedIcon />}>
                  {dbSize} patient{dbSize > 2 ?? "s"}
                </Chip>
              </Typography>
              <Typography>
                Cohorts are groups of patients that share common characteristics.
              </Typography>
            </Box>
            <Stack direction="row" spacing={2}>
              <Button
                disabled={dbSize === 0}
                startDecorator={<AddIcon />}
                onClick={() => {
                  setOpenNewCohortModal(true);
                }}
                color="success"
              >
                Build a new cohort
              </Button>
              <Button
                onClick={() => {
                  setAnalyticsModal(true);
                }}
                disabled={dbSize === 0}
                startDecorator={<AnalyticsIcon />}
                color="success"
              >
                Overall analytics
              </Button>
              <Tooltip
                title="Data are refreshed automatically every 30 minutes but you can do it manually. All non-frozen cohort will be updated."
                placement="bottom-end"
              >
                <Button
                  onClick={() => {
                    handleRefreshOmopDb();
                  }}
                  startDecorator={refreshOmopLoading ? <CircularProgress /> : <AutorenewIcon />}
                  color="success"
                  disabled={refreshOmopLoading}
                >
                  Refresh data
                </Button>
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
        <Grid xs={12} sx={{ mt: 4 }}>
          {cohorts === null ? (
            <Box sx={{ height: "70vh", width: "100%", overflow: "hidden", position: "relative" }}>
              <TableSkeleton />
            </Box>
          ) : (
            <CohortList cohorts={cohorts} deleteCohort={deleteCohort} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
