import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useLocation } from "react-router-dom";
import { NoteId } from "../../models/note";
import { NoteProvider } from "./NoteProvider";
import { PatientProvider } from "./PatientProvider";
import { DrawerContent } from "./components/DrawerContent/DrawerContent";
import { NoteDisplay } from "./components/NoteDisplay";

export default function NoteById() {
  const location = useLocation();
  const note_id: string = location.pathname.split("/")[2];
  // const fact_id: string = location.pathname.split("/")[4];

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <NoteProvider note_id={note_id as NoteId}>
          <PatientProvider>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "30vw",
                  minWidth: "350px",
                  flexShrink: 0,
                  background: "white",
                  overflowY: "scroll",
                  height: "100vh",
                }}
              >
                <DrawerContent />
              </Box>
              <Box sx={{ overflowY: "scroll", height: "100vh", flex: 1 }}>
                <NoteDisplay />
              </Box>
            </Box>
          </PatientProvider>
        </NoteProvider>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          width: "100vw",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <PhonelinkEraseIcon sx={{ fontSize: "10rem" }} />
        <Typography component="div" level="title-lg">
          Page not optimized for small screen.
        </Typography>
        <Typography level="body-lg">Please use wider screen or zoom out.</Typography>
      </Box>
    </>
  );
}
