import Typography from "@mui/joy/Typography";

export default function TitleStylizedUnderline({
  children,
  offset = 0,
  color = "linear-gradient(to right, red, blue)",
}: {
  children: string;
  offset?: number;
  color?: string;
}) {
  return (
    <Typography
      sx={{
        position: "relative",
        ":after": {
          content: '""',
          position: "absolute",
          right: "-8px",
          bottom: offset + "px",
          width: "100%",
          height: "16px",
          background: color,
          opacity: 0.18,
          borderRadius: "2px",
          transform: "skew(-12deg) translateY(-2px)",
        },
      }}
    >
      {children}
    </Typography>
  );
}
