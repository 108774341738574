/**
 * We offer separate contexts for each API groups to ease mocking
 * and separation of concerns.
 */
import * as React from "react";
import { IdentityProviderClient } from "./cloud/auth";
import { SimpleAuthenticationService } from "./onprem/auth";
import {
  AccountLifecycleQuartzClient,
  AdminQuartzClient,
  AnalyticsQuartzClient,
  CohortQuartzClient,
  DocumentQuartzClient,
  NoteQuartzClient,
  PersonQuartzClient,
  QuestionAnsweringQuartzClient,
  StudyQuartzClient,
  TaskQuartzClient,
  TerminologyQuartzClient,
} from "./quartz";
import { UserInfoProvider } from "./user_info";

export const AccountLifecycleQuartzClientContext =
  React.createContext<AccountLifecycleQuartzClient>(
    null as unknown as AccountLifecycleQuartzClient
  );

export const TerminologyQuartzClientContext = React.createContext<TerminologyQuartzClient>(
  null as unknown as TerminologyQuartzClient
);

export const NoteQuartzClientContext = React.createContext<NoteQuartzClient>(
  null as unknown as NoteQuartzClient
);

export const DocumentQuartzClientContext = React.createContext<DocumentQuartzClient>(
  null as unknown as DocumentQuartzClient
);

export const TaskQuartzClientContext = React.createContext<TaskQuartzClient>(
  null as unknown as TaskQuartzClient
);

export const UserInfoProviderContext = React.createContext<UserInfoProvider>(
  null as unknown as UserInfoProvider
);

export const CohortQuartzClientContext = React.createContext<CohortQuartzClient>(
  null as unknown as CohortQuartzClient
);

export const PersonQuartzClientContext = React.createContext<PersonQuartzClient>(
  null as unknown as PersonQuartzClient
);

export const StudyQuartzClientContext = React.createContext<StudyQuartzClient>(
  null as unknown as StudyQuartzClient
);

export const AnalyticsClientContext = React.createContext<AnalyticsQuartzClient>(
  null as unknown as AnalyticsQuartzClient
);

export const QuestionAnsweringClientContext = React.createContext<QuestionAnsweringQuartzClient>(
  null as unknown as QuestionAnsweringQuartzClient
);

// Cloud-specific:
export const IdentityProviderClientContext = React.createContext<IdentityProviderClient>(
  null as unknown as IdentityProviderClient
);

// Onprem-specific:
export const SimpleAuthenticationServiceContext = React.createContext<SimpleAuthenticationService>(
  null as unknown as SimpleAuthenticationService
);

export const AdminQuartzClientContext = React.createContext<AdminQuartzClient>(
  null as unknown as AdminQuartzClient
);
