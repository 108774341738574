import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import DialogActions from "@mui/joy/DialogActions";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import Divider from "@mui/joy/Divider";
import LinearProgress from "@mui/joy/LinearProgress";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import Stack from "@mui/joy/Stack";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import React from "react";
import { AdminQuartzClientContext, NoteQuartzClientContext } from "../clients/contexts";
import AlertDialogModal from "../common/modals/AlertDialogModal";
import SnackbarNotification from "../common/SnackbarNotification";
import TitleStylizedUnderline from "../components/TitleStylizedUnderline";
import { PutUserRequest, Role, User } from "../models/auth";
import CreateUserForm from "./CreateUserForm";
import EditPasswordForm from "./EditPasswordForm";
import EditUserForm from "./EditUserForm";
import UsersTable from "./UsersTable";
import ListTasks from "./ListTasks";

export default function AdminPage() {
  // Contexts
  const adminClient = React.useContext(AdminQuartzClientContext);
  const noteClient = React.useContext(NoteQuartzClientContext);

  // States
  const [userChange, setUserChange] = React.useState(0);
  const [users, setUsers] = React.useState<User[]>([]);
  const [form, setForm] = React.useState<PutUserRequest>({
    credentials: {
      user: "",
      password: "",
    },
    attributes: {
      given_name: "",
      name: "",
      organization: "",
      role: Role.INTERNAL,
    },
  });
  const [createDialogOpen, setCreateDialogOpen] = React.useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState<boolean>(false);
  const [editPasswordDialogOpen, setEditPasswordDialogOpen] = React.useState<boolean>(false);
  const [deleteUserDialog, setDeleteUserDialog] = React.useState<boolean>(false);
  const [replayNonValidatedNotesDialog, setReplayNonValidatedNotesDialog] =
    React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const [linearProgress, setLinearProgress] = React.useState<boolean>(false);
  const [deletingUsername, setDeletingUsername] = React.useState<string | null>(null);
  const [serverError, setServerError] = React.useState<string | null>(null);

  const handleCreateSubmit = async () => {
    try {
      await adminClient.createUser(form);
      setCreateDialogOpen(false);
      setUserChange(userChange + 1);
    } catch (e: any) {
      console.log(e.message);
      setServerError(e.message);
    }
  };

  const handleEditSubmit = async () => {
    await adminClient.updateUser(form?.credentials.user, form?.attributes);
    setEditDialogOpen(false);
    setUserChange(userChange + 1);
  };

  const handleEditPasswordSubmit = async () => {
    await adminClient.updateUserPassword(form?.credentials.user, form?.credentials);
    setEditPasswordDialogOpen(false);
    setUserChange(userChange + 1);
  };

  const deleteUser = async (username: string) => {
    setDeletingUsername(username);
    setDeleteUserDialog(true);
  };

  const handleDeleteUser = async () => {
    await adminClient.deleteUser(deletingUsername!);
    setDeleteUserDialog(false);
    setDeletingUsername(null);
    setUserChange(userChange + 1);
  };

  const editUserAttributes = async (user: User) => {
    setForm({ credentials: { user: user.email, password: "" }, attributes: user.attributes });
    setEditDialogOpen(true);
  };

  const editUserPwd = async (user: User) => {
    setForm({ credentials: { user: user.email, password: "" }, attributes: user.attributes });
    setEditPasswordDialogOpen(true);
  };

  const createUser = async () => {
    setForm({
      credentials: {
        user: "",
        password: "",
      },
      attributes: {
        given_name: "",
        name: "",
        organization: "",
        role: Role.INTERNAL,
      },
    });
    setCreateDialogOpen(true);
  };

  const handleReplayNonValidatedNotes = async () => {
    setReplayNonValidatedNotesDialog(false);
    setLinearProgress(true);
    setProgress(0);
    try {
      await noteClient.runNLPBatch();
    } catch (e: any) {
      console.log(e.message);
      setServerError(e.message);
    } finally {
      setLinearProgress(false);
    }
  };

  React.useEffect(() => {
    async function fetchUsers() {
      try {
        const resp = await adminClient.listUsers();
        setUsers(resp);
      } catch (e: any) {
        console.log(e);
        if (e.name === "ForbiddenError") {
          setServerError("You are not authorized to view this page");
        } else {
          setServerError(e.message);
        }
      }
    }
    fetchUsers();
  }, [adminClient, userChange]);

  return (
    <Box sx={{ p: 4 }}>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <Stack
        sx={{ width: "100%", mb: 4 }}
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Typography level="h2" sx={{ display: "inline-block" }}>
          <TitleStylizedUnderline>Admin panel</TitleStylizedUnderline>
        </Typography>
      </Stack>
      <Tabs
        defaultValue={0}
        sx={{
          bgcolor: "transparent",
        }}
      >
        <TabList
          tabFlex={1}
          size="sm"
          sx={{
            justifyContent: "left",
            [`&& .${tabClasses.root}`]: {
              fontWeight: "600",
              flex: "initial",
              color: "text.tertiary",
              [`&.${tabClasses.selected}`]: {
                bgcolor: "transparent",
                color: "text.primary",
                "&::after": {
                  height: "2px",
                  bgcolor: "primary.500",
                },
              },
            },
          }}
        >
          <Tab sx={{ borderRadius: "6px 6px 0 0" }} value={0}>
            <ListItemDecorator>
              <GroupIcon fontSize="small" />
            </ListItemDecorator>
            Users
          </Tab>
          <Tab
            sx={{ borderRadius: "6px 6px 0 0", mr: 1, ml: 1 }}
            indicatorInset
            color="danger"
            value={4}
          >
            <ListItemDecorator>
              <WarningRoundedIcon fontSize="small" />
            </ListItemDecorator>
            Danger zone
          </Tab>
        </TabList>
        <TabPanel value={0}>
          <Button startDecorator={<AddIcon />} onClick={() => createUser()} sx={{ mb: 1 }}>
            Add a user
          </Button>
          {users?.length === 0 ? (
            <Typography>No users found</Typography>
          ) : (
            <UsersTable
              users={users}
              editUserAttributes={editUserAttributes}
              editUserPwd={editUserPwd}
              deleteUser={deleteUser}
            />
          )}
        </TabPanel>
        <TabPanel value={4}>
          <Button color="danger" onClick={() => setReplayNonValidatedNotesDialog(true)}>
            Replay all non validated notes
          </Button>
          <ListTasks />
          {linearProgress && (
            <Box
              sx={{
                bgcolor: "white",
                width: "100%",
                mt: 2,
              }}
            >
              <LinearProgress
                determinate
                variant="outlined"
                color="neutral"
                size="sm"
                thickness={32}
                value={progress}
                sx={{
                  "--LinearProgress-radius": "0px",
                  "--LinearProgress-progressThickness": "24px",
                  boxShadow: "sm",
                  borderColor: "neutral.500",
                }}
              >
                <Typography
                  level="body-xs"
                  fontWeight="xl"
                  textColor="common.white"
                  sx={{ mixBlendMode: "difference" }}
                >
                  LOADING… {`${Math.round(progress)}%`}
                </Typography>
              </LinearProgress>
            </Box>
          )}
        </TabPanel>
      </Tabs>

      <AlertDialogModal
        alertMessage="Are you sure you want to replay all non validated notes? It might take a while."
        open={replayNonValidatedNotesDialog}
        setOpen={setReplayNonValidatedNotesDialog}
        handleDelete={handleReplayNonValidatedNotes}
      />

      <Modal open={createDialogOpen} onClose={() => setCreateDialogOpen(false)}>
        <ModalOverflow>
          <ModalDialog sx={{ width: "500px" }}>
            <ModalClose />
            <DialogTitle>Create new user</DialogTitle>
            <CreateUserForm form={form} setForm={setForm} handleCreateSubmit={handleCreateSubmit} />
          </ModalDialog>
        </ModalOverflow>
      </Modal>
      <Modal open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <ModalOverflow>
          <ModalDialog sx={{ width: "500px" }}>
            <ModalClose />
            <DialogTitle>Edit {form.credentials.user}</DialogTitle>
            <EditUserForm form={form} setForm={setForm} handleEditSubmit={handleEditSubmit} />
          </ModalDialog>
        </ModalOverflow>
      </Modal>
      <Modal open={editPasswordDialogOpen} onClose={() => setEditPasswordDialogOpen(false)}>
        <ModalDialog>
          <DialogTitle>Edit password for {form.credentials.user}</DialogTitle>
          <EditPasswordForm
            form={form}
            setForm={setForm}
            handleEditPasswordSubmit={handleEditPasswordSubmit}
          />
        </ModalDialog>
      </Modal>
      <Modal open={deleteUserDialog} onClose={() => setDeleteUserDialog(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            Are you sure you want to delete {deletingUsername}? All the user's data will be lost in
            3 weeks.
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDeleteUser()}>
              Delete user
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => {
                setDeleteUserDialog(false);
                setDeletingUsername(null);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Box>
  );
}
