import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FaceIcon from "@mui/icons-material/Face";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import Dropdown from "@mui/joy/Dropdown";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton/ListItemButton";
import listItemButtonClasses from "@mui/joy/ListItemButton/listItemButtonClasses";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { Role } from "../../models/auth";
import { UserContext } from "../../admin/UserProvider";
import logo from "./../../assets/logo_transparent_background.png";
import React from "react";

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = React.useContext(UserContext);

  return (
    <Stack
      sx={{
        position: "fixed",
        height: "100vh",
        borderRight: "1px solid",
        borderRightColor: "divider",
        backgroundColor: "background.surface",
      }}
      direction="column"
      justifyContent="space-between"
    >
      <Box
        sx={{
          width: "240px",
          flexShrink: 0,
          p: 2,
        }}
      >
        {user && (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="column">
              <Typography level="title-lg">
                {user.attributes.given_name + " " + user.attributes.name[0] + "."}
              </Typography>
              <Typography level="body-sm">{user.attributes.organization}</Typography>
            </Stack>
            <Dropdown>
              <MenuButton size="sm" sx={{ width: "32px" }}>
                <ArrowDropDownIcon />
              </MenuButton>

              <Menu>
                <MenuItem
                  onClick={() => {
                    navigate("/user/profile");
                  }}
                >
                  <FaceIcon fontSize="inherit" />
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/auth/logout");
                  }}
                >
                  <LogoutIcon fontSize="inherit" />
                  Logout
                </MenuItem>
              </Menu>
            </Dropdown>
          </Stack>
        )}

        <Divider sx={{ mt: 2 }} />

        <Box
          sx={{
            mt: 2,
            minHeight: 0,
            overflow: "hidden auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            [`& .${listItemButtonClasses.root}`]: {
              gap: 1.5,
            },
          }}
        >
          <List
            size="sm"
            sx={{
              gap: 1,
              "--ListItem-radius": (theme) => theme.vars.radius.sm,
            }}
          >
            <ListItem
              sx={{
                ...(location.pathname === "/patients" ? { background: "#e6edf8" } : {}),
                width: "100%",
              }}
            >
              <ListItemButton onClick={() => navigate("/patients")}>
                <ListItemDecorator>
                  <PersonOutlineIcon />
                </ListItemDecorator>
                <ListItemContent>Patients</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem
              sx={{
                ...(location.pathname === "/cohorts" ? { background: "#e6edf8" } : {}),
              }}
            >
              <ListItemButton onClick={() => navigate("/cohorts")}>
                <ListItemDecorator>
                  <GroupOutlinedIcon />
                </ListItemDecorator>
                <ListItemContent>Cohorts</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem
              sx={{
                ...(location.pathname === "/notes" ? { background: "#e6edf8" } : {}),
              }}
            >
              <ListItemButton onClick={() => navigate("/notes")}>
                <ListItemDecorator>
                  <NotesOutlinedIcon />
                </ListItemDecorator>
                <ListItemContent>Notes</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem
              sx={{
                ...(location.pathname === "/studies" ? { background: "#e6edf8" } : {}),
              }}
            >
              <ListItemButton onClick={() => navigate("/studies")}>
                <ListItemDecorator>
                  <FolderOutlinedIcon />
                </ListItemDecorator>
                <ListItemContent>Studies</ListItemContent>
              </ListItemButton>
            </ListItem>
            {window.CONFIG.auth.kind === "SIMPLE" && // only on onprem
            user &&
            user?.attributes.role === Role.ADMIN ? (
              <>
                <ListItem
                  sx={{
                    ...(location.pathname === "/admin" ? { background: "#e6edf8" } : {}),
                  }}
                >
                  <ListItemButton onClick={() => navigate("/admin")}>
                    <ListItemDecorator>
                      <SettingsSuggestIcon />
                    </ListItemDecorator>
                    <ListItemContent>Admin panel</ListItemContent>
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              <></>
            )}
          </List>
        </Box>
      </Box>
      <Box
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        <img alt="Gimli" style={{ height: "84px" }} src={logo} />
      </Box>
    </Stack>
  );
};
