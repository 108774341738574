import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import React from "react";
import { Cohort, CohortId } from "../../models/cohort";
import { PutStudyRequest } from "../../models/study";
import FormHelperText from "@mui/joy/FormHelperText";

interface NewStudyModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleCreateStudy: (putStudyRequest: PutStudyRequest) => void;
  cohorts: Cohort[];
}

export default function NewStudyModal({
  open,
  setOpen,
  handleCreateStudy,
  cohorts,
}: NewStudyModalProps) {
  const [studyRequest, setStudyRequest] = React.useState<PutStudyRequest>({
    title: "",
    acronym: "",
    cohort_id: null,
  });

  const handleCohortLink = (event: React.SyntheticEvent | null, cohortId: CohortId | null) => {
    setStudyRequest({ ...studyRequest, cohort_id: cohortId });
  };
  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <ModalClose />
          <DialogTitle>Create a new study</DialogTitle>
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              handleCreateStudy(studyRequest);
              setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Acronym</FormLabel>
                <Input
                  value={studyRequest.acronym ? studyRequest.acronym : ""}
                  onChange={(event) =>
                    setStudyRequest({ ...studyRequest, acronym: event.target.value })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Textarea
                  value={studyRequest.title}
                  onChange={(event) =>
                    setStudyRequest({ ...studyRequest, title: event.target.value })
                  }
                  minRows={3}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Link with an existing cohort</FormLabel>
                <Select
                  onChange={handleCohortLink}
                  disabled={cohorts.length === 0}
                  placeholder={
                    cohorts.length === 0 ? "You have no cohort." : "Select a cohort (optional)"
                  }
                >
                  {cohorts.map((cohort) => (
                    <Option key={cohort.cohort_id} value={cohort.cohort_id}>
                      {cohort.name}
                    </Option>
                  ))}
                </Select>
                <FormHelperText>
                  All patients <b>included</b> in the cohort will be added as{" "}
                  <b>potential candidates</b> in the study.
                </FormHelperText>
              </FormControl>
              <Button type="submit">Create</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
