import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import React from "react";
import { StudyQuartzClientContext } from "../../clients/contexts";
import { PutStudyRequest, Study, StudyDesign, StudyPhase } from "../../models/study";

interface EditStudyModalProps {
  onClose: () => void;
  refresh: () => void;
  study: Study;
}

export default function EditStudyModal({ onClose, refresh, study }: EditStudyModalProps) {
  const studyQuartzClient = React.useContext(StudyQuartzClientContext);
  const [studyRequest, setStudyRequest] = React.useState<PutStudyRequest>({
    title: study.title,
    acronym: study.acronym,
    cohort_id: study.cohort_id,
    sponsor: study.associated_parties.filter((party) => party.role === "sponsor")[0]?.name,
    principal_investigator: study.associated_parties.filter(
      (party) => party.role === "principal-investigator"
    )[0]?.name,
    description: study.description,
    study_design: study.study_design,
    phase: study.phase,
  });

  const handleEditStudy = async () => {
    await studyQuartzClient.updateStudy(study.study_id, studyRequest);
    refresh();
    onClose();
  };

  return (
    <Modal open onClose={() => onClose()}>
      <ModalOverflow>
        <ModalDialog sx={{ width: "500px" }}>
          <ModalClose />
          <DialogTitle>
            Edit study:{" "}
            <Typography level="inherit" color="neutral">
              {study.acronym}
            </Typography>
          </DialogTitle>
          <FormControl>
            <FormLabel>Acronym</FormLabel>
            <Input
              value={studyRequest.acronym ? studyRequest.acronym : ""}
              onChange={(event) =>
                setStudyRequest({ ...studyRequest, acronym: event.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Textarea
              value={studyRequest.title}
              onChange={(event) => setStudyRequest({ ...studyRequest, title: event.target.value })}
              minRows={3}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              value={studyRequest.description ? studyRequest.description : ""}
              onChange={(event) =>
                setStudyRequest({ ...studyRequest, description: event.target.value })
              }
              minRows={3}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Sponsor</FormLabel>
            <Input
              value={studyRequest.sponsor ? studyRequest.sponsor : ""}
              onChange={(event) =>
                setStudyRequest({ ...studyRequest, sponsor: event.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Principal investigator</FormLabel>
            <Input
              value={studyRequest.principal_investigator ? studyRequest.principal_investigator : ""}
              onChange={(event) =>
                setStudyRequest({ ...studyRequest, principal_investigator: event.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Phase</FormLabel>
            <Select
              defaultValue={study.phase}
              onChange={(event, newValue) =>
                setStudyRequest({ ...studyRequest, phase: newValue as StudyPhase })
              }
            >
              <Option value="n-a">N/A</Option>
              <Option value="early-phase-1">Early Phase 1</Option>
              <Option value="phase-1">Phase 1</Option>
              <Option value="phase-1-phase-2">Phase 1/2</Option>
              <Option value="phase-2">Phase 2</Option>
              <Option value="phase-2-phase-3">Phase 2/3</Option>
              <Option value="phase-3">Phase 3</Option>
              <Option value="phase-4">Phase 4</Option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Study design</FormLabel>
            <Select
              multiple
              defaultValue={study.study_design}
              onChange={(event, selected) =>
                setStudyRequest({ ...studyRequest, study_design: selected as StudyDesign[] })
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", gap: "0.25rem" }}>
                  {selected.map((selectedOption) => (
                    <Chip variant="soft" color="primary">
                      {selectedOption.label}
                    </Chip>
                  ))}
                </Box>
              )}
              sx={{
                minWidth: "15rem",
              }}
              slotProps={{
                listbox: {
                  sx: {
                    width: "100%",
                  },
                },
              }}
            >
              <Option value="observational">Observational</Option>
              <Option value="interventional">Interventional</Option>
              <Option value="retropsective">Retropsective</Option>
              <Option value="prospective">Prospective</Option>
              <Option value="randomized">Randomized</Option>
              <Option value="non-randomized">Non randomized</Option>
              <Option value="real-world-data">Real world data</Option>
            </Select>
          </FormControl>
          <Stack sx={{ mt: 2 }} direction="row" gap={1} justifyContent="flex-end">
            <Button color="primary" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button color="success" onClick={() => handleEditStudy()}>
              Update
            </Button>
          </Stack>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
