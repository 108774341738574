import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { IdentityProviderClientContext } from "../../clients/contexts";

export const Logout: React.FC<{}> = () => {
  const identityProviderClient = useContext(IdentityProviderClientContext);
  React.useEffect(() => {
    identityProviderClient.signOut();
  }, [identityProviderClient]);
  if (!identityProviderClient.isAuthenticated()) {
    return <Navigate to="/auth/signin" />;
  }
  return <div style={{ padding: "1rem" }}>Logging out...</div>;
};
