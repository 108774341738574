import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { keyframes } from "@mui/system";
import { useEffect, useState } from "react";
import proSanteConnectLogo from "../assets/ProSanteConnect_sidentifier_BLEU.svg";
import gimliLogoBlue from "../assets/logo_blue.png";
import starWhite from "../assets/star_white.svg";
import { MFAType } from "../models/auth";
import LoadingButton from "./LoadingButton";
import MFAChallenge from "./MFAChallenge";
import PasswordResetForm from "./PasswordResetForm";

export interface EmailCheckNotification {
  error: boolean;
  message: string;
}

export default function AuthPage({
  loading,
  onSubmit,
  errors,
  register,
  cloud = false,
  hidePSCButton = false,
  pscConfig,
  mfaType,
  email_check_notification = null,
}: {
  loading: boolean;
  onSubmit: () => void;
  errors: any;
  register: any;
  cloud?: boolean;
  hidePSCButton?: boolean;
  pscConfig?: any;
  mfaType?: MFAType;
  email_check_notification?: EmailCheckNotification | null;
}) {
  const [tab, setTab] = useState(0);
  const [secondPage, setSecondPage] = useState<MFAType | "passwordReset" | null>(mfaType || null);
  const [notification, setNotification] = useState<EmailCheckNotification | null>(
    email_check_notification || null
  );

  const shakeAnimation = keyframes`
      0% { transform: translateX(0); }
      10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
      20%, 40%, 60%, 80% { transform: translateX(10px); }
      100% { transform: translateX(0); }
  `;

  const initResetPassword = () => {
    setTab(1);
    setSecondPage("passwordReset");
  };

  useEffect(() => {
    if (mfaType) {
      setTab(1);
      setSecondPage(mfaType);
    }
  }, [mfaType]);

  useEffect(() => {
    if (tab !== 0) {
      setNotification(null);
    } else {
      setNotification(email_check_notification);
    }
  }, [tab, email_check_notification, notification]);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: "center",
          backgroundColor: "HSLA(210,89%,41%,1)",
          backgroundImage: `
          radial-gradient(at 10% 43%, hsla(235,98%,71%,1) 0px, transparent 50%),
          radial-gradient(at 41% 25%, hsla(28,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 0% 0%, hsla(210,89%,41%,1) 0px, transparent 50%),
          radial-gradient(at 80% 0%, hsla(210,74%,76%,1) 0px, transparent 50%),
          radial-gradient(at 0% 50%, hsla(355,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 73% 58%, hsla(340,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 0% 100%, hsla(22,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 80% 100%, hsla(242,100%,65%,1) 0px, transparent 50%);
            `,
        }}
      >
        {notification && (
          <Alert
            startDecorator={notification.error ? <ErrorIcon /> : <CheckIcon />}
            sx={{ width: { xs: "100%", sm: "400px" } }}
            color={notification.error ? "danger" : "success"}
          >
            {notification.message}
          </Alert>
        )}
        <Box
          sx={{
            display: { xs: "none", md: "block" },
            position: "absolute",
            height: "100%",
            width: "100%",
            aspectRatio: "1/1",
            backgroundPosition: "center",
            backgroundImage: `url(${starWhite})`,
            backgroundSize: "contain",
            filter: "blur(17px)",
            backgroundRepeat: "no-repeat",
          }}
        />
        <Box
          sx={{
            zIndex: 1,
            width: { xs: "100%", sm: "400px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            background: "white",
            p: { xs: 2, sm: 4 },
            borderRadius: { xs: 0, sm: "8px" },
            transition: "all .33s ease-out",
            height: { xs: "100vh", sm: "initial" },
            maxHeight: { xs: "100vh", sm: tab === 0 ? "700px" : "500px" },
            animation: Object.keys(errors).length > 0 ? `${shakeAnimation} 0.5s linear` : "none",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <img src={gimliLogoBlue} alt="logo" style={{ width: "200px" }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              width: "100%",
            }}
          >
            <Box
              sx={{
                //transform: `translateX(-${String(tab * 100)}%)`,
                opacity: tab !== 0 ? "0" : "1",
                transition: "all 0.33s ease",
                flexShrink: "0",
                width: "100%",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <FormControl disabled={loading} error={!!errors.email}>
                  <FormLabel>Email</FormLabel>
                  <Input {...register("email", { required: "Field required." })} type="email" />
                  <FormHelperText>{errors.email?.message}</FormHelperText>
                </FormControl>
                <FormControl disabled={loading} sx={{ mt: 2 }} error={!!errors.password}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    {...register("password", { required: "Field required." })}
                    type="password"
                  />
                  <FormHelperText>{errors.password?.message}</FormHelperText>
                </FormControl>
                <Stack gap={2}>
                  {cloud && (
                    <Box
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      {/* TODO REMEMBER <Checkbox size="sm" label="Remember me" name="persistent" /> */}
                      <Link level="body-sm" onClick={() => initResetPassword()}>
                        Forgot your password?
                      </Link>
                    </Box>
                  )}
                  <LoadingButton
                    loading={loading}
                    sx={{ mt: 2 }}
                    type="submit"
                    onClick={() => onSubmit()}
                    fullWidth
                    onKeyUp={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                      if (e.key === "Enter" && tab === 0) {
                        onSubmit();
                      }
                    }}
                  >
                    Sign in
                  </LoadingButton>
                  {cloud && (
                    <Box sx={{ textAlign: "center" }}>
                      <Link level="title-sm" href="/auth/signup">
                        Don't have an account? Open an account
                      </Link>
                    </Box>
                  )}
                </Stack>
                {cloud && !hidePSCButton && (
                  <>
                    <Divider sx={{ my: 4 }} />
                    <Box
                      sx={{
                        textAlign: "center",
                        transition: "all .33s ease-out",
                        ":hover": { transform: "scale(1.05)" },
                      }}
                    >
                      <a
                        href={
                          pscConfig.url +
                          "/auth?response_type=code&scope=openid scope_all&client_id=" +
                          pscConfig.client_id +
                          "&redirect_uri=" +
                          pscConfig.redirect_uri +
                          "&acr_values=eidas1"
                        }
                      >
                        <img
                          src={proSanteConnectLogo}
                          alt="logo pro sante connect"
                          style={{ width: "260px" }}
                        />
                      </a>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                transform: `translateX(-${tab * 100}%)`,
                transition: "transform 0.33s ease",
                flexShrink: "0",
                width: "100%",
              }}
            >
              {secondPage === "passwordReset" ? (
                <PasswordResetForm goBack={() => setTab(0)} />
              ) : secondPage === MFAType.SMS_MFA ? (
                <MFAChallenge description="We just sent an SMS on your phone. Please enter the code to continue." />
              ) : secondPage === MFAType.SOFTWARE_TOKEN_MFA ? (
                <MFAChallenge description="Please enter the code from your authenticator app to continue." />
              ) : null}
            </Box>
          </Box>
          <Box component="footer" sx={{ mt: 4 }}>
            <Typography level="body-xs" textAlign="center">
              ©{" "}
              <Link component="a" href="https://www.gimli-med.io/">
                Gimli
              </Link>{" "}
              {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
