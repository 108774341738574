import Box from "@mui/joy/Box";
import Drawer from "@mui/joy/Drawer";
import illustrationProfile from "../../assets/illustration-profile.png";
import { MFAType } from "../../models/auth";
import { UserContext } from "../../admin/UserProvider";
import { unknownType } from "../../utils/assert";
import { MFAConfiguration } from "./MFAConfiguration";
import MFAConfigurationSMS from "./MFAConfigurationSMS";
import MFAConfigurationSMS2 from "./MFAConfigurationSMS2";
import MFAConfigurationSoftwareToken from "./MFAConfigurationSoftwareToken";
import { UserProfileAccountLifecycle } from "./UserProfileAccountLifecycle";
import { UserProfileAttributes } from "./UserProfileAttributes";
import { UserProfileChangePassword } from "./UserProfileChangePassword";
import React from "react";

const enum DrawerContent {
  SMS_MFA_SETUP,
  SMS_MFA_SETUP_2,
  SOFTWARE_TOKEN_MFA_SETUP,
}

export default function UserProfilePage() {
  const { user, refreshUser } = React.useContext(UserContext);

  const [drawerContent, setDrawerContent] = React.useState<DrawerContent | null>(null);

  const initiateMFAConfiguration = (mfaType: MFAType) => {
    switch (mfaType) {
      case MFAType.SMS_MFA:
        setDrawerContent(DrawerContent.SMS_MFA_SETUP);
        break;
      case MFAType.SOFTWARE_TOKEN_MFA:
        setDrawerContent(DrawerContent.SOFTWARE_TOKEN_MFA_SETUP);
        break;
      default:
        unknownType(mfaType);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Box sx={{ p: 4 }}>
      <div className="columns">
        <div className="column is-half">
          <div className="content">
            <UserProfileAttributes />
            <UserProfileChangePassword />
            <MFAConfiguration
              initiateConfiguration={initiateMFAConfiguration}
              user={user}
              refreshUser={() => refreshUser()}
            />
            <UserProfileAccountLifecycle />
          </div>
        </div>
        <div className="column is-4 is-offset-1">
          <figure className="image">
            <img src={illustrationProfile} alt="illsutration profile" />
          </figure>
        </div>
        <Drawer anchor="right" open={drawerContent != null} onClose={() => setDrawerContent(null)}>
          {drawerContent === DrawerContent.SMS_MFA_SETUP && (
            <MFAConfigurationSMS
              onClose={(submitted) => {
                setDrawerContent(null);
                if (submitted) {
                  refreshUser();
                }
              }}
              onNext={() => {
                setDrawerContent(DrawerContent.SMS_MFA_SETUP_2);
              }}
            />
          )}
          {drawerContent === DrawerContent.SMS_MFA_SETUP_2 && (
            <MFAConfigurationSMS2
              onClose={(submitted) => {
                setDrawerContent(null);
                if (submitted) {
                  refreshUser();
                }
              }}
            />
          )}
          {drawerContent === DrawerContent.SOFTWARE_TOKEN_MFA_SETUP && (
            <MFAConfigurationSoftwareToken
              onClose={(submitted) => {
                setDrawerContent(null);
                if (submitted) {
                  refreshUser();
                }
              }}
            />
          )}
        </Drawer>
      </div>
    </Box>
  );
}
