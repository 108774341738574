import { Link, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div className="container is-fluid" id="error-page">
      <section className="section">
        <div className="container has-text-centered">
          <div className="columns is-centered">
            <div className="column is-7">
              <h1 className="title is-1">404</h1>
              <p className="subtitle is-3">Page not found</p>
              <p>Sorry, it seems like this page does not exist.</p>
            </div>
          </div>
          <Link className="button is-primary" to="/">
            Back to homepage
          </Link>
        </div>
      </section>
    </div>
  );
}
