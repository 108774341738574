import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { IdentityProviderClientContext } from "../clients/contexts";
import LoadingButton from "./LoadingButton";
import TitleStylizedUnderline from "./TitleStylizedUnderline";

export default function MFAChallenge({ description }: { description: string }) {
  const identityProviderClient = useContext(IdentityProviderClientContext);
  const [serverError, setServerError] = useState<string | null>("");
  const [code, setCode] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (code) {
      setLoading(true);
      try {
        await identityProviderClient.confirmSignIn(code);
      } catch (err: any) {
        if (err.code === "CodeMismatchException") {
          setLoading(false);
          setServerError("Invalid code.");
          return;
        } else if (err.code === "ExpiredCodeException") {
          setServerError("Code expired. Please try again.");
          return;
        }
        throw err;
      }
      navigate("/");
    } else {
      setServerError("You must provide a code.");
    }
  };

  return (
    <Box>
      <Stack
        sx={{ mt: 2, textAlign: "center", transition: "all .33s ease-out" }}
        spacing={serverError ? 2 : 4}
      >
        <Typography level="h2">
          <TitleStylizedUnderline>One more step</TitleStylizedUnderline>
        </Typography>
        {serverError && <Alert color="danger">{serverError}</Alert>}
        <Typography level="body-md">{description}</Typography>
        <Input
          size="lg"
          value={code ? code : ""}
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
        <LoadingButton
          loading={loading}
          type="submit"
          onClick={() => handleSubmit()}
          fullWidth
          onKeyUp={(e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        >
          Send code
        </LoadingButton>
      </Stack>
    </Box>
  );
}
