import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Gender, Person, PersonId } from "../../models/person";
import { PatientContext, useNote } from "./lib/context";
import { PersonQuartzClientContext } from "../../clients/contexts";
import { Fact } from "../../models/fact";

type PatientProviderProps = {
  children: ReactNode;
};

export const PatientProvider: React.FC<PatientProviderProps> = ({ children }) => {
  const personQuartzClient = useContext(PersonQuartzClientContext);
  const { note, setNote } = useNote();

  const defaultPatient = useMemo(
    () => ({
      person_id: "" as PersonId,
      family_name: note?.facts.find((fact: Fact) => fact.code.coding[0].code === "FAMILY_NAME")
        ?.code.text,
      given_name: note?.facts.find((fact: Fact) => fact.code.coding[0].code === "GIVEN_NAME")?.code
        .text,
      birth_date: null,
      gender: note?.facts.find((fact: Fact) => fact.code.coding[0].code === "GENDER")
        ?.value as Gender,
      deceased: false,
      deceased_date: null,
      external_ids: [],
      consent_withdrawal_date: null,
    }),
    [note]
  );
  const [patient, setPatientState] = useState<Person>(defaultPatient);

  const fetchPatient = useCallback(
    async (personId: PersonId | null | undefined) => {
      if (personId) {
        try {
          const fetchedPatient = await personQuartzClient.getPersonById(personId);
          setPatientState(fetchedPatient);
        } catch (error) {
          console.error("Failed to fetch patient:", error);
        }
      } else {
        setPatientState(defaultPatient);
      }
    },
    [personQuartzClient, setPatientState, defaultPatient]
  );

  const linkPatient = useCallback(
    (patientId: PersonId) => {
      if (note) {
        setNote({
          ...note,
          person_id: patientId,
        });
        fetchPatient(patientId);
      }
    },
    [setNote, note, fetchPatient]
  );

  useEffect(() => {
    fetchPatient(note?.person_id);
  }, [note?.note_id, note?.person_id, fetchPatient]);

  const setPatient = useCallback(
    (newPatient: Person) => {
      const updatePatient = async () => {
        try {
          if (newPatient.person_id) {
            const updatedPatient = await personQuartzClient.putPersonById(
              newPatient.person_id,
              newPatient
            );
            setPatientState(updatedPatient);
          }
        } catch (error) {
          console.error("Failed to update patient:", error);
        }
      };

      updatePatient();
    },
    [setPatientState, personQuartzClient]
  );

  const createPatient = useCallback(
    (newPatient: Person) => {
      const createNewPatient = async () => {
        try {
          const fetchedPatient = await personQuartzClient.postNewPerson(newPatient);
          setPatientState(fetchedPatient);
          linkPatient(fetchedPatient.person_id);
        } catch (error) {
          console.error("Failed to create new patient:", error);
        }
      };

      createNewPatient();
    },
    [setPatientState, personQuartzClient, linkPatient]
  );

  return (
    <PatientContext.Provider value={{ patient, setPatient, createPatient, linkPatient }}>
      {children}
    </PatientContext.Provider>
  );
};
