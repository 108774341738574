import React from "react";

export function useOutsideAlerter(
  refs: React.RefObject<HTMLElement>[],
  onClickOutside: () => void
) {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      let count = 0;
      for (const ref of refs) {
        if (ref.current && event.target !== null && !ref.current.contains(event.target as any)) {
          ++count;
        }
      }
      if (count === refs.length) {
        onClickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs, onClickOutside]);
}
