import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import logo from "../../assets/logo_blue.png";
import { IdentityProviderClientContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";
import LoadingButton from "../../components/LoadingButton";
import TitleStylizedUnderline from "../../components/TitleStylizedUnderline";
import { Role } from "../../models/auth";
import { PendingEmailValidation } from "./PendingEmailValidation";

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  organization: string;
  password: string;
  passwordVerification: string;
  role: Role;
};

export default function SignUpPage() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const identityProviderClient = React.useContext(IdentityProviderClientContext);
  const [serverError, setServerError] = React.useState<string | null>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();
  const [tab, setTab] = React.useState(0);

  const [email, setEmail] = React.useState<string>("");
  const [username, setUsername] = React.useState<string>("");

  const onSubmit = handleSubmit(async (data: FormValues) => {
    if (data.password !== data.passwordVerification) {
      setError("passwordVerification", { message: "Passwords do not match." });
      return;
    }
    setLoading(true);
    let username: string;
    try {
      username = await identityProviderClient.signUp(data.email, data.password, {
        name: data.lastName,
        given_name: data.firstName,
        organization: data.organization,
        role: data.role,
      });
    } catch (error: any) {
      if (error.code === "InvalidPasswordException") {
        setLoading(false);
        setError("password", { message: "Password does not meet requirements." });
        return;
      }
      setLoading(false);
      setServerError("Could not sign up");
      return;
    }
    setEmail(data.email);
    setUsername(username);
    setTab(1);
  });

  if (identityProviderClient.isAuthenticated()) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "HSLA(210,89%,41%,1)",
        backgroundImage: `
          radial-gradient(at 10% 43%, hsla(235,98%,71%,1) 0px, transparent 50%),
          radial-gradient(at 41% 25%, hsla(28,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 0% 0%, hsla(210,89%,41%,1) 0px, transparent 50%),
          radial-gradient(at 80% 0%, hsla(210,74%,76%,1) 0px, transparent 50%),
          radial-gradient(at 0% 50%, hsla(355,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 73% 58%, hsla(340,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 0% 100%, hsla(22,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 80% 100%, hsla(242,100%,65%,1) 0px, transparent 50%);
            `,
      }}
    >
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: { xs: "100%", lg: "100vh" },
          width: { xs: "100%", lg: "50vw" },
          background: "white",
        }}
      >
        <Box sx={{ mt: { xs: 6, md: 4, lg: 2 } }}>
          <img src={logo} alt="Gimli" style={{ width: "250px" }} />
        </Box>
        <Box
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflow: "hidden",
            maxWidth: "600px",
            p: 2,
          }}
        >
          <Box
            sx={{
              //transform: `translateX(-${String(tab * 100)}%)`,
              opacity: tab !== 0 ? "0" : "1",
              transition: "all 0.33s ease",
              flexShrink: "0",
              width: "100%",
            }}
          >
            <Typography sx={{ mb: { xs: 2, lg: 4 } }} level="h2" textAlign="center">
              <TitleStylizedUnderline>Sign up to Gimli</TitleStylizedUnderline>
            </Typography>

            <Stack direction="column" spacing={{ xs: 1.5, lg: 2 }} sx={{ mt: 2 }}>
              <FormControl disabled={loading} error={!!errors.organization}>
                <FormLabel>Organization</FormLabel>
                <Input
                  startDecorator={<CorporateFareIcon />}
                  {...register("organization", { required: "Field required." })}
                  placeholder="Enter organization name"
                  fullWidth
                />
                <FormHelperText>{errors.organization?.message}</FormHelperText>
              </FormControl>

              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <FormControl disabled={loading} sx={{ flex: 1 }} error={!!errors.firstName}>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    startDecorator={<PersonIcon />}
                    {...register("firstName", { required: "Field required." })}
                    placeholder="Enter your first name"
                    fullWidth
                  />
                  <FormHelperText>{errors.firstName?.message}</FormHelperText>
                </FormControl>

                <FormControl disabled={loading} sx={{ flex: 1 }} error={!!errors.lastName}>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    startDecorator={<PersonIcon />}
                    {...register("lastName", { required: "Field required." })}
                    placeholder="Enter your last name"
                    fullWidth
                  />
                  <FormHelperText>{errors.lastName?.message}</FormHelperText>
                </FormControl>
              </Stack>

              <FormControl disabled={loading} error={!!errors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  startDecorator={<EmailIcon />}
                  {...register("email", {
                    required: "Field required.",
                    pattern: { value: /^\S+@\S+$/i, message: "Invalid email address." },
                  })}
                  placeholder="Enter your email"
                  fullWidth
                />
                <FormHelperText>{errors.email?.message}</FormHelperText>
              </FormControl>

              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <FormControl disabled={loading} sx={{ flex: 1 }} error={!!errors.password}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    startDecorator={<LockIcon />}
                    type="password"
                    {...register("password", { required: "Field required." })}
                    placeholder="Enter your password"
                  />
                  <FormHelperText>{errors.password?.message}</FormHelperText>
                </FormControl>

                <FormControl
                  disabled={loading}
                  sx={{ flex: 1 }}
                  error={!!errors.passwordVerification}
                >
                  <FormLabel>Confirm Password</FormLabel>
                  <Input
                    startDecorator={<LockIcon />}
                    type="password"
                    {...register("passwordVerification", { required: "Field required." })}
                    placeholder="Confirm your password"
                  />
                  <FormHelperText>{errors.passwordVerification?.message}</FormHelperText>
                </FormControl>
              </Stack>

              <Typography level="body-sm">
                Your password must have at least 8 characters. It must contain at least one
                uppercase letter, one lowercase letter, one number, and one special character.
              </Typography>

              <LoadingButton
                loading={loading}
                type="submit"
                onClick={onSubmit}
                fullWidth
                onKeyUp={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              >
                Sign up
              </LoadingButton>

              <Divider />

              <Typography level="body-md" textAlign="center">
                Already have an account? <Link to="/auth/signin">Sign in</Link>
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              transform: `translateX(-${tab * 100}%)`,
              transition: "transform 0.33s ease",
              flexShrink: "0",
              width: "100%",
            }}
          >
            <PendingEmailValidation email={email} username={username} />
          </Box>
        </Box>
        <Box>
          <Typography sx={{ mb: 2 }} level="body-sm">
            © Gimli {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", lg: "flex" },
          justifyContent: "center",
          alignItems: "center",
          width: "50vw",
          height: "100vh",
        }}
      >
        <Box sx={{ maxWidth: "650px", p: 4, m: 4, borderRadius: "8px", background: "white" }}>
          <Typography level="title-lg" mb={2}>
            <TitleStylizedUnderline offset={-4}>Welcome to GIMLI!</TitleStylizedUnderline>
          </Typography>
          <Typography level="body-md">
            We are excited to have you on board as a new user. Please keep in mind that all types of
            feedback are more than welcome. This app is for research purposes only and should not be
            used to support patient care.
          </Typography>
          <Typography level="body-md" mt={2}>
            Currently, we offer several features such as:
          </Typography>
          <List>
            <ListItem>- Automatic extraction of French medical terms</ListItem>
            <ListItem>- Intuitive cohort builder</ListItem>
            <ListItem>- Pseudonymized data export in various formats</ListItem>
            <ListItem>- Basic analytics</ListItem>
          </List>
          <Typography level="body-md" mt={2}>
            Additionally, our app is designed to work particularly well in oncology settings.
          </Typography>
          <Typography level="body-md" mt={2}>
            Please note that our application is secured and that the data is stored in France, but
            the user is responsible for the treatment and use of the data they put in.
          </Typography>
          <Typography level="body-md" mt={2}>
            Thank you for being a part of our journey, and we look forward to hearing your thoughts
            on how we can improve.
          </Typography>
          <Typography textAlign="right" level="body-md" mt={2} fontStyle="italic">
            The Gimli team
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
