import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { NoteQuartzClientContext, TerminologyQuartzClientContext } from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";
import { UnixTimestamp } from "../models/common";
import { Person, PersonId } from "../models/person";
import { Code, Coding } from "../models/structuration";
import { JoyDatePicker } from "../modules/notes/components/Datepickers/JoyDatePicker";
import { unixToDayjs } from "../utils/time";
import { PutNoteRequest } from "../models/note";

interface AddNoteProps {
  person: Person | null;
}

export const AddNote = ({ person }: AddNoteProps) => {
  // Context
  const terminologyQuartzClient = React.useContext(TerminologyQuartzClientContext);
  const noteQuartzClient = React.useContext(NoteQuartzClientContext);
  const navigate = useNavigate();

  // State
  const [serverError, setServerError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [documentTypes, setDocumentTypes] = React.useState<Coding[]>([]);
  const [putNote, setPutNote] = React.useState<PutNoteRequest>({
    note_datetime: dayjs().valueOf() as UnixTimestamp,
    note_type: "3020785" as Code, // default to "Consult note"
    note_text: "",
    elements: [],
    note_title: "Note",
    person_id: person?.person_id as PersonId,
    facts: [],
    skip_annotation: false,
  });

  const handleUploadNewNote = async (data: PutNoteRequest) => {
    setLoading(true);
    try {
      data.note_datetime = data.note_datetime!.valueOf() as UnixTimestamp;
      const notes = await noteQuartzClient.postNewNote(data);
      if (notes.length > 0) {
        const note = notes[0];
        navigate(`/note/${note.note_id}`);
      }
    } catch (e: any) {
      setServerError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNoteTypeChange = (event: any, newValue: string | null) => {
    const newNoteType = documentTypes.find((type) => type.code === newValue);
    setPutNote({
      ...putNote,
      note_type: newNoteType?.code as Code,
    });
  };

  React.useEffect(() => {
    const getDocumentTypes = async () => {
      try {
        const documentTypes = await terminologyQuartzClient.listCodings("document-type");
        setDocumentTypes(documentTypes);
      } catch (e: any) {
        setServerError(e.message);
      }
    };

    getDocumentTypes();
  }, [terminologyQuartzClient]);

  React.useEffect(() => {
    if (person) {
      setPutNote((prevPutNote) => ({
        ...prevPutNote,
        person_id: person.person_id,
      }));
    }
  }, [person]);

  if (loading) {
    return <Typography>Analysing the new note...</Typography>;
  }

  return (
    <form
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        handleUploadNewNote(putNote);
      }}
    >
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <Card>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">New note</Typography>
          <Typography level="body-sm">
            You can write or copy/paste multiple notes by separating the notes with the symbols "
            {">>>"}".
          </Typography>
        </Box>
        <Divider />
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="stretch">
            <FormControl>
              <JoyDatePicker
                sx={{ width: 300 }}
                label="Date"
                disableFuture
                format="DD MMM YYYY"
                value={putNote.note_datetime ? unixToDayjs(putNote.note_datetime) : dayjs()}
                onChange={(newDate) => {
                  if (newDate === null) {
                    return;
                  }
                  setPutNote({
                    ...putNote,
                    note_datetime: newDate.valueOf() as UnixTimestamp,
                  });
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Note type</FormLabel>
              <Select value={putNote.note_type} onChange={handleNoteTypeChange} sx={{ width: 300 }}>
                {documentTypes.map((type) => (
                  <Option key={type.code} value={type.code}>
                    {type.display}
                  </Option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <FormControl>
            <FormLabel>Subject / note title</FormLabel>
            <Input
              required
              value={putNote.note_title}
              onChange={(event: any) =>
                setPutNote({
                  ...putNote,
                  note_title: event.target.value,
                })
              }
            />
          </FormControl>
          <Textarea
            placeholder="Enter the note here  ( free text )"
            minRows={20}
            value={putNote.note_text}
            onChange={(event: any) =>
              setPutNote({
                ...putNote,
                note_text: event.target.value,
              })
            }
            required
          />
        </Stack>
        <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
          <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
            <Button type="submit">Add note</Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </form>
  );
};
