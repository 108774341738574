// import Done from "@mui/icons-material/Done";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import Checkbox from "@mui/joy/Checkbox";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import React from "react";
import { FactQualifier } from "../../../../../../models/fact";
import EditionBox from "../EditionBox";

interface QualitfierEditProps {
  factQualifier: FactQualifier | null | undefined;
  onChange: (factQualifier: FactQualifier) => void;
}

enum Qualifier {
  FAMILY_RELATED = "Family related",
  NEGATED = "Negated",
  NOT_APPLICABLE = "Not applicable",
  UNCERTAIN = "Uncertain",
}

export function QualitfierEdit({ factQualifier, onChange }: QualitfierEditProps) {
  // Compute value based on the fact qualifier
  const determineValue = (factQualifier: FactQualifier | null | undefined): string[] => {
    if (!factQualifier) {
      return [];
    }
    const value: string[] = [];
    if (factQualifier.family_related) {
      value.push("Family related");
    }
    if (factQualifier.negated) {
      value.push("Negated");
    }
    if (factQualifier.not_applicable) {
      value.push("Not applicable");
    }
    if (factQualifier.uncertain) {
      value.push("Uncertain");
    }
    return value;
  };
  const [value, setValue] = React.useState<string[]>(determineValue(factQualifier));

  function updateFactQualifier(updatedValue: string[]): void {
    // set value then update fact qualifier of parent component
    setValue(updatedValue);
    onChange({
      family_related: updatedValue.includes("Family related"),
      negated: updatedValue.includes("Negated"),
      not_applicable: updatedValue.includes("Not applicable"),
      uncertain: updatedValue.includes("Uncertain"),
    });
  }

  const listOfQualifiers = [
    Qualifier.FAMILY_RELATED,
    Qualifier.NEGATED,
    Qualifier.NOT_APPLICABLE,
    Qualifier.UNCERTAIN,
  ];
  function isDisabled(current: Qualifier): boolean {
    // grey all other options if not applicable is selected
    if (value.includes(Qualifier.NOT_APPLICABLE)) {
      return current !== Qualifier.NOT_APPLICABLE;
    }
    if (value.includes(Qualifier.NEGATED)) {
      return current === Qualifier.NOT_APPLICABLE;
    }
    // grey not applicable if any other option is selected
    if (current === Qualifier.NOT_APPLICABLE) {
      return (
        value.includes(Qualifier.FAMILY_RELATED) ||
        value.includes(Qualifier.NEGATED) ||
        value.includes(Qualifier.UNCERTAIN)
      );
    }
    return false;
  }

  return (
    <EditionBox title="Qualifiers" icon={<BubbleChartIcon />}>
      <List
        orientation="horizontal"
        wrap
        sx={{
          "--List-gap": "8px",
          "--ListItem-radius": "20px",
          "--ListItem-minHeight": "32px",
          "--ListItem-gap": "4px",
        }}
      >
        {listOfQualifiers.map((item, index) => (
          <ListItem key={item}>
            {/* {value.includes(item) && (
                <Done
                  fontSize="small"
                  color="primary"
                  sx={{ ml: -0.5, zIndex: 2, pointerEvents: "none" }}
                />
              )} */}
            <Checkbox
              size="sm"
              disabled={isDisabled(item)}
              disableIcon
              overlay
              label={item}
              checked={value.includes(item)}
              variant={value.includes(item) ? "soft" : "outlined"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let updatedValue = [...value];
                if (event.target.checked) {
                  updatedValue = [...updatedValue, item];
                } else {
                  updatedValue = updatedValue.filter((i) => i !== item);
                }
                updateFactQualifier(updatedValue);
              }}
              slotProps={{
                action: ({ checked }) => ({
                  sx: checked
                    ? {
                        border: "1px solid",
                        borderColor: "primary.500",
                      }
                    : {
                        background: "#FFF",
                      },
                }),
              }}
            />
          </ListItem>
        ))}
      </List>
    </EditionBox>
  );
}
