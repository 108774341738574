import { createContext, useContext } from "react";
import { Note } from "../../../models/note";
import { Person, PersonId } from "../../../models/person";
import { Label } from "../NoteProvider";
import { Fact } from "../../../models/fact";

interface NoteContextState {
  note: Note | null;
  loading: boolean;
  setNote: (note: Note) => void;
  handleValidate: (next: boolean, skip: boolean) => void;
  handleReRunNLP: () => void;
  selectedLabel: Label | null;
  setSelectedLabel: (label: Label) => void;
  selectedFact: Fact | null;
  setSelectedFact: (fact: Fact) => void;
  setDisplayAllFacts: () => void;
  hoveredFact: Fact | null;
  setHoveredFact: (fact: Fact | null) => void;
  displayedFacts: Fact[];
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
}

export const NoteContext = createContext<NoteContextState>({
  note: null,
  loading: true,
  setNote: () => {},
  handleValidate: () => {},
  handleReRunNLP: () => {},
  selectedLabel: null,
  setSelectedLabel: () => {},
  selectedFact: null,
  setSelectedFact: () => {},
  setDisplayAllFacts: () => {},
  hoveredFact: null,
  setHoveredFact: () => {},
  displayedFacts: [],
  selectedTab: 0,
  setSelectedTab: () => {},
});

export const useNote = () => {
  return useContext(NoteContext);
};

interface PatientContextState {
  patient: Person;
  setPatient: (patient: Person) => void;
  createPatient: (patient: Person) => void;
  linkPatient: (patientId: PersonId) => void;
}

export const emptyPatient = {
  person_id: "" as PersonId,
  family_name: "",
  given_name: "",
  birth_date: null,
  gender: null,
  deceased: false,
  deceased_date: null,
  external_ids: [],
  consent_withdrawal_date: null,
};

export const PatientContext = createContext<PatientContextState>({
  patient: emptyPatient,
  setPatient: () => {},
  createPatient: () => {},
  linkPatient: () => {},
});

export const usePatient = () => {
  return useContext(PatientContext);
};
