import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Button from "@mui/joy/Button";
import DialogActions from "@mui/joy/DialogActions";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import Divider from "@mui/joy/Divider";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import React from "react";
import LoadingButton from "../../components/LoadingButton";

interface AlertDialogModalProps {
  alertMessage: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleDelete: () => void;
  loading?: boolean;
}

export default function AlertDialogModal({
  alertMessage,
  open,
  setOpen,
  handleDelete,
  loading = false,
}: AlertDialogModalProps) {
  return (
    <React.Fragment>
      <Modal sx={{ zIndex: 99999999 }} open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>{alertMessage}</DialogContent>
          <DialogActions>
            <LoadingButton variant="solid" color="danger" onClick={handleDelete} loading={loading}>
              Delete
            </LoadingButton>
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
