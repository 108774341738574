import CloseRounded from "@mui/icons-material/CloseRounded";
import FormControl from "@mui/joy/FormControl";
import IconButton from "@mui/joy/IconButton";
import Option from "@mui/joy/Option";
import Select, { SelectStaticProps } from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import React from "react";
import { SearchBar } from "../annotation/molecules/SearchBar";
import { TerminologyQuartzClientContext } from "../clients/contexts";
import { ValidationState } from "../models/note";
import { Coding } from "../models/structuration";

interface NoteFiltersProps {
  defaultStatus: ValidationState;
  defaultNoteType: string | null;
  searchByCoding: Coding | null;
  onStatusChange: (status: ValidationState) => void;
  onNoteTypeChange: (noteType: string | null) => void;
  onSearch: (coding: Coding | null) => void;
}

export default function NoteFilters({
  defaultStatus,
  defaultNoteType,
  searchByCoding,
  onStatusChange,
  onNoteTypeChange,
  onSearch,
}: NoteFiltersProps) {
  const terminologyQuartzClient = React.useContext(TerminologyQuartzClientContext);
  const [documentTypes, setDocumentTypes] = React.useState<Coding[]>([]);
  const status = defaultStatus !== "all" ? defaultStatus : null;
  const actionNoteType: SelectStaticProps["action"] = React.useRef(null);
  const actionStatus: SelectStaticProps["action"] = React.useRef(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await terminologyQuartzClient.listCodings("document-type");
        setDocumentTypes(response);
      } catch (error) {
        console.error("Failed to fetch document types:", error);
      }
    };

    fetchData();
  }, [terminologyQuartzClient]);

  return (
    <Stack direction="row" spacing={2}>
      <FormControl sx={{ width: "60%" }}>
        {/* <FormLabel id="search-bar" htmlFor="search-bar">
          Search
        </FormLabel> */}
        <SearchBar
          message="Search a fact among the notes"
          onChange={onSearch}
          isSmall
          coding={searchByCoding}
        />
      </FormControl>
      <FormControl sx={{ width: "20%" }}>
        {/* <FormLabel id="select-field-note-type" htmlFor="select-field-note-type">
          Note type
        </FormLabel> */}
        <Select
          placeholder="Filter on note type"
          defaultValue={defaultNoteType}
          onChange={(e, newValue) => onNoteTypeChange(newValue)}
          {...(defaultNoteType && {
            endDecorator: (
              <IconButton
                size="sm"
                variant="plain"
                color="neutral"
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onClick={() => {
                  onNoteTypeChange(null);
                  actionNoteType.current?.focusVisible();
                }}
              >
                <CloseRounded fontSize="small" />
              </IconButton>
            ),
            indicator: null,
          })}
        >
          {documentTypes.map((type) => (
            <Option key={type.code} value={type.code}>
              {type.display}
            </Option>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: "20%" }}>
        {/* <FormLabel id="select-field-status" htmlFor="select-field-status">
          Status
        </FormLabel> */}
        <Select
          placeholder="Filter on status"
          defaultValue={status}
          onChange={(e, newValue) => onStatusChange(newValue ? newValue : "all")}
          {...(status && {
            endDecorator: (
              <IconButton
                size="sm"
                variant="plain"
                color="neutral"
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onClick={() => {
                  onStatusChange("all");
                  actionStatus.current?.focusVisible();
                }}
              >
                <CloseRounded />
              </IconButton>
            ),
            indicator: null,
          })}
        >
          <Option value="validated-only">Verified</Option>
          <Option value="unvalidated-only">Unverified</Option>
        </Select>
      </FormControl>
    </Stack>
  );
}
