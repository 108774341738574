import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { UploadDocument } from "./UploadDocument";

export function UploadDocumentPage() {
  return (
    <Box sx={{ p: 4 }}>
      <Typography level="h1">Upload document</Typography>
      <Typography>
        Drag and drop one or more documents (PDF, Word or text) that will be analysed in a few
        seconds to create notes.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <UploadDocument />
      </Box>
    </Box>
  );
}
