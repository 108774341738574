import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { QuestionAnsweringClientContext } from "../clients/contexts";
import { Question, Questionnaire } from "../models/questionnaire";

interface ListQuestionnaireModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleSelectQuestionnaire: (questions: Question[]) => void;
}

export default function SaveQuestionnaireModal({
  open,
  setOpen,
  handleSelectQuestionnaire,
}: ListQuestionnaireModalProps) {
  // Context
  const questionAnsweringClient = React.useContext(QuestionAnsweringClientContext);

  // States
  const [selectedQuestions, setSelectedQuestions] = React.useState<Question[]>([]);
  const [questionnaires, setQuestionnaires] = React.useState<Questionnaire[]>([]);

  React.useEffect(() => {
    const fetchQuestionnaires = async () => {
      try {
        const response = await questionAnsweringClient.listQuestionnaires();
        setQuestionnaires(response);
      } catch (e: any) {
        console.error(e.message);
      }
    };
    if (open) {
      fetchQuestionnaires();
    }
  }, [open, questionAnsweringClient]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog sx={{ width: "500px" }}>
        <ModalClose />
        <DialogTitle>Choose from an existing template</DialogTitle>
        <Typography>
          Select a questionnaire from the list below. Depending on the number of questions and
          patients, this may take some time.
        </Typography>
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            selectedQuestions && handleSelectQuestionnaire(selectedQuestions);
            setOpen(false);
          }}
        >
          <Stack spacing={2}>
            <RadioGroup name="radio-select-questionnaire" sx={{ gap: 1, "& > div": { p: 1 } }}>
              {questionnaires.map((questionnaire) => (
                <FormControl key={questionnaire.questionnaire_id}>
                  <Radio
                    overlay
                    value={questionnaire.questionnaire_id}
                    label={`${questionnaire.title} (${questionnaire.item.length} questions) `}
                    onChange={() => setSelectedQuestions(questionnaire.item)}
                  />
                  <FormHelperText>{questionnaire.description}</FormHelperText>
                </FormControl>
              ))}
            </RadioGroup>
            <Button type="submit">Apply template</Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}
