import React from "react";
import { BaseCriteria, CriteriaGroup } from "../models/criteria";

interface CriteriaGroupDisplayProps {
  criteriaGroup: CriteriaGroup;
}

export default function CriteriaGroupDisplay({ criteriaGroup }: CriteriaGroupDisplayProps) {
  const generateCriteriaString = React.useMemo(
    () => (criteriaGroup: CriteriaGroup) => {
      return criteriaGroup.criteria_list
        .map((c: BaseCriteria) => {
          const { type, params } = c;
          let paramString = "";

          if (typeof params === "object") {
            if ("gender" in params) {
              paramString = `Only ${params.gender}`;
            } else if ("deceased" in params) {
              paramString = params.deceased ? "Deceased" : "Still alive";
            } else if ("coding" in params) {
              if (type === "DRUG") {
                paramString = `At least one exposure of <strong>${params.coding?.display}</strong>`;
              } else if (type === "OBSERVATION") {
                paramString = `At least one observation of <strong>${params.coding?.display}</strong>`;
              } else if (type === "CONDITION") {
                paramString = `At least one occurrence of <strong>${params.coding?.display}</strong>`;
              } else if (type === "PROCEDURE") {
                paramString = `At least one occurrence of <strong>${params.coding?.display}</strong>`;
              } else if (type === "MEASUREMENT") {
                paramString = `At least one mention of <strong>${params.coding?.display}</strong>`;
              } else if (type === "DEVICE") {
                paramString = `At least one exposure of device <strong>${params.coding?.display}</strong>`;
              }
            } else if ("minAge" in params && "maxAge" in params) {
              paramString = `AGE >= ${params.minAge} and <= ${params.maxAge}`;
            } else if ("base_coding" in params && "value_coding" in params) {
              paramString = `With <strong>${params.base_coding?.display}</strong> equals to <strong>${params.value_coding?.display}</strong>`;
            }
          } else {
            paramString = `${params}`;
          }

          return `${paramString}`;
        })
        .join(` <br /><small>${criteriaGroup.operator}</small> `);
    },
    []
  );

  return <div dangerouslySetInnerHTML={{ __html: generateCriteriaString(criteriaGroup) }} />;
}
