import classNames from "classnames";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { IdentityProviderClientContext } from "../../clients/contexts";
import SnackbarNotification from "../../common/SnackbarNotification";
import { Role } from "../../models/auth";
import { UserContext } from "../../admin/UserProvider";
import TitleStylizedUnderline from "../../components/TitleStylizedUnderline";
import Typography from "@mui/joy/Typography";

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  organization: string;
  role: Role;
};

export function UserProfileAttributes() {
  const auth = useContext(IdentityProviderClientContext);
  const { user } = useContext(UserContext);
  const [serverError, setServerError] = useState<string | null>("");
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isLoading },
  } = useForm<FormValues>({
    defaultValues: async () => {
      return {
        email: user?.email ?? "",
        firstName: user?.attributes.given_name ?? "",
        lastName: user?.attributes.name ?? "",
        organization: user?.attributes.organization ?? "",
        role: user?.attributes.role ?? Role.ADMIN,
      };
    },
  });
  const onSubmit = handleSubmit(async (data: FormValues) => {
    try {
      await auth.setUserAttributes(data.email, {
        given_name: data.firstName,
        name: data.lastName,
        organization: data.organization,
        role: data.role,
      });
    } catch (e: any) {
      setServerError(e.message);
    }
  });
  return (
    <>
      <Typography level="h2">
        <TitleStylizedUnderline>Personal profile</TitleStylizedUnderline>
      </Typography>
      <hr />
      <form onSubmit={onSubmit} style={{ opacity: isLoading ? 0.5 : 1 }}>
        <div className="block">
          <div className="field-body">
            <div className="field">
              <label className="label">First name</label>
              <p className="control">
                <input
                  className={errors.firstName ? "is-danger input" : "input"}
                  {...register("firstName", { required: "Field required." })}
                  placeholder="Enter first name"
                  disabled={isLoading || isSubmitting}
                />
              </p>
              <p className="help is-danger">{errors.firstName?.message}</p>
            </div>
            <div className="field">
              <label className="label">Last name</label>
              <p className="control">
                <input
                  className={errors.lastName ? "is-danger input" : "input"}
                  {...register("lastName", { required: "Field required." })}
                  placeholder="Enter last name"
                  disabled={isLoading || isSubmitting}
                />
              </p>
              <p className="help is-danger">{errors.lastName?.message}</p>
            </div>
          </div>
          <div className="field">
            <label className="label">Email</label>
            <p className="control">
              <input
                className={errors.email ? "is-danger input" : "input"}
                {...register("email", { required: "Field required." })}
                placeholder="Enter email address"
                disabled={true}
              />
            </p>
            <p className="help is-danger">{errors.email?.message}</p>
          </div>
          <div className="field">
            <label className="label">Organization</label>
            <p className="control">
              <input
                className={errors.organization ? "is-danger input" : "input"}
                {...register("organization", { required: "Field required." })}
                placeholder="Enter organization"
                disabled={isLoading || isSubmitting}
              />
            </p>
            <p className="help">The company, organization, or institution you are working for.</p>
            <p className="help is-danger">{errors.organization?.message}</p>
          </div>
          <button
            className={classNames("button", isSubmitting && "is-loading")}
            type="submit"
            disabled={isLoading || isSubmitting}
          >
            Update profile
          </button>
        </div>
      </form>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
    </>
  );
}
