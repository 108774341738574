import Stack from "@mui/joy/Stack";
import React from "react";
import { Sidebar } from "../common/Sidebar/Sidebar";
import Footer from "../layout/Footer";

interface PageLayoutProps {
  children: React.ReactNode;
  noLayout: boolean;
}

export default function PageLayout({ children, noLayout }: PageLayoutProps) {
  if (noLayout) return <div style={{ overflow: "hidden", minHeight: "100vh" }}>{children}</div>;
  return (
    <div>
      <Sidebar />
      <Stack
        sx={{ ml: "241px", minHeight: "100vh" }}
        direction="column"
        justifyContent="space-between"
      >
        <div>{children}</div>
        <Footer />
      </Stack>
    </div>
  );
}
