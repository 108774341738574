import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React, { ReactElement, ReactNode } from "react";

export default function EditionBox({
  title,
  icon,
  children,
}: {
  title: string;
  icon: ReactElement;
  children: ReactNode;
}) {
  const StylizedIcon = React.cloneElement(icon, {
    sx: { color: (theme: any) => theme.palette.text.secondary, fontSize: "18px" },
  });

  return (
    <Sheet
      variant="outlined"
      sx={{ width: "100%", p: 2, borderRadius: "sm" }}
      data-cy="temporality-edit"
    >
      <Stack direction="column" spacing={1.5}>
        <Stack direction="row" gap={0.5}>
          {StylizedIcon}
          <Typography level="body-sm" fontWeight="lg" sx={{ mt: -0.1 }}>
            {title}
          </Typography>
        </Stack>
        <Box>{children}</Box>
      </Stack>
    </Sheet>
  );
}
