import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { UserInfoProviderContext } from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";
import { Role, User } from "../models/auth";

interface UserContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  refreshUser: () => void;
}

export const UserContext = createContext<UserContextType>(null as unknown as UserContextType);

type UserProviderProps = {
  children: ReactNode;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const auth = useContext(UserInfoProviderContext);
  const [user, setUser] = useState<User | null>(null);
  const [serverError, setServerError] = useState<string | null>(null);

  const getUser = useCallback(async () => {
    try {
      if (window.DISABLE_AUTH) {
        setUser({
          email: "sauron@gimli-med.io",
          attributes: {
            given_name: "Alexandre",
            name: "Rastatapoulos",
            organization: "Gimli",
            role: Role.ADMIN,
          },
        });
      } else {
        const user = await auth.getUser();
        setUser(user);
      }
    } catch (e: any) {
      setServerError(e.message);
    }
  }, [auth]);

  const refreshUser = async () => {
    getUser();
  };

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <UserContext.Provider value={{ user, setUser, refreshUser }}>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      {children}
    </UserContext.Provider>
  );
};
