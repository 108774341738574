import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import { Link as LinkBundle } from "../models/bundle";

interface TablePaginationProps {
  totalEntries: number;
  pageSize: number;
  links: LinkBundle[];
  onPageChange: (offset: number) => void;
}

export default function TablePagination({
  totalEntries,
  pageSize,
  links,
  onPageChange,
}: TablePaginationProps) {
  const previousLink = links.find((link) => link.relation === "previous");
  const nextLink = links.find((link) => link.relation === "next");
  const firstLink = links.find((link) => link.relation === "first");
  const lastLink = links.find((link) => link.relation === "last");
  const selfLink = links.find((link) => link.relation === "self");
  const currentPageNumber =
    selfLink && Math.ceil(Number(selfLink.url.split("_offset=")[1]) / pageSize) + 1;
  const totalNumberOfPages = Math.ceil(totalEntries / pageSize);

  function extractOffset(url: string) {
    return Number(url.split("_offset=")[1]);
  }

  return (
    <>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled={previousLink ? false : true}
          onClick={() => {
            previousLink && onPageChange(extractOffset(previousLink.url));
          }}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {firstLink && selfLink && lastLink && totalNumberOfPages > 1 && (
          <>
            <IconButton
              key={"1"}
              size="sm"
              variant={selfLink.url === firstLink.url ? "outlined" : "plain"}
              color="neutral"
              onClick={() => {
                firstLink && onPageChange(extractOffset(firstLink.url));
              }}
            >
              {"1"}
            </IconButton>
            {totalNumberOfPages > 2 && currentPageNumber && currentPageNumber > 2 && (
              <IconButton key={"...1"} size="sm" variant="plain" color="neutral" disabled>
                {"..."}
              </IconButton>
            )}
            {totalNumberOfPages > 2 &&
              currentPageNumber !== 1 &&
              currentPageNumber !== totalNumberOfPages && (
                <IconButton key={currentPageNumber} size="sm" variant="outlined" color="neutral">
                  {currentPageNumber}
                </IconButton>
              )}
            {totalNumberOfPages > 2 &&
              currentPageNumber &&
              currentPageNumber < totalNumberOfPages - 1 && (
                <IconButton key={"...2"} size="sm" variant="plain" color="neutral" disabled>
                  {"..."}
                </IconButton>
              )}
            <IconButton
              key={totalNumberOfPages}
              size="sm"
              variant={selfLink.url === lastLink.url ? "outlined" : "plain"}
              color="neutral"
              onClick={() => {
                lastLink && onPageChange(extractOffset(lastLink.url));
              }}
            >
              {totalNumberOfPages}
            </IconButton>
          </>
        )}

        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          disabled={nextLink ? false : true}
          onClick={() => {
            nextLink && onPageChange(extractOffset(nextLink.url));
          }}
        >
          Next
        </Button>
      </Box>
    </>
  );
}
