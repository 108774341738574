import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import DialogTitle from "@mui/joy/DialogTitle";
import Grid from "@mui/joy/Grid";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import Typography from "@mui/joy/Typography";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { OmopDbReport } from "../models/analytics";

interface AnalyticsModalProps {
  isOpen: boolean;
  onClose: () => void;
  dbReport: OmopDbReport | null;
}

export default function AnalyticsModal({ isOpen, onClose, dbReport }: AnalyticsModalProps) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalOverflow>
        <ModalDialog layout="fullscreen">
          <ModalClose />
          <DialogTitle level="h2">Analytics</DialogTitle>
          {dbReport && (
            <Grid container direction="row" alignItems="stretch" spacing={4} sx={{ mt: 4 }}>
              <Grid md={4} sm={6} xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography level="title-md" sx={{ mb: 2 }}>
                      Number of patients
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ height: "250px" }}
                    >
                      <PeopleOutlineRoundedIcon fontSize="large" />
                      <Typography level="title-lg" sx={{ mt: 2 }}>
                        {dbReport.number_of_patient} patient{dbReport.number_of_patient > 1 && "s"}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid md={4} sm={6} xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography level="title-md" sx={{ mb: 2 }}>
                      Gender distribution
                    </Typography>
                    <Box sx={{ height: "250px" }}>
                      <PieChart
                        series={[
                          {
                            data: dbReport.gender_distribution,
                          },
                        ]}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {dbReport.year_of_birth_distribution &&
                dbReport.year_of_birth_distribution.xData &&
                dbReport.year_of_birth_distribution.serieData && (
                  <Grid md={4} xs={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography level="title-md" sx={{ mb: 2 }}>
                          Year of birth distribution
                        </Typography>
                        <Box sx={{ height: "250px" }}>
                          <BarChart
                            xAxis={[
                              {
                                scaleType: "band",
                                data: dbReport.year_of_birth_distribution.xData,
                              },
                            ]}
                            series={[{ data: dbReport.year_of_birth_distribution.serieData }]}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              {dbReport.condition_distribution && dbReport.condition_distribution.length > 0 && (
                <Grid md={6} xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography level="title-md" sx={{ mb: 2 }}>
                        10 most common conditions
                      </Typography>
                      <BarChart
                        layout="horizontal"
                        yAxis={[
                          {
                            scaleType: "band",
                            data: dbReport.condition_distribution.map((item) => item.label),
                          },
                        ]}
                        xAxis={[{ label: "Number of occurrences" }]}
                        series={[
                          { data: dbReport.condition_distribution.map((item) => item.value) },
                        ]}
                        height={350}
                        margin={{ left: 200 }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {dbReport.drug_distribution && dbReport.drug_distribution.length > 0 && (
                <Grid md={6} xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography level="title-md" sx={{ mb: 2 }}>
                        10 most common drugs
                      </Typography>
                      <BarChart
                        layout="horizontal"
                        yAxis={[
                          {
                            scaleType: "band",
                            data: dbReport.drug_distribution.map((item) => item.label),
                          },
                        ]}
                        xAxis={[{ label: "Number of exposures" }]}
                        series={[{ data: dbReport.drug_distribution.map((item) => item.value) }]}
                        height={350}
                        margin={{ left: 200 }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          )}
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
