import { RouteObject } from "react-router-dom";

import { Logout } from "./Logout";
import SignIn from "./Signin";

export const ONPREM_AUTH_ROUTES: RouteObject[] = [
  {
    path: "signin",
    element: <SignIn />,
  },
  {
    path: "logout",
    element: <Logout />,
  },
];
