import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import Button from "@mui/joy/Button";
import ToggleButtonGroup from "@mui/joy/ToggleButtonGroup";
import React from "react";
import { MilliDelta } from "../../../../../models/common";
import {
  ApproximateTime,
  ExactTime,
  QualitativeTimeDelta,
  QuantitativeTimeDelta,
  RelativeTime,
  TemporalPrecision,
  TimeInterval,
  TimeReference,
  TimeType,
} from "../../../../../models/human_time";
import {
  isApproximateTime,
  isFuturRelativeTime,
  isPastRelativeTime,
  isRelativeTime,
  isTimeInterval,
} from "../../../../../utils/fact";
import { generateApproximateTime, unixToDayjs } from "../../../../../utils/time";
import { useNote } from "../../../lib/context";
import { ComplexDatePicker } from "../../Datepickers/ComplexDatePicker";
import RelativeDatePicker from "../../Datepickers/RelativeDatePicker";
import EditionBox from "./EditionBox";

export enum Temporality {
  PAST = "Past",
  PRESENT = "Present",
  FUTURE = "Future",
  PRECISE = "Precise",
}

interface TemporalityEditProps {
  time: ApproximateTime | ExactTime | RelativeTime | TimeInterval | null | undefined;
  onChange: (time: ApproximateTime | ExactTime | RelativeTime | TimeInterval) => void;
}

export function TemporalityEdit({ time, onChange }: TemporalityEditProps) {
  type TemporalityLabel = {
    title: string;
    defaultValue: ApproximateTime | RelativeTime;
  };
  const temporalityLabels: TemporalityLabel[] = [
    {
      title: Temporality.PAST,
      defaultValue: {
        type: TimeType.RELATIVE,
        reference: TimeReference.NOTE,
        delta: QualitativeTimeDelta.BEFORE,
      } as RelativeTime,
    },
    {
      title: Temporality.PRESENT,
      defaultValue: {
        type: TimeType.RELATIVE,
        reference: TimeReference.NOTE,
        delta: {
          value: 0,
          precision: TemporalPrecision.DAY,
        } as QuantitativeTimeDelta,
      } as RelativeTime,
    },
    {
      title: Temporality.FUTURE,
      defaultValue: {
        type: TimeType.RELATIVE,
        reference: TimeReference.NOTE,
        delta: QualitativeTimeDelta.AFTER,
      } as RelativeTime,
    },
    {
      title: Temporality.PRECISE,
      defaultValue: generateApproximateTime(),
    },
  ];

  const { note } = useNote();

  const determineTemporality = (time: any): Temporality => {
    if (isPastRelativeTime(time) || (isTimeInterval(time) && isPastRelativeTime(time.begin))) {
      return Temporality.PAST;
    } else if (
      isFuturRelativeTime(time) ||
      (isTimeInterval(time) && isFuturRelativeTime(time.end))
    ) {
      return Temporality.FUTURE;
    } else if (isTimeInterval(time) || isApproximateTime(time)) {
      return Temporality.PRECISE;
    } else {
      return Temporality.PRESENT;
    }
  };

  const [temporality, setTemporality] = React.useState<Temporality>(determineTemporality(time));

  const updateTemporarily = (newTemporality: Temporality) => {
    setTemporality(newTemporality);
    if (newTemporality === Temporality.PRESENT) {
      onChange({
        type: TimeType.RELATIVE,
        reference: TimeReference.NOTE,
        delta: {
          value: 0,
          precision: TemporalPrecision.DAY,
        } as QuantitativeTimeDelta,
      } as RelativeTime);
    } else if (newTemporality === Temporality.PRECISE) {
      onChange(generateApproximateTime());
    } else if (newTemporality === Temporality.PAST || newTemporality === Temporality.FUTURE) {
      // we check if it is a relative time, and if the delta is quantitative
      // if it is, we need to inverse the delta value in case of relative date
      // if it is a interval, we need to inverse the begin and end date and inverse the delta value again
      // if it is a simple relative date with qualitative delta, we just need to inverse the delta from before to after and vice-versa
      if (isRelativeTime(time)) {
        let delta = time.delta;
        if (typeof delta !== "string") {
          if (delta.value === 0) {
            if (newTemporality === Temporality.PAST) {
              time.delta = QualitativeTimeDelta.BEFORE;
            } else {
              time.delta = QualitativeTimeDelta.AFTER;
            }
          } else {
            delta.value = -delta.value as MilliDelta;
          }
        } else {
          time.delta =
            delta === QualitativeTimeDelta.BEFORE
              ? QualitativeTimeDelta.AFTER
              : QualitativeTimeDelta.BEFORE;
        }
        onChange(time);
      } else {
        onChange({
          type: TimeType.RELATIVE,
          reference: TimeReference.NOTE,
          delta:
            temporality === Temporality.PAST
              ? QualitativeTimeDelta.BEFORE
              : QualitativeTimeDelta.AFTER,
        } as RelativeTime);
      }
    }
  };

  return (
    <EditionBox title="Temporality" icon={<AccessTimeFilledIcon />}>
      <ToggleButtonGroup
        value={temporality}
        onChange={(event, newValue) => {
          newValue && updateTemporarily(newValue);
        }}
      >
        {temporalityLabels.map((item, key) => (
          <Button key={key} value={item.title}>
            {item.title}
          </Button>
        ))}
      </ToggleButtonGroup>
      {temporality === Temporality.PRECISE && (
        <ComplexDatePicker
          date={time}
          note_datetime={unixToDayjs(note?.note_datetime)}
          onDateChange={onChange}
        />
      )}
      {[Temporality.PAST, Temporality.FUTURE].includes(temporality) && (
        <RelativeDatePicker
          time={time as RelativeTime | TimeInterval}
          temporality={temporality}
          onUpdate={(time) => {
            onChange(time);
          }}
        />
      )}
    </EditionBox>
  );
}
