import Checkbox from "@mui/joy/Checkbox";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import React from "react";
import { SearchBar } from "../../../../../../annotation/molecules/SearchBar";
import { TerminologyQuartzClientContext } from "../../../../../../clients/contexts";
import {
  CodeableConcept,
  Coding,
  Domain,
  Quantity,
  SimplifiedCoding,
} from "../../../../../../models/structuration";
import { getCode, instanceOfQuantity } from "../../../../../../utils/fact";
import { DebouncedFunction } from "../../../../../../utils/time";
import Box from "@mui/joy/Box";

type FactValueEditProps = {
  code: string | null;
  domain: string | null;
  value: Quantity | CodeableConcept | null;
  onChange: (newValue: Quantity | CodeableConcept | null) => void;
  onAeChange: (newValue: boolean, previousDomain?: Domain) => void;
};

function SimplifiedCodingToCoding(simplifiedCoding: SimplifiedCoding): Coding {
  return {
    code: simplifiedCoding.code,
    system: "OMOP",
    display: simplifiedCoding.display,
  };
}

const renderSelect = (
  concepts: SimplifiedCoding[],
  codeableConcept: CodeableConcept,
  handleChangeOfCodeableConcept: (newValue: string | null) => void
) => (
  <FormControl>
    <FormLabel>Fact value</FormLabel>
    <Select
      value={getCode(codeableConcept) || null}
      onChange={(event: React.SyntheticEvent | null, newValue: string | null) =>
        handleChangeOfCodeableConcept(newValue)
      }
      defaultValue={null}
    >
      {concepts.map((concept) => (
        <Option key={concept.code} value={concept.code}>
          {concept.display}
        </Option>
      ))}
      <Option value={null}>{"—"}</Option>
    </Select>
  </FormControl>
);

const renderFamilyHistory = (
  value: CodeableConcept,
  handleFamilyHistoryChange: (coding: Coding | null) => void
) => (
  <>
    <SearchBar isSmall onChange={handleFamilyHistoryChange} />
    {value.coding.length > 0 ? value.coding[0].display : "Select a value"}
  </>
);

export default function FactValueEdit({
  code,
  domain,
  value,
  onChange,
  onAeChange,
}: FactValueEditProps) {
  const terminologyQuartzClient = React.useContext(TerminologyQuartzClientContext);
  const debounce = DebouncedFunction();

  // Potential values
  const [defaultConcepts, setDefaultConcepts] = React.useState<SimplifiedCoding[]>([]);
  const [aeConcepts, setAeConcepts] = React.useState<SimplifiedCoding[]>([]);
  const [valueRanges, setValueRanges] = React.useState<SimplifiedCoding[]>([]);

  // Display default, quantity, or AE stat  es
  const [isQuantity, setIsQuantity] = React.useState(instanceOfQuantity(value));
  const [isAe, setIsAe] = React.useState(domain === "adverse-event");
  const [factValue, setFactValue] = React.useState<Quantity | CodeableConcept | null>(value);
  const [quantityInputValue, setQuantityInputValue] = React.useState<string>("");

  // we display the component, if there are AE concepts, value ranges,
  // default concepts or if the value is null and we have potential values
  const shouldDisplayComponent =
    aeConcepts.length > 0 ||
    valueRanges.length > 0 ||
    defaultConcepts.length > 0 ||
    (value === null &&
      (aeConcepts.length > 0 || valueRanges.length > 0 || defaultConcepts.length > 0));

  const handleChangeOfCodeableConcept = (newValue: string | null) => {
    let newCodeableConcept;
    if (!newValue) {
      newCodeableConcept = null;
    } else {
      newCodeableConcept = {
        coding: [
          {
            code: newValue,
            system: "OMOP",
            display:
              defaultConcepts.concat(aeConcepts).find((concept) => concept.code === newValue)
                ?.display || "",
          },
        ],
      };
    }
    setFactValue(newCodeableConcept);
    onChange(newCodeableConcept);
  };

  const handleChangeToAe = () => {
    let newFactValue: CodeableConcept;
    const previousDomain = domain as Domain;
    if (!isAe) {
      newFactValue = {
        coding: aeConcepts,
      } as CodeableConcept;
      setIsAe(true);
      onAeChange(true, previousDomain);
    } else {
      newFactValue = {
        coding: defaultConcepts,
      } as CodeableConcept;
      setIsAe(false);
      onAeChange(false);
    }
    setFactValue(newFactValue);
    onChange(newFactValue);
  };

  const handleChangeToQuantity = () => {
    let newFactValue: Quantity | CodeableConcept;
    if (!isQuantity) {
      newFactValue = {
        comparator: "=",
        value: 0,
        unit: valueRanges[0],
      } as Quantity;
      setQuantityInputValue("0");
      setIsQuantity(true);
    } else {
      newFactValue = {
        coding: defaultConcepts,
      } as CodeableConcept;
      setIsQuantity(false);
    }
    setFactValue(newFactValue);
    onChange(newFactValue);
  };

  // Changes in quantity
  const handleComparatorChange = (event: React.SyntheticEvent | null, newComparator: any) => {
    const newFactValue = {
      ...factValue!,
      comparator: newComparator,
    };
    setFactValue(newFactValue);
    onChange(newFactValue);
  };

  const handleQuantityUnitChange = (event: React.SyntheticEvent | null, newUnit: string | null) => {
    const unit = valueRanges.find((unit: SimplifiedCoding) => unit.code === newUnit);
    if (unit) {
      const newFactValue = {
        ...factValue!,
        unit: SimplifiedCodingToCoding(unit),
      };
      setFactValue(newFactValue);
      onChange(newFactValue);
    }
  };

  const handleDebouncedValueChange = debounce((newValue: string) => {
    const newFactValue = {
      ...factValue!,
      value: Number(newValue),
    };
    setFactValue(newFactValue);
    onChange(newFactValue);
  }, 500);

  React.useEffect(() => {
    const getPotentialValues = async () => {
      if (code) {
        try {
          const response = await terminologyQuartzClient.valueParameters(code);
          setDefaultConcepts(response.default || []);
          setAeConcepts(response.adverse_events || []);
          setValueRanges(response.measurement_units || []);
        } catch (error) {
          console.error("Failed to fetch value parameters:", error);
        }
      }
    };

    getPotentialValues();
  }, [code, terminologyQuartzClient]);

  React.useEffect(() => {
    if (isQuantity) {
      if (value && instanceOfQuantity(value)) {
        setQuantityInputValue(value.value.toString());
      } else {
        setQuantityInputValue("0");
      }
    }
  }, [value, isQuantity]);

  const handleFamilyHistoryChange = async (coding: Coding | null) => {
    if (coding) {
      const newFactValue: CodeableConcept = {
        ...factValue,
        coding: [coding],
      };
      setFactValue(newFactValue);
      onChange(newFactValue);
    }
  };

  const renderQuantityFields = (value: Quantity) => (
    <Stack direction="row" alignItems="center" spacing={1}>
      <FormControl>
        <Select
          name="comparator"
          value={value.comparator as "<" | "<=" | ">=" | ">" | "=" | null}
          onChange={handleComparatorChange}
        >
          {["<", "<=", ">", ">=", "="].map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ flexGrow: 1, minWidth: "100px" }}>
        <Input
          type="number"
          value={quantityInputValue}
          onChange={(e) => {
            setQuantityInputValue(e.target.value); // Update local state for immediate reflection in input
            handleDebouncedValueChange(e.target.value); // Use debounced function to set fact value
          }}
        />
      </FormControl>
      <FormControl>
        <Select name="unit" value={value.unit?.code as string} onChange={handleQuantityUnitChange}>
          {valueRanges.map((item: SimplifiedCoding) => (
            <Option key={item.display} value={item.code}>
              {item.display}
            </Option>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );

  if (shouldDisplayComponent) {
    return (
      <Box sx={{ px: 2, py: 2 }}>
        {aeConcepts.length > 0 && (
          <FormControl>
            <Checkbox checked={isAe} onChange={handleChangeToAe} label="Is an adverse event?" />
          </FormControl>
        )}

        {valueRanges.length > 0 && (
          <FormControl>
            <Checkbox
              checked={isQuantity}
              onChange={handleChangeToQuantity}
              label="Is a quantity?"
            />
          </FormControl>
        )}
        <Box sx={{ mt: 1 }}>
          <FormControl>
            {isQuantity
              ? renderQuantityFields(factValue as Quantity)
              : isAe
                ? renderSelect(
                    aeConcepts,
                    factValue as CodeableConcept,
                    handleChangeOfCodeableConcept
                  )
                : code === "4167217" || code === "1340204" // special case for family history and history of event
                  ? renderFamilyHistory(factValue as CodeableConcept, handleFamilyHistoryChange)
                  : defaultConcepts.length > 0
                    ? renderSelect(
                        defaultConcepts,
                        factValue as CodeableConcept,
                        handleChangeOfCodeableConcept
                      )
                    : null}
          </FormControl>
        </Box>
      </Box>
    );
  }
}
