import { Auth } from "aws-amplify";

declare global {
  interface Window {
    CONFIG: {
      env: string;
      auth: AWSCognitoAuthConfig | SimpleAuthConfig;
      backendUrl: string;
      cookieDomain?: string | null;
      frontendVersion: string;
    };
    DISABLE_AUTH: boolean;
  }
}

export interface AWSCognitoAuthConfig {
  kind: "AWS_COGNITO";
  awsRegion: string;
  userPoolId: string;
  userPoolWebClientId: string;
  oauthDomain: string;
  pscConfig: {
    url: string;
    client_id: string;
    redirect_uri: string;
  };
}

export interface SimpleAuthConfig {
  kind: "SIMPLE";
}

export function initialize() {
  if (window.CONFIG.auth.kind === "AWS_COGNITO") {
    Auth.configure({
      region: window.CONFIG.auth.awsRegion,
      userPoolId: window.CONFIG.auth.userPoolId,
      userPoolWebClientId: window.CONFIG.auth.userPoolWebClientId,
      oauth: {
        domain: window.CONFIG.auth.oauthDomain,
        scope: [
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin",
          "https://gimli-med.io/api.readwrite",
        ],
        redirectSignIn: `${window.location.protocol}//${window.location.host}/`,
        redirectSignOut: `${window.location.protocol}//${window.location.host}/`,
        responseType: "code",
      },
    });
  }
}
