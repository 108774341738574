import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Grid from "@mui/joy/Grid";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { Link } from "react-router-dom";
import illustrationDoc from "../../src/assets/illustration-document.png";
import { BundleContext } from "../App";
import {
  AdminQuartzClientContext,
  NoteQuartzClientContext,
  PersonQuartzClientContext,
} from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";
import HexagonBackground from "../components/HexagonBackground";
import TablePagination from "../components/TablePagination";
import TitleStylizedUnderline from "../components/TitleStylizedUnderline";
import TableSkeleton from "../components/skeletons/TableSkeleton";
import { BatchBundle, Link as LinkBundle } from "../models/bundle";
import { Note, NoteId, ValidationState } from "../models/note";
import { Person } from "../models/person";
import { Coding } from "../models/structuration";
import NoteFilters from "./NoteFilters";
import { ListOfNotes } from "./organisms/ListOfNotes";

interface AllNotesPageProps {
  noteList?: Note[];
  personList?: Person[];
  loading?: boolean;
}

export const AllNotesPage = ({ noteList, personList, loading = true }: AllNotesPageProps) => {
  // Contexts
  const noteQuartzClient = React.useContext(NoteQuartzClientContext);
  const personQuartzClient = React.useContext(PersonQuartzClientContext);
  const adminQuartzClient = React.useContext(AdminQuartzClientContext);
  const [, setBundleId] = React.useContext(BundleContext);

  // States
  const [persons, setPersons] = React.useState<Person[] | undefined>(personList);
  const [notes, setNotes] = React.useState<Note[] | undefined>(noteList);
  const [isLoading, setLoading] = React.useState(loading);
  const [validationState, setValidationState] = React.useState<ValidationState>("all");
  const [noteTypeCode, setNoteTypeCode] = React.useState<string | null>(null);
  const [searchByCoding, setSearchByCoding] = React.useState<Coding | null>(null);
  const [links, setLinks] = React.useState<LinkBundle[]>([]);
  const [totalEntries, setTotalEntries] = React.useState<number>(0);
  const [serverError, setServerError] = React.useState<string | null>("");
  const [refreshTime, setRefreshTime] = React.useState(new Date().getTime());
  const [offset, setOffset] = React.useState<number>(0);
  const count = 100;

  const refresh = () => {
    setRefreshTime(new Date().getTime());
  };

  const deleteNote = async (noteIds: NoteId[]) => {
    try {
      const bundle = {
        entry: noteIds.map((id) => {
          return {
            request: {
              method: "DELETE",
              url: `Note/${id}`,
            },
          };
        }),
      } as BatchBundle;
      await adminQuartzClient.batchOperation(bundle);
      refresh();
    } catch (e: any) {
      setServerError(e.message);
    }
  };

  React.useEffect(() => {
    const getNotes = async () => {
      setLoading(true);
      try {
        const bundle = await noteQuartzClient.listNotes(
          count,
          offset,
          validationState,
          noteTypeCode,
          undefined,
          searchByCoding
        );
        setTotalEntries(bundle.total ? bundle.total : 0);
        setBundleId(bundle.id ? bundle.id : null);
        setLinks(bundle.link ? bundle.link : []);
        const listOfNotes = bundle.entry as Note[];
        const listOfPersons = (await personQuartzClient.listPersons(500, 0, null))
          .entry as Person[];
        setNotes(listOfNotes);
        setPersons(listOfPersons);
      } catch (e: any) {
        setServerError(e.message);
      } finally {
        setLoading(false);
      }
    };

    getNotes();
  }, [
    refreshTime,
    count,
    offset,
    validationState,
    noteTypeCode,
    searchByCoding,
    noteQuartzClient,
    personQuartzClient,
    adminQuartzClient,
    setBundleId,
  ]);

  return (
    <Box sx={{ p: 4 }}>
      <HexagonBackground color="rgb(234, 154, 62)" />
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Typography level="h2" sx={{ display: "inline-block" }}>
          <TitleStylizedUnderline color="rgb(234, 154, 62)">Notes</TitleStylizedUnderline>
        </Typography>
        <Stack direction="row" spacing={1}>
          <Link to={"/add-document"}>
            <Button variant="solid" startDecorator={<PublishIcon />} color="warning">
              Upload note
            </Button>
          </Link>
          <Link to={"/add-note"}>
            <Button variant="solid" startDecorator={<AddIcon />} color="warning">
              Add a note
            </Button>
          </Link>
        </Stack>
      </Stack>
      <Grid xs={12} sx={{ mt: 4 }}>
        {isLoading ? (
          <Box sx={{ height: "70vh", width: "100%", overflow: "hidden", position: "relative" }}>
            <TableSkeleton includeFilters />
          </Box>
        ) : (
          <Stack direction="column" spacing={2}>
            {notes && (
              <NoteFilters
                defaultStatus={validationState}
                defaultNoteType={noteTypeCode}
                searchByCoding={searchByCoding}
                onStatusChange={setValidationState}
                onNoteTypeChange={setNoteTypeCode}
                onSearch={setSearchByCoding}
              />
            )}
            {notes && notes.length !== 0 ? (
              <ListOfNotes deleteNotes={deleteNote} notes={notes} persons={persons} />
            ) : (
              <Box
                sx={{
                  height: "calc(100vh - 250px)",
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <img src={illustrationDoc} style={{ width: "350px" }} alt="illustration" />
                </div>
                <Box justifyContent="center" alignContent="center">
                  <Typography level="h1" color="neutral">
                    No note here (for the moment)
                  </Typography>
                  <Typography sx={{ mt: 4 }} level="body-md">
                    Notes can come from consult notes, discharge reports, etc.
                  </Typography>
                  <List marker="decimal">
                    <ListItem>Upload document (PDF, Word) or add a manual note</ListItem>
                    <ListItem>Explore the facts found by our algorithms</ListItem>
                    <ListItem>Add, modify, or delete facts found in the notes</ListItem>
                  </List>
                  <Stack sx={{ mt: 4 }} direction="row" spacing={1} justifyContent="center">
                    <Link to={"/add-document"}>
                      <Button variant="solid" startDecorator={<PublishIcon />} color="warning">
                        Upload document
                      </Button>
                    </Link>
                    <Link to={"/add-note"}>
                      <Button
                        variant="solid"
                        startDecorator={<AddIcon />}
                        color="warning"
                        data-cy="add-note-button"
                      >
                        Add a note
                      </Button>
                    </Link>
                  </Stack>
                </Box>
              </Box>
            )}
            {totalEntries > count && (
              <TablePagination
                totalEntries={totalEntries}
                pageSize={count}
                links={links}
                onPageChange={(offset) => {
                  setOffset(offset);
                  refresh();
                }}
              />
            )}
          </Stack>
        )}
      </Grid>
    </Box>
  );
};
